import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {
    BulkStartRecipeExecutionsResult,
    GetWorkflowDetailsResult
} from "@amzn/sm-workflow-orchestration-service-js-client/lib/smworkfloworchestrationservicelambda";
import {AjvError} from "@rjsf/core";
import {SelectProps} from "@amzn/awsui-components-react-v3/polaris/select/interfaces";
import {
    pleaseSelectLabel,
    PROGRAM_OPTIONS_DEFINITIONS,
    PROGRAM_SUBPROGRAM_MAP,
    TARGETED_WEBSITES
} from "src/constants/workflow-instance";
import {WorkflowInstanceUtil} from "src/utils/workflow-instance-util";
import {toast} from "react-toastify";
import {DomainTracker} from "src/model/DomainTracker";
import {UserInputError} from "src/components/common/error-message-helper";
import {BaseChangeDetail} from "@amzn/awsui-components-react-v3/polaris/input/interfaces";
import {useQuery} from "@tanstack/react-query";
import {hasJsonKey} from "src/utils/string-helper";

export const useStartScreenUtil = () => {
    const [schemaLoading, setSchemaLoading] = useState<boolean>(false)
    const [startWFLoading, setStartWFLoading] = useState<boolean>(false)
    const [workflowDetails, setWorkflowDetails] = useState<GetWorkflowDetailsResult>({})
    const [errorListMap, setErrorListMap] = useState<{ [key: string]: AjvError[] }>({})
    const [enableLiveValidate, setEnableLiveValidate] = useState<boolean>(false)
    const errorListMapTemp: { [key: string]: AjvError[] } = {}
    const [workflowInput, setWorkflowInput] = useState<{ [key: string]: any }>({})
    const [adhocStartRecipeExecutionsResult, setAdhocStartRecipeExecutionsResult] =
        useState<BulkStartRecipeExecutionsResult>({successfulRequests: {}})
    const itemsRef = useRef<Array<HTMLButtonElement | null>>([]);

    const [
        programSelectedOption,
        setProgramSelectedOption
    ] = React.useState<SelectProps.Option>(pleaseSelectLabel);

    const [subProgramOptions, setSubProgramOptions] = React.useState<Array<SelectProps.Option>>([pleaseSelectLabel]);
    const [selectedSubProgram, setSelectedSubProgram] = React.useState<SelectProps.Option>(pleaseSelectLabel);
    const [domainName, setDomainName] = React.useState<string>("");
    const [programOptions, setProgramOptions] = useState<Array<SelectProps.Option>>(PROGRAM_OPTIONS_DEFINITIONS);
    const [isContinuous] = React.useState<boolean>(true);

    const targetWebsiteOptionsDefinitions: Array<SelectProps.Option> = [...TARGETED_WEBSITES.map(targetWebsite => {
        return {
            label: targetWebsite,
            value: targetWebsite
        }
    })
    ]
    const [targetWebsite, setTargetWebsite] = React.useState<SelectProps.Option>(targetWebsiteOptionsDefinitions[0]);
    const [targetWebsiteOptions, setTargetWebsiteOptions] = useState<Array<SelectProps.Option>>(targetWebsiteOptionsDefinitions)


    const [isScheduled, setIsScheduled] = React.useState<boolean>(false);
    const [isScheduleDisabled, setIsScheduleDisabled] = React.useState<boolean>(true);
    const [scheduleName, setScheduleName] = React.useState<string>("");
    const [cronExpression, setCronExpression] = React.useState<string>("");

    /* istanbul ignore next */
    const {data: programDefaults, isLoading: workflowDefaultLoading, error: workflowDefaultLoadError} = useQuery({
        queryKey: [`getWorkflowVariant-${programSelectedOption?.label}-${selectedSubProgram?.value}`],
        queryFn: () => new DomainTracker().getWorkflowVariant(programSelectedOption?.label || '', selectedSubProgram?.value || ''),
        enabled: !!programSelectedOption?.value && !!selectedSubProgram?.value
    });

    useEffect(() => {
        itemsRef.current = itemsRef.current.slice(0, Object.keys(workflowDetails.stageInstanceInputSchemaMap || {}).length);
    }, []);

    useEffect(() => {
        programDefaults && setWorkflowInput(JSON.parse(programDefaults?.defaultInputJSON || "{}"));
    }, [programDefaults, setWorkflowInput])

    const workflowUISchema = useMemo(() => {
        return programDefaults?.defaultUiSchemaJSON && hasJsonKey(programDefaults?.defaultUiSchemaJSON) ? JSON.parse(programDefaults?.defaultUiSchemaJSON) : workflowDetails.startUiSchemaMap
    }, [programDefaults, workflowDetails])

    function retrieveSchemaForProgram(program?: string, isContinuous?: boolean) {
        const recipeProgram = WorkflowInstanceUtil.getProgramRecipe(program || "", isContinuous);
        if ((recipeProgram === undefined) || (recipeProgram === "")) {
            toast.error("Program name can not be blank")
            return
        }
        setSchemaLoading(true)
        new DomainTracker().getWorkflowDetails(recipeProgram)
            .then(resp => {
                itemsRef.current = itemsRef.current.slice(0, Object.keys(resp.stageInstanceInputSchemaMap || {}).length);
                setWorkflowDetails({
                    stageInstanceInputSchemaMap: resp.stageInstanceInputSchemaMap,
                    startUiSchemaMap: resp.startUiSchemaMap,
                    stages: resp.stages
                })
            })
            .catch(err => {
                console.error("Get Workflow Details Error", err)
                toast.error("Failed to retrieve workflow details")
                setWorkflowDetails({})
            })
            .then(() => {
                setSchemaLoading(false)
            })
    }

    /**
     * callback function responsible for updating component's internal state of user form input data(workflowInput).
     * The state value holds the form data entered by user for every field.
     * triggered when user updates any field in the input form.
     */
    const onTabFormDataChange = useCallback((formInput, tabId: string) => {
        setAdhocStartRecipeExecutionsResult({
            ...adhocStartRecipeExecutionsResult,
            failedRequests: undefined,
        })
        setWorkflowInput(prevWorkflowInput => ({...prevWorkflowInput, [tabId]: formInput.formData}))
    }, [
        setAdhocStartRecipeExecutionsResult,
        adhocStartRecipeExecutionsResult,
        setWorkflowInput,
        workflowInput])


    /**
     * callback function responsible for updating component's internal state for user form input errors(errorListMap).
     * The state value holds the errors for any invalid form field entry.
     * triggered when user updates any invalid entry in the input form.
     * The errors are collected on state & displayed to user collectively on form submission attempt.
     */
    const onTabFormDataError = useCallback((err: AjvError[], tabId: string) => {
        errorListMapTemp[tabId] = err
        setErrorListMap(errorListMapTemp)
    }, [errorListMapTemp, setErrorListMap])

    const onTabDataChangeHandler = (tabId: string, tabIndex: number, callback: () => void) => {
        delete errorListMapTemp[tabId]
        setErrorListMap(errorListMapTemp)
        if (tabIndex + 1 == Object.keys(workflowDetails.stageInstanceInputSchemaMap || {}).length) {
            if (Object.keys(errorListMapTemp).length == 0) {
                callback();
            }
        }
    }

    const onFormSubmitHandler = (callback: () => void) => {
        setEnableLiveValidate(true)
        const stageInstanceInputSchemaMap = Object.entries(workflowDetails.stageInstanceInputSchemaMap || {});
        if (stageInstanceInputSchemaMap.length) {
            stageInstanceInputSchemaMap.forEach(([key, value], index) => {
                itemsRef.current[index]?.click();
            })
        } else {
            callback();
        }
    }

    const errorText = React.useMemo(() => {
        return (Object.keys(errorListMap).length > 0
            || Object.keys(adhocStartRecipeExecutionsResult.failedRequests || {}).length > 0) && <> {
            <div>
                <UserInputError errorListMap={errorListMap}/>
                {Object.entries(adhocStartRecipeExecutionsResult.failedRequests || {}).map(([key, value]) => {
                    return <p>{value}</p>
                })}
            </div>
        } </>
    }, [errorListMap, adhocStartRecipeExecutionsResult.failedRequests])

    const onProgramChangeHandler = useCallback((detail: SelectProps.ChangeDetail) => {
        setProgramSelectedOption(detail.selectedOption)
        setIsScheduleDisabled(WorkflowInstanceUtil.isProgramScheduleDisabled(detail.selectedOption))
        retrieveSchemaForProgram(detail.selectedOption.label, isContinuous)
        const subProgramOptions: Array<SelectProps.Option> = []

        // @ts-ignore
        const subPrograms = PROGRAM_SUBPROGRAM_MAP[detail.selectedOption.label] as Array<string>
        subPrograms.forEach(subProgram => {
            subProgramOptions.push({value: subProgram, label: subProgram})
        })
        setSubProgramOptions(subProgramOptions)
        setSelectedSubProgram(subProgramOptions[0])
    }, [
        setProgramSelectedOption,
        setSubProgramOptions,
        setSelectedSubProgram,
        isContinuous,
        retrieveSchemaForProgram,
        setIsScheduleDisabled
    ])

    const resetFormValues = useCallback(() => {
        setWorkflowInput({})
        setProgramSelectedOption(pleaseSelectLabel)
        setScheduleName("")
        setCronExpression("")
        setSubProgramOptions([])
        setSelectedSubProgram(pleaseSelectLabel)
    }, [
        setWorkflowInput,
        setProgramSelectedOption,
        setScheduleName,
        setCronExpression,
        setSubProgramOptions,
        setSelectedSubProgram
    ])

    const onSubProgramChangeHandler = useCallback((detail: SelectProps.ChangeDetail) => {
        setSelectedSubProgram(detail.selectedOption)
    }, [setSelectedSubProgram])

    const onDomainChangeHandler = useCallback((detail: BaseChangeDetail) => {
        setDomainName(detail.value.trim())
    }, [setDomainName])

    const onTargetWebsiteChange = useCallback((detail: SelectProps.ChangeDetail) => {
        setTargetWebsite(detail.selectedOption)
    }, [setTargetWebsite])

    const onScheduleNameChange = useCallback((scheduleName: string) => {
        setScheduleName(scheduleName)
    }, [setScheduleName])

    const onCronExpressionChange = useCallback((cronExpression: string) => {
        setCronExpression(cronExpression)
    }, [setCronExpression])

    const onScheduleStatusChange = useCallback((scheduleStatus: boolean) => {
        setIsScheduled(scheduleStatus);
    }, [setIsScheduled])

    const updateWorkflowLoadingStatus = useCallback((workflowLoadingStatus: boolean) => {
        setStartWFLoading(workflowLoadingStatus);
    }, [setStartWFLoading])

    const updateAdhocStartRecipeExecutionsResult = useCallback((adhocStartRecipeExecutionsResult: BulkStartRecipeExecutionsResult) => {
        setAdhocStartRecipeExecutionsResult(adhocStartRecipeExecutionsResult);
    }, [setAdhocStartRecipeExecutionsResult])

    return {
        schemaLoading,
        startWFLoading,
        workflowDetails,
        enableLiveValidate,
        workflowInput,
        adhocStartRecipeExecutionsResult,
        programSelectedOption,
        subProgramOptions,
        selectedSubProgram,
        domainName,
        programOptions,
        isContinuous,
        targetWebsite,
        targetWebsiteOptions,
        workflowUISchema,
        workflowDefaultLoading,
        workflowDefaultLoadError,
        retrieveSchemaForProgram,
        onTabFormDataChange,
        onTabFormDataError,
        errorText,
        itemsRef,
        errorListMap,
        onProgramChangeHandler,
        onSubProgramChangeHandler,
        onDomainChangeHandler,
        onTargetWebsiteChange,
        isScheduled,
        isScheduleDisabled,
        scheduleName,
        cronExpression,
        onScheduleNameChange,
        onCronExpressionChange,
        onScheduleStatusChange,
        resetFormValues,
        updateWorkflowLoadingStatus,
        updateAdhocStartRecipeExecutionsResult,
        onTabDataChangeHandler,
        onFormSubmitHandler
    }
}
