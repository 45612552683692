import { Alert, Box, Button, Container, Header, Link, Spinner } from "@amzn/awsui-components-react-v3";
import Form, { ISubmitEvent } from "@rjsf/core";
import "bootstrap/dist/css/bootstrap.min.css";
import * as Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { WorkitemDetails } from "src/interfaces/wim";
import { WorkitemManager } from "src/model/WorkitemManager";
import { MIDWAY_AUTH_USERNAME } from "src/utils/cookie-helper";
import { getWorkForceTypeIdentifierUrl } from "src/utils/wim";
// import { GetWorkItemRequest, GetWorkItemResult} from "@amzn/aravip-test-wim-lambda-client/lib/smswoworkitemmanagerlambda"

export default function WIMOutputContent() {
  const [loading, setLoading] = useState(true);
  const [outputSchema, setOutputSchema] = useState({});
  const [output, setOutput] = useState({});
  const [isWorkitemIdFound, setIsWorkitemIdFound] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [workitemStatus, setWorkitemStatus] = useState("");
  const [workForceTypeIdentifier, setWorkForceTypeIdentifier] = useState("");
  const [workForceTypes, setWorkForceTypes] = useState([] as Array<string>);
  const routeParam: any = useParams();
  const workitemId = routeParam["workitemId"];
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);

  useEffect(() => {
    async function retrieveWorkitem() {
      const wim = new WorkitemManager();
      await wim
        .getWorkitemDetails(workitemId)
        .then((resp) => {
          const workitemDetails: WorkitemDetails = resp.data;
          setWorkitemStatus(workitemDetails.status);
          setWorkForceTypeIdentifier(workitemDetails.workForceTypeIdentifier);
          delete workitemDetails.workForce["workForceId"];
          setWorkForceTypes(Object.keys(workitemDetails.workForce));
          if (workitemDetails.outputSchema != undefined && workitemDetails.outputSchema != "") {
            try {
              setOutputSchema(JSON.parse(workitemDetails.outputSchema));
            } catch (error) {
              toast.error("Error while parsing json output schema");
              console.log(error);
            }
          }
          if (workitemDetails.output != undefined && workitemDetails.output != "") {
            try {
              setOutput(JSON.parse(workitemDetails.output));
            } catch (error) {
              toast.error("Error while parsing output");
              console.log(error);
            }
          }
        })
        .catch((error) => {
          setIsWorkitemIdFound(false);
          if (error.response == undefined) {
            setErrorMessage(error.reason);
          } else {
            setErrorMessage(error.response.data.message);
          }
        });
      setLoading(false);
    }
    retrieveWorkitem();
  }, []);

  async function handleJsonSchemaSubmit(e: ISubmitEvent<any>) {
    const username = Cookies.get(MIDWAY_AUTH_USERNAME);
    if (username == undefined) {
      toast.error("Failed to retrieve username from cookie. Please refresh the page and try again");
      return;
    }
    setSubmitButtonLoading(true);
    await new WorkitemManager()
      .outputWorkitem(e.formData, workitemId, username)
      .then(() => {
        toast.success("Updated WIM output");
        setOutput(e.formData);
      })
      .catch((error) => toast.error(error.message))
      .then(() => setSubmitButtonLoading(false));
  }

  return (
    <>
      <Container
        header={
          <Header variant="h2">
            {workitemId} - {workitemStatus}
          </Header>
        }
      >
        {loading ? (
          <Spinner size="normal" />
        ) : (
          <>
            {!isWorkitemIdFound ? (
              <Alert header="Error" type="error" className="wim-not-found-error">
                {JSON.stringify(errorMessage)}
              </Alert>
            ) : (
              <>
                {workitemStatus == "COMPLETED" ? (
                  <>
                    <Alert type="info">Workitem status is {workitemStatus}</Alert>
                    <Box variant="h3">Output</Box>
                    <pre>{JSON.stringify(output, null, 2)}</pre>
                    <Box variant="h3">Schema</Box>
                    <pre>{JSON.stringify(outputSchema, null, 2)}</pre>
                  </>
                ) : (
                  <ErrorBoundary
                    FallbackComponent={() => (
                      <Alert header="Error" type="error">
                        Invalid/Unsupported JSON schema. Please contact SWO team sm-swo@amazon.com
                      </Alert>
                    )}
                  >
                    <>
                      <Alert header="SIM TT" type="info">
                        {workForceTypes.map((workForceType) => {
                          return (
                            <div key={workForceType}>
                              <Link
                                external
                                externalIconAriaLabel="Opens in a new tab"
                                className="wim-tt-link"
                                href={getWorkForceTypeIdentifierUrl(workForceType, workForceTypeIdentifier)}
                              >
                                Click here
                              </Link>{" "}
                              to go back to {workForceType}
                            </div>
                          );
                        })}
                      </Alert>
                      <Box variant="div" margin={{ top: "l" }}>
                        <Form schema={outputSchema} formData={output} onSubmit={handleJsonSchemaSubmit}>
                          <Button variant="primary" loading={submitButtonLoading}>
                            Submit
                          </Button>
                        </Form>
                      </Box>
                    </>
                  </ErrorBoundary>
                )}
              </>
            )}
          </>
        )}
      </Container>
    </>
  );
}
