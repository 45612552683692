export const sortPrimaryArrayBySecondaryArrayOrder = <T>(primaryArray: T[], secondaryArray: T[] | null | undefined) => {
    const indexMap = new Map();
    let result = primaryArray;
    if(secondaryArray){
        secondaryArray.forEach((el, index) => indexMap.set(el, index));
        result =[...primaryArray
            .sort((a, b) =>
                (indexMap.get(a) >= 0 ? indexMap.get(a) : secondaryArray.length)
                - (indexMap.get(b) >= 0 ? indexMap.get(b): secondaryArray.length))];
    }
    return result;
}

export const sortArrayAlphabetically =  (array: string[]) => {
    return array.sort((a, b) => a < b ? -1 : 1);
}
