import React from "react";
import {ExecutionAttemptsPageRouterParams} from "src/interfaces/execution-attempts";

/**
 * Header for execution attempts page.
 * @param props
 * @constructor
 */
export function ExecutionAttemptsPageHeader(props: ExecutionAttemptsPageRouterParams) {
    return <div className="awsui-util-mb-m awsui-util-mt-xs" id={"execution-attempts-page-header-container"}>
        <div className="awsui-util-action-stripe-large">
            <div className="awsui-util-action-stripe-title">
                <h1>Execution {props.workflowId}</h1>
            </div>
        </div>
    </div>
}