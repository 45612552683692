import React, {ReactNode, useEffect, useState} from "react";
import {MidwayAuth} from "src/utils/auth/midway-auth";
import {Spinner} from "@amzn/awsui-components-react-v3";
import {getUserAlias} from "src/utils/cookie-helper";

interface AuthenticationI {
    children: ReactNode
}
/**
 * Provides Authentication mechanism for all components.
 * @param props
 * @constructor
 */
export function Authentication(props: AuthenticationI) {
    const [authenticated, setAuthenticated] = useState(false)
    useEffect(() => {
        async function authenticate() {
            await MidwayAuth.signIn() // Sign in or redirect to midway for authentication
            setAuthenticated(true)

            const authenticatedUserEvent = new CustomEvent("cathode-customerId", {
                detail: {
                    customerId: getUserAlias(),
                },
            });
            document.dispatchEvent(authenticatedUserEvent);

        }
        authenticate();
    }, []);
    return (<>
        {authenticated ? <>{props.children}</> : <>
            <div className="awsui"><Spinner size={"large"}/>Authenticating...</div>
        </>}
    </>)
}