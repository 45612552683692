
import {CollectionPreferencesProps} from "@amzn/awsui-components-react-v3/polaris/collection-preferences/interfaces";
import {TableTypes} from "src/interfaces/domain-tracker";
import {TableMetadataInterface} from "src/interfaces/server-side-table";
import {UserTablePreferences} from "src/components/common/table-user-preferences";
import {CollectionPreferences} from "@amzn/awsui-components-react-v3";
import React from "react";
import {PAGE_SIZE_OPTIONS} from "src/components/table/table-config";

/*
 * Preference component to store visible columns, rows count and wrap lines
 */
export const Preferences = ({
                                disabled,
                                visibleContentOptions,
                                tableType,
                                tableMetadata,
                                handlePreferenceChange
                            }: {
    disabled: boolean
    visibleContentOptions: ReadonlyArray<CollectionPreferencesProps.VisibleContentOptionsGroup>
    tableType: TableTypes
    tableMetadata: TableMetadataInterface,
    handlePreferenceChange: any
}) => {
    const userTablePreference = new UserTablePreferences(tableType)
    const visibleContent = userTablePreference.getTablePreference().visibleColumns
    if (visibleContent.length == 0) {
        Object.entries(tableMetadata).forEach(([key, columnMetadata]) => {
            if (columnMetadata.uiFeatures.visibleOnLoad) visibleContent.push(key)
        })
    }
    const collectionPref: CollectionPreferencesProps.Preferences = {
        pageSize: userTablePreference.getTablePreference().size,
        wrapLines: userTablePreference.getTablePreference().wrapLines,
        visibleContent: visibleContent
    }
    return (
        <CollectionPreferences
            data-testid={"collection-preference-testid"}
            title="Preferences"
            confirmLabel="Confirm"
            cancelLabel="Cancel"
            disabled={disabled}
            preferences={collectionPref}
            onConfirm={({detail}) => {
                handlePreferenceChange(detail)
            }}
            pageSizePreference={{
                title: 'Page size',
                options: PAGE_SIZE_OPTIONS
            }}
            wrapLinesPreference={{
                label: 'Wrap lines',
                description: 'Check to see all the text and wrap the lines'
            }}
            visibleContentPreference={{
                title: 'Select visible columns',
                options: visibleContentOptions
            }}
        />
    )
}