import {SMSWOUnifiedDashboardServiceLambda} from "@amzn/swo-unified-dashboard-service-lambda-js-client";
import {TableTypes} from "src/interfaces/domain-tracker";
import React from "react";
import {CancelableEventHandler} from "@amzn/awsui-components-react-v3/polaris/internal/events";
import {ES_SEARCH_TYPES} from "src/constants/elastic-search";
import {
    ColumnMetadata,
    DataObjectColumns
} from "@amzn/swo-unified-dashboard-service-lambda-js-client/lib/smswounifieddashboardservicelambda";

export enum BubbleEditInputTypes {
    INPUT_TEXT,
    INPUT_TEXT_AREA,
    DATE,
    BOOLEAN,
    SELECT,
    MULTI_SELECT,
    DATE_RANGE
}

export enum TableTopFilterInputTypes {
    DATE,
    SELECT
}
// Table metadata for columns definition.
export interface TableMetadataInterface extends DataObjectColumns{
    // [key: string]: TableColumnDefinition;
}

export interface BubbleEditInterface {
    rowItem: SMSWOUnifiedDashboardServiceLambda.Types.Details
    columnKey: string;
    children: React.ReactNode,
    tableMetadata: TableMetadataInterface
}

export interface EditingFormFieldI {
    handleValueChange: any,
    columnKey: string,
    value: string,
    columnDefinition: TableColumnDefinition,
    modalVisible: boolean
}

export interface GetCounterI {
    counter?: number | string
    serverSide: boolean
    totalItems: Array<any>
    selectedItems: Array<any>
    totalItemCount: number
}

export interface TableHeaderI extends GetCounterI {
    title: string,
    updateTools: CancelableEventHandler | null,
    actionButtons: React.ReactNode
    description: React.ReactNode
}

export interface ServerSideTableHeaderI extends TableHeaderI{
    visible: boolean
    tableMetadata: TableMetadataInterface
    handleRefresh: any
    tableType: TableTypes
}

// Simply extending smithy generated interface. Will add more details if needed.
export interface TableColumnDefinition extends ColumnMetadata {

}
