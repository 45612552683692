import * as React from "react";
import {Modal} from "@amzn/awsui-components-react-v3";
import Box from "@amzn/awsui-components-react-v3/polaris/box";
import Button from "@amzn/awsui-components-react-v3/polaris/button";
import {StageInstanceEventsI} from "src/interfaces/workflow-instance-detail";
import ReactJson from "react-json-view";
import {SwoUiMetrics} from "src/utils/swo-ui-metrics/swo-ui-metrics";
import {metricFeaturesList, metricPageNames} from "src/constants/swo-ui-metric-constants";

export function StageInstanceEvents(props: StageInstanceEventsI) {
    const [visible, setVisible] = React.useState(false);

    return <>
        {visible && <Box data-testid={"stage-instance-events-modal-box"}
            >
            <Modal
            className={"stage-instance-events-modal"}
            data-testid={"stage-instance-events-modal"}
            onDismiss={() => setVisible(false)}
            visible={visible}
            closeAriaLabel="Close modal"
            size="medium"
            footer={
            <Box float="right">
            <Button variant="primary" onClick={() => setVisible(false)}>Close</Button>
            </Box>
        }
            header="Events"
            >
            <ReactJson src={props.events} collapsed={Object.keys(props.events).length > 5} enableClipboard={false}
            displayDataTypes={false}
            displayObjectSize={true}
            iconStyle={"triangle"}
            theme={"rjv-default"}
            name={false}

            />
            </Modal>
            </Box>}
        <Button onClick={() => {
            SwoUiMetrics.publishCloudWatchCountMetric(metricFeaturesList.showEventsPopup, metricPageNames.workflowDetailView)
            setVisible(true)
        }}>Show Events ({Object.keys(props.events).length})</Button>
    </>
}