import React from "react"
import {ServiceNavigation} from "src/components/navigation";
import {appLayoutNavigationLabels} from "src/common/labels";
import AppLayout from "@amzn/awsui-components-react-v3/polaris/app-layout";
import {ReleaseNotesContent} from "src/components/release-notes/content";
import {AppRoutes, BreadcrumbGroupWithRouter, RouteLabels} from "src/components/common/router-common";

export function ReleaseNotes() {
  const bcItems = [
    {
      "text": "Home",
      "href": "/"
    },
    {
      "text": RouteLabels.releaseNotes,
      "href": AppRoutes.releaseNotes
    },

  ]

  return <AppLayout
    navigation={<ServiceNavigation/>}
    content={<ReleaseNotesContent numberOfNotes={100}/>}
    contentType="default"
    disableContentPaddings={false}
    toolsHide={true}
    ariaLabels={appLayoutNavigationLabels}
    breadcrumbs={<BreadcrumbGroupWithRouter items={bcItems}/>}
  />

}