import {TableColumnDefinition} from "src/interfaces/server-side-table";
import {InputProps, SelectProps} from "@amzn/awsui-components-react-v3";
import {ES_SEARCH_TYPES} from "src/constants/elastic-search";
import {NonCancelableCustomEvent} from "@amzn/awsui-components-react-v3/polaris/internal/events";
import produce from "immer";
import {Range} from "@amzn/polaris-date-time-range";
import {MultiselectProps} from "@amzn/awsui-components-react-v3/polaris/multiselect/interfaces";
import {AttributeFilterValue} from "src/components/filters/attribute-filter-value";
import React from "react";
import {TableTypes} from "src/interfaces/domain-tracker";
import {AttributeFilterItemsI, FilterOperation} from "src/interfaces/attribute-filters";

/*
 * Find filtering operation based on column values.
 */
export const findFilteringOpertion = (metadata: TableColumnDefinition): Array<SelectProps.Option> => {
    const filterOperations: Array<FilterOperation> = []
    if (metadata.dataType == ES_SEARCH_TYPES.KEYWORD)
        filterOperations.push(FilterOperation.EQUALS, FilterOperation.NOT_EQUALS, FilterOperation.EXISTS, FilterOperation.NOT_EXISTS)
    else if (metadata.dataType == ES_SEARCH_TYPES.TEXT)
        filterOperations.push(FilterOperation.EQUALS, FilterOperation.NOT_EQUALS)
    else if (metadata.dataType == ES_SEARCH_TYPES.DATE)
        filterOperations.push(FilterOperation.BETWEEN, FilterOperation.EXISTS, FilterOperation.NOT_EXISTS)
    return filterOperations.map(filterOperation => {
        return {label: filterOperation, value: filterOperation}
    })
}

/*
 * Common component for filter attribute value for bother primary and secondary filters.
 */
export const CommonAttrValue = (items: AttributeFilterItemsI[], item: AttributeFilterItemsI, tableType: TableTypes, setItems: any, itemIndex: number, onSubmitAttributeFilter: any) => {
    return <AttributeFilterValue condition={item.condition} value={item.value}
                                 attributeName={item.attributeName}
                                 columnDefinition={item.columnDefinition}
                                 tableType={tableType}
                                 handleTextFieldInputChange={(e: NonCancelableCustomEvent<InputProps.ChangeDetail>) => {
                                     const updatedItems: Array<AttributeFilterItemsI> = produce(items, (draft: Array<AttributeFilterItemsI>) => {
                                         draft[itemIndex].value = e.detail.value

                                     })
                                     setItems(updatedItems)
                                     onSubmitAttributeFilter(updatedItems)
                                 }}
                                 handleDateRangeChange={(e: Range) => {
                                     const updatedItems: Array<AttributeFilterItemsI> = produce(items, (draft: Array<AttributeFilterItemsI>) => {
                                         draft[itemIndex].value = e
                                     })
                                     setItems(updatedItems)
                                     onSubmitAttributeFilter(updatedItems)
                                 }}
                                 handleMultiSelectChange={(e: NonCancelableCustomEvent<MultiselectProps.MultiselectChangeDetail>) => {
                                     const updatedItems: Array<AttributeFilterItemsI> = produce(items, (draft: Array<AttributeFilterItemsI>) => {
                                         draft[itemIndex].value = e.detail.selectedOptions.map(so => so.value as string)
                                     })
                                     setItems(updatedItems)
                                     onSubmitAttributeFilter(updatedItems)
                                 }}
    />
}