import {APIGWInterface} from "src/utils/api-gateway/mappings";
import {STAGES} from "src/utils/app-constants";

export const unifiedDashboardAPIConfigAlpha: APIGWInterface = {
    endpoint: "kodxm0l56d.execute-api.us-west-2.amazonaws.com",
    stage: "alpha",
    iam: "arn:aws:iam::199711326154:role/SM-SWO-UI-Alpha",
    region: "us-west-2",
}

export const unifiedDashboardAPIConfigBeta: APIGWInterface = {
    endpoint: "frs8wzl75j.execute-api.us-west-2.amazonaws.com",
    stage: "beta",
    iam: "arn:aws:iam::795619029800:role/SM-SWO-UI-Beta",
    region: "us-west-2",
}

export const unifiedDashboardAPIConfigGamma: APIGWInterface = {
    endpoint: "n15l0zdqwj.execute-api.us-east-1.amazonaws.com",
    stage: "gamma",
    iam: "arn:aws:iam::238292326407:role/SM-SWO-UI-Gamma",
    region: "us-east-1",
}

export const unifiedDashboardAPIConfigProd: APIGWInterface = {
    endpoint: "0cvk30kha3.execute-api.us-east-1.amazonaws.com",
    stage: "prod",
    iam: "arn:aws:iam::092773271534:role/SM-SWO-UI-Prod",
    region: "us-east-1",
}


// Get Unified Dashboard APIGW Endpoint, Stage, IAM and Region
export function unifiedDashboardAPIConfig(stage: string): APIGWInterface {
    let apiConfig;

    switch (stage) {
        case STAGES.PROD.toLowerCase():
            apiConfig = unifiedDashboardAPIConfigProd;
            break;
        case STAGES.GAMMA.toLowerCase():
            apiConfig = unifiedDashboardAPIConfigGamma;
            break;
        case STAGES.BETA.toLowerCase():
            apiConfig = unifiedDashboardAPIConfigBeta;
            break;
        default:
            apiConfig = unifiedDashboardAPIConfigAlpha;
    }
    return apiConfig;
}
