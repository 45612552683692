import { STAGES } from "src/utils/app-constants";

export enum HttpMethod {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  OPTIONS = "OPTIONS",
}

export interface APIGWInterface {
  endpoint: string;
  stage: string;
  iam: string;
  region: string;
}

export interface HttpDetails {
  path: string;
  method: HttpMethod;
}

interface APIGatewayPaths {
  outputWorkitem: HttpDetails;
  getWorkitem: HttpDetails;
}

// Alpha config
export const alphaWIMAPIConfig: APIGWInterface = {
  endpoint: "jdx6ot5zre.execute-api.us-west-2.amazonaws.com",
  stage: "alpha",
  iam: "arn:aws:iam::199711326154:role/SM-SWO-UI-Alpha",
  region: "us-west-2",
};

// Beta config
export const betaWIMAPIConfig: APIGWInterface = {
  endpoint: "kqxl2gplr1.execute-api.us-west-2.amazonaws.com",
  stage: "beta",
  iam: "arn:aws:iam::795619029800:role/SM-SWO-UI-Beta",
  region: "us-west-2",
};

// Gamma config
export const gammaWIMAPIConfig: APIGWInterface = {
  endpoint: "g2gajuxxra.execute-api.us-east-1.amazonaws.com",
  stage: "gamma",
  iam: "arn:aws:iam::238292326407:role/SM-SWO-UI-Gamma",
  region: "us-east-1",
};

// Prod config
export const prodWIMAPIConfig: APIGWInterface = {
  endpoint: "iotjioo0x7.execute-api.us-east-1.amazonaws.com",
  stage: "prod",
  iam: "arn:aws:iam::092773271534:role/SM-SWO-UI-Prod",
  region: "us-east-1",
};


// WIM API path and access method(GET, POST, PUT)
export const apiGatewayPaths: APIGatewayPaths = {
  outputWorkitem: { path: "/v0/workitem/output", method: HttpMethod.PUT },
  getWorkitem: { path: "/v0/workitem", method: HttpMethod.GET },
};


// Get Workitem Manager endpoint, stage, iam and region
export function wimAPIConfig(stage: string): APIGWInterface {
  let apiConfig;

  switch (stage) {
    case STAGES.PROD.toLowerCase():
      apiConfig = prodWIMAPIConfig;
      break;
    case STAGES.BETA.toLowerCase():
      apiConfig = betaWIMAPIConfig;
      break;
    case STAGES.GAMMA.toLowerCase():
      apiConfig = gammaWIMAPIConfig;
      break;
    default:
      apiConfig = alphaWIMAPIConfig;
  }
  return apiConfig;
}

