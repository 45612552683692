import React, {useState} from "react";
import {Button, Link, Popover} from "@amzn/awsui-components-react-v3";
import {buildExecutionViewUrl} from "src/components/sm-overview-dashboards/workflow-list-view/workflow-list-view.utils";
import {AppRoutes} from "src/components/common/router-common";

export const ExecutionViewRedirectionLink = ({filterQuery}: {filterQuery: string}) => {
    const [loading, setLoading] = useState(false);

    const onClickHandler = async () => {
        setLoading(true);
        const executionViewEsQuery = await buildExecutionViewUrl(filterQuery);
        setLoading(false);
        window.open(`${AppRoutes.executionList}?esQuery=${encodeURIComponent(executionViewEsQuery)}`, '_blank');
    }

    return <>
        <Button
            loading={loading}
            onClick={onClickHandler}
            iconName="external"
            data-testid="execution-view-redirection"
        >
            {`Act on Workflows`}
        </Button>
        <p style={{margin: '8px'}}>
            <Popover
                dismissButton={true}
                position="top"
                size="small"
                triggerType="custom"
                content={
                    <p>
                        {'This action enables user to act on top workflows filterd by list by redirecting user' +
                            'to execution view and fetching top 150 records'}
                    </p>
                }
            >
                <Link variant="info">Info</Link>
            </Popover>
        </p>
    </>
}
