import React from 'react';
import Button from '@amzn/awsui-components-react/polaris/button';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {BreadcrumbGroup} from "@amzn/awsui-components-react";
import {RouterBreadcrumbGroupInterface} from "src/interfaces/common";

/**
 * Button to navigate to another page(without reloading entire content) using react router.
 * @param props
 */
const RouterButton = (props: Button.Props & RouteComponentProps<{}>) => {
    return (
        <>
            <Button
                {...props}
                text={undefined}
                onClick={(ev) => {
                    ev.preventDefault();
                    if (ev.detail.metaKey || (props.icon == "external")) {
                        window.open(props.href as string, "_blank")
                    } else {
                        props.history.push(props.href as string);
                    }
                }
                }
            > {props.text} </Button>
        </>
    );
};
export const ButtonWithRouter = withRouter(RouterButton);

/**
 * Routes for all pages. All component should use this for creating links.
 * So that we can change URL without updating multiple components in future.
 */
export const AppRoutes = {
    home: `/`,
    wimOutput: (workitemId: string) => `/wim/output/${workitemId}`,
    wimSummary: `/wim/summary`,
    executionList: `/ud/executions`,
    executionDetail: (executionId: string) => `/ud/executions/${executionId}`,
    executionAttempts: (executionId: string) => `/ud/executions/${executionId}/attempts`,
    virtualPipelines: `/virtual-pipelines`,
    executionSummary: `/ud/executions-summary`,
    executionSummaryRedirect: `/ud/executions-summary/redirect`,
    releaseNotes: `/release-notes`,
    bulkStartWorkflowInstance: '/workflow/bulk-start',
    startWorkflowInstance: '/workflows/start',
    programSummary: '/program-summary',
    programSummaryDetailFailure: '/program-summary-detail/failure',
    programSummaryDetailRunning: '/program-summary-detail/running',
    workflowList: '/workflow-list',
    productSummary: '/product-summary'
}


/**
 * Add any external links here like wiki pages, kibana dashboard links.
 */
export const ExternalRoutes = {
    acbpAutomatedPipelineSummary: `https://search-eventsourceres-ycoy252zx3d3siugfkqxbgp3yq.us-east-1.es.amazonaws.com/_plugin/kibana/goto/ee4aa126308d471b1773e9220839003b`,
    e2ePipelineWorkitemSummary: `https://search-eventsourceres-ycoy252zx3d3siugfkqxbgp3yq.us-east-1.es.amazonaws.com/_plugin/kibana/goto/998005c0bcfc1c47eb6153ed76fecd11`,
    swoDashboardUserGuide: `https://quip-amazon.com/DyGNAHvc8bjy/User-Guide-for-SWO-Dashboard-WorkItems`,
    subStageLevelCycleTimeDashboard: `https://w.amazon.com/bin/view/QuickSightSampleDashboard`,
    stageLevelCycleTimeDashboard: `https://search-eventsourceres-ycoy252zx3d3siugfkqxbgp3yq.us-east-1.es.amazonaws.com/_plugin/kibana/goto/6e9e6284c0b0f219952c4aabac79fd66`,
    upcomingReleases: 'https://quip-amazon.com/yHeGA3sNVSBB/Untitled#YQM9CAxbBHH',
    workflowInstanceSIM: (workflowInstanceId: string) => `https://t.corp.amazon.com/issues?q=%20currentSeverity%3A%281%20OR%202%20OR%203%20OR%204%20OR%205%20OR%202.5%20OR%20N%29%20AND%20tags%3A%22WorkflowInstanceId%3D${workflowInstanceId}%22`,
    smDashboardFormulaSheet: 'https://quip-amazon.com/GAsyA1U4cmOC/Page-0-Metrics-formulas',
    rorDeepDiveWikiDashboard: 'https://w.amazon.com/bin/view/RoRDeepDiveDashboard/',
    workflowCycleTimeDeepDive: 'https://us-east-1.quicksight.aws.amazon.com/sn/dashboards/28f8381e-ad95-4560-aa01-d80b8783d876?#',
    failureAnalysis: 'https://us-east-1.quicksight.aws.amazon.com/sn/dashboards/ea8cd8ea-7f84-46b1-abdf-f4bd5d465e09?#',
    runningWorkflowDiveDeep: 'https://w.amazon.com/bin/view/RunningWorkflowDashboard#HRunningworkflowQuicksightDashboard'
}

export const RouteLabels = {
    e2ePipelineWorkitemSummary: `E2E Pipeline Workitems Summary`,
    workflowInstanceList: `Workflow Instance List`,
    workflowInstanceSummary: `Aggregate Workflow Instance Summary`,
    acbpAutomatedPipelineSummary: `Aggregate Workflow Instance Summary - Kibana`,
    stageLevelCycleTimeDashboard: `Stage level cycle time`,
    subStageLevelCycleTimeDashboard: `Sub-stage level cycle time`,
    releaseNotes: "Release Notes",
    bulkOnboarding: "Bulk Onboarding"
}

/**
 * App route params value.
 */
export const AppRouteParams = {
    executionDetail: {
        workflowId: "workflowId"
    },
    wimOutput: {
        workitemId: "workitemId"
    }
}

/**
 * BreadcrumbGroup to navigate to another page(without reloading entire content) using react router.
 * @param props
 */
const BreadcrumbGroupRouter = (props: RouterBreadcrumbGroupInterface) => {
    return <><BreadcrumbGroup items={props.items} onClick={(ev) => {
        ev.preventDefault();
        props.history.push(ev.detail.href);
    }
    }/></>
}
export const BreadcrumbGroupWithRouter = withRouter(BreadcrumbGroupRouter);
