import React, {useEffect, useState} from 'react';
import {SelectedItemsInterface} from "src/interfaces/domain-tracker";
import Button from "@amzn/awsui-components-react/polaris/button";
import {convertArrayToTableSelect, getStartDateAndEndDate} from "src/utils/table-helper";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import Select from "@amzn/awsui-components-react/polaris/select";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import {UpdateDelayAlert} from "src/components/common/user-alerts"
import {
    EXECUTION_ID,
    SWO_ORCHESTRATOR_SERVICE_CLIENT_ID
} from "src/constants/domain-tracker";
import {SMSWOUnifiedDashboardServiceLambda} from "@amzn/swo-unified-dashboard-service-lambda-js-client";
import {DomainTracker} from "src/model/DomainTracker";
import {v1 as uuidv1} from 'uuid';
import {getUserAlias} from "src/utils/cookie-helper";
import {
    GetRecipeExecutionDetailsResult,
} from "@amzn/sm-workflow-orchestration-service-js-client/lib/smworkfloworchestrationservicelambda";
import {SwoUiMetrics} from "src/utils/swo-ui-metrics/swo-ui-metrics";
import {metricFeaturesList, metricPageNames} from "src/constants/swo-ui-metric-constants";
import {logger} from "src/utils/logger";
import {Alert, Box, Input, SpaceBetween} from "@amzn/awsui-components-react-v3";
import ButtonV3 from "@amzn/awsui-components-react-v3/polaris/button";
import {toast} from "react-toastify";
import {Spinner} from "@amzn/awsui-components-react";
import DateTimeRange, {Range, Timezone, useDateTimeRange} from "@amzn/polaris-date-time-range";
import {REPUBLISH_ALLOWED_STAGES} from "src/constants/workflow-instance";
import {
    checkIfInputIsCamelCase,
    convertCamelCaseStringToWords,
    convertCharacterSeperatedStringToWords
} from "src/utils/string-helper";
import {TableProgressBar, useProgressBar} from "src/components/common/table-progress-bar";

/**
 * Republish batches with option to select data stage
 * @param props
 * @constructor
 */
export function ExecutionRepublishModal(props: SelectedItemsInterface) {
    const [selectedItems, setSelectedItems] = useState<Array<SMSWOUnifiedDashboardServiceLambda
        .Types.Details>>(props.selectedItems);
    const [republishReason, setRepublishReason] = useState<string>("");
    const [loadingWorkFlowDetails, setLoadingWorkFlowDetails] = useState<boolean>(false);
    const [showRepublishModal, setShowRepublishModal] = useState<boolean>(false);
    const nullValueOption: Select.Option = {id: "PLEASE_SELECT", label: "Please select"}
    const [progressBarVisible, setProgressBarVisible] = useState<boolean>(false)
    const [bulkUpdatePromises, setBulkUpdatePromises] = useState<Array<Promise<any>>>([])
    const [dateRangeInit, timeZone] = useDateTimeRange(getStartDateAndEndDate(30), Timezone.UTC);
    const [selectedDateRange, setSelectedDateRange] = useState<Range>(dateRangeInit);
    const toastSuccessMessage: React.ReactNode = <>Republished successfully. <UpdateDelayAlert
        feature={"Workflow status"} plainText={true}/></>;
    const {
        progressPercentage,
        progressLoading,
        progressStatus,
        isCompleted,
        isCompletedSuccessfully,
        errorMessages
    } = useProgressBar(bulkUpdatePromises, toastSuccessMessage);

    const defaultRepublishOptions = [...convertArrayToTableSelect([], true, nullValueOption)]
    const [republishDropdownOptions, setRepublishDropdownOptions] = useState<Array<Select.IOption | Select.Option | Select.OptionsGroup>>(defaultRepublishOptions)
    const [
        selectedOption,
        setSelectedOption
    ] = useState<Select.Option>(republishDropdownOptions[0] as Select.Option);

    useEffect(() => {
        setSelectedItems(props.selectedItems);
        showRepublishModal && setWorkflowDetails();
        setProgressBarVisible(false)
    }, [props.selectedItems, showRepublishModal])

    useEffect(() => {
        if (isCompletedSuccessfully) {
            setShowRepublishModal(false)
        }
    }, [isCompletedSuccessfully])

    function resetFormValues() {
        setRepublishDropdownOptions(defaultRepublishOptions)
        setSelectedOption(defaultRepublishOptions[0] as Select.Option)
    }

    const populateRepublishStageOptions = (recipeExecutionDetailsResult: GetRecipeExecutionDetailsResult) => {
        const workflowStages = recipeExecutionDetailsResult.stageDetails || [];
        const stagesOptions: Array<Select.IOption | Select.Option | Select.OptionsGroup> = workflowStages.filter(
            function (stageDetails) {
                return REPUBLISH_ALLOWED_STAGES.includes(stageDetails.stageName)
            }).map(stage => {
            return {
                id: stage.stageId,
                label: checkIfInputIsCamelCase(stage.stageName) ? convertCamelCaseStringToWords(stage.stageName) : convertCharacterSeperatedStringToWords(stage.stageName)
            }
        });
        if (stagesOptions.length === 0) {
            setShowRepublishModal(false)
            toast.error("No republish allow-listed stage found for the execution.")
        }
        setRepublishDropdownOptions(stagesOptions);
    }

    async function setWorkflowDetails() {
        setLoadingWorkFlowDetails(true)
        resetFormValues()
        const domainTracker = new DomainTracker();
        try {
            const recipeExecution: GetRecipeExecutionDetailsResult = await domainTracker.getWorkflowInstance(selectedItems[0][EXECUTION_ID]);
            populateRepublishStageOptions(recipeExecution)
        } catch (e) {
            setShowRepublishModal(false)
            toast.error("Failed to retrieve workflow execution details");
        } finally {
            setLoadingWorkFlowDetails(false)
        }
    }

    function handleDateRangeChange(timeRange: Range): void {
        setSelectedDateRange(timeRange)
    }

    function handleRepublishSubmitButton(): void {
        if (republishReason.trim() == "") {
            setRepublishReason("")
            return
        }
        if ((selectedDateRange != null) && (typeof selectedDateRange != "number") &&
            selectedDateRange[0] != null && selectedDateRange[1] != null) {
            logger.info(`Republishing ${selectedItems.length} workflow instances`, props.selectedItems.map(selectedItem => selectedItem[EXECUTION_ID]))
            setProgressBarVisible(true);
            SwoUiMetrics.publishCloudWatchCountMetric(metricFeaturesList.republishWorkflowInstance, metricPageNames.workflowInstanceListView)
            const domainTracker = new DomainTracker();
            const republishRequestPromise = domainTracker.republishExecution(SWO_ORCHESTRATOR_SERVICE_CLIENT_ID, selectedOption.id, uuidv1(),
                getUserAlias(), republishReason, selectedDateRange[0], selectedDateRange[1]);
            setBulkUpdatePromises([republishRequestPromise])
        }
    }

    return <>
        <Modal
            className={"col-xxs-12 awsui-util-mb-l"}
            id={"republish-execution-modal"}
            onDismiss={() => setShowRepublishModal(false)}
            visible={showRepublishModal}
            expandToFit={true}
            size="medium"
            header={`Republish Execution`}
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <ButtonV3 variant="link" loading={loadingWorkFlowDetails}
                                  id={"republish-execution-cancel-button"}
                                  onClick={() => setShowRepublishModal(false)}>Cancel</ButtonV3>
                        <ButtonV3 variant="primary" loading={loadingWorkFlowDetails}
                                  id={"republish-execution-submit-button"}
                                  onClick={handleRepublishSubmitButton}
                                  disabled={nullValueOption.id === selectedOption.id}>Republish</ButtonV3>
                    </SpaceBetween>
                </Box>
            }
        >
            {loadingWorkFlowDetails ? <><Spinner size={"big"}/> Loading execution details</> :
                <>

                    <FormField label="Select stage"
                               description={`Please select stage on which you want to perform republish action.`}>
                        <Select
                            id={"republish-execution-select-dropdown"}
                            selectedOption={selectedOption}
                            onChange={({detail}) => {
                                setSelectedOption(detail.selectedOption)
                            }
                            }
                            options={republishDropdownOptions}
                        />
                    </FormField>
                    <br></br>
                    <FormField
                        id={"republish-execution-date-range-form-field"}
                        label="Date range for republishing"
                    >
                        <DateTimeRange
                            showDefaultInputIcon={true}
                            onChange={(e) => handleDateRangeChange(e)}
                            timeZone={timeZone}
                            value={selectedDateRange}
                            hideClear={true}
                            startDateId={`attr-value-date-range-start-date`}
                            endDateId={`attr-value-date-range-end-date`}
                        />
                    </FormField>
                    <FormField
                        id={"republish-execution-reason-form-field"}
                        label="Reason for republishing"
                        errorText={republishReason == "" ? `Please provide reason for republishing` : null}
                    >
                        <Input value={republishReason} onChange={e => setRepublishReason(e.detail.value)}/>
                    </FormField>
                    {(nullValueOption.id != selectedOption.id) &&
                        <Box padding={{top: "l"}}>
                            <Alert type={"warning"}>Republish {selectedOption.label} stage with Id {selectedOption.id}?
                                This action cannot be
                                reversed.</Alert>
                        </Box>
                    }
                    <br/>
                    {((progressLoading || isCompleted) && progressBarVisible) &&
                        <Box variant={"div"}>
                            <TableProgressBar progressStatus={progressStatus}
                                              progressPercentage={progressPercentage}
                                              totalRecordLength={selectedItems.length}
                                              title={`Republishing ${selectedItems.length} action`}
                                              errorMessages={errorMessages}
                            />
                        </Box>
                    }
                </>
            }

        </Modal>
        <Button text={"Republish"}
                id={"republish-execution-button"}
                onClick={() => setShowRepublishModal(true)}
                disabled={props.selectedItems.length != 1}
        />

    </>
}