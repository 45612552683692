import {ES_QUERY_RESERVED_KEYWORDS, ES_SEARCH_TYPES, ES_SORT_TYPES} from "src/constants/elastic-search";
import * as lodash from "lodash"
import {Range} from "@amzn/polaris-date-time-range";
import * as Bodybuilder from "bodybuilder";
import {AttributeFilterItemsI, EsQueryTableFilterI, FilterOperation} from "src/interfaces/attribute-filters";

const bodybuilder = require('bodybuilder')

/**
 * Helper class to build ES queries.
 */
export class ElasticSearchHelper {

    /*
     * Convert table attribute filters to ES Query based on Column definition
     */
    static convertAttributeFilterTokensToESQueryV3(esQueryTableFilter: EsQueryTableFilterI): any {
        const esQueryBuilder: Bodybuilder.Bodybuilder = bodybuilder();
        esQueryBuilder.size(esQueryTableFilter.size);
        esQueryBuilder.from(esQueryTableFilter.from);

        // If no filters available, display any X number of results.
        if (esQueryTableFilter.attributeFilterItems.length == 0) {
            esQueryBuilder.query('match_all')
        }

        // Include sorting option if available
        if ((esQueryTableFilter.sortingOptions?.sortingColumn.sortingField != "") && (esQueryTableFilter.sortingOptions?.sortingColumn.sortingField != undefined)) {
            esQueryBuilder.sort(esQueryTableFilter.sortingOptions?.sortingColumn.sortingField, esQueryTableFilter.sortingOptions?.isDescending ? ES_SORT_TYPES.DESC : ES_SORT_TYPES.ASC)
        }

        const groupByAttributeName = groupingByAttrName(esQueryTableFilter);

        Object.entries(groupByAttributeName).forEach(([attrName, attributeFilterItems]) => {
            const groupByFilterOperation = lodash.groupBy(attributeFilterItems, function (attributeFilterItem) {
                return attributeFilterItem.condition
            })

            Object.entries(groupByFilterOperation).forEach(([condition, attributeFilterItem]) => {
                const itemsGroupedCondition: Array<string> = addAttributeValues(attributeFilterItem)

                if ((attributeFilterItem.length > 0) && (attributeFilterItem[0].columnDefinition != undefined)) {
                    const esType = attributeFilterItem[0].columnDefinition?.dataType

                    switch (esType) {
                        case ES_SEARCH_TYPES.TEXT:
                            matchAndTermsQueryBuilder(esQueryBuilder, itemsGroupedCondition, condition, ES_QUERY_RESERVED_KEYWORDS.match, attrName)
                            break;
                        case ES_SEARCH_TYPES.KEYWORD:
                            matchAndTermsQueryBuilder(esQueryBuilder, itemsGroupedCondition, condition, ES_QUERY_RESERVED_KEYWORDS.terms, attrName)
                            if (condition == FilterOperation.EXISTS) {
                                esQueryBuilder.filter(ES_QUERY_RESERVED_KEYWORDS.exists, attrName)
                            } else if (condition == FilterOperation.NOT_EXISTS) {
                                esQueryBuilder.notFilter(ES_QUERY_RESERVED_KEYWORDS.exists, attrName)
                            }
                            break;
                        case ES_SEARCH_TYPES.DATE:
                            // Date case is handled separately
                            break;
                        default:
                            break;
                    }
                }
            })
        })

        buildEsDateQuery(esQueryTableFilter, esQueryBuilder)
        return esQueryBuilder.build();
    }


    /*
     * Converts ES Queries to Attribute filters. Nested ES Queries are not supported. If nested queries are given,
     * It'll flatten out and make it Array of Attribute filters.
     */
    static convertEsQueryToTableFilters(esQuery: string): EsQueryTableFilterI {
        const attributeFilterItems: Array<AttributeFilterItemsI> = []
        let initialEsQuery: any;
        try {
            initialEsQuery = JSON.parse(esQuery)
        } catch (e) {
            console.error("Failed to parse ES query", e)
            throw new Error(e)
        }

        function recurse(query: any, parentKeyword?: string) {

            if (Array.isArray(query)) {
                query.forEach(q => {
                    recurse(q, parentKeyword)
                })
            } else {

                if (query[ES_QUERY_RESERVED_KEYWORDS.bool] != undefined) {
                    recurse(query[ES_QUERY_RESERVED_KEYWORDS.bool], ES_QUERY_RESERVED_KEYWORDS.bool)
                }

                if (query[ES_QUERY_RESERVED_KEYWORDS.filter] != undefined) {
                    recurse(query[ES_QUERY_RESERVED_KEYWORDS.filter], ES_QUERY_RESERVED_KEYWORDS.filter)
                }

                if (query[ES_QUERY_RESERVED_KEYWORDS.query] != undefined) {
                    recurse(query[ES_QUERY_RESERVED_KEYWORDS.query], ES_QUERY_RESERVED_KEYWORDS.query)
                }

                if (query[ES_QUERY_RESERVED_KEYWORDS.must] != undefined) {
                    recurse(query[ES_QUERY_RESERVED_KEYWORDS.must], ES_QUERY_RESERVED_KEYWORDS.must)
                }

                if (query[ES_QUERY_RESERVED_KEYWORDS.must_not] != undefined) {
                    recurse(query[ES_QUERY_RESERVED_KEYWORDS.must_not], ES_QUERY_RESERVED_KEYWORDS.must_not)
                }

                if (query[ES_QUERY_RESERVED_KEYWORDS.terms] != undefined) {
                    Object.entries(query[ES_QUERY_RESERVED_KEYWORDS.terms]).forEach(([termsKey, termsValue]) => {
                        attributeFilterItems.push(getAttributeFilterItems(termsKey, termsValue, ES_QUERY_RESERVED_KEYWORDS.terms, parentKeyword))
                    })
                }

                if (query[ES_QUERY_RESERVED_KEYWORDS.match] != undefined) {
                    Object.entries(query[ES_QUERY_RESERVED_KEYWORDS.match]).forEach(([termsKey, termsValue]) => {
                        attributeFilterItems.push(getAttributeFilterItems(termsKey, termsValue, ES_QUERY_RESERVED_KEYWORDS.match, parentKeyword))
                    })
                }

                if (query[ES_QUERY_RESERVED_KEYWORDS.range] != undefined) {
                    Object.entries(query[ES_QUERY_RESERVED_KEYWORDS.range]).forEach(([termsKey, termsValue]) => {
                        const dateRange: any = termsValue
                        attributeFilterItems.push({
                            attributeName: {value: termsKey, label: termsKey},
                            value: [
                                parseInt(dateRange[ES_QUERY_RESERVED_KEYWORDS.gte]) || null,
                                parseInt(dateRange[ES_QUERY_RESERVED_KEYWORDS.lte]) || null
                            ],
                            condition: FilterOperation.BETWEEN,
                            columnDefinition: null,
                            tableType: null
                        })
                    })
                }

                if (query[ES_QUERY_RESERVED_KEYWORDS.exists] != undefined) {
                    Object.entries(query[ES_QUERY_RESERVED_KEYWORDS.exists]).forEach(([termsKey, termsValue]) => {
                        const attrName = termsValue as string
                        attributeFilterItems.push(getAttributeFilterItems(attrName, "", ES_QUERY_RESERVED_KEYWORDS.exists, parentKeyword))
                    })
                }
            }

        }
        
        recurse(initialEsQuery)

        const esQueryTableFilter: EsQueryTableFilterI = {
            attributeFilterItems: attributeFilterItems,
            from: initialEsQuery["from"] || 0,
            size: initialEsQuery["size"] || 0,
        }

        addSortQuery(esQueryTableFilter, initialEsQuery)
        return esQueryTableFilter
    }
}

const getAttributeFilterItems = (attrName: string, termsValue: any, keyword: ES_QUERY_RESERVED_KEYWORDS, parentKeyword?: string): AttributeFilterItemsI => {
    let condition: FilterOperation;
    let value: any = termsValue;

    if (keyword == ES_QUERY_RESERVED_KEYWORDS.exists) {
        condition = parentKeyword?.endsWith("not") ? FilterOperation.NOT_EXISTS : FilterOperation.EXISTS
    } else {
        condition = parentKeyword?.endsWith("not") ? FilterOperation.NOT_EQUALS : FilterOperation.EQUALS
    }
    return {
        attributeName: {value: attrName, label: attrName},
        value: termsValue,
        condition: condition,
        columnDefinition: null,
        tableType: null
    }
}

const groupingByAttrName = (esQueryTableFilter: EsQueryTableFilterI) => {
    return lodash.groupBy<AttributeFilterItemsI>(esQueryTableFilter.attributeFilterItems, function (filteringToken) {
        return filteringToken.attributeName?.value
    })
}

const addAttributeValues = (attributeFilterItem: Array<AttributeFilterItemsI>): Array<any> => {
    const itemsGroupedCondition: Array<string> = []
    attributeFilterItem.forEach(attrFilterItem => {
        if (Array.isArray(attrFilterItem.value)) {
            if (attrFilterItem.value.length > 0) {
                itemsGroupedCondition.push(...attrFilterItem.value as Array<string>);
            }

        } else if ((attrFilterItem.value != null) && (attrFilterItem.value != "")) {
            const stringVal = attrFilterItem.value as string
            stringVal.split(",").forEach(stringVal1 => {
                if (stringVal1.trim() != "") {
                    itemsGroupedCondition.push(stringVal1.trim())
                }
            })
        }
    })
    return itemsGroupedCondition
}

const buildEsDateQuery = (esQueryTableFilter: EsQueryTableFilterI, esQueryBuilder: Bodybuilder.Bodybuilder) => {
    esQueryTableFilter.attributeFilterItems.forEach(attributeFilterItem => {
        const attrName: string = attributeFilterItem.attributeName?.value || ""
        if (attrName != "") {
            switch (attributeFilterItem.columnDefinition?.dataType) {
                case ES_SEARCH_TYPES.TEXT:
                    break;
                case ES_SEARCH_TYPES.KEYWORD:
                    break;
                case ES_SEARCH_TYPES.DATE:
                  if (attributeFilterItem.condition == FilterOperation.EXISTS) {
                      esQueryBuilder.filter(ES_QUERY_RESERVED_KEYWORDS.exists, attrName)
                  } else if (attributeFilterItem.condition == FilterOperation.NOT_EXISTS) {
                      esQueryBuilder.notFilter(ES_QUERY_RESERVED_KEYWORDS.exists, attrName)
                    } else {
                      const attrValue: Range = attributeFilterItem.value as Range
                      const dateRangeArray: Range = attrValue
                      let dateRange: { [key: string]: string } = {};
                      if ((dateRangeArray != null) && (typeof dateRangeArray != "number")) {
                          if (dateRangeArray[0] != null) dateRange["gte"] = dateRangeArray[0].toString();
                          if (dateRangeArray[1] != null) dateRange["lte"] = dateRangeArray[1].toString();
                          esQueryBuilder.filter('range', attrName, dateRange)
                      }
                  }

                    break;
                default:
                    break;
            }
        }
    })
}

const addSortQuery = (esQueryTableFilter: EsQueryTableFilterI, initialEsQuery: any) => {
    const sortQuery: any = initialEsQuery["sort"]
    if ((sortQuery != undefined) && (sortQuery.length > 0)) {
        let isDescending: boolean = false
        Object.entries(sortQuery[0]).forEach(([sortKey, sortValue]) => {
            const sortValueAny: any = sortValue
            if ((sortValueAny[ES_QUERY_RESERVED_KEYWORDS.order] != undefined) && (sortValueAny[ES_QUERY_RESERVED_KEYWORDS.order] == ES_QUERY_RESERVED_KEYWORDS.desc)) {
                isDescending = true
            }
            esQueryTableFilter.sortingOptions = {
                sortingColumn: {sortingField: sortKey},
                isDescending: isDescending
            }
        })
    }
}

const matchAndTermsQueryBuilder = (esQueryBuilder: Bodybuilder.Bodybuilder, itemsGroupedCondition: Array<string>,
                                   condition: string, esKeyword: ES_QUERY_RESERVED_KEYWORDS, attrName: string) => {
    let value: string | Array<string> = itemsGroupedCondition
    if (esKeyword == ES_QUERY_RESERVED_KEYWORDS.match) {
        value = value.join(" ")
    }
    if (itemsGroupedCondition.length > 0) {
        if (condition == FilterOperation.EQUALS) {
            esQueryBuilder.filter(esKeyword, attrName, value)
        } else if (condition == FilterOperation.NOT_EQUALS) {
            esQueryBuilder.notFilter(esKeyword, attrName, value)
        }
    }
}