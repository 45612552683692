import React, {useEffect, useState} from "react";
import {DomainTracker} from "src/model/DomainTracker";
import {TablePropertyFiltering} from "@amzn/awsui-components-react";
import {AppRouteParams} from "src/components/common/router-common";
import {ExecutionAttemptsPageHeader} from "src/components/execution-attempts/execution-attempts-page-header";
import {ExecutionAttemptsTable} from "src/components/execution-attempts/execution-attempts-table";
import {ExecutionAttemptsOverview} from "src/components/execution-attempts/execution-attempts-overview";
import {ExecutionAttemptsPageRouterParams} from "src/interfaces/execution-attempts";
import {TableMetadataInterface} from "src/interfaces/server-side-table";

/**
 * Should list of all attempts and recent attempt overview.
 * @param props
 * @constructor
 */
export function ExecutionAttemptsPageContent(props: ExecutionAttemptsPageRouterParams) {
    const [tableMetadata, setTableMetadata] = useState<TableMetadataInterface>({})
    const workflowId: string = props.workflowId

    // Filter by workflowId.
    const filteringTokens: Array<TablePropertyFiltering.FilteringToken> = [
        {
            propertyKey: AppRouteParams.executionDetail.workflowId,
            propertyLabel: AppRouteParams.executionDetail.workflowId,
            value: workflowId,
            label: workflowId,
            negated: false,
            isFreeText: false
        }
    ]

    useEffect(() => {
        async function retrieveColumnMetadata() {
            const metadata: TableMetadataInterface = await new DomainTracker().getTableMetadata("WORKFLOW")
            setTableMetadata(metadata)
        }

        retrieveColumnMetadata()
    }, [])

    return <>
        <ExecutionAttemptsPageHeader workflowId={props.workflowId}/>
        <ExecutionAttemptsOverview
            tableMetadata={tableMetadata}
            filteringTokensHidden={filteringTokens}/>
        <ExecutionAttemptsTable workflowId={props.workflowId}/>

    </>
}