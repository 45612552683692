import React from 'react';
import {TableMetadataInterface} from "src/interfaces/server-side-table";
import {SMSWOUnifiedDashboardServiceLambda} from "@amzn/swo-unified-dashboard-service-lambda-js-client";
import {BubbleEdit} from "src/components/common/bubble-edit";
import {formatColumnValue} from "src/utils/table-helper";
import {TableProps} from "@amzn/awsui-components-react-v3/polaris/table/interfaces";
import {CollectionPreferencesProps} from "@amzn/awsui-components-react-v3/polaris/collection-preferences/interfaces";
import {TableTypes} from "src/interfaces/domain-tracker";
import {UserTablePreferences} from "src/components/common/table-user-preferences";

// Column definitions for the table based on Table Metadata
export const columnDefinitionsV3 = (cd: TableMetadataInterface): ReadonlyArray<TableProps.ColumnDefinition<SMSWOUnifiedDashboardServiceLambda.Types.Details>> => {
    const colDefinitionNew: Array<TableProps.ColumnDefinition<SMSWOUnifiedDashboardServiceLambda.Types.Details>> = []
    Object.entries(cd).forEach(([columnKey, columnDefinition]) => {
            const cdSingle: TableProps.ColumnDefinition<SMSWOUnifiedDashboardServiceLambda.Details> = {
                id: columnKey,
                cell: (item) => <>
                    {columnDefinition.allowUpdateByUser ? <BubbleEdit
                            tableMetadata={cd}
                            rowItem={item}
                            columnKey={columnKey}>
                            {formatColumnValue(item[columnKey], columnDefinition.dataType)}
                        </BubbleEdit>
                        : formatColumnValue(item[columnKey], columnDefinition.dataType)}
                </>,
                header: columnDefinition.uiFeatures.visibleName,
                minWidth: 200,
                sortingField: columnDefinition.queryAble ? columnKey : undefined
            }
            colDefinitionNew.push(cdSingle)
        }
    );
    return colDefinitionNew;
};

/*
 * Visible columns to choose from in user preference settings modal.
 */
export const getVisibleContentOptions = (tableMetadata: TableMetadataInterface, tableType: TableTypes): ReadonlyArray<CollectionPreferencesProps.VisibleContentOption> => {
    const visibleContentOptions: Array<CollectionPreferencesProps.VisibleContentOption> = []
    Object.entries(tableMetadata).forEach(([key, value]) => {
        if (value.uiFeatures.visible) visibleContentOptions.push({
            id: key,
            label: value.uiFeatures.visibleName,
            editable: value.uiFeatures.visible
        })
    })

    return visibleContentOptions
}

/*
 * Visible columns to be displayed in the table. Based on table preference or default value.
 */
export const getVisibleColumns = (tableMetadata: TableMetadataInterface, tableType: TableTypes): ReadonlyArray<string> => {
    const userTablePreference = new UserTablePreferences(tableType)
    const visibleColumnsUserPreference = userTablePreference.getTablePreference().visibleColumns
    if (visibleColumnsUserPreference.length == 0) {
        Object.entries(tableMetadata).forEach(([key, columnMetadata]) => {
            if (columnMetadata.uiFeatures.visibleOnLoad && columnMetadata.uiFeatures.visible) visibleColumnsUserPreference.push(key)
        })
    } else {
        Object.entries(tableMetadata).forEach(([key, columnMetadata]) => {
            if (!columnMetadata.uiFeatures.visible) {
                const index = visibleColumnsUserPreference.indexOf(key)
                if (index != -1) {
                    visibleColumnsUserPreference.splice(index, 1)
                }
            }
        })
    }

    return visibleColumnsUserPreference
}


export const PAGE_SIZE_OPTIONS = [
    {value: 10, label: '10 Rows'},
    {value: 30, label: '30 Rows'},
    {value: 50, label: '50 Rows'}
];


