import {STAGES} from "src/utils/app-constants";
import AWS from "aws-sdk";

interface BulkStartWorkflowInstanceS3 {
    bucket: string,
    key: string
}

export const bulkStartWorkflowInstanceS3Beta: BulkStartWorkflowInstanceS3 = {
    bucket: "bulk-start-inputs-beta",
    key: "bulkRequests/"
}

export const bulkStartWorkflowInstanceS3Prod: BulkStartWorkflowInstanceS3 = {
    bucket: "bulk-start-inputs-prod",
    key: "bulkRequests/"
}

// Get Bulk Start workflow instance s3 details
export function bulkStartWorkflowInstanceS3Details(stage: string): BulkStartWorkflowInstanceS3 {
    let bulkStartWorkflowInstanceS3Config;

    switch (stage) {
        case STAGES.PROD.toLowerCase():
            bulkStartWorkflowInstanceS3Config = bulkStartWorkflowInstanceS3Prod;
            break;
        default:
            bulkStartWorkflowInstanceS3Config = bulkStartWorkflowInstanceS3Beta;
    }
    return bulkStartWorkflowInstanceS3Config;
}

export const getDownloadUrl = (bucket: string, filePath: string, fileNameMatch?: string): Promise<string> => {
    // TODO: Remove this region hardcoding once we start to get bucket region as metadata
    const s3Service = new AWS.S3({region: 'us-east-1'});
    return new Promise((resolve, reject) => {
        s3Service.listObjectsV2({Prefix: filePath, Bucket: bucket}, (err, data) => {
            if(err){
                reject(err.message);
            }
            const dataKey = fileNameMatch && data.Contents
                            ? data.Contents.find((listItem) => listItem.Key?.includes(fileNameMatch))
                            : data.Contents?.[0];
            dataKey &&  s3Service.getSignedUrl('getObject',
        {
                    Bucket: bucket,
                    Key: dataKey.Key,
                    Expires: 600
                }, (error, url) =>{
                if(error){
                    reject(error.message);
                }
                resolve(url);
            })
        })
    })
}