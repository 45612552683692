import React, {useEffect} from "react"
import AttributeEditor from "@amzn/awsui-components-react-v3/polaris/attribute-editor";
import Select from "@amzn/awsui-components-react-v3/polaris/select";
import produce from "immer"
import {Box, Container, ExpandableSection, SelectProps} from "@amzn/awsui-components-react-v3";
import {ES_SEARCH_TYPES} from "src/constants/elastic-search";
import {CommonAttrValue, findFilteringOpertion} from "src/components/filters/common";
import {AttributeFilterI, AttributeFilterItemsI, FilterOperation} from "src/interfaces/attribute-filters";

/**
 * Additional filter apart from filter on the top. An attribute can't be present in both filters to avoid confusion
 * @param props
 * @constructor
 */
export function SecondaryFilter(props: AttributeFilterI) {
    const [items, setItems] = React.useState<Array<AttributeFilterItemsI>>(props.attributeFilterItems);
    const tableMetadata = props.tableMetadata

    const attributeOptionsTemp: Array<SelectProps.Option> = []
    Object.entries(tableMetadata).forEach(([columnKey, columnDefinition]) => {
        if (!columnDefinition.uiFeatures.onTopFilter && columnDefinition.queryAble) {
            attributeOptionsTemp.push({value: columnKey, label: columnDefinition.uiFeatures.visibleName})
        }
    })

    const [
        attributeOptions,
        setAttributeOptions
    ] = React.useState(attributeOptionsTemp);

    useEffect(() => {
        setAttributeOptions(attributeOptionsTemp)
        setItems(props.attributeFilterItems)
    }, [props.tableMetadata, props.attributeFilterItems])


    return (
        <>
            <ExpandableSection
                defaultExpanded
                header="Filters"
                variant="default"
            >
                <Container>
                    <AttributeEditor
                        onAddButtonClick={() => setItems([...items, {
                            attributeName: null,
                            condition: FilterOperation.EQUALS,
                            value: "",
                            columnDefinition: null,
                            tableType: props.tableType
                        }])}
                        onRemoveButtonClick={({
                                                  detail: {itemIndex}
                                              }) => {
                            const tmpItems = [...items];
                            tmpItems.splice(itemIndex, 1);
                            setItems(tmpItems);
                            props.onFilteringTokenUpdate(tmpItems)
                        }}
                        items={items}
                        definition={[
                            {
                                label: "Attribute name",
                                control: (item, itemIndex) => (
                                    <Box padding={{top: item.columnDefinition?.dataType == ES_SEARCH_TYPES.DATE ? "xxs" : "xxxs"}}>
                                    <Select
                                        selectedOption={items[itemIndex].attributeName}
                                        onChange={e => {
                                            const updatedItems: Array<AttributeFilterItemsI> = produce(items, (draft: Array<AttributeFilterItemsI>) => {
                                                draft[itemIndex].attributeName = e.detail.selectedOption
                                                if (e.detail.selectedOption.value != undefined) {
                                                    draft[itemIndex].columnDefinition = props.tableMetadata[e.detail.selectedOption.value] || null
                                                    if (tableMetadata[e.detail.selectedOption.value].dataType == ES_SEARCH_TYPES.DATE) {
                                                        draft[itemIndex].condition = FilterOperation.BETWEEN
                                                    } else {
                                                        draft[itemIndex].condition = FilterOperation.EQUALS
                                                        draft[itemIndex].value = null
                                                    }
                                                }
                                            })
                                            setItems(updatedItems)
                                            props.onFilteringTokenUpdate(updatedItems)
                                        }}
                                        options={attributeOptions}
                                        selectedAriaLabel="Selected"
                                        filteringType={"auto"}
                                        data-testid={`secondary-filter-attribute-${itemIndex}`}
                                    />
                                    </Box>
                                )
                            },
                            {
                                label: "Condition",
                                control: (item, itemIndex) => (
                                    <Box padding={{top: item.columnDefinition?.dataType == ES_SEARCH_TYPES.DATE ? "xxs" : "xxxs"}}>
                                    <Select
                                        selectedOption={{label: item.condition, value: item.condition}}
                                        onChange={e => {
                                            const updatedItems: Array<AttributeFilterItemsI> = produce(items, (draft: Array<AttributeFilterItemsI>) => {
                                                draft[itemIndex].condition = FilterOperation[e.detail.selectedOption.value as keyof typeof FilterOperation || FilterOperation.EQUALS]
                                            })
                                            setItems(updatedItems)
                                            props.onFilteringTokenUpdate(updatedItems)
                                        }}
                                        options={item.columnDefinition == null ? [] : findFilteringOpertion(item.columnDefinition)}
                                        selectedAriaLabel="Selected"
                                        disabled={item.attributeName == null}
                                        data-testid={`secondary-filter-condition-${itemIndex}`}
                                    />
                                    </Box>
                                )
                            },
                            {
                                label: "Value",
                                control: (item, itemIndex) => (
                                    <>
                                        {CommonAttrValue(items, item, props.tableType, setItems, itemIndex, props.onFilteringTokenUpdate)}
                                    </>
                                )
                            }
                        ]}
                        addButtonText="Add filter"
                        removeButtonText="Remove"
                        empty="Please add filters to display"
                    />
                </Container>
            </ExpandableSection>
        </>
    );
}