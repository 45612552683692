import React, {ReactNode} from "react"
import {Alert, Box, ColumnLayout, Container, Header} from "@amzn/awsui-components-react-v3";
import {ExternalRoutes} from "src/components/common/router-common";

export interface ReleaseNotesDescription {
  title: string
  featureList: Array<ReactNode>
}

export interface ReleaseNotesI {
  date: Date
  features: Array<ReleaseNotesDescription>
}

export interface ReleaseNotesContentI {
  numberOfNotes: number
}

export const RELEASE_NOTE_AUG_09: ReleaseNotesI = {
  date: new Date("08-09-2021"),
  features: [{
    title: `Home Page Changes`,
    featureList: [
      `Added “Aggregate summary for workflow instances” (explained below)`,
      `Added metrics for all workitems generated via SWO apart from e2e pipeline workitems`,
      `Stage level cycle time – Displays cycle time at stage boundaries ex crawl, ccm, scrape planner, sporc and the trends for any date range`,
      `Sub stage level cycle time – Drill down into stage specific cycle time, ex, if a user needs to investigate what are the major bottlenecks in crawl stage cycle time, use this report to drill down into cycle time for components/sub stages within crawl.`]
  },
    {
      title: `Workflow Instance Aggregate Summary`,
      featureList: [
        `Displays aggregate workflow instance view as funnel charts with options to filter on workflow status, workflow start date and other fields. User can see count of running/failed/successful workflow across crawl, scrape planner and sporc stage.`,
        `User can click on aggregate count which redirects to workflow list view with all filters applied on summary view.`
      ]
    },
    {
      title: `Shareable URLs`,
      featureList: [
        `User can now apply filter and bookmark/share urls with others. When someone open the URL, they can see filters applied and get exact results.`]
    },
    {
      title: `Filter View Changes`,
      featureList: [`UI support for additional filters(exists, Not exists, not equals) along with existing filters(equals and date range filter.). For ex, User can now make queries like Workflow Status not equals WORKFLOW_FAILED, Workflow Start date exists.`,
        `User can query for multiple value at once by providing comma separated values like -  Domain equals www.gardenchic.co.uk_uk, www.verdemaratevoce.com.br,  www.harrisoncameras.co.uk_ots, barbecue-co.com`]
    },
    {
      title: `SIM Portal search / filter crawl workitems by following tags`,
      featureList: [
        `StrategyId ex - CrawlQAComputationStrategy`,
        `Domain ex - www.wearlizer.com`,
        `WorkItemType ex - CrawlQA`
      ]
    }

  ]
}

export const RELEASE_NOTES: Array<ReleaseNotesI> = [
  RELEASE_NOTE_AUG_09
]


export function ReleaseNotesContent(props: ReleaseNotesContentI) {
  return <div>

    <Container header={<Header variant="h2">Release Notes</Header>}>
      <ColumnLayout columns={1} variant="text-grid">
          <Box padding={"xxs"}> <Alert header={"Release notes"}>
              Release notes, upcoming releases and bug fixes are tracked here: <a href={ExternalRoutes.upcomingReleases} target={"_blank"}>
              {ExternalRoutes.upcomingReleases}</a>
          </Alert></Box>
        {/*TODO: Once we've planned release, we'll add this again.*/}
        {/*{RELEASE_NOTES.map((releaseNote, index) => {*/}
        {/*  return <div>*/}
        {/*    {index < props.numberOfNotes && <>*/}
        {/*        <Box variant="h2" padding={{top: 'n'}}>*/}
        {/*          {releaseNote.date.toDateString()}*/}
        {/*        </Box>*/}
        {/*        <ol>*/}
        {/*          {releaseNote.features.map(feature => {*/}
        {/*            return <li>*/}
        {/*              {feature.title}*/}
        {/*              <ul>*/}
        {/*                {feature.featureList.map(featureList => {*/}
        {/*                  return <li>{featureList}</li>*/}
        {/*                })}*/}
        {/*              </ul>*/}
        {/*            </li>*/}
        {/*          })}*/}
        {/*        </ol>*/}
        {/*    </>*/}
        {/*    }*/}
        {/*  </div>*/}
        {/*})}*/}
      </ColumnLayout>
    </Container>
  </div>
}