import AWS from 'aws-sdk';
import {MetricData} from "aws-sdk/clients/cloudwatch";
import {getUserAlias} from "src/utils/cookie-helper";
import {metricNamespace} from "src/constants/swo-ui-metric-constants";
import {logger} from "src/utils/logger";

/**
 * Publish user metric to cloudwatch
 */
export class SwoUiMetrics {

    /**
     * Publish user level feature metric. 
     * @param metricName - Feature name
     * @param page - Page name
     * @param value - Number of counts. Defaults to 1
     */
    static publishCloudWatchCountMetric(metricName: string, page: string, value?: number) {
        const cloudWatch = new AWS.CloudWatch();
        const userAliasDimension = {
                Name: "UserAlias",
                Value: getUserAlias()
            }
            const pageDimension = {
                Name: "Page",
                Value: page
            }

        const metricData: MetricData = [{
            MetricName: metricName,
            Unit: "Count",
            Value: value || 1,
            Dimensions: [userAliasDimension, pageDimension]
        }]

        const metricDataWithoutUser: MetricData = [{
            MetricName: metricName,
            Unit: "Count",
            Value: value || 1,
            Dimensions: [pageDimension]
        }]

        cloudWatch.putMetricData({Namespace: metricNamespace, MetricData: metricDataWithoutUser})
            .promise().then(() => {
            console.log("Published cloudwatch metric success", metricName, page)
        }).catch((error) => {
            logger.error("Failed to publish cloudwatch metric", error, {metricName: metricName, page: page})
        })

        cloudWatch.putMetricData({Namespace: metricNamespace, MetricData: metricData})
            .promise().then(() => {
            console.log("Published cloudwatch metric success", metricName, page)
        }).catch((error) => {
            logger.error("Failed to publish cloudwatch metric", error, {metricName: metricName, page: page})
        })
    }
}