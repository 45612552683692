import React, {useCallback, useState, useEffect} from "react";
import {Box, Header, Pagination, Table, Button} from "@amzn/awsui-components-react-v3";
import {useInfiniteQuery} from "@tanstack/react-query";
import {
    workflowListViewColumnDefinition
} from "src/components/sm-overview-dashboards/workflow-list-view/workflow-list-column-config";
import {metricFeaturesList, metricPageNames} from "src/constants/swo-ui-metric-constants";
import {SMOverviewAppLayout} from "src/components/sm-overview-dashboards/sm-overview-app-layout";
import {TableProps} from "@amzn/awsui-components-react-v3/polaris/table/interfaces";
import {useHistory, useLocation} from "react-router-dom";
import {AppLayoutProps} from "@amzn/awsui-components-react-v3/polaris/app-layout";
import {UserActionsInfoBar} from "src/components/sm-overview-dashboards/common/UserActionsInfoBar";
import {WorkflowList} from "src/model/WorkflowList";
import {FILTER_QUERY_KEY} from "src/components/sm-overview-dashboards/workflow-list-view/filters/filters";
import {WorkflowView} from "src/components/sm-overview-dashboards/workflow-list-view/workflow-view";
import {QueryParams} from "src/components/sm-overview-dashboards/common/query-params";
import {
    getNextPageCursor
} from "src/components/sm-overview-dashboards/workflow-list-view/workflow-list-view.utils";
import {SMSWOUnifiedDashboardServiceLambda} from "@amzn/swo-unified-dashboard-service-lambda-js-client";
import {DashboardRefreshTime} from "src/components/sm-overview-dashboards/common/dashboard-refresh-time";
import {FilterInput} from "src/components/sm-overview-dashboards/workflow-list-view/filters/filterInput";
import {
    ExecutionViewRedirectionLink
} from "src/components/sm-overview-dashboards/workflow-list-view/filters/execution-view-redirection-link";

export const PAGE_COUNT = 1;
export const PAGE_SIZE = 25;
export const WorkflowListView =
    ({openSplitPanel}: {openSplitPanel?: (size?: number, preference?: AppLayoutProps.SplitPanelPreferences) => void}) => {
    const location = useLocation();
    const history = useHistory()
    const params = new URLSearchParams(location.search);
    const filterQuery = params.get(FILTER_QUERY_KEY) || '';
    const [currentPageIndex, setCurrentPageIndex] = useState<number>(1);
    const {
        data: workflowListData,
        isLoading: isWorkflowListLoading,
        error,
        hasNextPage,
        fetchNextPage,
        isFetchingNextPage
    } = useInfiniteQuery({
        queryKey: ['getWorkflowListView', filterQuery],
        /* istanbul ignore next */
        queryFn: ({pageParam = null }) =>  new WorkflowList().geWorkflowList({
            filterQuery: filterQuery,
            pageCursor: pageParam
        }),
        enabled: !!filterQuery,
        keepPreviousData: true,
        getNextPageParam: getNextPageCursor
    });
    const workflowList = workflowListData?.pages[currentPageIndex-1]?.filteredWorkflowRecords;
    const [
        selectedItems,
        setSelectedItems
    ] = useState<any[]>([]);

    const onNextPageClick = (currentPageIndex: number) => {
        if(hasNextPage){
            setCurrentPageIndex(currentPageIndex);
            fetchNextPage()
        }
    }

    useEffect(() => {
        if(filterQuery){
            setCurrentPageIndex(1);
        }
    },[filterQuery])

    /* istanbul ignore next */
    const onSelectionChangeHandler = useCallback(
        ({detail}: {detail: TableProps.SelectionChangeDetail<SMSWOUnifiedDashboardServiceLambda.Types.WorkflowRecord>}
        ) => {
            params.delete(QueryParams.WORKFLOW_ID);
            params.append(QueryParams.WORKFLOW_ID, detail.selectedItems[0].recipeExecutionId||'');
            setSelectedItems(detail.selectedItems);
            history.replace({search: params.toString()})
            openSplitPanel && openSplitPanel(700, {position: 'side'});
        }, [openSplitPanel, setSelectedItems, params, history])

    return <>
        <Box padding={{vertical: "s", horizontal: "xl"}}>
            <UserActionsInfoBar view={metricFeaturesList.page2ListView} hasError={!!error}/>
            <DashboardRefreshTime />
        </Box>
        <Box padding={{vertical: "s", horizontal: "xl"}}>
            <FilterInput />
        </Box>
        <Box padding={{vertical: "l", horizontal: "l"}}>
            <Table
                data-testid='workflow-list-view'
                items={workflowList || []}
                columnDefinitions={workflowListViewColumnDefinition}
                loadingText='Loading Workflow data'
                loading={isWorkflowListLoading || isFetchingNextPage}
                onSelectionChange={onSelectionChangeHandler}
                selectedItems={selectedItems}
                selectionType='single'
                wrapLines
                empty={
                    <Box textAlign="center" color="inherit">
                        <b>No resources</b>
                        <Box
                            padding={{ bottom: "s" }}
                            variant="p"
                            color="inherit"
                        >
                            No Workflow Executions to Display.
                        </Box>
                    </Box>
                }
                header = {
                    <Header
                        variant={"h2"}
                        actions = {
                            <ExecutionViewRedirectionLink filterQuery={filterQuery}/>
                        }
                    >
                        Workflow List
                    </Header>
                }
                pagination={
                    <Pagination
                        openEnd={true}
                        pagesCount={PAGE_COUNT}
                        currentPageIndex={currentPageIndex}
                        disabled={isWorkflowListLoading}
                        onNextPageClick={(e) => onNextPageClick(e.detail.requestedPageIndex)}
                        onChange={(e) => setCurrentPageIndex(e.detail.currentPageIndex)}
                    />
                }
            />
        </Box>
    </>
}

export const WorkFlowList = () => <SMOverviewAppLayout
    content={<WorkflowListView />}
    metricName={metricFeaturesList.page2ListView}
    page={metricPageNames.workflowList}
    addSplitPanel={true}
    splitPanelContent={<WorkflowView />}
    splitPanelHeader={'Workflow Details'}
/>
