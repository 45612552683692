// import the token here to test that our jest-preset handles it
import * as tokens from "@amzn/awsui-design-tokens";
import React from "react";
import {Box, ColumnLayout, Container, Grid, Header, Link, Popover, SpaceBetween} from '@amzn/awsui-components-react-v3';
import {AppRoutes, ButtonWithRouter, ExternalRoutes, RouteLabels} from "src/components/common/router-common";
import {
    DataStoreFields,
    FilterOperation,
    QueryVerb
} from "src/components/sm-overview-dashboards/workflow-list-view/filters/filter-constants";
import {getTimeRange} from "src/components/sm-overview-dashboards/workflow-detail-view/workflow-detail-view.utils";
import {getTimeDiff} from "src/utils/sm-overview-dashboard-utils";
import {QueryParams} from "src/components/sm-overview-dashboards/common/query-params";

// The content in the main content area of the App layout

export const MoreInfo: React.FC<{infoText: string, detail: string}> = ({infoText, detail}) => {
    return  <p style={{margin: '8px', display: 'inline-flex'}}>
        <Popover
            dismissButton={true}
            position="top"
            size="large"
            triggerType="custom"
            content={
            <div>
                <p>
                    {infoText}
                </p>
                <br/>
                <p>
                    {detail}
                </p>
            </div>
            }
        >
            <Link variant="info">Info</Link>
        </Popover>
    </p>
}

const getDashboardLinkWithDefaultParams = (link: string) => {
    const filterQueryParam = {
        [`${QueryVerb.MUST}::${FilterOperation.RANGE}:${DataStoreFields.WORKFLOW_START_TIME}`]
            : getTimeRange(getTimeDiff(3, 'MONTH'), 0)
    };
    return `${link}?${QueryParams.FILTER_QUERY_KEY}=${JSON.stringify(filterQueryParam)}`;
}

export default function HomepageContent() {
    return (
        <div>
            <div style={{background: tokens.colorBackgroundHomeHeader}} className={"custom-home__header"}>
                <Box padding={{vertical: "xxl", horizontal: "s"}}>
                    <Grid
                        gridDefinition={[
                            {colspan: {l: 8, xxs: 10}, offset: {l: 2, xxs: 1}},
                            {colspan: {l: 5, s: 6, xxs: 10}, offset: {l: 2, xxs: 1}},
                            {colspan: {l: 3, s: 4, xxs: 10}, offset: {s: 0, xxs: 1}},
                        ]}
                    >
                        <Box padding={{top: "xs"}} fontWeight="light">
                            <span className="custom-home-text-inverted">SMT</span>
                        </Box>
                        <div className="custom-home-text-inverted">
                            <Box variant="h1" fontWeight="bold" padding="n" fontSize="display-l" color="inherit">
                                SWO UI
                            </Box>
                            <Box fontWeight="light" padding={{bottom: "s"}} fontSize="display-l" color="inherit">
                                System Workflow Orchestrator
                            </Box>
                            <Box variant="p" fontWeight="light">
                                <span className="custom-home-text-secondary">
                  SWO translates a business intent of retrieving external selection information into a series of steps
                  involving SM systems and manual tasks
                </span>
                            </Box>
                        </div>
                    </Grid>
                </Box>
            </div>

            <Box margin={{vertical: 's'}} padding={{top: 'xl', horizontal: 'xl'}}>
                <Grid
                    gridDefinition={[
                        {colspan: {xl: 8, l: 8, s: 8, xxs: 10}, offset: {l: 2, xxs: 1}}
                    ]}
                >
                    <div className="custom-home-main-content-area">
                        <SpaceBetween size="l">
                            <div>
                                <Container>
                                    <Container
                                       header={
                                            <Header
                                                variant="h3"
                                            >
                                                Start Workflows
                                            </Header>
                                        }
                                    >
                                        <ColumnLayout borders="vertical"  columns={0} >
                                            <div>
                                                <Box variant="p" padding={{vertical: 'xs', horizontal: 'xs'}}>
                                                    This screen enables user to start Workflow instances on SWO orchestration engine.
                                                    The view offers 2 modes for start, single workflow start and bulk start.
                                                </Box>
                                                <Box variant="div" padding={{vertical: 'xs', horizontal: 'xs'}}>
                                                    <ButtonWithRouter href={AppRoutes.startWorkflowInstance}
                                                                      text={'Start new workflow instance'} icon={"external"}
                                                                      iconAlign={"right"}/>
                                                </Box>
                                            </div>
                                        </ColumnLayout>
                                    </Container>
                                    <Container
                                        header={
                                            <Header
                                                variant="h3"
                                            >
                                                Workflow Monitoring
                                            </Header>
                                        }
                                    >
                                        <ColumnLayout borders="vertical" columns={0}>
                                            <div>
                                                <ColumnLayout borders="horizontal">
                                                    <div>
                                                        <Box variant="p" >
                                                            Page 0 (Program Summary)
                                                            <MoreInfo
                                                                infoText={'Gives a birds eye view of the key metrics, at program grain.'}
                                                                detail={'This view is interactive and offers ability to user explore or drill-down the metrics and navigate to next layer (Page 1).\n' +
                                                                    '                                                    This First layer adds the 360 degree view with Aggregate Metrics. This view consolidates health of SM System.'}
                                                            />
                                                        </Box>
                                                        <Box variant="div" padding={{bottom: 'xs'}}>
                                                            <ButtonWithRouter href={getDashboardLinkWithDefaultParams(AppRoutes.programSummary)}
                                                                              text={'Page 0'} icon={"external"}
                                                                              iconAlign={"right"}/>
                                                        </Box>

                                                    </div>
                                                    <div>
                                                        <Box variant="p" >
                                                            Page 1 (Program Analysis)
                                                            <MoreInfo
                                                                infoText={'This presents a dive deep view of specific metric that user opted to explore from page 0 view.'}
                                                                detail={
                                                                    'Workflow failure dive deep - Point in time analysis of failed workflows.\n' +
                                                                    'Running workflow dive deep - Age distribution of running workflows and their respective stages.\n' +
                                                                    'Workflow failure analysis - Complete analysis of all failures reported for workflows. \n' +
                                                                    'Workflow cycle time analysis - e2e cycle time split by system \n' +
                                                                    'Workitem cycle time analysis - e2e cycle time split by system Vs ROR Vs failure time etc'} />
                                                        </Box>
                                                        <Box variant="div" padding={{bottom: 'xs'}} >
                                                            <ColumnLayout columns={0} >
                                                                <ButtonWithRouter href={getDashboardLinkWithDefaultParams(AppRoutes.programSummaryDetailFailure)}
                                                                                  text={'Workflow failure dive deep'} icon={"external"}
                                                                                  iconAlign={"right"}/>
                                                                <ButtonWithRouter href={getDashboardLinkWithDefaultParams(AppRoutes.programSummaryDetailRunning)}
                                                                                  text={'Running workflow dive deep'} icon={"external"}
                                                                                  iconAlign={"right"}/>
                                                                <ButtonWithRouter href={ExternalRoutes.workflowCycleTimeDeepDive}
                                                                                  text={'Workflow cycle time analysis'} icon={"external"}
                                                                                  iconAlign={"right"}/>
                                                                <ButtonWithRouter href={ExternalRoutes.rorDeepDiveWikiDashboard}
                                                                                  text={'Workitem cycle time analysis'} icon={"external"}
                                                                                  iconAlign={"right"}/>
                                                                <ButtonWithRouter href={ExternalRoutes.failureAnalysis}
                                                                                  text={'Workflow failure analysis'} icon={"external"}
                                                                                  iconAlign={"right"}/>
                                                            </ColumnLayout>
                                                        </Box>
                                                    </div>
                                                    <div>
                                                        <Box variant="p" >
                                                            Page 2 (Workflow list)
                                                            <MoreInfo
                                                                infoText={'This view offers user to further dive deep and identify workflow instances contributing to metrics in page 1.'}
                                                                detail={'This View offers user an ability to Debug\n' +
                                                                    ', User has clear understanding and correlation of metrics in previous layer\n' +
                                                                    '       , and this also helps in self validation of dashboard, building trust in user about the data reported.'} />
                                                        </Box>

                                                        <Box variant="div" padding={{bottom: 'xs'}}>
                                                            <ColumnLayout columns={0} >
                                                                <ButtonWithRouter href={getDashboardLinkWithDefaultParams(AppRoutes.workflowList)}
                                                                                  text={'Workflow list'} icon={"external"}
                                                                                  iconAlign={"right"}/>
                                                                <ButtonWithRouter href={AppRoutes.executionList}
                                                                                  text={'Executions view'} icon={"external"}
                                                                                  iconAlign={"right"}/>
                                                            </ColumnLayout>
                                                        </Box>

                                                    </div>
                                                </ColumnLayout>
                                            </div>
                                        </ColumnLayout>
                                    </Container>
                                    <Container
                                        media={{

                                            content: (
                                                <iframe
                                                    sandbox="allow-scripts allow-popups allow-presentation allow-same-origin"
                                                    src="https://broadcast.amazon.com/embed/848210"
                                                    loading="lazy" width="682" height="384"
                                                    allowFullScreen></iframe>
                                            )
                                        }}
                                    >
                                        <SpaceBetween direction="vertical" size="s">
                                            <SpaceBetween direction="vertical" size="xxs">
                                                <Box variant="h2">
                                                    <Link fontSize="heading-m" href="#">
                                                        Workflow Monitoring Overview
                                                    </Link>
                                                </Box>
                                            </SpaceBetween>
                                            <Box variant="p">
                                                This video demonstrates the usage of SM dashboards.
                                                It highlights the drill down ability that the dashboard provides
                                                and how it can be used to surface insights
                                            </Box>
                                        </SpaceBetween>
                                    </Container>
                                </Container>
                            </div>
                        </SpaceBetween>
                    </div>
                </Grid>
            </Box>

        </div>
    );
}
