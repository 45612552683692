import React from "react";
import {Popover, SpaceBetween} from "@amzn/awsui-components-react-v3";

export const HeaderCell: React.FunctionComponent<{text: string, description:  React.ReactNode, columnType: string}>
    = ({text, description, columnType}) => {
    return <>
        <SpaceBetween direction="horizontal" size="xs">
            {
                <Popover
                    dismissButton={false}
                    position="top"
                    size="small"
                    triggerType="custom"
                    content={description}
                    header={columnType}
                >
                    {text}
                </Popover>
            }
        </SpaceBetween>
    </>
}
