import React, {useEffect, useState} from "react"
import {
    IdleAndOperationLatencyExtractor,
    idleOperationInfoType, idleTimeType, operationTimeType
} from "src/components/virtual-pipeline/virtual-pipeline-instance/idle-operation-latency/idle-operation-latency-extractor";
import {BarChart, Box, Container, Grid, Header, LineChart, Spinner} from "@amzn/awsui-components-react-v3";
import {SMVirtualPipelineLambda} from "@amzn/sm-virtual-pipeline-lambda-js-client";
import Moment from "moment";
import {TPHelper} from "src/utils/table-helper";
import {GridProps} from "@amzn/awsui-components-react-v3/polaris";
import {noDataMessage} from "src/components/virtual-pipeline/virtual-pipeline-instance/dashboard-utils";

/**
 * Dashboard for virtual pipeline operation idle time and operation time.
 * charts: Idle time and operation time of operations based on date for stacked bar chart to use.
 * TPs: TP 50/90 information of idle time and operation time of operations.
 * lines: All in one idle time and operation time of operations based on date for line chart to use.
 * @param props
 * @constructor
 */
export function VirtualPipelineInstanceIdleOperationDashboard(props: {
    instances: SMVirtualPipelineLambda.ListOfVirtualPipelineInstances,
    pipelineInfo: SMVirtualPipelineLambda.DatasetNode
    isLoading: boolean
}) {
    const maximumLatency = Math.max.apply(Math, props.instances.map((inst) => { return inst["endToEndLatency"]! })) / (60 * 60 * 1000)
    const [idleAndOperation, setIdleAndOperation] = useState(new Map<string, idleOperationInfoType | Date[]>())

    const chartDateFormatter = (t: { toLocaleString: (arg0: string, arg1: { maximumFractionDigits: number; }) => any; }) =>
        t.toLocaleString("hours", { maximumFractionDigits: 2 })

    function createChartData(value: idleOperationInfoType, valueType: string, timeType: string) {
        if (valueType === "operationTimes") {
            return value.operationTimes.map((operationTime: operationTimeType) => {
                return {
                    x: operationTime["resolveDate"],
                    y: isNaN(operationTime.operationTime) ? 0 : operationTime.operationTime / (60 * 60 * 1000)
                }
            })
        } else {
            return value.idleTimes.map((idleTime: idleTimeType) => {
                return {
                    x: idleTime["resolveDate"],
                    y: isNaN(idleTime.idleTime) ? 0 : idleTime.idleTime / (60 * 60 * 1000)
                }
            })
        }
    }

    const lineChartList = new Map()
    lineChartList.set("instanceCreationDates", idleAndOperation?.get("instanceCreationDates") || [])
    lineChartList.set("lines", [])

    const gridDefinitionList: Array<GridProps.ElementDefinition> = [
        { colspan: {default: 12 } },
        { colspan: {default: 12 } }
    ]

    let operationCount = 0
    idleAndOperation?.forEach((value, key) => {
        if (key != "instanceCreationDates") {
            operationCount++

            lineChartList.get("lines").push(
                {
                    title: key + " (operation)",
                    type: "line",
                    data: createChartData(value as idleOperationInfoType, "operationTimes", "operationTime"),
                    valueFormatter: chartDateFormatter
                },
                {
                    title: key + " (idle)",
                    type: "line",
                    data: createChartData(value as idleOperationInfoType, "idleTimes", "idleTime"),
                    valueFormatter: chartDateFormatter
                }
            )

            // Add separate grid definition for each bar chart
            for (let i = 0; i < operationCount; i++) {
                gridDefinitionList.push(
                    { colspan: { l: 6, m: 6 , default: 12 } }
                )
            }
        }
    })

    useEffect(() => {
        setIdleAndOperation(IdleAndOperationLatencyExtractor(JSON.parse(JSON.stringify(props.instances))))

    }, [props.instances])

    if (props.isLoading) {
        return (
            <>
                <div className="awsui"><Spinner size={"large"}/>Loading...</div>
            </>
        )
    }

    const idleAndOperationArray = Array.from(idleAndOperation, ([key, value]) => ({ key, value })).reverse();

    return (
        <Grid
            gridDefinition={gridDefinitionList}
        >
            <Container
                id={"TPs"}
                header={
                    <Header variant={"h2"}
                            description={"TPs for operation idle & operation time"}>
                        Operation TPs
                    </Header>
                }
            >
                {idleAndOperationArray.map(({key, value}) => {
                    if (key != "instanceCreationDates") {
                        const idleTimes = (value as idleOperationInfoType).idleTimes.map((idleTime: idleTimeType) => idleTime["idleTime"])
                        const operationTimes = (value as idleOperationInfoType).operationTimes.map((operationTime: operationTimeType) => operationTime["operationTime"])

                        return (
                            <Grid
                                gridDefinition={[
                                    {colspan: {l: 4, m: 4, default: 12}},
                                    {colspan: {l: 2, m: 2, default: 12}},
                                    {colspan: {l: 2, m: 2, default: 12}},
                                    {colspan: {l: 2, m: 2, default: 12}},
                                    {colspan: {l: 2, m: 2, default: 12}}
                                ]}
                            >
                                <div>
                                    <Box margin={{bottom: 'xxxs'}} color="text-label">
                                        Operation Id
                                    </Box>
                                    <div>{key}</div>
                                </div>
                                <div>
                                    <Box margin={{bottom: 'xxxs'}} color="text-label">
                                        Idle Time TP50
                                    </Box>
                                    <div>{TPHelper(idleTimes, 2, "TP50", "hours")} hours
                                        ({TPHelper(idleTimes, 2, "TP50", "days")} days)
                                    </div>
                                </div>
                                <div>
                                    <Box margin={{bottom: 'xxxs'}} color="text-label">
                                        Idle Time TP90
                                    </Box>
                                    <div>{TPHelper(idleTimes, 2, "TP90", "hours")} hours
                                        ({TPHelper(idleTimes, 2, "TP90", "days")} days)
                                    </div>
                                </div>
                                <div>
                                    <Box margin={{bottom: 'xxxs'}} color="text-label">
                                        Operation Time TP50
                                    </Box>
                                    <div>{TPHelper(operationTimes, 2, "TP50", "hours")} hours
                                        ({TPHelper(operationTimes, 2, "TP50", "days")} days)
                                    </div>
                                </div>
                                <div>
                                    <Box margin={{bottom: 'xxxs'}} color="text-label">
                                        Operation Time TP90
                                    </Box>
                                    <div>{TPHelper(operationTimes, 2, "TP90", "hours")} hours
                                        ({TPHelper(operationTimes, 2, "TP90", "days")} days)
                                    </div>
                                </div>
                            </Grid>
                        )
                    }
                })}
            </Container>
            <Container
                id={"LatencyLineChart"}
                header={
                    <Header variant={"h2"}
                            description={"Line chart for operation idle & operation time"}>
                        Operation latencies
                    </Header>
                }
            >
                <LineChart
                    height={220}
                    yDomain={[0, maximumLatency + 20]}
                    xDomain={lineChartList?.get("instanceCreationDates") || []}
                    series={lineChartList?.get("lines") || []}
                    i18nStrings={{
                        filterLabel: "Filter displayed data",
                        filterPlaceholder: "Filter operations data",
                        xTickFormatter: t =>
                            Moment(t).format('YYYY-MM-DDTHH')
                    }}
                    xTitle="Date"
                    yTitle="Time (hours)"
                    ariaLabel="Idle time and operation time stacked bar chart"
                    errorText="Error loading data."
                    loadingText="Loading chart"
                    recoveryText="Retry"
                    statusType="finished"
                    xScaleType="categorical"
                    empty={noDataMessage}
                />
            </Container>
            {
                idleAndOperationArray.map(({key, value}) => {
                    if (key != "instanceCreationDates") {
                        return (
                            <Container
                                id={key + "chart"}
                                header={
                                    <Header variant={"h2"}
                                            description={"Viewing idle time and operation time of operation" + key}>
                                        {key}
                                    </Header>
                                }
                            >
                                <BarChart
                                    height={220}
                                    yDomain={[0, maximumLatency + 20]}
                                    xDomain={idleAndOperation.get("instanceCreationDates") as Date[]}
                                    series={[
                                        {
                                            title: "Idle",
                                            type: "bar",
                                            color: "red",
                                            data: (value as idleOperationInfoType).idleTimes.map((idleTime: idleTimeType) => {
                                                return {
                                                    x: idleTime["resolveDate"],
                                                    y: idleTime["idleTime"] / (60 * 60 * 1000)
                                                }
                                            }),
                                            valueFormatter: chartDateFormatter
                                        },
                                        {
                                            title: "Process",
                                            type: "bar",
                                            color: "green",
                                            data: (value as idleOperationInfoType).operationTimes.map((operationTime: operationTimeType) => {
                                                return {
                                                    x: operationTime["resolveDate"],
                                                    y: operationTime["operationTime"] / (60 * 60 * 1000)
                                                }
                                            }),
                                            valueFormatter: chartDateFormatter
                                        },
                                    ]}
                                    stackedBars
                                    i18nStrings={{
                                        filterLabel: "Filter displayed data",
                                        filterPlaceholder: "Filter data",
                                        xTickFormatter: t =>
                                            Moment(t).format('YYYY-MM-DDTHH')
                                    }}
                                    xTitle="Date"
                                    yTitle="Time (hours)"
                                    ariaLabel="Idle time and operation time stacked bar chart"
                                    errorText="Error loading data."
                                    loadingText="Loading chart"
                                    recoveryText="Retry"
                                    statusType="finished"
                                    xScaleType="categorical"
                                    empty={noDataMessage}
                                />
                            </Container>
                        )
                    }
                })
            }
        </Grid>
    )
}