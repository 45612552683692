import {
    DetailedVirtualPipelineNode
} from "@amzn/sm-virtual-pipeline-lambda-js-client/lib/smvirtualpipelinelambda";
import {ElementDefinition} from "cytoscape";

/**
 * A helper function to create a list of elements for visualization using Cytoscape.
 * Including nodes, edges.
 * @param pipelineInformation: A detailed virtual pipeline target dataset node.
 * @constructor
 */
export function PipelineExtractor(
    pipelineInformation: DetailedVirtualPipelineNode
) {

    function addNodeHelper(
        pipelineInformation: DetailedVirtualPipelineNode,
        currentId: number
    ) {
        let elements: Array<any> = []
        if (pipelineInformation["type"] === "DATASET") {
            elements.push({
                data: {
                    id: currentId,
                    label: pipelineInformation["id"],
                    type: "dataset"
                },
                style: {
                    'background-color': '#3ec99a',
                }
            })
        } else {
            elements.push({
                data: {
                    id: currentId,
                    label: pipelineInformation["id"],
                    type: "operation",
                    isExpanded: "false"
                },
                style: {
                    'background-color': '#3ec1d3',
                    'shape': 'round-triangle'
                }
            })
        }
        return {
            newElements: elements,
            newCurrentId: currentId
        }
    }

    function recursive(
        pipelineInformation: DetailedVirtualPipelineNode,
        currentId: number = 0
    ) {
        let elements: Array<any> = []
        let notDisplayedElements: {[key: number]: ElementDefinition[]} = {}
        if (pipelineInformation != null) {
            if (currentId == 0) {
                elements.push({
                    data: {
                        id: currentId,
                        label: pipelineInformation["id"],
                        type: "dataset",
                    },
                    style: {
                        'background-color': '#ff165d'
                    }
                })
            } else {
                let {newElements, newCurrentId} = addNodeHelper(pipelineInformation, currentId)
                currentId = newCurrentId
                elements = elements.concat(newElements)
            }
            if (pipelineInformation["parents"] != null) {
                let previousNodeIdRecord: number = -1
                let maximumIdRecord: number = -1
                let notInVirtualPipelineDatasetIdCounter = 0;
                notDisplayedElements[currentId] = []
                pipelineInformation["parents"].map(parent => {
                    if (parent["type"] === "OPERATION") {
                        const {returnedElem, notDisplayedElem, previousNodeId, maximumId} = recursive(parent,
                            (maximumIdRecord == -1 ? currentId : maximumIdRecord) + 1)!
                        previousNodeIdRecord = previousNodeId
                        maximumIdRecord = maximumId
                        elements = elements.concat(returnedElem)
                        notDisplayedElements = Object.assign(notDisplayedElements, notDisplayedElements, notDisplayedElem)
                        elements.push({
                            data: {
                                source: currentId,
                                target: previousNodeId,
                                label: "From " + parent["id"] + " to " + pipelineInformation["id"],
                                type: "edge"
                            }
                        })
                    } else {
                        if (parent["isInVirtualPipeline"]) {
                            const {returnedElem, notDisplayedElem, previousNodeId, maximumId} = recursive(parent,
                                (maximumIdRecord == -1 ? currentId : maximumIdRecord) + 1)!
                            previousNodeIdRecord = previousNodeId
                            maximumIdRecord = maximumId
                            elements = elements.concat(returnedElem)
                            notDisplayedElements = Object.assign(notDisplayedElements, notDisplayedElements, notDisplayedElem)
                            elements.push({
                                data: {
                                    source: currentId,
                                    target: previousNodeId,
                                    label: "From " + parent["id"] + " to " + pipelineInformation["id"],
                                    type: "edge"
                                }
                            })
                        } else {
                            notDisplayedElements[currentId].push({
                                data: {
                                    id: currentId.toString() + "." + notInVirtualPipelineDatasetIdCounter.toString(),
                                    label: parent["id"],
                                    type: "consumedDataset"
                                },
                                style: {
                                    'background-color': 'grey',
                                }
                            })
                            notDisplayedElements[currentId].push({
                                data: {
                                    source: currentId,
                                    target: currentId.toString() + "." + notInVirtualPipelineDatasetIdCounter.toString(),
                                    label: "From " + pipelineInformation["id"] + " to " + parent["id"],
                                    type: "consumedDatasetEdge"
                                }
                            })
                            notInVirtualPipelineDatasetIdCounter += 1
                        }
                    }
                })
                return {
                    returnedElem: elements,
                    notDisplayedElem: notDisplayedElements,
                    maximumIdRecord: maximumIdRecord,
                    previousNodeId: currentId,
                    maximumId: currentId >= maximumIdRecord ? currentId : maximumIdRecord
                };
            } else {
                return {
                    returnedElem: elements,
                    notDisplayedElem: notDisplayedElements,
                    maximumIdRecord: currentId,
                    previousNodeId: currentId,
                    maximumId: currentId
                }
            }
        }
    }

    const returned = recursive(pipelineInformation)!
    if (returned != null) {
        return {
            returnedElems: returned.returnedElem,
            notDisplayedElems: returned.notDisplayedElem,
            numberOfNodes: returned.maximumIdRecord
        }
    } else {
        return {
            returnedElems: [],
            notDisplayedElems: [],
            numberOfNodes: 0,
        }
    }

}
