import React, {useEffect, useState} from "react"
import {Alert, Spinner} from "@amzn/awsui-components-react-v3";
import {ExecutionSummary} from "src/model/ExecutionSummary";
import {AppRoutes} from "src/components/common/router-common";
import {UrlHelper} from "src/utils/url-helper";
import {SwoUiMetrics} from "src/utils/swo-ui-metrics/swo-ui-metrics";
import {metricFeaturesList, metricPageNames} from "src/constants/swo-ui-metric-constants";

/**
 * When user click execution count in stacked area chart tooltip, it'll reach this component.
 * It'll fetch redirection context and open right page.
 * @constructor
 */
export function ExecutionSummaryRedirectPage() {
    const [fetchingFailed, setFetchingFailed] = useState<boolean>(false)
    const redirectKey = UrlHelper.getParamValue("redirectionContext")
    const esQuery = UrlHelper.getParamValue(UrlHelper.ES_QUERY_PARAMS_KEY)

    useEffect(() => {
        if (redirectKey == null) {
            return;
        }
        SwoUiMetrics.publishCloudWatchCountMetric(metricFeaturesList.redirectURL, metricPageNames.workflowAggregateInstanceView)

        new ExecutionSummary().getRedirectURL(esQuery || "", redirectKey )
            .then((resp) => {
                window.location.href = `${AppRoutes.executionList}?esQuery=${encodeURIComponent(resp.queryString)}`
                setFetchingFailed(false)
            })
            .catch((e) => {
                console.error("Failed to find redirect url", e)
                setFetchingFailed(true)
            })
    }, [])
    return <>{fetchingFailed ?
        <Alert type={"error"} data-testid={"execution-summary-redirection-failure-alert"}>Failed to find redirect url. Please refresh the page and try again.</Alert> :
        <> <Spinner size={"big"}/> Fetching redirection url. Please wait... </>
    }</>
}