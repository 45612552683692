import AppLayout from '@amzn/awsui-components-react-v3/polaris/app-layout';
import React, {useState} from 'react';
import {appLayoutNavigationLabels} from 'src/common/labels';
import {ServiceNavigation} from 'src/components/navigation';
import 'src/styles/index.scss';
import {AppRoutes, BreadcrumbGroupWithRouter} from "src/components/common/router-common";
import {VirtualPipelineList} from "src/components/virtual-pipeline/virtual-pipeline-list";

/**
 * The virtual pipelines table page.
 * @constructor
 */
export default function VirtualPipeline() {
    const [navigationOpen, setNavigationOpen] = useState(false);
    const items = [
        {
            "text": "Home",
            "href": "/"
        },
        {
            "text": "Virtual Pipeline List",
            "href": AppRoutes.virtualPipelines
        }
    ]
    return (
        <AppLayout
            navigation={<ServiceNavigation/>}
            breadcrumbs={<BreadcrumbGroupWithRouter items={items}/>}
            content={<VirtualPipelineList/>}
            contentType="table"
            disableContentPaddings={false}
            navigationOpen={navigationOpen}
            onNavigationChange={(event: any) => setNavigationOpen(event.detail.open)}
            toolsHide={true}
            ariaLabels={appLayoutNavigationLabels}
        />
    );
}