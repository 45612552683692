import React, {useCallback, useEffect, useRef, useMemo, useState} from 'react';
import {SelectedItemRequiredKeysInterface, WorkflowInstanceRestartConfigOverrideI} from "src/interfaces/domain-tracker";
import {DomainTracker} from "src/model/DomainTracker";
import {toast} from "react-toastify";
import {Alert, Checkbox, Spinner} from "@amzn/awsui-components-react";
import {
    GetRecipeExecutionDetailsResult,
    GetWorkflowDetailsResult
} from "@amzn/sm-workflow-orchestration-service-js-client/lib/smworkfloworchestrationservicelambda";
import {AjvError} from "@rjsf/core";
import {
    checkIfInputIsCamelCase,
    convertCamelCaseStringToWords,
    convertCharacterSeperatedStringToWords
} from "src/utils/string-helper";
import Select from "@amzn/awsui-components-react/polaris/select";
import {TabInputForm} from "src/components/common/tab-input-form";
import Button from "@amzn/awsui-components-react/polaris/button";
import {UserInputError} from "src/components/common/error-message-helper";
import {VALID_RESTART_REQUEST_TIME_WINDOW} from "src/constants/workflow-instance";
import {STATES} from "src/interfaces/workflow";
const RECIPE_EXECUTION_INPUT = "RecipeExecutionInput";

/**
 * Create JSON schema based form to provide input while restarting from any stage.
 * @param props
 * @constructor
 */
export function ExecutionRestartInputOverride(props: WorkflowInstanceRestartConfigOverrideI) {
    const [enableInputOverride, setEnableInputOverride] = useState<boolean>(false);
    const [overrideRecentlyUpdatedItems, setOverrideRecentlyUpdatedItems] = useState<boolean>(false);
    const [userInputJsonSchema, setUserInputJsonSchema] = useState<Record<string, string>>({})
    const [userInputFormData, setUserInputFormData] = useState<{[key: string]: any}>({})
    const [workflowDetails, setWorkflowDetails] = useState<GetWorkflowDetailsResult>({})
    const fieldItemsRef = useRef<Array<HTMLButtonElement | null>>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [errorListMap, setErrorListMap] = useState<{ [key: string]: AjvError[] }>({})
    const [recentlyUpdatedItems, setRecentlyUpdatedItems] = useState<Array<GetRecipeExecutionDetailsResult>>([]);
    const selectedItem = props.selectedItems[0]
    const isMoreThanOneRecordSelected = props.selectedItems.length > 1;
    const useStageInputSchema = useRef(true);

    function validateAndSetSchema(recipeExecutionDetailsResult: GetRecipeExecutionDetailsResult) {
        if ((recipeExecutionDetailsResult.recipeExecutionInputSchema != undefined) && (recipeExecutionDetailsResult.recipeExecutionInputSchema != "")) {
            try{
                JSON.parse(recipeExecutionDetailsResult.recipeExecutionInputSchema);
                const recipeExecutionInputSchema = {[RECIPE_EXECUTION_INPUT]: recipeExecutionDetailsResult.recipeExecutionInputSchema || "{}"};
                setUserInputJsonSchema(recipeExecutionInputSchema);
            }catch {
                toast.error("Error while parsing execution input schema");
            }
        }
    }

    function validateAndSetInputForm(recipeExecutionDetailsResult: GetRecipeExecutionDetailsResult) {
        if ((recipeExecutionDetailsResult.recipeExecutionInput != undefined) && (recipeExecutionDetailsResult.recipeExecutionInput != "")) {
            try {
                const stageInputSchema = JSON.parse(recipeExecutionDetailsResult.recipeExecutionInput);
                setUserInputFormData(stageInputSchema);
            } catch (error) {
                toast.error("Error while parsing execution input");
                setUserInputFormData({});
            }
        }
    }

    const populateRestartStageOptions = (
        recipeExecutionDetailsResult: GetRecipeExecutionDetailsResult
    ) => {
        const workflowStages = recipeExecutionDetailsResult.stages || [];
        const stagesOptions: Array<Select.IOption | Select.Option | Select.OptionsGroup> =  workflowStages
            .map(stage => {
               return  {
                   id:stage.name,
                   label: checkIfInputIsCamelCase(stage.name) ? convertCamelCaseStringToWords(stage.name) : convertCharacterSeperatedStringToWords(stage.name)
                }
            });
        /**
         * for bulk restart show default options only
         * */
        isMoreThanOneRecordSelected ?  props.setRestartStageOptions([]) : props.setRestartStageOptions(stagesOptions);
    }

    async function retrieveJSONSchema() {
        setLoading(true)
        const domainTracker = new DomainTracker();
        setRecentlyUpdatedSelectedItems(domainTracker);
        const workflowInstanceId = selectedItem.workflowId
        try{
            const recipeExecutionDetails = await domainTracker.getWorkflowInstance(workflowInstanceId);
            validateAndSetSchema(recipeExecutionDetails);
            validateAndSetInputForm(recipeExecutionDetails);
            populateRestartStageOptions(recipeExecutionDetails);
            const {recipeId, recipeVersion} = recipeExecutionDetails;
            const workflowDetails = await domainTracker.getWorkflowDetails(recipeId, recipeVersion)
            if(Object.entries(workflowDetails?.stageInstanceInputSchemaMap || {}).length > 0){
                fieldItemsRef.current = fieldItemsRef.current.slice(0, Object.keys(workflowDetails.stageInstanceInputSchemaMap || {}).length);
                setWorkflowDetails({
                    stageInstanceInputSchemaMap: workflowDetails.stageInstanceInputSchemaMap,
                    restartUiSchemaMap: workflowDetails.restartUiSchemaMap,
                    stages: workflowDetails.stages
                })
                useStageInputSchema.current = true;
            }else{
                useStageInputSchema.current = false;
            }
        }catch (e){
            toast.error("Failed to retrieve workflow execution details");
            setEnableInputOverride(false);
            setUserInputFormData({});
            setUserInputJsonSchema({});
            setWorkflowDetails({})
        }
        finally {
            setLoading(false)
        }
    }

    const getUserInputFormData = useCallback((formData: Record<string, Object>) => {
        return useStageInputSchema.current ? formData
            : formData && formData[RECIPE_EXECUTION_INPUT];
    }, [useStageInputSchema, userInputFormData])


    function handleFormSubmit() {
        const stageInstanceInputSchemaMap = Object.entries(workflowDetails.stageInstanceInputSchemaMap || {});
        if(stageInstanceInputSchemaMap.length && enableInputOverride) {
            stageInstanceInputSchemaMap.forEach(([key, value], index) => {
                fieldItemsRef.current[index]?.click()
            })
        }else{
            props.onRestartSubmitButton(getUserInputFormData(userInputFormData), enableInputOverride);
        }
    }

    const onTabFormDataChange = useCallback((formInput, tabId) => {
        setUserInputFormData(prevUserInputFormData => ({...prevUserInputFormData, [tabId]: formInput.formData}));
    }, [userInputFormData, setUserInputFormData])

    const onTabFormDataSubmit = useCallback((tabId: string, tabIndex: number) => {
        delete errorListMap[tabId]
        const newErrorListMap = {...errorListMap};
        setErrorListMap(newErrorListMap);
        if (tabIndex + 1 === Object.keys(workflowDetails.stageInstanceInputSchemaMap || {}).length
            && Object.keys(newErrorListMap).length === 0) {
            props.onRestartSubmitButton(getUserInputFormData(userInputFormData), enableInputOverride);
        }
    }, [userInputFormData, errorListMap, workflowDetails, setErrorListMap, props.onRestartSubmitButton])

    const onTabFormDataError = useCallback((err: AjvError[], tabId: string) => {
        const newErrorListMap = {...errorListMap, [tabId]: err};
        setErrorListMap(newErrorListMap)
    }, [setErrorListMap])


    function handleEnableInputOverrideChange(e: CustomEvent<Checkbox.ChangeDetail>) {
        const checked: boolean = e.detail.checked
        setEnableInputOverride(checked)
    }

    function handleOverrideRecentlyUpdatedItems(e: CustomEvent<Checkbox.ChangeDetail>) {
        const checked: boolean = e.detail.checked
        setOverrideRecentlyUpdatedItems(checked)
    }

    /**
     * Fetch workflow instance details for the selected items
     * and filter to only those with status "Running" updated within VALID_RESTART_REQUEST_TIME_WINDOW
     */
    async function setRecentlyUpdatedSelectedItems(domainTracker: DomainTracker) {

        const getWorkflowInstancePromises = props.selectedItems.map(item =>
            domainTracker.getWorkflowInstance(item.workflowId)
        );
        const recipeExecutionDetailsList = await Promise.all(getWorkflowInstancePromises).catch(e => {
            console.error("Error while getting workflow instance details", e);
            return [];
        });
        const currentTimeInMs = new Date().getTime();
        const recentlyUpdatedItems = recipeExecutionDetailsList.filter(recipeExecutionDetail => {
            const timeSinceLastUpdate = currentTimeInMs - (recipeExecutionDetail.lastUpdateTimestamp ?? 0);
            return (recipeExecutionDetail.recipeExecutionState === STATES.RUNNING
                && timeSinceLastUpdate < VALID_RESTART_REQUEST_TIME_WINDOW);
        });
        setRecentlyUpdatedItems(recentlyUpdatedItems);
    }

    const isRestartAllowed = useMemo(() => {
        return (props.disableWorkflowRestart ||
            !overrideRecentlyUpdatedItems && recentlyUpdatedItems.length > 0)
    }, [props.disableWorkflowRestart, overrideRecentlyUpdatedItems, recentlyUpdatedItems]);

    const isRestartWarningVisible = useMemo(() => {
        return !(loading || recentlyUpdatedItems.length == 0)
    }, [loading, recentlyUpdatedItems])


    useEffect(() => {
        setEnableInputOverride(false);
        setUserInputFormData({});
        setUserInputJsonSchema({});
        setWorkflowDetails({});
        setRecentlyUpdatedItems([]);
        setOverrideRecentlyUpdatedItems(false);
        props.modalVisible && retrieveJSONSchema();
    }, [props.selectedItems, props.modalVisible])

    return <>
        {isMoreThanOneRecordSelected ?
            <div>
                <Alert className={"awsui-util-pb-l"}
                      data-testid={"execution-restart-input-override-not-supported-alert"}>

                    <b>Input override</b> feature is not supported for bulk restart.
                </Alert>

                <Alert visible={isRestartWarningVisible} type={"warning"} data-testid={"execution-restart-confirmation-alert"}>
                <Checkbox className={"awsui-util-pb-l"} checked={overrideRecentlyUpdatedItems}
                          data-testid={"execution-restart-alert-override-checkbox"}
                          onChange={handleOverrideRecentlyUpdatedItems}>
                    <p>{recentlyUpdatedItems.length} out of {props.selectedItems.length} selected
                        workflow(s) have an active update within the last 5 mins. Are you sure you want to continue with restart?</p>
                </Checkbox>
                </Alert>
            </div>
            :
            <div>
                <Checkbox className={"awsui-util-pb-l"} checked={enableInputOverride}
                          data-testid={"execution-restart-input-override-checkbox"}
                          onChange={handleEnableInputOverrideChange} disabled={loading}>
                    Override input?
                </Checkbox>

                <Alert data-testid={"execution-restart-confirmation-alert"}
                       visible={isRestartWarningVisible} type={"warning"}>
                    <Checkbox className={"awsui-util-pb-l"} checked={overrideRecentlyUpdatedItems}
                            data-testid={"execution-restart-alert-override-checkbox"}
                            onChange={handleOverrideRecentlyUpdatedItems}>
                        <p>Selected workflow has an active update within the last 5 mins. Are you sure you want to continue with restart?</p>
                    </Checkbox>
                </Alert>
            </div>
        }

        {loading ? <><Spinner size={"big"}/> Loading execution details</> :
            <>
                {userInputJsonSchema == undefined &&
                <Alert>Override input is not available for this workflow instance</Alert>
                }
            </>
        }
        {enableInputOverride && <div className={"col-xxs-12"}>
            <TabInputForm
                stageInstanceInputSchemaMap={workflowDetails.stageInstanceInputSchemaMap || userInputJsonSchema}
                formInputData={userInputFormData}
                onTabFormChange={onTabFormDataChange}
                onTabFormSubmit={onTabFormDataSubmit}
                onTabFormError={onTabFormDataError}
                ref={fieldItemsRef}
                enableLiveValidate={true}
                stageInstanceUISchemaMap={workflowDetails.restartUiSchemaMap}
                stageRenderingOrder={workflowDetails.stages}
            />
        </div>}
        {errorListMap.length && <UserInputError errorListMap={errorListMap}/>}
        <div className={"col-xxs-12 awsui-util-pv-l"}>
            <Button text={"Cancel"}
                    id={"restart-execution-cancel-button"}
                    onClick={props.closeRestartDialog}
                    loading={props.workflowDetailsLoading ?? false}
            />
            <Button variant="primary"
                    className={"col-xxs-12"}
                    disabled={isRestartAllowed} text={"Restart"}
                    loading={props.workflowDetailsLoading ?? false}
                    data-testid={"execution-restart-submit-button"}
                    id={"restart-execution-submit-button"}
                    onClick={handleFormSubmit}
            />
        </div>
    </>
}
