const downloadFileFromBlobUrl = (blob: string, filename: string) => {
    var a = document.createElement('a');
    a.download = filename;
    a.href = blob;
    document.body.appendChild(a);
    a.click();
    a.remove();
}

export const downloadResourceFromUrl = async (url: string, fileName: string) => {
    const resource = await fetch(url, {
        mode: 'cors'
    });

    const resourceBlob = await resource.blob();
    const blobUrl = window.URL.createObjectURL(resourceBlob);
    downloadFileFromBlobUrl(blobUrl, fileName);
}
