import {SMSWOUnifiedDashboardServiceLambda} from "@amzn/swo-unified-dashboard-service-lambda-js-client";
import {unifiedDashboardAPIConfig} from "src/utils/api-gateway/swo-ud-api-mappings";
import {getStage} from "src/utils/env";
import {getUserAlias} from "src/utils/cookie-helper";
import {APPLICATION_NAME} from "src/utils/app-constants";
import {
    GetWorkflowListRequest
} from "@amzn/swo-unified-dashboard-service-lambda-js-client/lib/smswounifieddashboardservicelambda";

export class WorkflowList {
    private unifiedDashboardServiceLambda: SMSWOUnifiedDashboardServiceLambda;

    constructor() {
        const unifiedDashboardConfig = unifiedDashboardAPIConfig(getStage());
        this.unifiedDashboardServiceLambda = new SMSWOUnifiedDashboardServiceLambda({
            endpoint: `https://${unifiedDashboardConfig.endpoint}/${unifiedDashboardConfig.stage}`,
            region: unifiedDashboardConfig.region
        })
    }

    async geWorkflowList(requestParams: Omit<GetWorkflowListRequest, 'requesterSystemType'|'requesterUserName'>) {
        let defaultRequestParams = {
            requesterSystemType: APPLICATION_NAME,
            requesterUserName: getUserAlias()
        }

        return this.unifiedDashboardServiceLambda
            .getWorkflowList({...requestParams, ...defaultRequestParams}).promise();
    }
}
