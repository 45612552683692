import React from 'react';
import {Box, Container, Header, Link, Popover, Table} from '@amzn/awsui-components-react-v3';
import {Link as LinkRouter} from 'react-router-dom';
import {QueryParams} from "src/components/sm-overview-dashboards/common/query-params";
import {
    DataStoreFields, FilterOperation,
    QueryVerb
} from "src/components/sm-overview-dashboards/workflow-list-view/filters/filter-constants";
import {
    mergeFiltersToFilterQuery
} from "src/components/sm-overview-dashboards/workflow-list-view/filters/filters.utils";
import {UserActionsInfoBar} from "src/components/sm-overview-dashboards/common/UserActionsInfoBar";
import {metricFeaturesList} from "src/constants/swo-ui-metric-constants";
import {DashboardRefreshTime} from "src/components/sm-overview-dashboards/common/dashboard-refresh-time";
import {FilterInput} from "src/components/sm-overview-dashboards/workflow-list-view/filters/filterInput";
import {
    programSummaryColumnDefinitions
} from "src/components/sm-overview-dashboards/summary-view/summary-column-config";
import {PROGRAM_SUMMARY_HEADER} from "src/components/sm-overview-dashboards/summary-view/program-summary-view";
import {SummaryData, SummaryDataView} from "src/interfaces/SMSummaryInterface";
import {TableProps} from "@amzn/awsui-components-react-v3/polaris/table/interfaces";

export const ExternalDashboardLink: React.FunctionComponent<{displayNode: React.ReactNode | null, externalLink: string}> = ({displayNode, externalLink}) => {
    return <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        <LinkRouter
            to={{pathname: externalLink}} target="_blank">
            <Link external>{displayNode}</Link>
        </LinkRouter>
        {!displayNode && <p style={{marginLeft: '4px'}}>
            <Popover
                dismissButton={true}
                position="top"
                size="small"
                triggerType="custom"
                content={
                    <p>
                        {'The metrics number will be visible on dashboard soon'}
                    </p>
                }
            >
                <Link variant="info">Info</Link>
            </Popover>
        </p>}
    </div>
}

export const InternalDiveDeepLink: React.FunctionComponent<{displayNode: React.ReactNode, filterQuery: string, route: string, program: string}>
    = ({displayNode, filterQuery, route, program}) => {
    return <>
        <LinkRouter
            to={`${route}?${QueryParams.FILTER_QUERY_KEY}=${mergeFiltersToFilterQuery(filterQuery, {[`${QueryVerb.MUST}::${FilterOperation.MATCH_ANY}:${DataStoreFields.PROGRAM}`]: program})}`}>
            <Link external>{displayNode}</Link>
        </LinkRouter>
    </>
}

export const DiveDeepLink: React.FunctionComponent<{displayNode: React.ReactNode, filterQuery: string, route: string, aggregateKey: string, aggregateKeyValue: string}>
    = ({displayNode, filterQuery, route, aggregateKey, aggregateKeyValue}) => {
    return <>
        <LinkRouter
            to={`${route}?${QueryParams.FILTER_QUERY_KEY}=${mergeFiltersToFilterQuery(filterQuery, {[`${QueryVerb.MUST}::${FilterOperation.MATCH_ANY}:${aggregateKey}`]: aggregateKeyValue})}`}>
            <Link external>{displayNode}</Link>
        </LinkRouter>
    </>
}

export interface SummaryViewProps {
    summaryData: SummaryData[],
    columnDefinitions: TableProps.ColumnDefinition<SummaryData>[]
    loading?: boolean,
    hasErrors?: boolean,
    viewName?: string,
    headerName?: string
}

export const SummaryView: React.FunctionComponent<SummaryViewProps> = ({
        summaryData,
        columnDefinitions,
        loading,
        hasErrors,
        viewName,
        headerName
    }) => {
    return <>
        <Box padding={{vertical: "s", horizontal: "l"}}>
            <UserActionsInfoBar view={viewName || ''} hasError={hasErrors}/>
            <DashboardRefreshTime />
        </Box>
        <Box padding={{vertical: "s", horizontal: "xl"}}>
            <FilterInput />
        </Box>
        <Box padding={{vertical: "xxl", horizontal: "xl"}} data-testid={'program-summary-table'}>
            <Container
                disableContentPaddings
            >
                <Table
                    items={summaryData}
                    // test this
                    columnDefinitions={columnDefinitions}
                    loading={loading}
                    wrapLines
                    header={
                        <Header
                            variant={"h2"}
                        >
                            {headerName}
                        </Header>
                    }
                />
            </Container>
        </Box>
    </>
}
