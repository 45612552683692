import React, {useState} from "react";
import {AppRoutes, BreadcrumbGroupWithRouter} from "src/components/common/router-common";
import AppLayout from "@amzn/awsui-components-react-v3/polaris/app-layout";
import {ServiceNavigation} from "src/components/navigation";
import {VirtualPipelineVisualizationDashboard} from "src/components/virtual-pipeline/virtual-pipeline-visualization/virtual-pipeline-visualization-dashboard";

/**
 * Virtual pipeline visualization page.
 * @param props: contains vpId coming from the url.
 * @constructor
 */
export default function VirtualPipelineVisualization(props: { match: { params: { [x: string]: any; }; }; }) {
    const [navigationOpen, setNavigationOpen] = useState(false);
    const vpId = props.match.params["vpId"]

    const items = [
        {
            "text": "Home",
            "href": "/"
        },
        {
            "text": "Virtual Pipeline",
            "href": AppRoutes.virtualPipelines
        },
        {
            "text": vpId,
            "href": vpId
        }
    ]
    return (
        <AppLayout
            navigation={<ServiceNavigation/>}
            breadcrumbs={<BreadcrumbGroupWithRouter items={items}/>}
            navigationOpen={navigationOpen}
            onNavigationChange={(event: any) => setNavigationOpen(event.detail.open)}
            content={<VirtualPipelineVisualizationDashboard vpId={vpId}/>}
        />
    );
}