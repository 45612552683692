import React, {useEffect} from "react"
import {AttributeFilterItemsI, FiltersI} from "src/interfaces/attribute-filters";
import Container from "@amzn/awsui-components-react-v3/polaris/container";
import {Box, Button, ExpandableSection, Header, SpaceBetween} from "@amzn/awsui-components-react-v3";
import {PrimaryFilter} from "src/components/filters/primary-filter";
import {SecondaryFilter} from "src/components/filters/secondary-filter";
import produce from "immer";
import {getAttributeFilterHeader} from "src/utils/table-helper";
import "src/styles/filters.scss"

/**
 * Filter to be displayed in tables and aggregate dashboards based on Polaris attribute editor.
 * https://polaris.a2z.com/components/awsui-attribute-editor/?example=default
 * @param props
 * @constructor
 */
export function Filters(props: FiltersI) {
    const [primaryFilters, setPrimaryFilters] = React.useState<Array<AttributeFilterItemsI>>([]);
    const [secondaryFilters, setSecondaryFilters] = React.useState<Array<AttributeFilterItemsI>>([]);
    const [hiddenFilters, setHiddenFilters] = React.useState<Array<AttributeFilterItemsI>>([]);

    useEffect(() => {
        const recordWithColDef = produce(props.attributeFilterItems, (draft: Array<AttributeFilterItemsI>) => {
            draft.forEach(draftTemp => {
                const metadata = props.tableMetadata[draftTemp.attributeName?.value || ""]
                if (metadata != undefined) {
                    draftTemp.columnDefinition = metadata
                }
            })
        })
        const secondaryItemsFiltered: Array<AttributeFilterItemsI> = recordWithColDef.filter(rec => {
            return rec.columnDefinition == null ? false : (!rec.columnDefinition?.uiFeatures.onTopFilter && rec.columnDefinition?.uiFeatures.visible)
        })
        const primaryItemsFiltered: Array<AttributeFilterItemsI> = recordWithColDef.filter(rec => {
            return rec.columnDefinition == null ? false : (rec.columnDefinition?.uiFeatures.onTopFilter && rec.columnDefinition?.uiFeatures.visible)
        })

        const hiddenItemsFiltered: Array<AttributeFilterItemsI> = recordWithColDef.filter(rec => {
            return rec.columnDefinition == null ? false : !rec.columnDefinition?.uiFeatures.visible
        })

        setPrimaryFilters(primaryItemsFiltered)
        setSecondaryFilters(secondaryItemsFiltered)
        setHiddenFilters(hiddenItemsFiltered)
    }, [props.tableMetadata, props.attributeFilterItems, props.loading])

    return <>
        <ExpandableSection
            variant="container"
            defaultExpanded={true}
            disableContentPaddings={true}
            data-testid={"filters-expandable-section"}
            header={
                <Header variant="h2">
                    {getAttributeFilterHeader(props.tableType)}
                </Header>
            }
        >

            <Container
                footer={
                    <Box>
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="primary"
                                    data-testid={"filters-submit-button"}
                                    onClick={e => props.onSubmitAttributeFilterItems([...secondaryFilters, ...primaryFilters, ...hiddenFilters])}
                                    loading={props.loading}
                                disabled={props.loading}
                            >
                                Search
                            </Button>
                        </SpaceBetween>
                    </Box>
                }
            >
                {!props.hidePrimaryFilter &&
                <Box data-testid={"primary-filter-index"}>
                    <PrimaryFilter
                        tableMetadata={props.tableMetadata}
                        attributeFilterItems={primaryFilters}
                        onFilteringTokenUpdate={(primaryFilters: Array<AttributeFilterItemsI>) => setPrimaryFilters(primaryFilters)}
                        loading={props.loading} tableType={props.tableType}
                    />
                </Box>
                }
                {!props.hideSecondaryFilter &&
                <Box data-testid={"secondary-filter-index"}>
                    <SecondaryFilter
                        tableMetadata={props.tableMetadata}
                        attributeFilterItems={secondaryFilters}
                        loading={props.loading}
                        tableType={props.tableType}
                        onFilteringTokenUpdate={(secondaryFilters: Array<AttributeFilterItemsI>) =>
                            setSecondaryFilters(secondaryFilters)}
                    />
                </Box>
                }
            </Container>

        </ExpandableSection>
    </>
}