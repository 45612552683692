import {
    DatasetInstanceNode,
    ListOfVirtualPipelineInstances
} from "@amzn/sm-virtual-pipeline-lambda-js-client/lib/smvirtualpipelinelambda";

/**
 * A function to extract idle time and operation time of operations from each vp instances.
 * @param pipelineInstances
 * @constructor
 */
export function IdleAndOperationLatencyExtractor(
    pipelineInstances: ListOfVirtualPipelineInstances
) {

    function createInstanceNodesMap(
        pipelineInstanceInformation: DatasetInstanceNode
    ) {
        const operationNodes = new Map<string, idleOperationInfoType | Date[]>()
        let currentInstanceNode = pipelineInstanceInformation
        let exitFlag = true

        operationNodes.set("instanceCreationDates", [])
        while (currentInstanceNode.parents?.length != 0 || exitFlag) {
            const emptyIdleAndOperationInfo: idleOperationInfoType = {
                idleTimes: [],
                operationTimes: []

            }
            operationNodes.set(currentInstanceNode.operationId!, emptyIdleAndOperationInfo)
            if (currentInstanceNode.parents?.length != 0) {
                currentInstanceNode = currentInstanceNode.parents![0]
            } else {
                exitFlag = false
            }
        }

        return operationNodes
    }

    if (pipelineInstances.length == 0) {
        return new Map<string, idleOperationInfoType | Date[]>()
    }

    const operationNodes = createInstanceNodesMap(pipelineInstances[0].pipelineInstanceInformation!)
    pipelineInstances.forEach(instance => {
        let currentInstanceNode = instance.pipelineInstanceInformation!
        let exitFlag = true
        const instanceCreationDate: Date = instance.creationDate;
        (operationNodes.get("instanceCreationDates")! as Date[]).push(instanceCreationDate)
        while (currentInstanceNode.parents?.length != 0 || exitFlag) {
            const idleTime: idleTimeType = {
                resolveDate: instanceCreationDate, // Use resolve time of operation consuming the parent node
                idleTime: currentInstanceNode.operationIdleTime![0]
            }

            const operationTime: operationTimeType = {
                resolveDate: instanceCreationDate, // Use current instance creation date
                operationTime: currentInstanceNode.operationProcessTime!
            }

            const emptyIdleAndOperationInfo: idleOperationInfoType = {
                idleTimes: [],
                operationTimes: []
            }

            if (operationNodes.get(currentInstanceNode.operationId!) === undefined) {
                operationNodes.set(currentInstanceNode.operationId!, emptyIdleAndOperationInfo)
            }
            (operationNodes.get(currentInstanceNode.operationId!) as idleOperationInfoType)["idleTimes"].push(idleTime);
            (operationNodes.get(currentInstanceNode.operationId!) as idleOperationInfoType)["operationTimes"].push(operationTime);
            if (currentInstanceNode.parents?.length != 0) {
                currentInstanceNode = currentInstanceNode.parents![0]
            } else {
                exitFlag = false
            }
        }
    })

    return operationNodes
}

export interface idleOperationInfoType {
    idleTimes: idleTimeType[]
    operationTimes: operationTimeType[]
}

export interface idleTimeType {
    resolveDate: Date
    idleTime: number
}

export interface operationTimeType {
    resolveDate: Date
    operationTime: number
}