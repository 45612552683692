import {
    Box,
    Button,
    Container,
    DateRangePickerProps,
    Header,
    SpaceBetween,
    Spinner
} from "@amzn/awsui-components-react-v3";
import { isWithinInterval, addHours, addSeconds, addMinutes, addWeeks, addMonths, addYears, addDays } from 'date-fns';
import {AppRoutes} from "src/components/common/router-common";
import {VirtualPipelineVisualization} from "src/constants/virtual-pipelines";
import {ButtonWithRouter} from "src/components/virtual-pipeline/common-components";
import React from "react";
import Moment from "moment";

export const differenceInDays = (startDate: string, endDate: string) => {
    const startDateMoment = Moment(startDate)
    const endDateMoment = Moment(endDate)
    return endDateMoment.diff(startDateMoment, "days")
};

export function isValidRangeFunction( value: DateRangePickerProps.Value | null):
    {valid: true} | {valid: false; errorMessage: string;} {
    if (value === null) {
        return {
            valid: false,
            errorMessage: 'Empty value.'
        }
    }
    if (value.type === 'absolute') {
        const [startDateWithoutTime] = value.startDate.split('T');
        const [endDateWithoutTime] = value.endDate.split('T');

        if (!startDateWithoutTime || !endDateWithoutTime) {
            return {
                valid: false,
                errorMessage: 'The selected date value is incomplete. Select a start and end date for the date value.'
            };
        }

        if (differenceInDays(value.startDate, value.endDate) < 0) {
            return {
                valid: false,
                errorMessage: 'Start date should never be after end date. Please specify a valid date range.'
            }
        }
    } else if (value.type === 'relative') {
        if (isNaN(value.amount)) {
            return {
                valid: false,
                errorMessage: 'The selected date value is incomplete. Specify a duration for the date value.'
            };
        }
    }
    return { valid: true };
}

export function formatRelativeRange(range: { amount: number; unit: any; }) {
    const unit = range.amount === 1 ? range.unit : `${range.unit}s`;
    return `Last ${range.amount} ${unit}`;
}

export function convertToAbsoluteRange(range: DateRangePickerProps.Value) {
    if (range.type === 'absolute') {
        return {
            start: new Date(range.startDate),
            end: new Date(range.endDate)
        };
    } else {
        const now = new Date();
        const start = (() => {
            // Neither month or year for relative date range
            switch (range.unit) {
                case 'year':
                    return addYears(now, -range.amount);
                default:
                    return addMonths(now, -range.amount);
            }
        })();
        return {
            start: start,
            end: now
        };
    }
}

export const dateRangeFilterI18nStrings = {
    todayAriaLabel: 'Today',
    nextMonthAriaLabel: 'Next month',
    previousMonthAriaLabel: 'Previous month',
    customRelativeRangeDurationLabel: 'Duration',
    customRelativeRangeDurationPlaceholder: 'Enter duration',
    customRelativeRangeOptionLabel: 'Custom duration',
    customRelativeRangeOptionDescription: 'Set a custom range in the past',
    customRelativeRangeUnitLabel: 'Unit of time',
    formatUnit: (unit: any, value: number) => (value === 1 ? unit : `${unit}s`),
    formatRelativeRange: formatRelativeRange,
    dateTimeConstraintText: '',
    relativeModeTitle: 'Relative range',
    absoluteModeTitle: 'Absolute range',
    relativeRangeSelectionHeading: 'Choose a range',
    startDateLabel: 'Start date',
    endDateLabel: 'End date',
    startTimeLabel: 'Start time',
    endTimeLabel: 'End time',
    clearButtonLabel: 'Clear',
    cancelButtonLabel: 'Cancel',
    applyButtonLabel: 'Apply'
};

export function timeConversion(duration: number, type: string = "hour") {
    const portions: string[] = [];

    if (type === "day") {
        const msInDay = 1000 * 60 * 60 * 24;
        const days = Math.trunc(duration / msInDay);
        if (days > 0) {
            portions.push(days + 'd');
            duration = duration - (days * msInDay)
        }
    }

    const msInHour = 1000 * 60 * 60;
    const hours = Math.trunc(duration / msInHour);
    if (hours > 0) {
        portions.push(hours + 'h');
        duration = duration - (hours * msInHour);
    }

    const msInMinute = 1000 * 60;
    const minutes = Math.trunc(duration / msInMinute);
    if (minutes > 0) {
        portions.push(minutes + 'm');
        duration = duration - (minutes * msInMinute);
    }

    const seconds = Math.trunc(duration / 1000);
    if (seconds > 0) {
        portions.push(seconds + 's');
    }

    return portions.join(' ');
}

export const PageHeader = (props:{vpId: string, vpName: string, isLoading: boolean}) => {
    if (props.isLoading) {
        return (
            <div className="awsui"><Spinner size={"large"}/>Loading...</div>
        )
    }

    return (
        <div className='awsui-util-mb-m awsui-util-mt-xs'>
            <div className='awsui-util-action-stripe-large '>
                <div className='awsui-util-action-stripe-title'>
                    <h1>{props.vpName}</h1>
                </div>
                <div className='awsui-util-action-stripe-group awsui-util-pv-n'>
                    <ButtonWithRouter
                        variant={"normal"}
                        href={`${AppRoutes.virtualPipelines}/visualization/${props.vpId}`}
                    >
                        {VirtualPipelineVisualization.header}
                    </ButtonWithRouter>
                </div>
            </div>
        </div>
    )
};

export const noDataMessage =
    <Box textAlign="center" color="inherit">
        <b>No data available</b>
        <Box variant="p" color="inherit">
            There is no data available
        </Box>
    </Box>