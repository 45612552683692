import React from "react";
import {TableProps} from "@amzn/awsui-components-react-v3/polaris/table/interfaces";
import {Badge} from "@amzn/awsui-components-react-v3";
import {SMSWOUnifiedDashboardServiceLambda} from "@amzn/swo-unified-dashboard-service-lambda-js-client";
import {
    ALL_SWO_ERRORS,
    FAILED_STATE,
    FIVE_DAY_OLD_RANGE_KEY,
    getTimeRange,
    NOT_SWO_ERROR,
    STAGE_APP_ERROR_REGEX_MATCH,
    STAGE_SYS_ERROR_REGEX_MATCH,
    STOPPED_STATE,
    SWO_APP_ERROR_REGEX_MATCH,
    SWO_SYS_ERROR_REGEX_MATCH,
    timeDistributionRangeMap,
    TOTAL_KEY_NAMES,
    VALID_STAGE_ERROR_REGEX_MATCH,
    ZERO_DAY_OLD_RANGE_KEY
} from "src/components/sm-overview-dashboards/workflow-detail-view/workflow-detail-view.utils";
import {
    DataStoreFields,
    FilterOperation,
    QueryVerb,
    START_TIME_MIN
} from "src/components/sm-overview-dashboards/workflow-list-view/filters/filter-constants";
import {FilterLink} from "src/components/sm-overview-dashboards/workflow-list-view/filters/filters";
import {fixStringCasing} from "src/utils/string-helper";

export const FailureTableHeaderCell: React.FC<{title: string, totalCount?: number, addWarning?: boolean}>
    = ({title, totalCount, addWarning}) => {
    return (
    <>
        {title}
        <br/>
        <br />
        {totalCount && totalCount >= 0 ?<Badge color={addWarning && totalCount >= 0 ? 'red':'blue'}>{`Total - ${totalCount}`}</Badge> : null}
    </>)
}

export const SWO_ROW_NAME = 'SWO';
export const UNACCOUNTED_FAILURES = 'UNACCOUNTED_SYSTEM_ERRORS';

const ERROR_REGEX_STRINGS = {
    ["SWO_APP"] : SWO_APP_ERROR_REGEX_MATCH,
    ["STAGE_APP"] : STAGE_APP_ERROR_REGEX_MATCH,
    ["STAGE_SYS"]: STAGE_SYS_ERROR_REGEX_MATCH,
    ["SWO_SYS"]: SWO_SYS_ERROR_REGEX_MATCH,
    ["SWO"]: ALL_SWO_ERRORS
}

export const isSWORecord = (stage: string|undefined) =>  stage === SWO_ROW_NAME;
export const isStageRecord = (record: string|undefined) => record !== SWO_ROW_NAME && record !== UNACCOUNTED_FAILURES;

export const getStageNameFilterPayload = (stage: string|undefined): Record<string, string> => {
    return isStageRecord(stage) ? {[`${QueryVerb.MUST}::${FilterOperation.MATCH}:${DataStoreFields.STAGE_NAME}`]: stage || ''} : {};
}

/**
 * Function is responsible for identifying Regex filters for App and sys errors in swo and stage rows on failure dive
 * deep view. This filter payload is used to derive the page 2 query for navigation from page 1 to page 2 view.
 * @param stage
 * @param errorType
 */
const getClassifiedErrorFilterPayload = (stage:string | undefined, errorType: keyof typeof ERROR_REGEX_STRINGS) => {
    return isSWORecord(stage) ?
        {
            [`${QueryVerb.MUST}::${FilterOperation.REGEX}:${DataStoreFields.ERROR}`]: ERROR_REGEX_STRINGS[errorType]
        } :
        {
            [`${QueryVerb.MUST}::${FilterOperation.REGEX}:${DataStoreFields.STAGE_ERROR}`]: ERROR_REGEX_STRINGS[errorType],
            [`${QueryVerb.MUST}::${FilterOperation.REGEX}:${DataStoreFields.ERROR}`]: NOT_SWO_ERROR
        }
}

/**
 * Function is responsible for identifying Regex filters for all error columns (APP, SYS and non classified errors) for
 * stages, swo , and errors which are not attributed to any stage or SWO. This filter payload is used to derive
 * the page 2 query for navigation from page 1 to page 2 view.
 * @param stage
 */
export const getAllErrorColumnFilterPayload = (stage: string|undefined) => {
    switch (stage) {
        case SWO_ROW_NAME:
            return ({
                [`${QueryVerb.MUST}::${FilterOperation.REGEX}:${DataStoreFields.ERROR}`]: ALL_SWO_ERRORS
            });
        case UNACCOUNTED_FAILURES:
            return ({
                [`${QueryVerb.MUST_NOT}::${FilterOperation.REGEX}:${DataStoreFields.STAGE_ERROR}`]: VALID_STAGE_ERROR_REGEX_MATCH,
                [`${QueryVerb.MUST_NOT}::${FilterOperation.REGEX}:${DataStoreFields.ERROR}`]: ALL_SWO_ERRORS
            })
        default:
            return ({[`${QueryVerb.MUST}::${FilterOperation.REGEX}:${DataStoreFields.ERROR}`]: NOT_SWO_ERROR});
    }
}

const getFieldToComputeAgeOfWorkflow = (stage: string|undefined) => {
    /*
    * Note: In case of UNACCOUNTED_FAILURES, ATTEMPT_LAST_UPDATE_TIMESTAMP is not a reliable field for computation of age of workflow
    * as there could be cases where there is no attempt registered for a workflow.
    * */
    return stage === UNACCOUNTED_FAILURES ? DataStoreFields.WORKFLOW_LAST_UPDATE_TIMESTAMP : DataStoreFields.ATTEMPT_LAST_UPDATE_TIMESTAMP;
}

export const getFailureSummaryColumnDefinition = (filterQuery: string, totalCountMap: Record<string, number>)
    : Array<TableProps.ColumnDefinition<SMSWOUnifiedDashboardServiceLambda.Types.ProgramFailureDetail>> => {
    const filterQueryPayload = JSON.parse(filterQuery);
    return [
        {
            id: 'stage',
            header: 'Stage',
            cell: (item) => fixStringCasing(item.stage || ''),
            minWidth: 50
        },
        {
            id: 'appErrors',
            header: <FailureTableHeaderCell title={'Human Errors  '}
                                            totalCount={totalCountMap[`${TOTAL_KEY_NAMES.APP_ERROR_TOTAL}-${ZERO_DAY_OLD_RANGE_KEY}`]}/>,
            cell: (item) =>
                <FilterLink
                    displayText={item.appErrorCountDistributionBucket?.[ZERO_DAY_OLD_RANGE_KEY] || 0}
                    disabled={!Boolean(item.appErrorCountDistributionBucket?.[ZERO_DAY_OLD_RANGE_KEY] || 0)}
                    payload={{
                        ...filterQueryPayload,
                        ...getStageNameFilterPayload(item.stage),
                        ...getClassifiedErrorFilterPayload(item.stage, isSWORecord(item.stage) ? 'SWO_APP': 'STAGE_APP'),
                        [`${QueryVerb.MUST}::${FilterOperation.MATCH}:${DataStoreFields.WORKFLOW_STATUS}`]: FAILED_STATE,
                        [`${QueryVerb.MUST}::${FilterOperation.RANGE}:${getFieldToComputeAgeOfWorkflow(item.stage)}`]
                            : getTimeRange(START_TIME_MIN, timeDistributionRangeMap[ZERO_DAY_OLD_RANGE_KEY]),
                        [`${QueryVerb.MUST_NOT}::${FilterOperation.MATCH}:${DataStoreFields.ERROR}`]: STOPPED_STATE
                    }}/>,
            minWidth: 50
        },
        {
            id: 'appErrorsBreachingSLA',
            header: <FailureTableHeaderCell
                title={`Human errors > ${timeDistributionRangeMap[FIVE_DAY_OLD_RANGE_KEY]} days  `}
                totalCount={totalCountMap[`${TOTAL_KEY_NAMES.APP_ERROR_TOTAL}-${FIVE_DAY_OLD_RANGE_KEY}`]}
                addWarning
            />,
            cell: (item) =>
                <>
                    <FilterLink
                        displayText={item.appErrorCountDistributionBucket?.[FIVE_DAY_OLD_RANGE_KEY] || 0}
                        disabled={!Boolean(item.appErrorCountDistributionBucket?.[FIVE_DAY_OLD_RANGE_KEY] || 0)}
                        payload={{
                            ...filterQueryPayload,
                            ...getStageNameFilterPayload(item.stage),
                            ...getClassifiedErrorFilterPayload(item.stage, isSWORecord(item.stage) ? 'SWO_APP': 'STAGE_APP'),
                            [`${QueryVerb.MUST}::${FilterOperation.MATCH}:${DataStoreFields.WORKFLOW_STATUS}`]: FAILED_STATE,
                            [`${QueryVerb.MUST}::${FilterOperation.RANGE}:${getFieldToComputeAgeOfWorkflow(item.stage)}`]
                                : getTimeRange(START_TIME_MIN, timeDistributionRangeMap[FIVE_DAY_OLD_RANGE_KEY]),
                            [`${QueryVerb.MUST_NOT}::${FilterOperation.MATCH}:${DataStoreFields.ERROR}`]: STOPPED_STATE
                        }}/>
                </>,
            minWidth: 50
        },
        {
            id: 'sysErrors',
            header: <FailureTableHeaderCell
                title={'System Errors  '}
                totalCount={totalCountMap[`${TOTAL_KEY_NAMES.SYS_ERROR_TOTAL}-${ZERO_DAY_OLD_RANGE_KEY}`]}
            />,
            cell: (item) =>
                <FilterLink
                    displayText={item.sysErrorCountDistributionBucket?.[ZERO_DAY_OLD_RANGE_KEY] || 0}
                    disabled={!Boolean(item.sysErrorCountDistributionBucket?.[ZERO_DAY_OLD_RANGE_KEY] || 0)}
                    payload={{
                        ...filterQueryPayload,
                        ...getStageNameFilterPayload(item.stage),
                        ...getClassifiedErrorFilterPayload(item.stage, isSWORecord(item.stage) ? 'SWO_SYS': 'STAGE_SYS'),
                        [`${QueryVerb.MUST}::${FilterOperation.MATCH}:${DataStoreFields.WORKFLOW_STATUS}`]: FAILED_STATE,
                        [`${QueryVerb.MUST}::${FilterOperation.RANGE}:${getFieldToComputeAgeOfWorkflow(item.stage)}`]
                            : getTimeRange(START_TIME_MIN, timeDistributionRangeMap[ZERO_DAY_OLD_RANGE_KEY]),
                        [`${QueryVerb.MUST_NOT}::${FilterOperation.MATCH}:${DataStoreFields.ERROR}`]: STOPPED_STATE
                    }}/>,
            minWidth: 50
        },
        {
            id: 'sysErrorsBreaching',
            header: <FailureTableHeaderCell
                title={`System errors > ${timeDistributionRangeMap[FIVE_DAY_OLD_RANGE_KEY]} days  `}
                totalCount={totalCountMap[`${TOTAL_KEY_NAMES.SYS_ERROR_TOTAL}-${FIVE_DAY_OLD_RANGE_KEY}`]} addWarning/>,
            cell: (item) =>
                <>
                    <FilterLink
                        displayText={item.sysErrorCountDistributionBucket?.[FIVE_DAY_OLD_RANGE_KEY] || 0}
                        disabled={!Boolean(item.sysErrorCountDistributionBucket?.[FIVE_DAY_OLD_RANGE_KEY] || 0)}
                        payload={{
                            ...filterQueryPayload,
                            ...getStageNameFilterPayload(item.stage),
                            ...getClassifiedErrorFilterPayload(item.stage, isSWORecord(item.stage) ? 'SWO_SYS': 'STAGE_SYS'),
                            [`${QueryVerb.MUST}::${FilterOperation.MATCH}:${DataStoreFields.WORKFLOW_STATUS}`]: FAILED_STATE,
                            [`${QueryVerb.MUST}::${FilterOperation.RANGE}:${getFieldToComputeAgeOfWorkflow(item.stage)}`]
                                : getTimeRange(START_TIME_MIN, timeDistributionRangeMap[FIVE_DAY_OLD_RANGE_KEY]),
                            [`${QueryVerb.MUST_NOT}::${FilterOperation.MATCH}:${DataStoreFields.ERROR}`]: STOPPED_STATE
                        }}/>
                </>,
            minWidth: 50
        },
        {
            id: 'allErrorsInTimeRange',
            header: <FailureTableHeaderCell title={'All errors  '}
                                            totalCount={totalCountMap[`${TOTAL_KEY_NAMES.ERROR_TOTAL}-${ZERO_DAY_OLD_RANGE_KEY}`]}/>
            ,
            cell: (item) =>
                <FilterLink
                    displayText={item.errorCountDistributionBucket?.[ZERO_DAY_OLD_RANGE_KEY] || 0}
                    disabled={!Boolean(item.errorCountDistributionBucket?.[ZERO_DAY_OLD_RANGE_KEY] || 0)}
                    payload={{
                        ...filterQueryPayload,
                        ...getStageNameFilterPayload(item.stage),
                        ...getAllErrorColumnFilterPayload(item.stage),
                        [`${QueryVerb.MUST}::${FilterOperation.MATCH}:${DataStoreFields.WORKFLOW_STATUS}`]: FAILED_STATE,
                        [`${QueryVerb.MUST}::${FilterOperation.RANGE}:${getFieldToComputeAgeOfWorkflow(item.stage)}`]
                            : getTimeRange(START_TIME_MIN, timeDistributionRangeMap[ZERO_DAY_OLD_RANGE_KEY]),
                        [`${QueryVerb.MUST_NOT}::${FilterOperation.MATCH}:${DataStoreFields.ERROR}`]: STOPPED_STATE
                    }}/>,
            minWidth: 120
        },
        {
            id: 'allErrorsInTimeRangeBreachingSLA',
            header: <FailureTableHeaderCell
                title={`All errors > ${timeDistributionRangeMap[FIVE_DAY_OLD_RANGE_KEY]} days  `}
                totalCount={totalCountMap[`${TOTAL_KEY_NAMES.ERROR_TOTAL}-${FIVE_DAY_OLD_RANGE_KEY}`]} addWarning/>,
            cell: (item) =>
                <>
                    <FilterLink
                        displayText={item.errorCountDistributionBucket?.[FIVE_DAY_OLD_RANGE_KEY] || 0}
                        disabled={!Boolean(item.errorCountDistributionBucket?.[FIVE_DAY_OLD_RANGE_KEY] || 0)}
                        payload={{
                            ...filterQueryPayload,
                            ...getStageNameFilterPayload(item.stage),
                            ...getAllErrorColumnFilterPayload(item.stage),
                            [`${QueryVerb.MUST}::${FilterOperation.MATCH}:${DataStoreFields.WORKFLOW_STATUS}`]: FAILED_STATE,
                            [`${QueryVerb.MUST}::${FilterOperation.RANGE}:${getFieldToComputeAgeOfWorkflow(item.stage)}`]
                                : getTimeRange(START_TIME_MIN, timeDistributionRangeMap[FIVE_DAY_OLD_RANGE_KEY]),
                            [`${QueryVerb.MUST_NOT}::${FilterOperation.MATCH}:${DataStoreFields.ERROR}`]: STOPPED_STATE
                        }}/>
                </>,
            minWidth: 50
        }
    ]
}

export const getErrorDistributionColumnDefinition = (filterQuery: string, stage: string)
    : Array<TableProps.ColumnDefinition<SMSWOUnifiedDashboardServiceLambda.Types.StageFailureDetail>> => {
    const filterQueryPayload = JSON.parse(filterQuery);
    return ([
    {
        id: 'error',
        header: 'Error',
        cell: (item) => item.stageerror,
        minWidth: 50
    },
    {
        id: 'allErrorsInTimeRange',
        header: <FailureTableHeaderCell title={'All Errors'} />,
        cell: (item) =>
            <FilterLink
                payload={{
                    ...filterQueryPayload,
                    ...getStageNameFilterPayload(stage),
                    [`${QueryVerb.MUST}::${FilterOperation.MATCH}:${DataStoreFields.WORKFLOW_STATUS}`]: FAILED_STATE,
                    [`${QueryVerb.MUST}::${FilterOperation.MATCH}:${DataStoreFields.STAGE_ERROR}`]:item.stageerror,
                    [`${QueryVerb.MUST}::${FilterOperation.RANGE}:${getFieldToComputeAgeOfWorkflow(stage)}`]
                        : getTimeRange(START_TIME_MIN, timeDistributionRangeMap[ZERO_DAY_OLD_RANGE_KEY]),
                    [`${QueryVerb.MUST_NOT}::${FilterOperation.MATCH}:${DataStoreFields.ERROR}`]: STOPPED_STATE
                }}
                disabled={!Boolean(item.errorCountDistributionBucket?.[ZERO_DAY_OLD_RANGE_KEY] || 0)}
                displayText={item.errorCountDistributionBucket?.[ZERO_DAY_OLD_RANGE_KEY] || 0} />,
        minWidth: 50
    },
    {
        id: 'allErrorsInTimeRangeBreachingSLA',
        header: <FailureTableHeaderCell title={`All Errors > ${timeDistributionRangeMap[FIVE_DAY_OLD_RANGE_KEY]} days`} addWarning/>,
        cell: (item) =>
            <>
                <FilterLink
                    payload={{
                        ...filterQueryPayload,
                        ...getStageNameFilterPayload(stage),
                        [`${QueryVerb.MUST}::${FilterOperation.MATCH}:${DataStoreFields.WORKFLOW_STATUS}`]: FAILED_STATE,
                        [`${QueryVerb.MUST}::${FilterOperation.MATCH}:${DataStoreFields.STAGE_ERROR}`]:item.stageerror,
                        [`${QueryVerb.MUST}::${FilterOperation.RANGE}:${getFieldToComputeAgeOfWorkflow(stage)}`]
                            : getTimeRange(START_TIME_MIN, timeDistributionRangeMap[FIVE_DAY_OLD_RANGE_KEY]),
                        [`${QueryVerb.MUST_NOT}::${FilterOperation.MATCH}:${DataStoreFields.ERROR}`]: STOPPED_STATE
                    }}
                    disabled={!Boolean(item.errorCountDistributionBucket?.[FIVE_DAY_OLD_RANGE_KEY] || 0)}
                    displayText={item.errorCountDistributionBucket?.[FIVE_DAY_OLD_RANGE_KEY] || 0} />
            </>,
        minWidth: 50
    }
])}
