import React, {useMemo, useState} from 'react';
import {Box, Table,Header, Pagination}
    from "@amzn/awsui-components-react-v3";
import {useQuery} from "@tanstack/react-query";
import {useLocation} from 'react-router-dom';
import {
    getErrorDistributionColumnDefinition, getStageNameFilterPayload
} from "src/components/sm-overview-dashboards/workflow-detail-view/workflow-failure-column-config";
import {SMSummary} from "src/model/SMSummary";
import {
    getFailureViewTimeDistributionRanges, NOT_SWO_ERROR
} from "src/components/sm-overview-dashboards/workflow-detail-view/workflow-detail-view.utils";
import {useCollection} from "@amzn/awsui-collection-hooks";
import {QueryParams} from "src/components/sm-overview-dashboards/common/query-params";
import {
    mergeFiltersToFilterQuery
} from "src/components/sm-overview-dashboards/workflow-list-view/filters/filters.utils";
import {fixStringCasing} from "src/utils/string-helper";
import {
    DataStoreFields,
    FilterOperation,
    QueryVerb
} from "src/components/sm-overview-dashboards/workflow-list-view/filters/filter-constants";

const PAGE_SIZE = 8;

export const WorkflowFailureDistributionView = () => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const stage = params.get(QueryParams.STAGE) || '';
    const program = params.get(QueryParams.PROGRAM) || '';
    const filterQuery = params.get(QueryParams.FILTER_QUERY_KEY) || JSON.stringify({});
    const filterQueryWithStagePayload = useMemo(() => {
        return mergeFiltersToFilterQuery(filterQuery,
            {
                ...({[`${QueryVerb.MUST}::${FilterOperation.REGEX}:${DataStoreFields.ERROR}`]: NOT_SWO_ERROR}),
                ...getStageNameFilterPayload(stage)
            })
    }, [filterQuery, stage])
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const {
        data: errorDistribution,
        isLoading: isErrorDistributionLoading,
        error: failuresInFetchingErrorDistribution
        } = useQuery({
        queryKey: ['workflowFailureDistributionErrors', filterQueryWithStagePayload],
        queryFn: () => new SMSummary().getStageFailureDetailData({
            filterQuery: filterQueryWithStagePayload,
            timeRangeBucket: getFailureViewTimeDistributionRanges()
        })
    });

    const {items: tableItems, paginationProps} = useCollection(
        errorDistribution?.failureDetails || [], {
            pagination: { pageSize: PAGE_SIZE}
        }
    )

    return (
        <>
            <Box padding={{vertical: "l", horizontal: "l"}}>
                <Table
                    data-testid='workflow-failure-distribution-error-table'
                    items={tableItems || []}
                    columnDefinitions={getErrorDistributionColumnDefinition(filterQuery, stage)}
                    loadingText='Loading App Error distribution'
                    loading={isErrorDistributionLoading}
                    wrapLines
                    empty={
                        <Box textAlign="center" color="inherit">
                            <b>No resources</b>
                            <Box
                                padding={{ bottom: "s" }}
                                variant="p"
                                color="inherit"
                            >
                                No errors to Display.
                            </Box>
                        </Box>
                    }
                    pagination={
                        <Pagination currentPageIndex={paginationProps.currentPageIndex}
                                    pagesCount={paginationProps.pagesCount}
                                    onChange={paginationProps.onChange}
                        />
                    }
                header = {
                    <Header
                        variant={"h3"}
                        description={'Note: App errors are human errors' +
                            ' Sys Errors are system failures and any other errors reported are result of data,' +
                            ' reported prior to error classification being mandated for SM systems'}
                    >
                        {`Error Distribution for stage ${fixStringCasing(stage)}`}
                    </Header>
                }
                 />
            </Box>
        </>

    )
}
