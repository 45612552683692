import AppLayout from '@amzn/awsui-components-react-v3/polaris/app-layout';
import React, {useEffect, useState} from 'react';
import {appLayoutNavigationLabels} from 'src/common/labels';
import {ServiceNavigation} from 'src/components/navigation';
import 'src/styles/index.scss';
import {ExecutionAttemptsPageContent} from "src/components/execution-attempts/execution-attempts-page-content";
import {AppRouteParams, AppRoutes, BreadcrumbGroupWithRouter} from "src/components/common/router-common";
import {RouteComponentProps} from "react-router-dom";
import {BreadcrumbGroupProps} from "@amzn/awsui-components-react-v3/polaris/breadcrumb-group/interfaces";
import {ExecutionAttemptsPageRouterParams} from "src/interfaces/execution-attempts";
import {SwoUiMetrics} from "src/utils/swo-ui-metrics/swo-ui-metrics";
import {metricFeaturesList, metricPageNames} from "src/constants/swo-ui-metric-constants";

/**
 * Layout for execution attempts page.
 * @param props
 * @constructor
 */
export default function ExecutionAttemptsPage(props: RouteComponentProps<ExecutionAttemptsPageRouterParams>) {
    const [navigationOpen, setNavigationOpen] = useState(false);
    useEffect(() => {
        SwoUiMetrics.publishCloudWatchCountMetric(metricFeaturesList.index, metricPageNames.workflowAttemptsView)
    }, [])
    const workflowId: string = props.match.params.workflowId

    const bcItems: Array<BreadcrumbGroupProps.Item> = [
        {
            "text": "Home",
            "href": AppRoutes.home
        },
        {
            "text": "Executions",
            "href": AppRoutes.executionList
        },
        {
            "text": workflowId,
            "href": AppRoutes.executionDetail(workflowId)
        },
        {
            "text": "attempts",
            "href": "#"
        }
    ]


    return (
        <AppLayout
            navigation={<ServiceNavigation/>}
            content={<ExecutionAttemptsPageContent workflowId={workflowId}/>}
            breadcrumbs={<BreadcrumbGroupWithRouter items={bcItems}/>}
            contentType="default"
            disableContentPaddings={false}
            navigationOpen={navigationOpen}
            onNavigationChange={(event: any) => setNavigationOpen(event.detail.open)}
            toolsHide={true}
            ariaLabels={appLayoutNavigationLabels}
        />
    );
}