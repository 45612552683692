import * as React from "react";
import {useEffect} from "react";
import Cards from "@amzn/awsui-components-react-v3/polaris/cards";
import Box from "@amzn/awsui-components-react-v3/polaris/box";
import Header from "@amzn/awsui-components-react-v3/polaris/header";
import {
    StageInstance,
    StageInstances
} from "@amzn/swo-unified-dashboard-service-lambda-js-client/lib/smswounifieddashboardservicelambda";
import {SWOStageDetailDataI, WorkflowDetailSubStagesCardsI} from "src/interfaces/workflow-instance-detail";
import {CardsProps} from "@amzn/awsui-components-react-v3/polaris/cards/interfaces";
import {SMSWOUnifiedDashboardServiceLambda} from "@amzn/swo-unified-dashboard-service-lambda-js-client";
import {Alert, ColumnLayout, ExpandableSection, Link} from "@amzn/awsui-components-react-v3";
import {StageInstanceEvents} from "src/components/execution-detail/stage-instance-events";
import {convertEsDateToHumanReadable} from "src/utils/table-helper";
import {SWOStageDetailData} from "src/components/execution-detail/swo-stages-detail-data";

export function convertOptionslEpochToHumanReadable(date: number | undefined): string {
    return (date == undefined || date == 0) ? "-" : convertEsDateToHumanReadable(date.toString())
}

export function WorkflowDetailSubStagesCards(props: WorkflowDetailSubStagesCardsI) {
    const [
        selectedItems,
        setSelectedItems
    ] = React.useState<StageInstances>([]);

    const cardItems: Array<StageInstance> = props.stageInstances

    useEffect(() => {
        setSelectedItems([])
    }, [props.columnNumber])

    const cardDef: CardsProps.CardDefinition<StageInstance> = {
        header(item: SMSWOUnifiedDashboardServiceLambda.StageInstance): React.ReactNode {
            return item.stageName
        },
        sections: [
            {
                id: "status",
                content(item: SMSWOUnifiedDashboardServiceLambda.StageInstance): React.ReactNode {
                    return <div><b>Status: </b>{item.status || "-"}</div>
                }
            },
            {
                id: "beginTime",
                header: "Begin Time",
                content(item: SMSWOUnifiedDashboardServiceLambda.StageInstance): React.ReactNode {
                    return <div>{item.beginTime == undefined ? "-" :
                        convertEsDateToHumanReadable(item.beginTime.toString())}</div>
                }
            },
            {
                id: "endTime",
                header: "End Time",
                content(item: SMSWOUnifiedDashboardServiceLambda.StageInstance): React.ReactNode {
                    return <div>{item.endTime == undefined ? "-" :
                        convertEsDateToHumanReadable(item.endTime.toString())}</div>
                }
            },
            {
                id: "events",
                header: "Events",
                content(item: SMSWOUnifiedDashboardServiceLambda.StageInstance): React.ReactNode {
                    if ((item.events == undefined) || (item.events.length == 0)) {
                        return "No events found"
                    }

                    const eventRes = item.events.reduce((obj, event) => ({
                        ...obj,
                        [event.name || event.eventId]: event
                    }), {})

                    return <StageInstanceEvents events={eventRes} key={JSON.stringify(item.events)}/>
                }
            },
            {
                id: "subStageInstances",
                header: "Sub stage Instances",
                content(item: SMSWOUnifiedDashboardServiceLambda.StageInstance): React.ReactNode {
                    if (item.subStageInstances == undefined || item.subStageInstances?.length == 0) {
                        return "No Sub stages"
                    }
                    return <Link variant={"primary"}
                                 onFollow={() => {

                                     const selectedIndex = props.stageInstances.findIndex((inx) => {
                                         return inx == item
                                     })
                                     props.handleSelectionChange(item.subStageInstances, props.columnNumber, selectedIndex)
                                     setSelectedItems([item])
                                 }
                                 }
                    > {item.subStageInstances?.length} sub-stages </Link>
                }
            },
            {
                id: "moreDetails",
                content(item: SMSWOUnifiedDashboardServiceLambda.StageInstance): React.ReactNode {
                    const eventRes = item.events?.reduce((obj, event) => ({
                        ...obj,
                        [`${event.name || event.eventId}-${event.type}-${convertOptionslEpochToHumanReadable(event.time)}`]: event
                    }), {})
                    return <ColumnLayout columns={1}>
                        <div><b>StageInstanceId: </b>{item.stageInstanceId || "-"}</div>
                        <div><b> Begin Time: </b> {convertOptionslEpochToHumanReadable(item.beginTime)}</div>
                        <div><b> {props.columnNumber == 1 ? "Last Activity Time:" : `End Time:`} </b> {convertOptionslEpochToHumanReadable(item.endTime)}
                        </div>
                        <div><b>Events: </b>
                            {((item.events == undefined) || (item.events.length == 0)) ? "No events found" :
                                <StageInstanceEvents events={eventRes || {}}
                                                     key={JSON.stringify(item.events[0].eventId || "")}/>
                            }
                        </div>
                        <div><b>Sub Stages: </b>
                            {(item.subStageInstances == undefined || item.subStageInstances?.length == 0)
                                ? "No Sub stages" : <Link variant={"primary"}
                                                          onFollow={() => {
                                                              const selectedIndex = props.stageInstances.findIndex((inx) => {
                                                                  return inx == item
                                                              })
                                                              props.handleSelectionChange(item.subStageInstances, props.columnNumber, selectedIndex)
                                                              setSelectedItems([item])
                                                          }
                                                          }
                                > {item.subStageInstances?.length} sub-stages </Link>}
                        </div>
                        <div><b> More Details: </b>
                            <SWOStageDetailData stageInstance={[item]}
                                                key={JSON.stringify(item.stageInstanceId || "")}/>
                        </div>
                    </ColumnLayout>

                }
            }

        ]
    }

    if (props.stageInstances.length == 0) {
        return <Alert>{props.columnNumber == 1 ? "No stage instances found" : "No sub stage instances found"} </Alert>
    }
    return (

        <Cards
            className={`stage-instance-column-${props.columnNumber}`}
            selectionType={"single"}
            selectedItems={selectedItems}
            trackBy={item => item.stageInstanceId || ""}
            cardDefinition={cardDef}
            cardsPerRow={[
                {cards: 1},
                {minWidth: 500, cards: 1}
            ]}
            items={cardItems}
            loadingText="Loading resources"
            visibleSections={["status", "moreDetails"]}
            empty={
                <Box textAlign="center" color="inherit">
                    <b>No resources</b>
                    <Box
                        padding={{bottom: "s"}}
                        variant="p"
                        color="inherit"
                    >
                        No resources to display.
                    </Box>
                </Box>
            }
            header={
                <Header
                    counter={`(${cardItems.length.toString()})`}
                >
                    {props.columnNumber == 1 ? "Stage" : `Sub Stage Level ${props.columnNumber - 1}`}
                </Header>
            }

            onSelectionChange={({detail}) => {
                const selectedIndex = props.stageInstances.findIndex((stageInstance) => {
                    return stageInstance == detail.selectedItems[0]
                })
                props.handleSelectionChange(detail.selectedItems[0].subStageInstances, props.columnNumber, selectedIndex)
                setSelectedItems(detail.selectedItems)
            }

            }
        />
    );
}
