import axios, { AxiosResponse } from "axios";
import { apiGatewayPaths, wimAPIConfig, APIGWInterface } from "src/utils/api-gateway/mappings";
import { MidwayAuth } from "src/utils/auth/midway-auth";
import { getStage } from "src/utils/env";
import {logger} from "src/utils/logger";

/**
 * Workitem manager API gateway model
 */
export class WorkitemManager {
  
  private apiConfig: APIGWInterface;
  private static readonly EXECUTE_API: string = 'execute-api'
  private static readonly HTTPS_PREFIX: string = 'https://'
  
  constructor() {
    this.apiConfig = wimAPIConfig(getStage())
  }

  /**
   * Get workitem details
   */
  async getWorkitemDetails(workitemId: string): Promise<AxiosResponse<any>> {
    const path = `/${this.apiConfig.stage}${apiGatewayPaths.getWorkitem.path}/${workitemId}`;
    const request = {
      hostname: this.apiConfig.endpoint,
      method: apiGatewayPaths.getWorkitem.method,
      url: WorkitemManager.HTTPS_PREFIX + this.apiConfig.endpoint + path,
      path: path,
    };

    const signedRequest = await MidwayAuth.signRequest(request);
    const resp: Promise<AxiosResponse<any>>  = axios.request(signedRequest)
    return resp;
  }

  /**
   * Update workitemoutput
   */
  async outputWorkitem(output: object, workitemId: string, user: string) {
    const path = `/${this.apiConfig.stage}${apiGatewayPaths.outputWorkitem.path}`;
    const body = {
      user: user,
      workItemId: workitemId,
      output: JSON.stringify(output),
    };
    logger.info(`Output workitem ${workitemId} `, body)
    const request = {
      host: this.apiConfig.endpoint,
      url: WorkitemManager.HTTPS_PREFIX + this.apiConfig.endpoint + path,
      data: body,
      body: JSON.stringify(body),
      method: apiGatewayPaths.outputWorkitem.method.toString(),
      service: WorkitemManager.EXECUTE_API,
      path: path,
      region: this.apiConfig.region,
      headers: {
        "Content-Type": "application/x-amz-json-1.1",
      },
    };
    const signedRequest = await MidwayAuth.signRequest(request);
    return axios.request(signedRequest);
  }
}
