import {useEffect, useState} from "react";
import {VirtualPipelineDetails} from "src/components/virtual-pipeline/virtual-pipeline-list-table-config";
import {VirtualPipeline} from "src/model/VirtualPipeline";
import {EMPTY_DATASET_NODE} from "src/constants/virtual-pipelines";

/**
 * Fetch data from virtual pipeline service api.
 */
export const useApiRequest = () => {
    const [items, setItems] = useState<Array<VirtualPipelineDetails>>([])
    const [error, setError] = useState("");
    const [isLoaded, setIsLoaded] = useState(false)
    const fetchData = () => {
        setIsLoaded(true)
        new VirtualPipeline()
            .vp()
            .then((resp) => {
                setItems(JSON.parse(JSON.stringify(resp.virtualPipelines)) || [])
                setIsLoaded(false)
                setError("")
            })
            .catch((error) => {
                console.log(error)
                setIsLoaded(false)
                setError(JSON.stringify(error))
                setItems([])
            })
    }
    useEffect(() => {
        fetchData()
    }, []);
    return {error, items, isLoaded};
}

/**
 * Fetch detailed virtual pipeline info from virtual pipeline service api.
 * @param vpId As request query string
 */
export const useApiRequestDetailedVP = (
    vpId: string
) => {
    const [detailedPipelineInfo, setDetailedPipelineInfo] = useState<any>()
    const [error, setError] = useState("");
    const [isLoaded, setIsLoaded] = useState(false)
    const fetchData = () => {
        setIsLoaded(true)
        new VirtualPipeline()
            .vpDetail(vpId)
            .then((resp) => {
                setDetailedPipelineInfo(JSON.parse(JSON.stringify(resp.detailedVirtualPipeline.detailedPipelineInformation)))
                setIsLoaded(false)
                setError("")
            })
            .catch((error) => {
                console.log(error)
                setIsLoaded(false)
                setError(JSON.stringify(error))
                setDetailedPipelineInfo(null)
            })
    }

    useEffect(() => {
        fetchData()
    }, [])
    return {error, detailedPipelineInfo, isLoaded}
}

/**
 * Fetch virtual pipeline instances info within given time range from virtual pipeline service api.
 * @param vpId As query string vpId
 * @param timeInterval Given time interval for query
 */
export const useApiRequestVPInstance = (
    vpId: string,
    timeInterval: { startTime: number, endTime: number },
) => {
    const [instances, setInstances] = useState<Array<any>>([])
    const [error, setError] = useState("");
    const [isLoaded, setIsLoaded] = useState(false)
    const fetchData = () => {
        setIsLoaded(true)
        new VirtualPipeline()
            .vpi(vpId, timeInterval)
            .then((resp) => {
                setInstances(JSON.parse(JSON.stringify(resp.virtualPipelineInstances)) || [])
                setIsLoaded(false)
                setError("")
            })
            .catch((error) => {
                console.log(error)
                setIsLoaded(false)
                setError(JSON.stringify(error))
                setInstances([])
            })
    }

    useEffect(() => {
        fetchData()
    }, [vpId, timeInterval])
    return {error, instances, isLoaded}
}

export const useApiRequestVPInfo = (
    vpId: string
) => {
    const [vp, setVp] = useState({"vpName": "", "pipelineInformation": EMPTY_DATASET_NODE})
    const [isVpLoaded, setIsVpLoaded] = useState(false)
    const [infoError, setInfoError] = useState("");

    const fetchData = () => {
        setIsVpLoaded(true)
            new VirtualPipeline()
            .vpInfo(vpId)
            .then((resp) => {
                setVp(JSON.parse(JSON.stringify(resp.virtualPipeline)))
                setIsVpLoaded(false)
                setInfoError("")
            })
            .catch((error) => {
                console.log(error)
                setInfoError(JSON.stringify(error))
                setIsVpLoaded(false)
                setVp({"vpName": "", "pipelineInformation": EMPTY_DATASET_NODE})
            })
    }

    useEffect(() => {
        fetchData()
    }, [vpId])

    return {infoError, vp, isVpLoaded}
}
