import React from "react";
import {Alert, Container, Header, Spinner} from "@amzn/awsui-components-react-v3";
import {useApiRequestDetailedVP} from "src/hooks/vp-table-hooks";
import {VirtualPipelineVisualizationGraph} from "src/components/virtual-pipeline/virtual-pipeline-visualization/virtual-pipeline-visualization-graph";

/**
 * A dashboard for virtual pipeline visualization, currently have one container with the
 * visualized virtual pipeline using cytoscape.
 * @param props
 * @constructor
 */
export function VirtualPipelineVisualizationDashboard(props: { vpId: string; }) {

    const {
        error,
        detailedPipelineInfo,
        isLoaded
    } = useApiRequestDetailedVP(props.vpId)

    return (
        <>
            <Alert type={"error"} visible={error != ""}> {error}</Alert>
            <Container
                header={
                    <Header className={"virtual-pipeline-visualization-dashboard-header"} variant="h2" description="This is a visualized virtual pipeline">
                        Visualized Virtual Pipeline
                    </Header>
                }
            >
                {
                    !isLoaded ?
                        <>
                            <div className={"visualization-graph-container"}>
                                <VirtualPipelineVisualizationGraph pipelineInfo={detailedPipelineInfo}/>
                            </div>
                        </> :
                        <>
                            <div className="visualization-loading"><Spinner size={"large"}/>Loading visualization...</div>
                        </>
                }
            </Container>
        </>
    )
}