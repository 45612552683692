// It should be provided by WIM NodeJS client. Until we have that package, we're manually creating it.

export enum workForceTypes {
  SIM = "SIM",
}
export interface WorkitemDetails {
  output: string;
  outputSchema: string;
  status: string;
  workForceTypeIdentifier: string;
  workForceType: workForceTypes;
  workForce: {[key: string]: any}
}
