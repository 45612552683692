import React from "react"
import {VirtualPipelineDetailModal} from "src/components/virtual-pipeline/virtual-pipeline-detail-modal";
import {Button, ButtonProps, Header} from "@amzn/awsui-components-react-v3";
import {getHeaderCounterText} from "src/components/common/table-counter-strings";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {VirtualPipelinesListTable} from "src/constants/virtual-pipelines";

const RouterButton = (props: ButtonProps & RouteComponentProps<{}>) => {
    return (
        <div>
            <Button
                {...props}
                onClick={(ev) => {
                    ev.preventDefault();
                    if (ev.detail.metaKey) {
                        window.open(props.href as string, "_blank")
                    } else {
                        props.history.push(props.href as string);
                    }
                }
                }
            />
        </div>
    );
};
export const ButtonWithRouter = withRouter(RouterButton)

export const VirtualPipelineListTableHeader =
    (props: {selectedItems: Array<any>, totalItems: Array<any>, totalItemCount: number}) => {

    return (
        <Header
            {...props}
            counter={getHeaderCounterText(props.totalItems, props.selectedItems)}
            actions={<>
                <VirtualPipelineDetailModal {...props}/>
            </>}
            description={""}
        >
            {VirtualPipelinesListTable.header}
        </Header>
    );
};