import AppLayout from '@amzn/awsui-components-react-v3/polaris/app-layout';
import React, {useState} from 'react';
import {ServiceNavigation} from 'src/components/navigation';
import 'src/styles/index.scss';
import {AppRoutes, BreadcrumbGroupWithRouter} from "src/components/common/router-common";
import {VirtualPipelineInstanceDashboard} from "src/components/virtual-pipeline/virtual-pipeline-instance/virtual-pipeline-instance-dashboard";

/**
 * The virtual pipelines instance dashboard page.
 * @constructor
 */
export default function VirtualPipelineInstance(props: { match: { params: { [x: string]: any; }; }; }) {
    const [navigationOpen, setNavigationOpen] = useState(false);
    const vpId = props.match.params["vpId"]

    const items = [
        {
            "text": "Home",
            "href": "/"
        },
        {
            "text": "Virtual Pipeline",
            "href": AppRoutes.virtualPipelines
        },
        {
            "text": vpId,
            "href": "/instance/" + vpId
        }
    ]
    return (
        <AppLayout
            navigation={<ServiceNavigation/>}
            breadcrumbs={<BreadcrumbGroupWithRouter items={items}/>}
            navigationOpen={navigationOpen}
            toolsHide={true}
            onNavigationChange={(event: any) => setNavigationOpen(event.detail.open)}
            content={<VirtualPipelineInstanceDashboard vpId={vpId}/>}
        />
    );
}