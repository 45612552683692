import {APIGWInterface} from "src/utils/api-gateway/mappings";
import {STAGES} from "src/utils/app-constants";

export const virtualPipelineAPIConfigBeta: APIGWInterface = {
    endpoint: "kn1o0rlsw6.execute-api.us-west-2.amazonaws.com",
    stage: "beta",
    iam: "arn:aws:iam::795619029800:role/SM-SWO-UI-Beta",
    region: "us-west-2",
}

export const virtualPipelineAPIConfigProd: APIGWInterface = {
    endpoint: "l46knt1g2a.execute-api.us-east-1.amazonaws.com",
    stage: "prod",
    iam: "arn:aws:iam::092773271534:role/SM-SWO-UI-Prod",
    region: "us-east-1",
}

// Get Virtual Pipeline APIGW Endpoint, Stage, IAM and Region
export function virtualPipelineAPIConfig(stage: string): APIGWInterface {
    let apiConfig;

    switch (stage) {
        case STAGES.PROD.toLowerCase():
        case STAGES.GAMMA.toLowerCase():
            apiConfig = virtualPipelineAPIConfigProd;
            break;
        case STAGES.BETA.toLowerCase():
            apiConfig = virtualPipelineAPIConfigBeta;
            break;
        default:
            apiConfig = virtualPipelineAPIConfigBeta;
    }
    return apiConfig;
}