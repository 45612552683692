import {
    ColumnLayout,
    ExpandableSection,
    Spinner,
    TablePropertyFiltering,
    TableSorting
} from "@amzn/awsui-components-react";
import {Alert} from "@amzn/awsui-components-react-v3";
import React, {useEffect, useState} from "react";
import {TableTypes} from "src/interfaces/domain-tracker";
import {formatColumnValue} from "src/utils/table-helper";
import "src/styles/table-select.scss"
import {useApiRequestV3} from "src/hooks/table-hooks";
import {WORKFLOW_START_DATE} from "src/constants/domain-tracker";
import {ExecutionAttemptsOverviewInterface} from "src/interfaces/execution-attempts";
import {EditExecutionDetail} from "src/components/domain-tracker/edit-execution-detail";
import {EsQueryTableFilterI, FilterOperation} from "src/interfaces/attribute-filters";

/**
 * Should latest attempt details in overview component. Since we've more than 20 columns, displaying only top 8 columns and
 * hiding other in expandable section.
 *
 * @param props
 * @constructor
 */
export function ExecutionAttemptsOverview(props: ExecutionAttemptsOverviewInterface) {
    const tableMetadata = props.tableMetadata;
    const pageSize: number = 1;
    const overviewColumns: ColumnLayout.Columns = 4;
    const overviewRows: number = 2;
    const overviewKeyPairsCount = overviewColumns * overviewRows;

    const [filteringTokens, setFilteringTokens] = useState<Array<TablePropertyFiltering.FilteringToken>>([]);
    const [filteringTokensHidden, setFilteringTokensHidden] = useState<Array<TablePropertyFiltering.FilteringToken>>(props.filteringTokensHidden || []);
    const [sortingOptions, setSortingOptions] = useState<TableSorting.SortingChangeDetail>({
        sortingColumn: WORKFLOW_START_DATE,
        sortingDescending: false
    })

    const [esQueryTableFilter, setWsQueryTableFilter] = useState<EsQueryTableFilterI>({
        size: pageSize,
        from: 0,
        attributeFilterItems: [],
        sortingOptions: {
            isDescending: false,
            sortingColumn: {
                sortingField: WORKFLOW_START_DATE
            }
        }
    })

    useEffect(() => {

        setWsQueryTableFilter({
            ...esQueryTableFilter, attributeFilterItems: [{
                columnDefinition: tableMetadata[filteringTokensHidden[0].propertyKey],
                attributeName: {
                    label: filteringTokensHidden[0].propertyKey,
                    value: filteringTokensHidden[0].propertyKey
                },
                value: filteringTokensHidden[0].value,
                condition: FilterOperation.EQUALS,
                tableType: TableTypes.EXECUTION_ATTEMPTS
            }]
        })
    }, [props.tableMetadata])

    const {
        error,
        loading,
        items,
        totalCount
    } = useApiRequestV3(esQueryTableFilter, props.tableMetadata, TableTypes.EXECUTION_LIST);


    return (
        <>
            <Alert type={"error"} visible={error != ""}> {error}</Alert>
            <div className="awsui-util-container">
                <div className="awsui-util-container-header">
                    <h2>Execution overview
                        <div className="awsui-util-action-stripe-group awsui-util-f-r">
                            <EditExecutionDetail selectedItems={items} tableMetadata={tableMetadata}/>
                        </div>
                    </h2>
                    <div className="awsui-util-container-header-description">
                        Latest attempt details
                    </div>
                </div>

                {!loading ? <>
                    <ColumnLayout columns={overviewColumns} variant={"text-grid"} borders={"none"}>
                        <div data-awsui-column-layout-root="true">
                            {Object.entries(props.tableMetadata).map(([columnKey, colDefinition], index) => {
                                return <>{index < overviewKeyPairsCount && <div key={columnKey}>
                                    <div className="awsui-util-label">{colDefinition.uiFeatures.visibleName}</div>
                                    <div>{formatColumnValue(items.length == 0 ? "-" : items[0][columnKey], colDefinition.dataType)}</div>
                                </div>} </>
                            })}
                        </div>
                    </ColumnLayout>
                    {items.length != 0 &&
                    <div
                        className={"awsui-util-container-footer"}>{Object.keys(items[0]).length >= overviewKeyPairsCount &&
                    <ExpandableSection header="More details" variant={"borderless"}>
                        <ColumnLayout columns={overviewColumns} variant={"text-grid"}>
                            <div data-awsui-column-layout-root="true">
                                {Object.entries(props.tableMetadata).map(([columnKey, colDefinition], index) => {
                                    return <>{index >= overviewKeyPairsCount && <div key={columnKey}>
                                        <div className="awsui-util-label">{colDefinition.uiFeatures.visibleName}</div>
                                        <div>{formatColumnValue(items.length == 0 ? "-" : items[0][columnKey], colDefinition.dataType)}</div>
                                    </div>} </>
                                })}
                            </div>
                        </ColumnLayout>
                    </ExpandableSection>
                    }</div>
                    }
                </> : <div className={"awsui-util-t-c"}><Spinner variant={"normal"} size={"big"}/> Loading ...</div>
                }
            </div>

        </>
    );
}
