import {APIGWInterface} from "src/utils/api-gateway/mappings";
import {STAGES} from "src/utils/app-constants";

export const orchestratorServiceAPIConfigAlpha: APIGWInterface = {
    endpoint: "svrc0oot21.execute-api.us-west-2.amazonaws.com",
    stage: "alpha",
    iam: "arn:aws:iam::199711326154:role/SM-SWO-UI-Alpha",
    region: "us-west-2",
}

export const orchestratorServiceAPIConfigBeta: APIGWInterface = {
    endpoint: "h8d4slyrf7.execute-api.us-west-2.amazonaws.com",
    stage: "beta",
    iam: "arn:aws:iam::795619029800:role/SM-SWO-UI-Beta",
    region: "us-west-2",
}

export const orchestratorServiceAPIConfigGamma: APIGWInterface = {
    endpoint: "p72bz8u1ok.execute-api.us-east-1.amazonaws.com",
    stage: "gamma",
    iam: "arn:aws:iam::238292326407:role/SM-SWO-UI-Gamma",
    region: "us-east-1",
}

export const orchestratorServiceAPIConfigProd: APIGWInterface = {
    endpoint: "rnpd2nixka.execute-api.us-east-1.amazonaws.com",
    stage: "prod",
    iam: "arn:aws:iam::092773271534:role/SM-SWO-UI-Prod",
    region: "us-east-1",
}


// Get Unified Dashboard APIGW Endpoint, Stage, IAM and Region
export function orchestratorServiceAPIConfig(stage: string): APIGWInterface {
    let apiConfig;

    switch (stage) {
        case STAGES.ALPHA.toLowerCase():
            apiConfig = orchestratorServiceAPIConfigAlpha;
            break;
        case STAGES.GAMMA.toLowerCase():
            apiConfig = orchestratorServiceAPIConfigGamma;
            break;
        case STAGES.PROD.toLowerCase():
            apiConfig = orchestratorServiceAPIConfigProd;
            break;
        default:
            apiConfig = orchestratorServiceAPIConfigBeta;
    }
    return apiConfig;
}
