import React from "react";
import {TableTypes} from "src/interfaces/domain-tracker";

export type VisibleColumnsTablePreferenceInterface = Array<string>;

export const DEFAULT_TABLE_PREFERENCE: UserTablePreferencesInterface = {
  size: 10,
  visibleColumns: [],
  wrapLines: true
}

export interface UserTablePreferencesInterface {
  size: number
  visibleColumns: VisibleColumnsTablePreferenceInterface
  wrapLines: boolean
}

/**
 * User can set preferences like visible columns, page size, line wrap.
 * Settings will be stored in browser using browser local storage.
 */
export class UserTablePreferences {
  private readonly table: string

  /**
   *
   * @param table Table name
   */
  constructor(table: TableTypes) {
    this.table = table.toString()
  }

  defaultTablePreference: UserTablePreferencesInterface = DEFAULT_TABLE_PREFERENCE

  getTablePreference = (): UserTablePreferencesInterface => {
    const tableLocalStorage = window.localStorage;
    const tablePreference = tableLocalStorage.getItem(this.table)
    return tablePreference == null ? this.defaultTablePreference : JSON.parse(tablePreference)
  }

  setTablePreference(tablePreference: UserTablePreferencesInterface) {
    const myStorage = window.localStorage;
    myStorage.setItem(this.table, JSON.stringify(tablePreference))
  }

  setVisibleColumnsTablePreference(visibleColumns: VisibleColumnsTablePreferenceInterface) {
    const tablePref = this.getTablePreference()
    tablePref.visibleColumns = visibleColumns
    this.setTablePreference(tablePref)
  }

  setWrapLinesTablePreference(wrapLines: boolean) {
    const tablePref = this.getTablePreference()
    tablePref.wrapLines = wrapLines
    this.setTablePreference(tablePref)
  }

  setPageSizePreference(pageSize: number) {
    const tablePref = this.getTablePreference()
    tablePref.size = pageSize
    this.setTablePreference(tablePref)
  }
}
