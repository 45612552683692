import React, {useEffect, useState} from "react";
import {TableTypes} from "src/interfaces/domain-tracker";
import {DomainTracker} from "src/model/DomainTracker";
import {AppRouteParams} from "src/components/common/router-common";
import {WORKFLOW_START_DATE} from "src/constants/domain-tracker";
import {ExecutionAttemptsPageRouterParams} from "src/interfaces/execution-attempts";
import {TableMetadataInterface} from "src/interfaces/server-side-table";
import {ServerSideTableV3} from "src/components/table/server-side-table-v3";
import {Spinner} from "@amzn/awsui-components-react-v3";
import produce from "immer";
import {UrlHelper} from "src/utils/url-helper";
import {toast} from "react-toastify";
import {DEFAULT_ES_QUERY_FILTER} from "src/constants/elastic-search";
import {AttributeFilterItemsI, EsQueryTableFilterI, FilterOperation} from "src/interfaces/attribute-filters";

/**
 * Execution attempts table will be similar to execution list. It queries "ATTEMPTS" list card instead of "WORKFLOWS".
 * And some attempt related columns are set to visible onload.
 * @param props
 * @constructor
 */
export function ExecutionAttemptsTable(props: ExecutionAttemptsPageRouterParams) {
    const [tableMetadata, setTableMetadata] = useState<TableMetadataInterface>({})
    const workflowId: string = props.workflowId
    let urlFilteringTokens: EsQueryTableFilterI | null = null;
    try {
        urlFilteringTokens = UrlHelper.getTableFiltersFromUrlParams(UrlHelper.ES_QUERY_PARAMS_KEY)
    } catch (e) {
        console.error("UrlHelper.getTableFiltersFromUrlParams fails", e)
        toast.error("Error while parsing url params. Redirecting to default view.")
    }

    const workflowIdExists = urlFilteringTokens?.attributeFilterItems.find(attributeFilterItem => {
        return attributeFilterItem.attributeName?.value == AppRouteParams.executionDetail.workflowId
    })
    if ((urlFilteringTokens == null) || (workflowIdExists == undefined)) {
        urlFilteringTokens = {
            ...DEFAULT_ES_QUERY_FILTER,
            attributeFilterItems: [{
                columnDefinition: null,
                attributeName: {
                    value: AppRouteParams.executionDetail.workflowId,
                    label: AppRouteParams.executionDetail.workflowId
                },
                condition: FilterOperation.EQUALS,
                value: workflowId,
                tableType: TableTypes.EXECUTION_ATTEMPTS
            }],
            sortingOptions: {
                isDescending: true,
                sortingColumn: {
                    sortingField: WORKFLOW_START_DATE
                }
            }
        }
    }
    const [urlFilteringTokensInitial, setUrlFilteringTokensInitial] = useState<EsQueryTableFilterI>(urlFilteringTokens)

    useEffect(() => {
        async function retrieveColumnMetadata() {
            const executionAttemptsTableMetadata: TableMetadataInterface = await new DomainTracker().getTableMetadata("WORKFLOW_ATTEMPT")
            const urlFilteringTokensInitialWithCD = produce(urlFilteringTokensInitial?.attributeFilterItems, (draft: Array<AttributeFilterItemsI>) => {
                draft.forEach(urlFilteringToken => {
                    if (executionAttemptsTableMetadata[urlFilteringToken.attributeName?.value || ""] != undefined) {
                        urlFilteringToken.columnDefinition = executionAttemptsTableMetadata[urlFilteringToken.attributeName?.value || ""] || null
                        urlFilteringToken.tableType = TableTypes.EXECUTION_ATTEMPTS
                    }
                    if (urlFilteringToken.attributeName?.value == AppRouteParams.executionDetail.workflowId) {
                        urlFilteringToken.value == workflowId
                    }
                })
            })

            if (urlFilteringTokensInitialWithCD != undefined) {
                setUrlFilteringTokensInitial({
                    ...urlFilteringTokensInitial,
                    attributeFilterItems: urlFilteringTokensInitialWithCD
                })
            }

            setTableMetadata(executionAttemptsTableMetadata)
        }

        retrieveColumnMetadata()
    }, [])
    return <>
        <>
            <>{JSON.stringify(tableMetadata) != "{}" ?
                <ServerSideTableV3 tableMetadata={tableMetadata}
                                   tableType={TableTypes.EXECUTION_ATTEMPTS}
                                   hidePrimaryFilter={true}
                                   hideSecondaryFilter={false}
                                   esQueryTableFilter={urlFilteringTokensInitial}
                /> : <><Spinner/>Loading metadata..</>
            }
            </>
        </>
    </>
}