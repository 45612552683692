import React, {useEffect, useState} from "react";
import Button from "@amzn/awsui-components-react/polaris/button";
import {
    ASSIGNEE,
    ATTEMPT_ID,
    EXECUTION_ID,
    SWO_ORCHESTRATOR_SERVICE_CLIENT_ID,
    USER_COMMENTS
} from "src/constants/domain-tracker";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import {UpdateDelayAlert} from "src/components/common/user-alerts";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import {ObserveExecutionRequestObservationValueMap} from "@amzn/sm-workflow-orchestration-service-js-client/lib/smworkfloworchestrationservicelambda";
import {DomainTracker} from "src/model/DomainTracker";
import {getUserAlias} from "src/utils/cookie-helper";
import {toast} from "react-toastify";
import {EditSelectedItemsInterface} from "src/interfaces/domain-tracker";
import {SMSWOUnifiedDashboardServiceLambda} from "@amzn/swo-unified-dashboard-service-lambda-js-client";
import {v1 as uuidv1} from 'uuid';
import {KeyValuePairString} from "src/interfaces/common";
import {EditingFormField} from "src/components/common/editing-form-field";
import initialMetricsPublisher from 'src/metrics';
import * as KatalMetrics from "@katal/metrics";
import {SwoUiMetrics} from "src/utils/swo-ui-metrics/swo-ui-metrics";
import {metricFeaturesList, metricPageNames} from "src/constants/swo-ui-metric-constants";
import {logger} from "src/utils/logger";

/**
 * Modal to update execution details. Support single and multiple columns based on visibleColumn Param.
 * @param props
 * @constructor
 */
export function EditExecutionModel(props: EditSelectedItemsInterface) {
    const [selectedItem, setSelectedItem] = useState<SMSWOUnifiedDashboardServiceLambda.Types.Details>(props.selectedItems[0] || {});
    const [selectedItemWithoutModification, setSelectedItemWithoutModification] = useState<SMSWOUnifiedDashboardServiceLambda.Types.Details>(props.selectedItems[0] || {});
    const [submitButtonLoading, setSubmitButtonLoading] = useState<boolean>(false);
    const actionMetricsPublisher = initialMetricsPublisher.newChildActionPublisherForMethod("SingleEditExecutionDetail")

    function handleValueChange(columnValue: string, columnKey: string): void {
        const newValue: KeyValuePairString = {}
        newValue[columnKey] = columnValue
        const updatedItem = {
            ...selectedItem,
            ...newValue
        }
        setSelectedItem(updatedItem)
    }

    useEffect(() => {

        // Deep clone
        const selectedItemTemp = JSON.parse(JSON.stringify(props.selectedItems[0] || {}))
        setSelectedItemWithoutModification(props.selectedItems[0] || {})
        // "userComments" column is a special case. we don't want to allow user to edit existing comment.
        // Instead we ask them to enter new comment everytime.
        if (selectedItemTemp[USER_COMMENTS] !== undefined) {
            selectedItemTemp[USER_COMMENTS] = ""
        }
        setSelectedItem(selectedItemTemp)
    }, [props.selectedItems[0]])



    async function handleEditExecutionSubmitButton(event: CustomEvent<Button.ClickDetail>) {

        setSubmitButtonLoading(true)
        const obserationValues: ObserveExecutionRequestObservationValueMap = {}

        // Updating values only if there is any change in the value. Checking if there is any change by comparing it with original value.
        Object.entries(props.selectedItems[0]).forEach(([key, value]) => {
            if (key == USER_COMMENTS) {
                if (selectedItem[key].trim() !== "") {
                    obserationValues[key] = selectedItem[key].trim()
                }
            } else if (selectedItem[key] !== value) {
                obserationValues[key] = selectedItem[key].trim()
            }
        })

        const latestAttemptId: string = selectedItem[ATTEMPT_ID] || selectedItem[EXECUTION_ID]
        SwoUiMetrics.publishCloudWatchCountMetric(metricFeaturesList.observeWorkflowInstance, metricPageNames.workflowInstanceListView)
        const katalMetricTimerStopwatch = new KatalMetrics.Metric.TimerStopwatch('latency').withMonitor();
        if(obserationValues[ASSIGNEE]) {
            logger.info(`Assign workflow ${selectedItem[EXECUTION_ID]}`)
            await new DomainTracker().assignWorkflowInstance(selectedItem[EXECUTION_ID], obserationValues[ASSIGNEE],)
                .then((resp) => {
                    logger.info(`Assign workflow ${selectedItem[EXECUTION_ID]} success`)
                    toast.success(<>
                        Assigned successfully. <UpdateDelayAlert feature={"value"} plainText={true}/>
                    </>, {autoClose: 10000})
                    props.handleModalVisibleChange(false);
                })
                .catch((error) => {
                    logger.error(`Assign workflow ${selectedItem[EXECUTION_ID]} failure`, error)
                })
        }
        logger.info(`Observe workflow ${selectedItem[EXECUTION_ID]}`)
        await new DomainTracker().observeExecution(selectedItem[EXECUTION_ID], SWO_ORCHESTRATOR_SERVICE_CLIENT_ID, latestAttemptId, uuidv1(), "COMMENTS",
            obserationValues, getUserAlias())
            .then((resp) => {
                logger.info(`Observe workflow ${selectedItem[EXECUTION_ID]} success`)
                toast.success(<>
                    Updated successfully. <UpdateDelayAlert feature={"value"} plainText={true}/>
                </>, {autoClose: 10000})
                props.handleModalVisibleChange(false);
            })
            .catch((error) => {
                logger.error(`Observe workflow ${selectedItem[EXECUTION_ID]} failure`, error)
                toast.error(JSON.stringify(error.message))
            })
            .then(() => {
                setSubmitButtonLoading(false)
                actionMetricsPublisher.publishCounterMonitor("clicks", 1)
                actionMetricsPublisher.publish(katalMetricTimerStopwatch)
            })
    }

    return (<Modal
            id={"edit-execution-modal"}
            onDismiss={() => props.handleModalVisibleChange(false)}
            visible={props.modalVisible}
            expandToFit={true}
            size="medium"
            footer={
                <span className={"awsui-util-f-r"}>
                    <Button text={"Cancel"}
                            id={"edit-execution-cancel-button"}
                            onClick={() => props.handleModalVisibleChange(false)}
                            loading={submitButtonLoading}
                    />
                    <Button variant="primary" onClick={handleEditExecutionSubmitButton}
                            text={"Update"}
                            loading={submitButtonLoading}
                            id={"edit-execution-submit-button"}
                    />
                </span>
            }
            header={<>{`Update Execution ${selectedItem[EXECUTION_ID]}`}<p className={"awsui-text-secondary"}>
                Maximum 1000 characters per field.</p></>}>

            {Object.entries(props.tableMetadata).map(([key, value]) => {
                return <div key={key}> {value.allowUpdateByUser && <div>
                    <FormField label={value.uiFeatures.visibleName} id={`${key}-table-edit-form`} key={key}
                               hintText={key == USER_COMMENTS && `Existing value: ${selectedItemWithoutModification[key] || "<blank>"}`}
                               className={`awsui-util-spacing-v-s ${props.visibleColumnKey != undefined && props.visibleColumnKey !== key && "awsui-util-d-n"}`}>

                        <EditingFormField columnDefinition={props.tableMetadata[key]} value={selectedItem[key]}
                                          handleValueChange={(key: string, value: string) => handleValueChange(key, value)}
                                          columnKey={key} modalVisible={props.modalVisible}
                        />
                    </FormField>
                </div>
                } </div>
            })}
            <br/>
            <UpdateDelayAlert feature={"value"} plainText={false}/>
        </Modal>
    )
}
