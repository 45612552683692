import React from "react"
import {SMVirtualPipelineLambda} from "@amzn/sm-virtual-pipeline-lambda-js-client";
import {Box, ColumnLayout, Container, Header, Spinner} from "@amzn/awsui-components-react-v3";
import {TPHelper} from "src/utils/table-helper";
import {AppRoutes} from "src/components/common/router-common";
import {VirtualPipelineVisualization} from "src/constants/virtual-pipelines";
import {ButtonWithRouter} from "src/components/virtual-pipeline/common-components";

/**
 * Show overview of all instances. Including TP50 and TP90 of latencies in hours and time range
 * that currently being used.
 * @param props Getting virtual pipeline instances, time range and isLoading flag from dashboard.
 * @constructor
 */
export default function InstanceOverview(props: {
    instances: SMVirtualPipelineLambda.ListOfVirtualPipelineInstances,
    isLoading: boolean,
    date: {
        startDate: string,
        endDate: string
    }
}) {
    const instances: SMVirtualPipelineLambda.ListOfVirtualPipelineInstances = props.instances
    const latencies = instances.map(instance => instance["endToEndLatency"])

    if (props.isLoading) {
        return (
            <Container
                id={"virtual-pipeline-instance-dashboard-instance-overview-container"}
                header={
                    <Header variant="h2" description="Viewing data from VP service">
                        Virtual Pipeline Instances overview
                    </Header>
                }
            >
                <>
                    <div className="awsui"><Spinner size={"large"}/>Loading...</div>
                </>
            </Container>
        )
    }

    return (
        <Container
            id={"virtual-pipeline-instance-dashboard-instance-overview-container"}
            header={
                <Header variant="h2" description="Viewing data from VP service">
                    Virtual Pipeline Instances overview
                </Header>
            }
        >
            <ColumnLayout columns={4} variant="text-grid">
                <div>
                    <Box margin={{ bottom: 'xxxs' }} color="text-label">
                        Number of Instances
                    </Box>
                    <div>{instances.length}</div>
                </div>
                <div>
                    <Box margin={{ bottom: 'xxxs' }} color="text-label">
                        TP50
                    </Box>
                    <div>{TPHelper(latencies, 2, "TP50", "hours")} hours ({TPHelper(latencies, 2, "TP50", "days")} days)</div>
                </div>
                <div>
                    <Box margin={{ bottom: 'xxxs' }} color="text-label">
                        TP90
                    </Box>
                    <div>{TPHelper(latencies, 2, "TP90", "hours")} hours ({TPHelper(latencies, 2, "TP90", "days")} days)</div>
                </div>
                <div id={"virtual-pipeline-instance-dashboard-instance-overview-date-box"}>
                    <Box margin={{ bottom: 'xxxs' }} color="text-label">
                        Seeing Data Between
                    </Box>
                    <div>{props.date["startDate"]} & {props.date["endDate"]}</div>
                </div>
            </ColumnLayout>
        </Container>
    )
}