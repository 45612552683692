export const paginationLabels = {
    nextPageLabel: 'Next page',
    previousPageLabel: 'Previous page',
    pageLabel: (pageNumber: number) => `Page ${pageNumber} of all pages`
};

export const virtualPipelineSelectionLabels = {
    itemSelectionLabel: (data: any, row: any ) => `select ${row.id}`,
    allItemsSelectionLabel: () => 'select all',
    selectionGroupLabel: 'Virtual Pipeline selection'
};
