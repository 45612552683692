import React, {useMemo} from 'react';
import {TableHeader} from 'src/components/common/table-common-components';
import {ExecutionQuickOverviewModal} from "src/components/domain-tracker/execution-quick-overview-modal";
import {ExecutionRestartModal} from "src/components/domain-tracker/execution-restart-modal";
import {ExecutionRepublishModal} from "src/components/domain-tracker/execution-republish-modal";
import {AppRoutes} from "src/components/common/router-common";
import {SMSWOUnifiedDashboardServiceLambda} from "@amzn/swo-unified-dashboard-service-lambda-js-client";
import {ATTEMPT_ID, EXECUTION_ID} from "src/constants/domain-tracker";
import {SelectedItemRequiredKeysInterface, TableTypes} from "src/interfaces/domain-tracker";
import {Button} from "@amzn/awsui-components-react";
import {ServerSideTableHeaderI} from "src/interfaces/server-side-table";
import {EditExecutionDetail} from "src/components/domain-tracker/edit-execution-detail";
import {TerminateExecution} from "src/components/domain-tracker/terminate-execution";

/**
 * Table header for execution list and execution attempts. Will extend it to other tables later.
 * Decides table actions based on table type.
 * @param props
 * @constructor
 */
export const ServerSideTableHeader = (props: ServerSideTableHeaderI) => {
    const {selectedItems} = props;
    const selectedItem: SMSWOUnifiedDashboardServiceLambda.Types.Details = selectedItems[0] || {};
    const title: string = "Search results"
    const isOnlyOneSelected = selectedItems.length === 1;

    const selectedItemsWithRequiredKeys = useMemo(() => {
        return selectedItems.map((item):SelectedItemRequiredKeysInterface => ({
            workflowId: item[EXECUTION_ID],
            recipeExecutionAttemptId: item[ATTEMPT_ID]
        }))
    }, [selectedItems])

    return (
        <TableHeader
            {...props}
            title={title}
            actionButtons={props.tableType == TableTypes.EXECUTION_LIST ? <>
                {/* TODO: Implement Refresh button*/}
                {/*<Button icon={"refresh"}*/}
                {/*        onClick={props.handleRefresh}/>*/}
                <ExecutionQuickOverviewModal {...props} />
                <ExecutionRestartModal selectedItems={selectedItemsWithRequiredKeys} />
                <ExecutionRepublishModal {...props} />
                <EditExecutionDetail {...props} />
                <TerminateExecution selectedItems={selectedItemsWithRequiredKeys} />
                <Button text={"View attempts"} target={"_blank"}
                        href={`${AppRoutes.executionAttempts(selectedItem[EXECUTION_ID])}`} icon={"external"}
                        disabled={!isOnlyOneSelected}
                        iconAlign={"right"}/>
                <Button text={"Workflow Instance detail"} target={"_blank"}
                        href={`${AppRoutes.executionDetail(selectedItem[EXECUTION_ID])}`} icon={"external"}
                        disabled={!isOnlyOneSelected}
                        iconAlign={"right"}/>
            </> : <>
                <ExecutionQuickOverviewModal {...props} />
            </>}
            description={""}
            totalItems={props.totalItems}
            selectedItems={props.selectedItems}
            updateTools={null}
        />
    );
};


