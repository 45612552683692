import React, {useEffect, useState} from 'react';
import {SwoUiMetrics} from "src/utils/swo-ui-metrics/swo-ui-metrics";
import AppLayout, {AppLayoutProps} from "@amzn/awsui-components-react-v3/polaris/app-layout";
import {ServiceNavigation} from "src/components/navigation";
import {appLayoutNavigationLabels} from "src/common/labels";
import {SplitPanel} from "@amzn/awsui-components-react-v3";

export const SPLIT_PANEL_I18NSTRINGS = {
    preferencesTitle: 'Split panel preferences',
    preferencesPositionLabel: 'Split panel position',
    preferencesPositionDescription: 'Choose the default split panel position for the service.',
    preferencesPositionSide: 'Side',
    preferencesPositionBottom: 'Bottom',
    preferencesConfirm: 'Confirm',
    preferencesCancel: 'Cancel',
    closeButtonAriaLabel: 'Close panel',
    openButtonAriaLabel: 'Open panel',
    resizeHandleAriaLabel: 'Resize split panel',
};

export const SMOverviewAppLayout: React.FunctionComponent<{
    metricName: string;
    page: string;
    content: React.ReactElement;
    splitPanelContent?: React.ReactElement;
    addSplitPanel?: boolean;
    splitPanelHeader?: string;
}> = ({
        metricName,
        page,
        content,
        splitPanelContent,
        addSplitPanel = false,
        splitPanelHeader
      }) => {
    const [navigationOpen, setNavigationOpen] = useState(false);
    const [splitPanelOpen, setSplitPanelOpen] = useState(false);
    const [splitPanelSize, setSplitPanelSize] = useState(500);
    const [splitPanelPreference, setSplitPanelPreference]
        = useState<AppLayoutProps.SplitPanelPreferences>({position: 'side'})
    useEffect(() => {
        SwoUiMetrics.publishCloudWatchCountMetric(metricName, page);
    }, [])
    const Content = addSplitPanel ? React.cloneElement(content, {
            openSplitPanel: (size?: number, preference?: AppLayoutProps.SplitPanelPreferences) => {
                setSplitPanelOpen(true);
                size && setSplitPanelSize(size);
                preference && setSplitPanelPreference(preference);
            }
        })
        : content;
    return (
        <AppLayout
            navigation={<ServiceNavigation/>}
            content={Content}
            contentType='default'
            disableContentPaddings={true}
            navigationOpen={navigationOpen}
            onNavigationChange={(event: any) => setNavigationOpen(event.detail.open)}
            toolsHide={true}
            splitPanelSize={splitPanelSize}
            onSplitPanelResize={
                /* istanbul ignore next */
                ({ detail: { size } }: { detail: { size: number } }) => {setSplitPanelSize(size);}
            }
            splitPanelPreferences={splitPanelPreference}
            ariaLabels={appLayoutNavigationLabels}
            splitPanelOpen={splitPanelOpen}
            onSplitPanelToggle={() => {
                setSplitPanelOpen((prevSplitPanelState) => !prevSplitPanelState)
            }}
            splitPanel={
                addSplitPanel ?
                    <SplitPanel
                        hidePreferencesButton={true}
                        header={splitPanelHeader || ''}
                        i18nStrings={SPLIT_PANEL_I18NSTRINGS}
                    >
                        {splitPanelContent}
                    </SplitPanel>
                    : null
            }
        />
    );
}
