import React, {useEffect, useState} from "react";
import {ExpandableSection, Flashbar, ProgressBar} from "@amzn/awsui-components-react-v3";
import {convertProgressStatusToFlashType} from "src/components/common/table-common-components";
import {ProgressBarProps} from "@amzn/awsui-components-react-v3/polaris/progress-bar/interfaces";
import {toast} from "react-toastify";
import {ErrorMessageHelper} from "src/components/common/error-message-helper";
import {logger} from "src/utils/logger";


interface UseProgressBarI {
    progressPercentage: number
    progressLoading: boolean
    progressStatus: ProgressBarProps.Status
    isCompleted: boolean,
    isCompletedSuccessfully: boolean
    errorMessages: Array<any>
}


export function useProgressBar(promises: Array<Promise<any>>, toastSuccessMessage: React.ReactNode): UseProgressBarI {

    const [progressPercentage, setProgressPercentage] = useState<number>(0);
    const [progressLoading, setProgressLoading] = useState<boolean>(false)
    const [progressStatus, setProgressStatus] = useState<ProgressBarProps.Status>("in-progress");
    const [isCompleted, setIsCompleted] = useState<boolean>(false);
    const [isCompletedSuccessfully, setIsCompletedSuccessfully] = useState<boolean>(false);
    const [errorMessages, setErrorMessages] = useState<Array<any>>([]);
    useEffect(() => {

        async function allProgress(promises: Array<Promise<any>>, progressCallback: Function): Promise<any> {
            let count = 0;
            progressCallback(0);
            for (const promise of promises) {
                promise.then((resp) => {
                    count++;
                    const percent = (count * 100) / promises.length
                    setProgressPercentage(percent)
                    progressCallback(percent);
                }).catch((error) => {
                    console.error("Exception", error)
                })
            }

            return Promise.allSettled(promises);
        }

        if (promises.length > 0) {
            setProgressLoading(true)
            setIsCompleted(false)
            setIsCompletedSuccessfully(false)
            setProgressStatus("in-progress")
            setErrorMessages([])
            allProgress(promises, (progressPercentage: number) => {
                setProgressPercentage(Math.trunc(progressPercentage))
            })
                // : Array<PromiseFulfilledResult<any> | PromiseRejectedResult>
                .then((promiseResults: Array<PromiseFulfilledResult<any> | PromiseRejectedResult>) => {
                    setIsCompleted(true)
                    setProgressLoading(false)
                    const errorReasons: Array<any> = [];
                    if (promiseResults.some(promiseResponse => promiseResponse.status == "rejected")) {
                        setProgressStatus("error")
                        setIsCompletedSuccessfully(false)
                    } else {
                        setIsCompletedSuccessfully(true)
                        setProgressStatus("success")
                        toast.success(toastSuccessMessage, {autoClose: 10000})
                    }

                    promiseResults.forEach(promiseResponse => {
                        if (promiseResponse.status == "rejected") {
                            logger.error(`${promiseResponse.reason}`)
                            errorReasons.push(promiseResponse.reason)
                        } else {
                            logger.info(promiseResponse.status)
                        }
                    })

                    setErrorMessages(errorReasons);
                });

        }

    }, [promises])

    return {
        progressPercentage,
        progressLoading,
        progressStatus,
        isCompleted,
        isCompletedSuccessfully,
        errorMessages
    }
}

interface TableProgressBarI {
    progressStatus: ProgressBarProps.Status
    progressPercentage: number
    totalRecordLength: number
    description?: React.ReactNode
    title: React.ReactNode
    errorMessages: Array<any>
}

export function TableProgressBar(props: TableProgressBarI) {

    return <div>
        <Flashbar items={[
            {
                content: <ProgressBar
                    id={"table-progress-bar"}
                    status={props.progressStatus}
                    value={props.progressPercentage}
                    variant="flash"
                    description={props.description}
                    label={props.title}
                    resultText={getResultText(props.progressStatus, props.progressPercentage, props.totalRecordLength)}
                />,
                type: convertProgressStatusToFlashType(props.progressStatus)
            }
        ]}
        />

        {props.progressStatus == "error" &&
        <ExpandableSection header="Error reason">

            <ol>
                {props.errorMessages.map((errorMessage, index) => {
                        return <li key={index}>
                            <ErrorMessageHelper error={errorMessage}/>
                        </li>
                    }
                )}
            </ol>
        </ExpandableSection>
        }
    </div>
}

const getResultText = (progressStatus: ProgressBarProps.Status, progressPercentage: number, selectedItemLength: number) => {

    return <>
        {progressStatus == "success" ? "Updated all records successfully" :
            <>
                {`${Math.round(progressPercentage * selectedItemLength / 100)} out of ${selectedItemLength} records succeeded`}
            </>}
    </>
}