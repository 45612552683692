/**
 * Get count numbers for table headers
 * @param items
 * @param selectedItems
 * @returns {string}
 */
import {Details} from "@amzn/swo-unified-dashboard-service-lambda-js-client/lib/smswounifieddashboardservicelambda";

export const getHeaderCounterText = (items : Array<any>, selectedItems : Array<any>) => {
    return selectedItems && selectedItems.length > 0 ? `(${selectedItems.length}/${items.length})` : `(${items.length})`;
};

/**
 * Get server side count number for table headers
 * @param totalCount
 * @param selectedItems
 * @returns {string}
 */
export const getServerHeaderCounterText = (totalCount: number, selectedItems: Array<string>) => {
    return selectedItems && selectedItems.length > 0 ? `(${selectedItems.length}/${totalCount})` : `(${totalCount})`;
};

/**
 * Shows filtering count next to property filtering input text box.
 * @param items
 * @param pagesCount
 * @param pageSize
 * @returns {string|string}
 */
export const getServerFilterCounterText = (items: Array<any>, pagesCount: number, pageSize: number) => {
    const count = pagesCount > 1 ? `${pageSize * (pagesCount - 1)}+` : items.length + '';
    return count === '1' ? `1 match` : `${count} matches`;
};