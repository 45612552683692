import React from 'react';
import {Box, ColumnLayout, Container, Header, Link} from "@amzn/awsui-components-react-v3";
import {WorkflowInstanceOverviewI} from "src/interfaces/workflow-instance-detail";
import {ExternalRoutes} from "src/components/common/router-common";
import {convertOptionslEpochToHumanReadable} from "src/components/execution-detail/workflow-detail-sub-stages-cards";
import {buildSporcUiLink} from "src/components/execution-detail/execution-detail-util";

export function WorkflowInstanceOverview(props: WorkflowInstanceOverviewI) {
    return <Box margin={{ bottom: 'l' }}>
        <Container header={<Header variant="h2">Overview</Header>} >
            <ColumnLayout columns={4} variant="text-grid">
                <div>
                    <Box margin={{ bottom: 'xxxs' }} color="text-label">
                        Workflow Instance Id
                    </Box>
                    <div>{props.workflowInstance?.workflowInstanceId || '-'}</div>
                </div>
                <div>
                    <Box margin={{ bottom: 'xxxs' }} color="text-label">
                        Workflow Name
                    </Box>
                    <div>{props.workflowInstance?.workflowName || '-'}</div>
                </div>

                <div>
                    <Box margin={{ bottom: 'xxxs' }} color="text-label">
                        Domain
                    </Box>
                    <div>{props.workflowInstance?.domain}</div>
                </div>
                {props?.profileId ? <div>
                    <Box margin={{ bottom: 'xxxs' }} color="text-label">
                        Profile Id
                    </Box>
                    <div data-testid={"workflow-profile-id"}>{props?.profileId}</div>
                </div> : null}
                <div>
                    <Box margin={{ bottom: 'xxxs' }} color="text-label">
                        Status
                    </Box>
                    <div>{props.workflowInstance?.status || '-'}</div>
                </div>
                <div>
                    <Box margin={{ bottom: 'xxxs' }} color="text-label">
                        Program
                    </Box>
                    <div>{props.workflowInstance?.program || '-'}</div>
                </div>
                <div>
                    <Box margin={{ bottom: 'xxxs' }} color="text-label">
                        Sub Program
                    </Box>
                    <div>{props.workflowInstance?.subProgram || '-'}</div>
                </div>

                <div>
                    <Box margin={{ bottom: 'xxxs' }} color="text-label">
                        Current stage
                    </Box>
                    <div>{props.workflowInstance?.currentStage || '-'}</div>
                </div>



                <div>
                    <Box margin={{ bottom: 'xxxs' }} color="text-label">
                        Begin time
                    </Box>
                    <div>{props.workflowInstance?.beginTime == undefined ? "-" :
                        convertOptionslEpochToHumanReadable(props.workflowInstance?.beginTime)}</div>
                </div>

                <div>
                    <Box margin={{ bottom: 'xxxs' }} color="text-label">
                        End time
                    </Box>
                    <div>{props.workflowInstance?.endTime == undefined ? "-" :
                        convertOptionslEpochToHumanReadable(props.workflowInstance?.endTime)}</div>
                </div>

                <div>
                    <Box margin={{ bottom: 'xxxs' }} color="text-label">
                        Workitems(SIM)
                    </Box>

                    <Link href={ExternalRoutes.workflowInstanceSIM(props.workflowId)} external={true} >
                        Click here</Link>
                </div>

                {props?.orchestratorEngineType === "SPORC"
                    && props?.orchestratorEngineId && <div>
                    <Box margin={{ bottom: 'xxxs' }} color="text-label">
                        Batch level details
                    </Box>

                    <Link
                        href={buildSporcUiLink(props?.orchestratorEngineId)}
                        external={true}
                        data-testid={"workflow-sporc-execution-route"}
                    >
                        Click here
                    </Link>
                </div>
                }
            </ColumnLayout>
        </Container>
    </Box>
}