import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {Alert, Box, Container, Grid, Spinner} from "@amzn/awsui-components-react-v3";
import {StackedAreaChart} from "src/components/charts/stacked-area-chart";
import {ExecutionSummaryChartI} from "src/interfaces/charts";
import {ExecutionSummary} from "src/model/ExecutionSummary";
import {ElasticSearchHelper} from "src/utils/elastic-search-helper";
import {GetAggregateCardResponse} from "@amzn/swo-unified-dashboard-service-lambda-js-client/lib/smswounifieddashboardservicelambda";
import {WorkflowSubStageGraph} from "src/components/execution-summary/workflow-sub-stage-graph";

/**
 * Aggregate Execution summary chart to display stages, status and execution count.
 * @param props
 * @constructor
 */
export const ExecutionSummaryChart = (props: ExecutionSummaryChartI) => {
    const esQuery = ElasticSearchHelper.convertAttributeFilterTokensToESQueryV3(props.esQueryTableFilter)
    const [aggregateCardResponse, setAggregateCardResponse] = useState<GetAggregateCardResponse | null>(null)
    const [aggregateCardError, setAggregateCardResponseError] = useState<boolean>(false)
    const [showSubStage, setShowSubStage] = useState<boolean>(false)
    const [currentSubStage, setCurrentSubStage] = useState<string>("")
    const subStageGraphRef = useRef(null)
    // @ts-ignore
    const executeScroll = () => subStageGraphRef.current.scrollIntoView()

    useEffect(() => {
        if (props.esQueryTableFilter.attributeFilterItems.length == 0) {
            return;
        }
        if ((esQuery["query"] == "") || (esQuery["query"] == undefined)) {
            return;
        }
        props.handleChartLoading(true)
        setAggregateCardResponseError(false)
        setShowSubStage(false)
        const aggregateCard = new ExecutionSummary().getSummaryDetails("WORKFLOWS_STAGE", JSON.stringify(esQuery))
        aggregateCard.then((resp) => {
            setAggregateCardResponse(resp)
        }).catch(error => {
            setAggregateCardResponseError(true)
            setAggregateCardResponse(null)
        }).then(() => {
            props.handleChartLoading(false)
        })

    }, [props.esQueryTableFilter])
    return (
        <>
            {aggregateCardError &&
            <Alert type={"error"} data-testid={"execution-summary-chart-error-alert"}>Failed to retrieve chart details.
                Please refresh the page and try again.</Alert>}
            <Box variant={"div"} textAlign={"center"}>
                <Container>
                    <Grid
                        key={"execution-summary-chart"}
                        gridDefinition={[
                            {colspan: {default: 12, xxs: 10, xs: 8}, offset: {default: 0, xxs: 1, xs: 2}},
                            {colspan: {default: 12, xxs: 10, xs: 8}, offset: {default: 0, xxs: 1, xs: 2}},
                            {colspan: {default: 12, xxs: 10, xs: 8}, offset: {default: 0, xxs: 1, xs: 2}},
                        ]}
                    >
                        <div>{props.loading ? <><Spinner size={"large"}/> Loading... </> : <>
                            {aggregateCardResponse != null && <StackedAreaChart data={aggregateCardResponse}
                                                                                handleSubStageLinkClick={(stageName: string) => {
                                                                                    setShowSubStage(true)
                                                                                    setCurrentSubStage(stageName)
                                                                                    executeScroll()
                                                                                }}
                            />}
                        </>}</div>
                        <div><hr /></div>

                        <div ref={subStageGraphRef} id={"workflow-sub-stage-chart-parent-div"}>
                            {showSubStage &&
                            <WorkflowSubStageGraph esQueryTableFilter={props.esQueryTableFilter} loading={props.loading}
                                                   subStage={currentSubStage}
                            />
                            }
                        </div>
                    </Grid>
                </Container>
            </Box>
        </>
    );
}