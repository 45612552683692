import {SMVirtualPipelineLambda} from "@amzn/sm-virtual-pipeline-lambda-js-client";
import {virtualPipelineAPIConfig} from "src/utils/api-gateway/vp-api-mappings";
import {getStage} from "src/utils/env";

/**
 * Model to interact with virtual pipeline service API Gateway. Currently will go to the test endpoint of the service.
 */
export class VirtualPipeline {

    private smVirtualPipelineLambda: SMVirtualPipelineLambda;

    constructor() {
        const virtualPipelineConfig = virtualPipelineAPIConfig(getStage())
        this.smVirtualPipelineLambda = new SMVirtualPipelineLambda({
            endpoint: `https://${virtualPipelineConfig.endpoint}/${virtualPipelineConfig.stage}`,
            region: virtualPipelineConfig.region
        })
    }

    async vp()
    : Promise<{
        virtualPipelines: SMVirtualPipelineLambda.Types.ListOfVirtualPipelines
    }>{
        const request: SMVirtualPipelineLambda.Types.GetAllVirtualPipelinesRequest = {}
        const response = await this.smVirtualPipelineLambda.getAllVirtualPipelines(request).promise()
        if (response.virtualPipelines == undefined) {
            throw new Error("Unable to get data from virtual pipeline service try again later.")
        }

        return Promise.resolve({
            virtualPipelines: response.virtualPipelines
        })
    }

    async vpInfo(
        vpId: string
    ): Promise<{
        virtualPipeline: SMVirtualPipelineLambda.Types.VirtualPipeline
    }> {
        const request: SMVirtualPipelineLambda.Types.GetVirtualPipelineByVpIdRequest = {
            vpId: vpId
        }

        const response = await this.smVirtualPipelineLambda.getVirtualPipelineByVpId(request).promise()
        if (response.virtualPipeline == undefined) {
            throw new Error("Unable to get data from virtual pipeline service. Request vpId is either not exists or service not responding. Please check and try again.")
        }
        const virtualPipeline = response.virtualPipeline
        return Promise.resolve({
            virtualPipeline: virtualPipeline
        })
    }

    async vpDetail(
        vpId: string
    ): Promise<{
        detailedVirtualPipeline: SMVirtualPipelineLambda.Types.DetailedVirtualPipeline
    }> {
        const request: SMVirtualPipelineLambda.Types.GetDetailedVirtualPipelineByVpIdRequest = {
            vpId: vpId
        }

        const response = await this.smVirtualPipelineLambda.getDetailedVirtualPipelineByVpId(request).promise()
        if (response.detailedVirtualPipeline == undefined) {
            throw new Error("Unable to get data from virtual pipeline service. Request vpId is either not exists or service not responding. Please check and try again.")
        }
        const detailedVirtualPipeline = response.detailedVirtualPipeline
        return Promise.resolve({
            detailedVirtualPipeline: detailedVirtualPipeline
        })
    }

    async vpi(
        vpId: string,
        timeInterval: { startTime: any, endTime: any },
    ): Promise<{
        virtualPipelineInstances: SMVirtualPipelineLambda.Types.ListOfVirtualPipelineInstances
    }> {
        const request: SMVirtualPipelineLambda.Types.GetVirtualPipelineInstancesByVpIdAndTimeRangeRequest = {
            vpId: vpId,
            startTime: timeInterval.startTime,
            endTime: timeInterval.endTime
        }

        const response = await this.smVirtualPipelineLambda.getVirtualPipelineInstancesByVpIdAndTimeRange(request).promise()
        if (response.virtualPipelineInstances == undefined) {
            throw new Error("Unable to get data from virtual pipeline service try again later.")
        }
        const filteredVirtualPipelineInstances = response.virtualPipelineInstances
        return Promise.resolve({
            virtualPipelineInstances: filteredVirtualPipelineInstances
        })
    }
}
