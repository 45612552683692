import React, {useCallback, useState} from 'react';
import {useStartScreenUtil} from "src/components/start-workflow-instance/start-screen-util-hook";
import {Alert, Box, Button, Link, Form as AWSUIForm, Container, Spinner} from "@amzn/awsui-components-react-v3";
import Header from "@amzn/awsui-components-react-v3/polaris/header";
import {WorkflowMetadata} from "src/components/start-workflow-instance/start-screen-components";
import {TabInputForm} from "src/components/common/tab-input-form";
import {DomainTracker} from "src/model/DomainTracker";
import {SaveWorkflowVariantRequest} from '@amzn/sm-workflow-orchestration-service-js-client/lib/smworkfloworchestrationservicelambda';
import {useMutation} from "@tanstack/react-query";

export const SaveWorkflowConfig = () => {
    /* istanbul ignore next */
    const mutation = useMutation({
        mutationFn: (saveWorkflowConfigRequest: SaveWorkflowVariantRequest) => {
            return new DomainTracker().saveProgramDefaults(saveWorkflowConfigRequest)
        }
    })
    const {
        schemaLoading,
        startWFLoading,
        workflowDetails,
        enableLiveValidate,
        workflowInput,
        programSelectedOption,
        subProgramOptions,
        selectedSubProgram,
        domainName,
        programOptions,
        targetWebsite,
        targetWebsiteOptions,
        workflowUISchema,
        onTabFormDataChange,
        onTabFormDataError,
        errorText,
        itemsRef,
        onProgramChangeHandler,
        onSubProgramChangeHandler,
        onTargetWebsiteChange,
        onTabDataChangeHandler
    } = useStartScreenUtil();

    const handleFormSubmit = () => {
        const saveWorkflowConfigRequest: SaveWorkflowVariantRequest = {
            workflowId: programSelectedOption.label || '',
            variantId: selectedSubProgram.value || '',
            defaultInputJSON: JSON.stringify(workflowInput)
        }
        mutation.mutate(saveWorkflowConfigRequest);
    }

    const onTabFormDataSubmit = useCallback((tabId: string, tabIndex: number) => {
        onTabDataChangeHandler(tabId, tabIndex, () => {});
    }, [onTabDataChangeHandler])

    return (
        <>
            <Box padding={{bottom: "l"}}>
                <Alert visible={mutation?.isSuccess}
                       data-testid={"save-workflow-config-success-alert"}
                       type={"success"}
                       dismissible={true}
                       onDismiss={() => mutation.reset()}>
                    <p>Successfully saved workflow config defaults.</p>
                </Alert>
                <Alert visible={Boolean(mutation?.error)}
                       data-testid={"save-workflow-config-error-alert"}
                       type={"error"}
                       dismissible={true}
                       onDismiss={() => mutation.reset()}>
                    <p>Failed to saved workflow config defaults.</p>
                </Alert>
            </Box>
            <AWSUIForm
                errorText={errorText}
                actions={
                    <Button variant="primary"
                            data-testid={"save-workflow-config-submit-button"}
                            disabled={schemaLoading || mutation.isLoading || programSelectedOption?.value === "" || selectedSubProgram?.value === ""}
                            loading={startWFLoading || mutation.isLoading}
                            onClick={() => handleFormSubmit()}>Save Workflow Config</Button>

                }
            >
                <Container header={<Header variant="h2">Save Workflow Config</Header>}>
                    <WorkflowMetadata
                        programSelectedOption={programSelectedOption}
                        programOptions={programOptions}
                        selectedSubProgram={selectedSubProgram}
                        subProgramOptions={subProgramOptions}
                        targetWebsite={targetWebsite}
                        targetWebsiteOptions={targetWebsiteOptions}
                        domainName={domainName}
                        disableDomain={true}
                        disableTargetWebsiteOption={true}
                        isScheduled={false}
                        startWFLoading={startWFLoading}
                        schemaLoading={schemaLoading}
                        isScheduleDisabled={true}
                        onProgramChangeHandler={onProgramChangeHandler}
                        onSubProgramChangeHandler={onSubProgramChangeHandler}
                        onTargetWebsiteChange={onTargetWebsiteChange}
                    />
                </Container>
                <Container
                    data-testid={"workflow-input-rjsf-form-field"}
                    header={<Header variant={"h3"}>Workflow Input</Header>}
                >
                    {programSelectedOption.value == "" ?
                        <Alert>Please select program to provide workflow input</Alert> :
                        <>
                            {schemaLoading ? <><Spinner size={"big"}/> Loading Workflow input form... </> :
                                <TabInputForm
                                    stageInstanceInputSchemaMap={workflowDetails.stageInstanceInputSchemaMap || {}}
                                    formInputData={workflowInput}
                                    onTabFormChange={onTabFormDataChange}
                                    onTabFormSubmit={onTabFormDataSubmit}
                                    onTabFormError={onTabFormDataError}
                                    ref={itemsRef}
                                    enableLiveValidate={enableLiveValidate}
                                    stageInstanceUISchemaMap={workflowUISchema || {}}
                                    stageRenderingOrder={workflowDetails.stages || []}
                                />
                            }
                        </>
                    }
                </Container>
            </AWSUIForm>
        </>
    )
}
