import React, {useEffect, useState} from "react"
import {Alert, Spinner} from "@amzn/awsui-components-react-v3";
import {TableTypes} from "src/interfaces/domain-tracker";
import {TableMetadataInterface} from "src/interfaces/server-side-table";
import {UrlHelper} from "src/utils/url-helper";
import {toast} from "react-toastify";
import {Filters} from "src/components/filters";
import {ExecutionSummaryChart} from "src/components/execution-summary/execution-summary-chart";
import {WORKFLOW_START_DATE} from "src/constants/domain-tracker";
import {getStartDateAndEndDate} from "src/utils/table-helper";
import produce from "immer";
import {DEFAULT_ES_QUERY_FILTER} from "src/constants/elastic-search";
import {AttributeFilterItemsI, EsQueryTableFilterI, FilterOperation} from "src/interfaces/attribute-filters";
import {DomainTracker} from "src/model/DomainTracker";
import {UserTablePreferences} from "src/components/common/table-user-preferences";

/**
 * Retrieves execution summary metadata and displays charts
 * @constructor
 */
export function ExecutionSummaryContent() {
    const [workitemSummaryMetadata, setWorkitemSummaryMetadata] = useState<TableMetadataInterface>({})
    const [metadataError, setMetadataError] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(true)
    const userTablePreference = new UserTablePreferences(TableTypes.EXECUTION_LIST)
    let urlFilteringTokens: EsQueryTableFilterI | null = null;
    try {
        urlFilteringTokens = UrlHelper.getTableFiltersFromUrlParams(UrlHelper.ES_QUERY_PARAMS_KEY)
    } catch (e) {
        console.error("Error while parsing url params", e)
        toast.error("Error while parsing url params. Redirecting to default view.")
    }

    const isWFStartDateExists = urlFilteringTokens?.attributeFilterItems.find(attributeFilterItem => {
        return attributeFilterItem.attributeName?.value == WORKFLOW_START_DATE
    })


    if ((urlFilteringTokens == null) || (isWFStartDateExists == undefined)) {
        urlFilteringTokens = {
            ...DEFAULT_ES_QUERY_FILTER,
            size: userTablePreference.getTablePreference().size || DEFAULT_ES_QUERY_FILTER.size,
            attributeFilterItems: [...DEFAULT_ES_QUERY_FILTER.attributeFilterItems, {
                value: getStartDateAndEndDate(30),
                columnDefinition: null,
                attributeName: {
                    value: WORKFLOW_START_DATE,
                    label: WORKFLOW_START_DATE
                },
                condition: FilterOperation.BETWEEN,
                tableType: TableTypes.EXECUTION_SUMMARY
            }]
        }
        UrlHelper.setUrlParamsFromTableFilters(urlFilteringTokens)
    }

    const [urlFilteringTokensInitial, setUrlFilteringTokensInitial] = useState<EsQueryTableFilterI>(urlFilteringTokens)
    useEffect(() => {

        async function retrieveColumnMetadata() {

            await new DomainTracker().getTableMetadata("WORKFLOW").then((resp) => {

                const urlFilteringTokensInitialWithCD = produce(urlFilteringTokens?.attributeFilterItems, (draft: Array<AttributeFilterItemsI>) => {
                    draft.forEach(urlFilteringToken => {
                        if (resp[urlFilteringToken.attributeName?.value || ""] != undefined) {
                            urlFilteringToken.columnDefinition = resp[urlFilteringToken.attributeName?.value || ""] || null
                            urlFilteringToken.tableType = TableTypes.EXECUTION_SUMMARY
                        }
                    })
                })
                if (urlFilteringTokensInitialWithCD != undefined) {
                    const urlFilteringTokensInitialWithCd = {
                        ...urlFilteringTokensInitial,
                        attributeFilterItems: urlFilteringTokensInitialWithCD
                    }
                    setUrlFilteringTokensInitial(urlFilteringTokensInitialWithCd)
                    UrlHelper.setUrlParamsFromTableFilters(urlFilteringTokensInitialWithCd)
                }
                setWorkitemSummaryMetadata(resp)
            })
                .catch((e) => {
                    setMetadataError(true)
                    setWorkitemSummaryMetadata({})
                })
        }

        retrieveColumnMetadata()
    }, [])

    return <>
        {metadataError &&
        <Alert type={"error"} data-testid={"execution-summary-retrieve-metadata-alert"}>Failed to retrieve metadata.
            Please refresh the page and try again.</Alert>}
        <>{JSON.stringify(workitemSummaryMetadata) != "{}" ?
            <>
                <Filters
                    tableType={TableTypes.EXECUTION_SUMMARY} loading={loading} tableMetadata={workitemSummaryMetadata}
                    onSubmitAttributeFilterItems={(attributeFilterItems: Array<AttributeFilterItemsI>) => {
                        const esQueryItems = {
                            ...urlFilteringTokensInitial,
                            attributeFilterItems: attributeFilterItems
                        }
                        setUrlFilteringTokensInitial(esQueryItems)
                        UrlHelper.setUrlParamsFromTableFilters(esQueryItems)
                    }}
                    attributeFilterItems={urlFilteringTokensInitial.attributeFilterItems}
                    hidePrimaryFilter={false}
                    hideSecondaryFilter={false}
                />
                <ExecutionSummaryChart esQueryTableFilter={urlFilteringTokensInitial} loading={loading}
                                       handleChartLoading={(loading: boolean) => {
                                           setLoading(loading)
                                       }
                                       }/>

            </>
            : <><Spinner/>Loading metadata..</>
        }
        </>
    </>
}