import React, {useState} from "react";
import Button from "@amzn/awsui-components-react/polaris/button";
import {SelectedItemsInterface} from "src/interfaces/domain-tracker";
import {EditExecutionModel} from "src/components/domain-tracker/edit-execution-detail-model";
import {BulkEditExecutionModel} from "src/components/domain-tracker/bulk-edit-execution-detail-model";

/**
 * Button to keep in table actions for editing execution values defined in table metadata.
 * @param props
 * @constructor
 */
export function EditExecutionDetail(props: SelectedItemsInterface) {
    const [showEditExecutionDetailModal, setShowEditExecutionDetailModal] = useState<boolean>(false);
    const [showBulkEditExecutionDetailModal, setBulkShowEditExecutionDetailModal] = useState<boolean>(false);
    const isOnlyOneSelected = props.selectedItems.length === 1;
    return <>
        <EditExecutionModel selectedItems={props.selectedItems} tableMetadata={props.tableMetadata} bubbleEdit={false}
                            modalVisible={showEditExecutionDetailModal}
                            handleModalVisibleChange={(visible: boolean) => setShowEditExecutionDetailModal(visible)}/>
        <BulkEditExecutionModel selectedItems={props.selectedItems} tableMetadata={props.tableMetadata} bubbleEdit={false}
                                                    modalVisible={showBulkEditExecutionDetailModal}
                                                    handleModalVisibleChange={(visible: boolean) => setBulkShowEditExecutionDetailModal(visible)} />
        <Button text={"Edit"}
                id={"edit-execution-detail-button"}
                onClick={() => {
                    if (isOnlyOneSelected) {
                        setShowEditExecutionDetailModal(true)
                        setBulkShowEditExecutionDetailModal(false)
                    } else {
                        setShowEditExecutionDetailModal(false)
                        setBulkShowEditExecutionDetailModal(true)
                    }
                }}
                disabled={props.selectedItems.length === 0}
        /></>
}