import {Box, Container, Link} from "@amzn/awsui-components-react-v3";
import {AppRoutes} from "src/components/common/router-common";
import React from "react";
import {UrlHelper} from "src/utils/url-helper";
import {CustomTooltipI} from "src/interfaces/charts";

/**
 * Custom tooltip to display while hovering anywhere on stacked chart area.
 * @param props
 * @constructor
 */
export const ChartCustomTooltip = (props: CustomTooltipI) => {
    if (!props.active || props.payload == null) {
        return null;
    }
    const payloadNew: Array<any> = [...props.payload].reverse()
    return <><Container id={"recharts-custom-tooltip"}>
        <h3>{props.label}</h3>
        {props.showSubStage && <Link href={""} onFollow={() => props.handleSubStageLinkClick(props.label)}>Show sub stages</Link>}
        {payloadNew.map((payload: any, index: number) => {
            const xAxisKey = props.isXAxisPrimaryKey ? props.label : payload.dataKey
            const yAxisKey = props.isXAxisPrimaryKey ? payload.dataKey : props.label
            const redirectionContext = props.xToY[xAxisKey][yAxisKey].redirectionContext
            const redirectUrl = `${AppRoutes.executionSummaryRedirect}?redirectionContext=${encodeURIComponent(redirectionContext || "")}&${UrlHelper.ES_QUERY_PARAMS_KEY}=${UrlHelper.getParamValue(UrlHelper.ES_QUERY_PARAMS_KEY)}`
            return <div key={`custom-tooltip-payload-${index}`}> <span style={{
                color: payload.stroke
            }}>{payload.name} </span>

                <Box float={"right"} margin={ {left: "s" }}
                >{redirectionContext != undefined ?
                    <Link
                        href={redirectUrl}
                        external={true}> {payload.value} </Link>
                    : <>{payload.value}</>}</Box>
            </div>
        })}
    </Container>
    </>
};
