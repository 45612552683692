import { workForceTypes } from "src/interfaces/wim";

// Find url for workforce identifier.
export const getWorkForceTypeIdentifierUrl = (workForceType: string, workForceTypeIdentifier: string): string => {
  let workForceTypeIdentifierUrlPrefix = "";
  switch (workForceType.toLowerCase()) {
    case workForceTypes.SIM.toString().toLowerCase():
      workForceTypeIdentifierUrlPrefix = `http://t.corp.amazon.com/${workForceTypeIdentifier}`;
      break;
    default:
      workForceTypeIdentifierUrlPrefix = "";
  }
  return workForceTypeIdentifierUrlPrefix;
};
