import React, {useEffect, useMemo, useState} from "react"
import {Input, Multiselect, SelectProps, Spinner, DateRangePicker} from "@amzn/awsui-components-react-v3";
import {DomainTracker} from "src/model/DomainTracker";
import DateTimeRange, {Range, Timezone, useDateTimeRange} from "@amzn/polaris-date-time-range";
import {
    convertToAbsoluteRange,
    dateRangeFilterI18nStrings,
    isValidRangeFunction
} from "src/components/virtual-pipeline/virtual-pipeline-instance/dashboard-utils";
import {getStartDateAndEndDate, searchAttributeValuesByKey} from "src/utils/table-helper";
import {ES_SEARCH_TYPES} from "src/constants/elastic-search";
import {AttributeFilterValueI, FilterOperation} from "src/interfaces/attribute-filters";
import Moment from "moment";

/**
 * Different Component to enter user values. Support 3 types.
 * 1. Date range component. Applicable only for es type is DATE
 * 2. Multi select component from dropdown. For eg status, Only applicable if dropdown values are available.
 * 3. Default - Free text field. Support multiple values by comma separated.
 * @param props
 * @constructor
 */
export function AttributeFilterValue(props: AttributeFilterValueI): JSX.Element {
    let inputComponent: JSX.Element;
    const [dropDownLoading, setDropDownLoading] = useState<boolean>(false)
    const [dropDownValues, setDropDownValues] = useState<Array<string>>([])
    const selectedOptions: Array<SelectProps.Option> = []
    let selectedDateRange: Range = [null, null];

    let textFieldInput: string = ""
    if (typeof props.value == "string") {
        textFieldInput = props.value
    } else if (props.value == null) {
        textFieldInput = ""
    } else if (Array.isArray(props.value)) {
        textFieldInput = props.value.join(",")
    }


    if (props.columnDefinition != null) {
        if (props.columnDefinition.dataType == ES_SEARCH_TYPES.KEYWORD) {
            if (Array.isArray(props.value)) {
                props.value.forEach((pv: any) => {
                    if ((pv != "") && (pv != null)) {
                        selectedOptions.push({
                            label: pv,
                            value: pv
                        })
                    }
                })
            } else {
                if ((props.value != "") && (props.value != null)) {
                    selectedOptions.push({
                        label: props.value as string,
                        value: props.value as string
                    })
                }
            }
        }
        if (props.columnDefinition.dataType == ES_SEARCH_TYPES.DATE) {
            if (Array.isArray(props.value)) {
                if (props.value.length == 0) {
                    selectedDateRange = [null, null]
                } else {
                    selectedDateRange = props.value as Range
                }

            } else {
                selectedDateRange = [null, null]
            }
        }
    }

    useEffect(() => {

        if ((props.columnDefinition?.dataType == ES_SEARCH_TYPES.KEYWORD) && (props.tableType != null)){
            setDropDownLoading(true)
            setDropDownValues([])
            const attributeValues = searchAttributeValuesByKey(props.attributeName?.value || "", props.tableType)
            attributeValues.then((resp) => {
                setDropDownValues(resp)
            }).catch((error) => {
                setDropDownValues([])
            }).then(() => setDropDownLoading(false))
        }

    }, [props.attributeName?.value])

    const [startDateRangeStart, startDateRangeEnd] = useMemo(() =>
        ([(selectedDateRange as Array<number>)[0], (selectedDateRange as Array<number>)[1]])
    , [selectedDateRange]) ;

    switch (props.condition) {
        case FilterOperation.BETWEEN:
            inputComponent = <>
                <DateRangePicker
                    id={"executions-view-time-select-date-range-picker"}
                    onChange={({ detail }) => {
                        if (detail.value !== null) {
                            const range = convertToAbsoluteRange(detail.value);
                            props.handleDateRangeChange([Moment(range.start, Moment.ISO_8601).valueOf()
                                , Moment(range.end, Moment.ISO_8601).valueOf()])
                        }
                    }}
                    value={{
                        startDate: Moment(startDateRangeStart).toISOString(),
                        endDate: Moment(startDateRangeEnd).toISOString(),
                        type: 'absolute'
                    }}
                    placeholder="Filter by a date and time range"
                    rangeSelectorMode = 'absolute-only'
                    relativeOptions={[]}
                    isValidRange={isValidRangeFunction}
                    i18nStrings={dateRangeFilterI18nStrings}
                />
            </>
            break;
        case FilterOperation.EXISTS:
        case FilterOperation.NOT_EXISTS:
            inputComponent = <>-</>
            break;

        default:
            if (dropDownValues.length > 0) {
                inputComponent = <Multiselect
                    data-testid={"attr-value-multi-select"}
                    selectedOptions={selectedOptions}
                    statusType={dropDownLoading ? "loading" : "finished"}
                    onChange={(e) =>
                        props.handleMultiSelectChange(e)
                    }
                    deselectAriaLabel={e => "Remove " + e.label}
                    options={dropDownValues.map(dropDownValue => {
                        return {
                            label: dropDownValue,
                            value: dropDownValue
                        }
                    })}
                    filteringType="auto"
                    placeholder="Choose options"
                    recoveryText={""}
                    selectedAriaLabel="Selected"
                    tokenLimit={2}
                    i18nStrings={{
                        tokenLimitShowMore: "Show more chosen options",
                        tokenLimitShowFewer: "Show fewer chosen options"
                    }}
                />
            } else {
                inputComponent = <Input
                    className={"attr-value-input"}
                    data-testid={"attr-value-input"}
                    aria-label={"attr-value-input"}
                    disabled={props.attributeName == null}
                    value={textFieldInput}
                    placeholder="Comma separated values"
                    onChange={(e) => {
                        props.handleTextFieldInputChange(e)
                    }}
                />
            }
            if (dropDownLoading) {
                inputComponent = <Spinner size="normal"/>
            }
            break;
    }
    return <>
        {inputComponent}
    </>
}
