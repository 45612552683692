import {isProd} from "src/utils/env";

const buildSporcUiQueryParams = (orchestratorEngineId: string) => {
    const [pipelineId, executionId] = orchestratorEngineId.split(":");
    if(executionId && pipelineId){
        return `execution_id=${executionId}&pipeline_id=${pipelineId}`;
    }
    return '';
}

export const buildSporcUiLink = (orchestratorEngineId: string) => {
    const urlQueryParams = buildSporcUiQueryParams(orchestratorEngineId);
    if(isProd()){
        return `https://smt-sporc-v2.corp.amazon.com/execution?${urlQueryParams}`;
    }
    return `https://smt-sporc-v2.integ.amazon.com/execution?${urlQueryParams}`;
}
