import React from "react"
import {BarChart, Box, Container, Header, Spinner} from "@amzn/awsui-components-react-v3";
import Moment from "moment"
import {SMVirtualPipelineLambda} from "@amzn/sm-virtual-pipeline-lambda-js-client";
import {noDataMessage} from "src/components/virtual-pipeline/virtual-pipeline-instance/dashboard-utils";

/**
 * Display bar chart to visualize virtual pipeline instances latency.
 * @param props Getting virtual pipeline instances from parent.
 * @constructor
 */
export default function EndToEndLatency(props: {
    instances: SMVirtualPipelineLambda.ListOfVirtualPipelineInstances,
    isLoading: boolean
}) {
    const instances = props.instances;
    const maximumLatency = Math.max.apply(Math, instances.map((inst) => { return inst["endToEndLatency"]! })) / (60 * 60 * 1000)

    if (props.isLoading) {
        return (
            <Container
                id={"virtual-pipeline-instance-dashboard-e2e-latency-container"}
                header={
                    <Header variant="h2" description="Viewing data from VP service">
                        End to End Latency
                    </Header>
                }
            >
                <>
                    <div className="awsui"><Spinner size={"large"}/>Loading...</div>
                </>
            </Container>
        )
    }

    return (
        <Container
            id={"virtual-pipeline-instance-dashboard-e2e-latency-container"}
            header={
                <Header variant="h2" description="Viewing data from VP service">
                    End to End Latency
                </Header>
            }
        >
            <BarChart
                height={220}
                yDomain={[0, maximumLatency + 20]}
                xDomain={instances.map(inst => Moment(inst["creationDate"]).format('YYYY-MM-DDTHH'))}
                series={instances.length === 0 ? [] : [
                    {
                        title: "Latencies",
                        type: "bar",
                        data: instances.map(inst => {
                            return {
                                x: Moment(inst["creationDate"]).format('YYYY-MM-DDTHH'),
                                y: inst["endToEndLatency"]! / (60 * 60 * 1000)
                            }
                        }),
                        valueFormatter: t =>
                            t.toLocaleString("hours", { maximumFractionDigits: 2 })
                    }]}
                i18nStrings={{
                    filterLabel: "Filter displayed data",
                    filterPlaceholder: "Filter data"
                }}
                hideFilter
                xTitle="Date"
                yTitle="End to End Latency (hours)"
                ariaLabel="Single data series line chart"
                errorText="Error loading data."
                loadingText="Loading chart"
                recoveryText="Retry"
                statusType="finished"
                xScaleType="categorical"
                empty={noDataMessage}
            />
        </Container>
    )
}