import React, {useState} from "react";
import Icon from "@amzn/awsui-components-react-v3/polaris/icon";
import Box from "@amzn/awsui-components-react-v3/polaris/box";
import {BubbleEditInterface} from "src/interfaces/server-side-table";
import {EditExecutionModel} from "src/components/domain-tracker/edit-execution-detail-model";

/**
 * Each option for each editable field when user hovers the cell.
 * Providing option to enter new values in a Modal.
 * @param props
 * @constructor
 */
export const BubbleEdit = (props: BubbleEditInterface) => {
    const [modalVisible, setModalVisible] = useState<boolean>(false);

    return (
        <>
            <div className={"bubble-inline-edit"}>
                <span>{props.children}</span>
                <span id={"bubble-edit-icon"}
                      className="edit-icon awsui-util-ml-s" onClick={() => {
                    setModalVisible(true)
                }}>
            <Box
                variant={"span"}
                margin={{bottom: "xxxs"}}
                color="text-status-info">
              <Icon variant="link" name="edit"/>
              Edit
            </Box>
          </span></div>

            {modalVisible && <EditExecutionModel selectedItems={[props.rowItem]} tableMetadata={props.tableMetadata}
                                                 bubbleEdit={true}
                                                 modalVisible={modalVisible}
                                                 visibleColumnKey={props.columnKey}
                                                 handleModalVisibleChange={(visible: false) => setModalVisible(visible)}/>
            }
        </>
    )
}
