import React, {useState} from "react";
import {ElasticSearchHelper} from "src/utils/elastic-search-helper";
import {Box, Button, Grid, SpaceBetween, Textarea} from "@amzn/awsui-components-react-v3";
import {EsQueryTableFilterI} from "src/interfaces/attribute-filters";

/**
 * Only for testing and debugging purpose. Given es Query, it's display Array of Primary and Secondary filter values
 * @constructor
 */
export function EsQueryConverter() {
    const [esQuery, setEsQuery] = useState<string>(JSON.stringify({}))
    const [tokens, setTokens] = useState<EsQueryTableFilterI>()

    return <>
        <Box variant={"h1"} textAlign={"center"}>For Testing/Debugging purpose only</Box>
        <Box variant={"div"} textAlign={"center"}>
        <Grid
            gridDefinition={[
                {colspan: {default: 12, xxs: 5}},
            ]}
        >
            <SpaceBetween size={"l"} direction={"vertical"}>
                <div><Textarea value={esQuery} rows={10} onChange={(e) => setEsQuery(e.detail.value)}/></div>
                <div>
                    <pre>{JSON.stringify(tokens, null, 2)}</pre>
                </div>
                <div><Button
                    variant={"primary"}
                    onClick={() => {
                        setTokens(ElasticSearchHelper.convertEsQueryToTableFilters(esQuery))
                    }

                    }> Convert </Button></div>

            </SpaceBetween>
        </Grid>
        </Box>

    </>
}