import {TableTypes} from "src/interfaces/domain-tracker";
import {TablePropertyFiltering, TableSorting} from "@amzn/awsui-components-react";
import {useEffect, useState} from "react";
import {SMSWOUnifiedDashboardServiceLambda} from "@amzn/swo-unified-dashboard-service-lambda-js-client";
import {DomainTracker} from "src/model/DomainTracker";
import {TableMetadataInterface} from "src/interfaces/server-side-table";
import {getListCardName} from "src/utils/table-helper";
import {EsQueryTableFilterI} from "src/interfaces/attribute-filters";

/**
 * React hook to fetch list card data.
 * @param esQueryTableFilter - primary and secondary table filter
 * @param tableMetadata - Table metadata
 * @param tableType - To find List card name
 */
export const useApiRequestV3 = (
    esQueryTableFilter: EsQueryTableFilterI,
    tableMetadata: TableMetadataInterface,
    tableType: TableTypes
) => {
    const filterTokens = esQueryTableFilter
    const [items, setItems] = useState<Array<SMSWOUnifiedDashboardServiceLambda.Details>>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [totalCount, setTotalCount] = useState(0);
    const listCardName: SMSWOUnifiedDashboardServiceLambda.Types.ListCard = getListCardName(tableType)

    const fetchData = () => {
        setLoading(true);
        new DomainTracker()
            .searchV3(filterTokens, tableMetadata, listCardName)
            .then((resp) => {
                const records = resp.records?.map(rec => rec.details)
                setItems(records || []);
                setTotalCount(resp.totalRecords || 0);
                setError("")
            })
            .catch((error) => {
                setError(JSON.stringify(error));
                setItems([])
                setTotalCount(0)
            }).then(() => {
            setLoading(false);
        });
    };


    useEffect(() => {
        if ((filterTokens.attributeFilterItems.length > 0) && (JSON.stringify(tableMetadata) != "{}")) {
            if (filterTokens.attributeFilterItems.some(attrFilter => attrFilter.columnDefinition != null)) {
                fetchData();
            }
        }
    }, [esQueryTableFilter, tableMetadata]);

    return {error, loading, items, totalCount};
};
