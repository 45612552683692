import AppLayout from '@amzn/awsui-components-react-v3/polaris/app-layout';
import React, { useState } from 'react';
import { appLayoutNavigationLabels } from '../../common/labels';
import { ServiceNavigation } from '../navigation';
import WIMOutputContent from './WIMOutputContent';

export default function WIMOutput() {
  const [navigationOpen, setNavigationOpen] = useState(false);
  return (
    <AppLayout
      navigation={<ServiceNavigation />}
      content={<WIMOutputContent />}
      contentType="form"
      disableContentPaddings={false}
      navigationOpen={navigationOpen}
      onNavigationChange={(event: any) => setNavigationOpen(event.detail.open)}
      toolsHide={true}
      ariaLabels={appLayoutNavigationLabels}
    />
  );
}