import React, {createRef, useCallback, useEffect, useState} from 'react';
import {
    SelectedItemRequiredKeys,
    SelectedItemRequiredKeysInterface
} from "src/interfaces/domain-tracker";
import Button from "@amzn/awsui-components-react/polaris/button";
import {convertArrayToTableSelect} from "src/utils/table-helper";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import Select from "@amzn/awsui-components-react/polaris/select";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import {UpdateDelayAlert} from "src/components/common/user-alerts"
import {
    ATTEMPT_ID,
    EXECUTION_ID,
    EXECUTION_STAGES_LIST,
    SWO_ORCHESTRATOR_SERVICE_CLIENT_ID
} from "src/constants/domain-tracker";
import {SMSWOUnifiedDashboardServiceLambda} from "@amzn/swo-unified-dashboard-service-lambda-js-client";
import {DomainTracker} from "src/model/DomainTracker";
import {v1 as uuidv1} from 'uuid';
import {getUserAlias} from "src/utils/cookie-helper";
import {TableProgressBar, useProgressBar} from "src/components/common/table-progress-bar";
import {RestartRecipeExecutionResult} from "@amzn/sm-workflow-orchestration-service-js-client/lib/smworkfloworchestrationservicelambda";
import {ExecutionRestartInputOverride} from "src/components/domain-tracker/execution-restart-input-override";
import {SwoUiMetrics} from "src/utils/swo-ui-metrics/swo-ui-metrics";
import {metricFeaturesList, metricPageNames} from "src/constants/swo-ui-metric-constants";
import {logger} from "src/utils/logger";

/**
 * Restarts execution with option to select stage
 * @param props
 * @constructor
 */
export function ExecutionRestartModal(props: {selectedItems: Array<SelectedItemRequiredKeysInterface>}) {
    const [selectedItems, setSelectedItems] = useState<Array<{[k in SelectedItemRequiredKeys]: string}>>(props.selectedItems);
    const [showRestartModal, setShowRestartModal] = useState<boolean>(false);
    const nullValueOption: Select.Option = {id: "PLEASE_SELECT", label: "Please select"}
    const completeRestartOption: Select.Option = {id: "COMPLETE_RESTART", label: "<Restart from the beginning>"}
    const [bulkUpdatePromises, setBulkUpdatePromises] = useState<Array<Promise<any>>>([])
    const [progressBarVisible, setProgressBarVisible] = useState<boolean>(false)
    const submitFormRef: any = createRef();
    const toastSuccessMessage: React.ReactNode = <>Restarted successfully. <UpdateDelayAlert feature={"Workflow status"}
                                                                                             plainText={true}/></>;
    const {
        progressPercentage,
        progressLoading,
        progressStatus,
        isCompleted,
        isCompletedSuccessfully,
        errorMessages
    } = useProgressBar(bulkUpdatePromises, toastSuccessMessage);

    const defaultRestartOptions = [...convertArrayToTableSelect([], true, nullValueOption),
        completeRestartOption]

    const [restartDropdownOptions, setRestartDropdownOptions] = useState<Array<Select.IOption | Select.Option | Select.OptionsGroup>>(defaultRestartOptions)

    const [
        selectedOption,
        setSelectedOption
    ] = useState<Select.Option>(restartDropdownOptions[0] as Select.Option);

    useEffect(() => {
        setSelectedItems(props.selectedItems)
        setProgressBarVisible(false)
        setSelectedOption(nullValueOption)
    }, [props.selectedItems, showRestartModal])

    useEffect(() => {
        if (isCompletedSuccessfully) {
            setShowRestartModal(false)
        }
    }, [isCompletedSuccessfully])


    function handleRestartSubmitButton(inputOverrideData: JSON, enableConfigOverride: boolean): void {
        logger.info(`Restarting ${selectedItems.length} workflow instances`, props.selectedItems.map(selectedItem => selectedItem.workflowId))
        SwoUiMetrics.publishCloudWatchCountMetric(props.selectedItems.length > 1 ? metricFeaturesList.bulkRestartWorkflowInstance : metricFeaturesList.restartWorkflowInstance, metricPageNames.workflowInstanceListView)
        const domainTracker = new DomainTracker();
        setProgressBarVisible(true);
        const startPluginName: null | string = selectedOption.id === completeRestartOption.id ? null : selectedOption.id;
        const hasInputOverrideData = Object.keys(inputOverrideData).length > 0 ;
        const restartExecutionInput = enableConfigOverride && hasInputOverrideData ? JSON.stringify(inputOverrideData) : undefined;
        const restartRequestPromises: Array<Promise<RestartRecipeExecutionResult>> = selectedItems.map(selectedItem => {
            const lastAttemptId: string = selectedItem.recipeExecutionAttemptId || selectedItem.workflowId
            return domainTracker.restartExecution(SWO_ORCHESTRATOR_SERVICE_CLIENT_ID, selectedItem.workflowId, lastAttemptId,
                uuidv1(), getUserAlias(), startPluginName, restartExecutionInput)
        })
        setBulkUpdatePromises(restartRequestPromises);
    }

    const updateRestartOptions = useCallback((restartStages: Array<Select.IOption | Select.Option | Select.OptionsGroup>) => {
        setRestartDropdownOptions([...defaultRestartOptions, ...restartStages]);
    }, [restartDropdownOptions])

    const closeRestartDialog = useCallback(() => {
        setShowRestartModal(false)
    }, [setShowRestartModal])

    return <>
        <Modal
            className={"col-xxs-12 awsui-util-mb-l"}
            id={"restart-execution-modal"}
            onDismiss={() => setShowRestartModal(false)}
            visible={showRestartModal}
            expandToFit={true}
            size="medium"
            header={`Restart Execution`}
        >
            <FormField label="Select stage"
                       description={`Please select stage form which you want to restart the execution. 
                       Select ${completeRestartOption.label} to restart execution without any stage preference`}>
                <Select
                    id={"restart-execution-select-dropdown"}
                    selectedOption={selectedOption}
                    onChange={({detail}) => {
                        setProgressBarVisible(false)
                        setSelectedOption(detail.selectedOption)
                    }
                    }
                    options={restartDropdownOptions}
                />
            </FormField>
            <br/>

            <ExecutionRestartInputOverride selectedItems={props.selectedItems}
                                           onRestartSubmitButton={handleRestartSubmitButton}
                                           modalVisible={showRestartModal}
                                           submitFormRef={submitFormRef}
                                           setRestartStageOptions={updateRestartOptions}
                                           closeRestartDialog={closeRestartDialog}
                                           workflowDetailsLoading={progressLoading}
                                           disableWorkflowRestart={nullValueOption.id === selectedOption.id}
            > </ExecutionRestartInputOverride>
            {((progressLoading || isCompleted) && progressBarVisible) &&
            <div>
                <TableProgressBar progressStatus={progressStatus}
                                  progressPercentage={progressPercentage}
                                  totalRecordLength={selectedItems.length}
                                  title={`Restarting ${selectedItems.length} execution(s)`}
                                  description={`Action: ${selectedOption.label}`}
                                  errorMessages={errorMessages}
                />
            </div>
            }
        </Modal>
        <Button text={"Restart"}
                id={"restart-execution-button"}
                onClick={() => setShowRestartModal(true)}
                disabled={props.selectedItems.length == 0}
        />

    </>
}
