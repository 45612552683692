import {AggregateCard} from "@amzn/swo-unified-dashboard-service-lambda-js-client/lib/smswounifieddashboardservicelambda";

export const getAggregateCardName = (stage: string): string => {
    let aggregateCardName: AggregateCard = "";
    switch (stage) {
        case "CRAWL":
            aggregateCardName = "WORKFLOWS_CRAWL_SUB_STAGE"
            break;
        case "SCRAPERPLANNER":
            aggregateCardName = "WORKFLOWS_SCRAPERPLANNER_SUB_STAGE"
            break;
        case "SPORC":
            aggregateCardName = "WORKFLOWS_SPORC_SUB_STAGE"
            break;
        default:
            aggregateCardName = "WORKFLOWS_STAGE"
            break;
    }
    return aggregateCardName;
}

export const getRandomChartColor = (): string => {
    return '#'+(Math.random()*0xFFFFFF<<0).toString(16);
}
export const CHART_COLORS: Array<string> = ["#7492e7",
    "#c33d69",
    "#2ea597",
    "#8456ce",
    "#e07941",
    "#3759ce",
    "#962249",
    "#096f64",
    "#6237a7",
    "#a84401",
    "#273ea5",
    "#780d35",
    "#03524a",
    "#4a238b",
    "#7e3103",
    "#1b2b88",
    "#ce567c",
    "#003e38",
    "#9469d6",
    "#602400",
    "#4066df",
    "#a32952",
    "#0d7d70",
    "#6b40b2"]