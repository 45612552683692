import {ElasticSearchHelper} from "src/utils/elastic-search-helper";
import {EsQueryTableFilterI} from "src/interfaces/attribute-filters";

/**
 * Helper class to set/update/get url params
 */
export class UrlHelper {
    static ES_QUERY_PARAMS_KEY = "esQuery"
    static getTableFiltersFromUrlParams(paramsKey: string) : EsQueryTableFilterI | null {
        const url = new URL(window.location.href)
        const params = new URLSearchParams(url.search);
        const esQuery = params.get(paramsKey)
        if (esQuery != null) {
            try {
                JSON.parse(esQuery)
                return ElasticSearchHelper.convertEsQueryToTableFilters(esQuery)
            } catch (e) {
                console.error("Fails to parse esquery from URL", e)
                throw new SyntaxError("something");
            }
        }
        return null
    }

    static setUrlParamsFromTableFilters(esQueryTableFilter: EsQueryTableFilterI): void {
        const url = new URL(window.location.href)
        const params = new URLSearchParams(url.search);
        params.set(this.ES_QUERY_PARAMS_KEY, JSON.stringify(ElasticSearchHelper.convertAttributeFilterTokensToESQueryV3(esQueryTableFilter)))
        history.replaceState(null, "", `${window.location.pathname}?${params.toString()}`);
    }

    static getParamValue(paramsKey: string): string | null {
        const url = new URL(window.location.href)
        const params = new URLSearchParams(url.search);
        return params.get(paramsKey)
    }
}