import React, {useMemo, useState} from "react"
import {
    Alert,
    Box,
    Button, Checkbox,
    ColumnLayout,
    Container,
    Form,
    FormField,
    Header,
    Link
} from "@amzn/awsui-components-react-v3";
import * as Papa from "papaparse";
import {
    BULK_START_CSV_MAX_ROWS, BULK_START_ENABLED_PROGRAMS, BULK_START_PLUGIN_FIELD_NAME_DELIMITER
    , PROGRAM_RECIPE_MAP, SELECT_DROPDOWN_VALUE_DELIMITER
} from "src/constants/workflow-instance";
import {Map} from "@amzn/sm-workflow-orchestration-service-js-client/lib/smworkfloworchestrationservicelambda";
import {toast} from "react-toastify";
import {SelectProps} from "@amzn/awsui-components-react-v3/polaris/select/interfaces";
import Select from "@amzn/awsui-components-react-v3/polaris/select";
import {WorkflowInstanceUtil} from "src/utils/workflow-instance-util";

export function BulkStartWorkflowInstance() {
    const [selectedFile, setSelectedFile] = useState<null | Blob>();
    const [failedFileContent, setFailedFileContent] = useState<Array<Map>>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [totalNumberOfRows, setTotalNumberOfRows] = useState<number>(0)
    //Todo: abstarct  isContinuous state management to context API to avoid duplicate code in start & bulk start screen
    const [isContinuous, setIsContinuous] = useState(true)
    const [isContinuousCheckEnabled, setIsContinuousCheckEnabled] = useState(false)
    const pleaseSelectLabel: SelectProps.Option = {label: "Please Select", value: ""}
    BULK_START_ENABLED_PROGRAMS.map(allowedProgram => {
        return {
            label: allowedProgram,
            value: `${PROGRAM_RECIPE_MAP[allowedProgram]}${SELECT_DROPDOWN_VALUE_DELIMITER}${allowedProgram}`,

        }
    })
    const programOptionsDefinitions: Array<SelectProps.Option> = [pleaseSelectLabel,
        ...BULK_START_ENABLED_PROGRAMS.map(allowedProgram => {
            return {
                label: allowedProgram,
                value: `${PROGRAM_RECIPE_MAP[allowedProgram]}-${allowedProgram}`
            }
        })
    ]
    const [programOptions, setProgramOptions] = useState<Array<SelectProps.Option>>(programOptionsDefinitions)
    const [
        programSelectedOption,
        setProgramSelectedOption
    ] = React.useState<SelectProps.Option>(pleaseSelectLabel);

    function handleOnInput(e: any) {
        setSelectedFile(e.target.files[0])
    }

    /*
      If `true`, numeric and boolean data will be converted to their type instead of remaining strings.
      If user wants to set some columns to string, then can do it by appending ".string" in column header.
      For eg, if schema expects websiteId to be string, user can set column header like "crawler.websiteId.string".
     */

    function handleDynamicTyping(e: string | number): boolean {
        if (typeof e === "string") {
            const columnHeader = e.split(BULK_START_PLUGIN_FIELD_NAME_DELIMITER)
            return !((columnHeader.length > 2) && (columnHeader[2].toLowerCase() === "string"))
        }
        return true
    }

    function handleBulkStartButtonClick(e: any) {
        setFailedFileContent([])
        if (programSelectedOption.value === "") {
            toast.error("Please select a program")
            return;
        }

        if (selectedFile == null) {
            toast.error("Please select a file.")
            return;
        }

        Papa.parse(selectedFile, {
            download: true,
            header: true,
            dynamicTyping: handleDynamicTyping,
            skipEmptyLines: true,
            complete: (parsingResults) => {
                if (parsingResults.data.length === 0) {
                    toast.error("CSV File should have at least one row")
                    return
                }

                if (parsingResults.data.length >= BULK_START_CSV_MAX_ROWS) {
                    toast.error(`Max ${BULK_START_CSV_MAX_ROWS} rows allowed`)
                    return
                }
                setLoading(true)
                WorkflowInstanceUtil.handleParseComplete(
                    parsingResults,
                    programSelectedOption,
                    setTotalNumberOfRows,
                    setFailedFileContent,
                    setLoading,
                    isContinuous)
            },
            error: (error: Error) => {
                console.error("Parsing Error", JSON.stringify(error))
            }

        })
    }

    const bulkUploadSampleFileLinks = useMemo(() => {
        return (<>
            Max {BULK_START_CSV_MAX_ROWS} records.
            <br/>
            <Link href={""} onFollow={() => WorkflowInstanceUtil.handleDownloadSampleLinkFollow()}>
                Download sample file for non-continuous monitoring
            </Link>
            <br/>
            <Link href={""} onFollow={() => WorkflowInstanceUtil.handleDownloadSampleLinkFollow(true)}>
                Download sample file for continuous monitoring
            </Link>
        </>)
    }, [])

    return <Box padding={{top: "l"}}>
        <Container
            header={
                <Header
                    variant="h2"
                >
                    Bulk Start Workflow Instance
                </Header>
            }
        >
            <Alert
                id={"bulk-start-error-alert"}
                type={"error"} visible={failedFileContent.length > 0}> {failedFileContent.length} out
                of {totalNumberOfRows} records failed <Button
                    iconName={"download"}
                    onClick={() => WorkflowInstanceUtil.downloadBulkStartErrorFile(failedFileContent)}> Download Error
                    file </Button></Alert>

            <Form
                id={"bulk-start-form"}
                actions={<Button
                    id={"bulk-start-submit-button"}
                    variant={"primary"}
                    loading={loading}
                    onClick={(e) => handleBulkStartButtonClick(e)}>Bulk Start Workflow Instance</Button>}>
                <ColumnLayout columns={2} borders={"vertical"}>
                    <Box padding={{top: "xs", bottom: "l"}}>
                        <FormField
                            id={"bulk-start-program-selection-field"}
                            label="Select Program"
                        >
                            <Select
                                id={"bulk-start-program-selection-dropdown"}
                                selectedOption={programSelectedOption}
                                disabled={loading}
                                onChange={({detail}) => {
                                    setProgramSelectedOption(detail.selectedOption)
                                    setIsContinuous(true);
                                    setIsContinuousCheckEnabled(WorkflowInstanceUtil.isContinuousProgramVariantCheckEnabled(detail.selectedOption))
                                }
                                }
                                options={programOptions}
                                selectedAriaLabel="Selected"
                            />
                        </FormField>
                    </Box>
                    {WorkflowInstanceUtil.isContinuousProgramVariantSupported(programSelectedOption.label) && <FormField
                        label={<>&nbsp;</>}>
                        <Checkbox
                            onChange={({detail}) => {
                                setIsContinuous(detail.checked);
                            } }
                            checked={isContinuous}
                            disabled={!isContinuousCheckEnabled}
                            data-testid={"continuous-wi-checkbox"}
                        >
                            Is Continuous Workflow
                        </Checkbox>
                    </FormField>}
                </ColumnLayout>
                <Box padding={{top: "xs", bottom: "l"}}>
                    <FormField label={"Select CSV file"}
                               id={"form-field-file-input"}
                               description={bulkUploadSampleFileLinks}>
                        <input data-testid={"bulk-start-file-input"} id={"bulk-start-file-input"} type={"file"}
                               onInput={handleOnInput}
                               onChange={handleOnInput}
                               accept=".csv" disabled={loading}/>
                    </FormField>
                </Box>
            </Form>

        </Container>

    </Box>
}