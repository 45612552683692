import {TableColumnDefinition, TableMetadataInterface} from "src/interfaces/server-side-table";
import {TableTypes} from "src/interfaces/domain-tracker";
import {TableProps} from "@amzn/awsui-components-react-v3/polaris/table/interfaces";
import {KeyValuePairString} from "src/interfaces/common";
import {SelectProps} from "@amzn/awsui-components-react-v3";
import {Range} from "@amzn/polaris-date-time-range";

export enum FilterOperation {
    EQUALS = "EQUALS",
    NOT_EQUALS = "NOT_EQUALS",
    EXISTS = "EXISTS",
    NOT_EXISTS = "NOT_EXISTS",
    BETWEEN = "BETWEEN"
}

export interface EsQueryTableFilterI {
    attributeFilterItems: Array<AttributeFilterItemsI>,
    size: number,
    from: number,
    sortingOptions?: TableProps.SortingState<KeyValuePairString>
}

export interface AttributeFilterI {
    tableMetadata: TableMetadataInterface,
    loading: boolean,
    attributeFilterItems: Array<AttributeFilterItemsI>,
    onFilteringTokenUpdate: Function,
    tableType: TableTypes
}

export interface SelectDropdownOptions {
    [key: string]: Array<string>;
}

export interface AttributeFilterItemsI {
    attributeName: SelectProps.Option | null
    condition: FilterOperation
    value: Range | string | Array<string>
    columnDefinition: TableColumnDefinition | null
    tableType: TableTypes | null
}

export interface AttributeFilterValueI extends AttributeFilterItemsI {
    handleTextFieldInputChange: Function
    handleMultiSelectChange: Function
    handleDateRangeChange: Function
}

export interface FiltersI {
    tableMetadata: TableMetadataInterface
    attributeFilterItems: Array<AttributeFilterItemsI>
    loading: boolean
    tableType: TableTypes
    onSubmitAttributeFilterItems: Function
    hidePrimaryFilter: boolean
    hideSecondaryFilter: boolean
    title?: string
}