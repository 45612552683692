import Select from "@amzn/awsui-components-react/polaris/select";
import {ISubmitEvent} from "@rjsf/core";
import {JSONSchema7} from "json-schema";
import {SelectDropdownOptions} from "src/interfaces/attribute-filters";
import {PROGRAM_NAMES_ENUM,TARGETED_WEBSITES} from "src/constants/workflow-instance";

export const TABLE_PAGE_SIZE = "TABLE_PAGE_SIZE"
export const TABLE_METADATA = "TABLE_METADATA"
export const TABLE_FILTERING_TOKENS = "TABLE_FILTERING_TOKENS"


export const PROGRAMS: Array<string> = Object.values(PROGRAM_NAMES_ENUM)

//TODO: Need to be updated.
export const WORKFLOW_STATUS_OPTIONS: Array<string> = []
//TODO: Need to be updated.
export const FAILED_WORKFLOW_TYPE_OPTIONS: Array<string> = ["-", "Recoverable", "Unrecoverable - Infeasible website", "Unrecoverable - Duplicate WF", "Unrecoverable - Invalid website", "Unrecoverable - Dropped for other reasons"]
export const CRAWL_ROUTE_TYPE_OPTIONS: Array<string> = ["EC2", "DSL"]

export const SELECT_DROPDOWN_ALL_PLACEHOLDER = "[ALL]"

export const EXECUTION_STAGES_LIST: Array<Select.IOption | Select.Option | Select.OptionsGroup> = [
    {id: "domainOnboarder", label: "Domain Onboarder"},
    {id: "crawlSampling", label: "Crawl Sampling"},
    {id: "crawl", label: "Crawl"},
    {id: "scraperPlanner", label: "Scrape Planner"},
    {id: "sporc", label: "Sporc"}
]

export const EXECUTION_ID: string = 'workflowId'
export const WORKFLOW_START_DATE: string = 'workflowStartDate'
export const ATTEMPT_ID: string = 'recipeExecutionAttemptId'
export const USER_COMMENTS: string = 'userComments'
export const FAILED_WORKFLOW_TYPE: string = 'failedWorkflowType'
export const CRAWL_ROUTE_TYPE: string = 'crawlRouteType'
export const TARGETED_WEBSITE: string = 'targetedWebsite'
export const WORKFLOW_STATUS: string = 'workFlowStatus'
export const PROGRAM: string = 'program'
export const ASSIGNEE: string = 'assignee'


export const EXECUTION_LIST_DROPDOWN_OPTIONS: SelectDropdownOptions = {
    [FAILED_WORKFLOW_TYPE]: FAILED_WORKFLOW_TYPE_OPTIONS,
    [CRAWL_ROUTE_TYPE]: CRAWL_ROUTE_TYPE_OPTIONS,
    [TARGETED_WEBSITE]: TARGETED_WEBSITES,
    [PROGRAM]: PROGRAMS,
    [WORKFLOW_STATUS]: WORKFLOW_STATUS_OPTIONS
}

export const ExecutionQuickOverview = {
    header: "Quick overview"
}
export const ExecutionsListTable = {
    header: "Execution List"
}
export const ExecutionsSummary = {
    header: "Execution Summary"
}

export const SWO_ORCHESTRATOR_SERVICE_CLIENT_ID: string = "SM"
