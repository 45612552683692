import AppLayout from '@amzn/awsui-components-react-v3/polaris/app-layout';
import React, {useEffect, useState} from 'react';
import {appLayoutNavigationLabels} from 'src/common/labels';
import {ServiceNavigation} from 'src/components/navigation';
import 'src/styles/index.scss';
import {AppRoutes, BreadcrumbGroupWithRouter} from "src/components/common/router-common";
import {BreadcrumbGroupProps} from "@amzn/awsui-components-react-v3/polaris/breadcrumb-group/interfaces";
import {SwoUiMetrics} from "src/utils/swo-ui-metrics/swo-ui-metrics";
import {metricFeaturesList, metricPageNames} from "src/constants/swo-ui-metric-constants";
import {StartWorkflowInstanceContent} from "src/components/start-workflow-instance/start-workflow-Instance-content";
import {BulkStartWorkflowInstance} from "src/components/start-workflow-instance/bulk-start-workflow-instance";
import {Box, Container, Header, Tiles} from "@amzn/awsui-components-react-v3";
import {TilesProps} from "@amzn/awsui-components-react-v3/polaris/tiles/interfaces";
import {SaveWorkflowConfig} from "src/components/start-workflow-instance/save-workflow-config";

export default function StartWorkflowInstance() {

    const adhocStart: TilesProps.TilesDefinition = {
        value: 'adhoc',
        label: 'Start',
        description: 'Use this option for starting/scheduling single workflow instance.'
    }
    const bulkStart: TilesProps.TilesDefinition = {
        value: 'bulk',
        label: 'Bulk Start',
        description:
            'Use this option to start multiple workflow instance using csv file.'
    }

    const configSave: TilesProps.TilesDefinition = {
        value: 'config-save',
        label: 'Save Workflow configurations',
        description: 'Use this option if you wish to configure defaults for a program/subprogram combination.'
    }

    const items: ReadonlyArray<TilesProps.TilesDefinition> = [adhocStart, bulkStart, configSave]

    const [selectedOption, setSelectedOption] = useState<string>(adhocStart.value);
    useEffect(() => {
        SwoUiMetrics.publishCloudWatchCountMetric(metricFeaturesList.index, metricPageNames.startWorkflowInstance)
    }, [])

    const bcItems: Array<BreadcrumbGroupProps.Item> = [
        {
            "text": "Home",
            "href": AppRoutes.home
        },
        {
            "text": "Workflow Instances",
            "href": AppRoutes.executionList
        },
        {
            "text": "Start Workflow Instances",
            "href": "#"
        }
    ]

    return (
        <AppLayout
            navigation={<ServiceNavigation/>}
            content={<>
                <Container header={<Header variant={"h2"}>Please select</Header>}>
                    <Tiles
                        items={items}
                        value={selectedOption}
                        onChange={e => setSelectedOption(e.detail.value)}
                    />
                </Container>
                {selectedOption === bulkStart.value && <Box><BulkStartWorkflowInstance/></Box>}
                {selectedOption === adhocStart.value && <Box><StartWorkflowInstanceContent/></Box>}
                {selectedOption === configSave.value && <Box><SaveWorkflowConfig/></Box>}
            </>}
            breadcrumbs={<BreadcrumbGroupWithRouter items={bcItems}/>}
            contentType="form"
            disableContentPaddings={false}
            toolsHide={true}
            ariaLabels={appLayoutNavigationLabels}
            maxContentWidth={Number.MAX_VALUE} // the main content panel will occupy the full available width.
        />
    );
}
