import React, {useEffect} from "react";
import {Flashbar, FlashbarProps} from "@amzn/awsui-components-react-v3";
import {metricFeaturesList} from "src/constants/swo-ui-metric-constants";


const COLUMN_HEADER_TOOLTIP_ACTION = 'To understand what each column value' +
    ' represents click on column headers, it will open a pop up ' +
    'showing metric description.';
const NAVIGATION_ACTION = 'The columns having navigation icons support' +
    ' drill down views, click on column cell values, and you will be' +
    ' redirected to view that gives a more details about metric';
const ROW_SELECTION_ACTION = 'Selection of rows opens up a split panel displaying more details about ' +
    'selected entity.';
export const ERROR_MESSAGE = 'Something went wrong please try refreshing the screen';

const viewUserActionMap = {
    [metricFeaturesList.page0]: [COLUMN_HEADER_TOOLTIP_ACTION, NAVIGATION_ACTION],
    [metricFeaturesList.programDetailsFailure] : [NAVIGATION_ACTION, ROW_SELECTION_ACTION],
    [metricFeaturesList.page2ListView]: [ROW_SELECTION_ACTION]
}


export const UserActionsInfoBar = ({view, hasError}: {view: string, hasError?: boolean}) => {
    const userActionList = viewUserActionMap[`${view}`] || [];
    const [items, setItems] = React.useState<FlashbarProps.MessageDefinition[]>([
        {
            type: 'info',

            dismissible: true,
            dismissLabel: "Dismiss message",
            onDismiss: () => {
                /* istanbul ignore next */
                setItems((items) => items.filter(item => item.id !== "message_1"))
            },
            content:
                <>
                    <h5>{'User Actions on this screen'}</h5>
                    <ul>
                        {userActionList.map(userActionText => <li>{userActionText}</li>)}
                    </ul>

                </>,
            id: "message_1"
        }
    ])

    useEffect(() => {
        const errorNotification: FlashbarProps.MessageDefinition = {
            type: 'error',
            dismissible: true,
            dismissLabel: "Dismiss message",
            /* istanbul ignore next */
            onDismiss: () => {
                /* istanbul ignore next */
                setItems(items => items.filter(item => item.id !== "message_2"))
            },
            content:
            <>
                <p>{ERROR_MESSAGE}</p>
            </>,
            id: "message_2"
        }

        hasError && setItems(items => [errorNotification, ...items])
    }, [hasError])

    return <Flashbar items={items} i18nStrings={{
        ariaLabel: "Notifications",
        notificationBarAriaLabel:
            "View all notifications",
        notificationBarText: "Notifications",
        errorIconAriaLabel: "Error",
        warningIconAriaLabel: "Warning",
        successIconAriaLabel: "Success",
        infoIconAriaLabel: "Info",
        inProgressIconAriaLabel: "In progress"}}
        stackItems={true}
    />;
}
