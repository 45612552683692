import * as React from "react";
import {useApiRequest} from "src/hooks/vp-table-hooks";
import {Alert} from "@amzn/awsui-components-react-v3/polaris";
import {VirtualPipelineListTable} from "src/components/virtual-pipeline/virtual-pipeline-list-table";

/**
 * A table displaying all metadata of virtual pipelines.
 * @constructor
 */
export function VirtualPipelineList() {
    const {
        error,
        items,
        isLoaded
    } = useApiRequest();

    return (
        <>
            <Alert type={"error"} visible={error != ""}> {error}</Alert>
            <VirtualPipelineListTable instances={items} isLoaded={isLoaded}/>
        </>
    );
}