export const metricNamespace: string = "SMSWOUnifiedDashboardWebsite"
export const metricPageNames = {
    workflowInstanceListView: "workflowInstanceListView",
    workflowDetailView: "workflowDetailView",
    startWorkflowInstance: "startWorkflowInstance",
    workflowAttemptsView: "workflowAttemptsView",
    workflowAggregateInstanceView: "workflowAggregateInstanceView",
    homePage: "homePage",
    programSummary: "programSummary",
    programDetails: "programDetails",
    workflowList: "workflowList"
}
export const metricFeaturesList = {
    terminateWorkflowInstance: "terminateWorkflowInstance",
    bulkTerminateWorkflowInstance: "bulkTerminateWorkflowInstance",
    restartWorkflowInstance: "restartWorkflowInstance",
    bulkRestartWorkflowInstance: "bulkRestartWorkflowInstance",
    republishWorkflowInstance: "republishWorkflowInstance",
    observeWorkflowInstance: "observeWorkflowInstance",
    bulkObserveWorkflowInstance: "bulkObserveWorkflowInstance",
    redirectURL: "redirectURL",
    outputWorkitem: "outputWorkitem",
    showSubStage: "showSubStage",
    showSubStageCard: "showSubStageCard",
    showEventsPopup: "showEventsPopup",
    index: "index",
    bulkStart: "bulkStart",
    adhocStart: "adhocStart",
    scheduleStart: "scheduleStart",
    page0: 'page0',
    productSummary: 'productSummary',
    programDetailsFailure: "programDetailsFailure",
    programDetailsRunning: "programDetailsRunning",
    page2ListView: "workflowList"
}

export const additionalAttributes = {
    isRestartOverrideInput: "isRestartOverrideInput"
}
