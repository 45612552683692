import AppLayout from '@amzn/awsui-components-react-v3/polaris/app-layout';
import React, {useEffect, useState} from 'react';
import {appLayoutNavigationLabels} from 'src/common/labels';
import {ServiceNavigation} from 'src/components/navigation';
import {ExecutionListTableV3} from 'src/components/domain-tracker/execution-list-table';
import 'src/styles/index.scss';
import {AppRoutes, BreadcrumbGroupWithRouter} from "src/components/common/router-common";
import {RouteComponentProps} from "react-router-dom";
import {SwoUiMetrics} from "src/utils/swo-ui-metrics/swo-ui-metrics";
import {metricFeaturesList, metricPageNames} from "src/constants/swo-ui-metric-constants";

/**
 * Domain tracker(Execution tracker) page
 * @constructor
 */
export default function DomainTracker(props: RouteComponentProps<{}>) {
    const [navigationOpen, setNavigationOpen] = useState(false);

    useEffect(() => {
        SwoUiMetrics.publishCloudWatchCountMetric(metricFeaturesList.index, metricPageNames.workflowInstanceListView)
    }, [])

    const items = [
        {
            "text": "Home",
            "href": "/"
        },
        {
            "text": "Executions",
            "href": AppRoutes.executionList
        }
    ]
    return (
        <AppLayout
            navigation={<ServiceNavigation/>}
            breadcrumbs={<BreadcrumbGroupWithRouter items={items}/>}
            content={<ExecutionListTableV3/>}
            contentType="table"
            disableContentPaddings={false}
            navigationOpen={navigationOpen}
            onNavigationChange={(event: any) => setNavigationOpen(event.detail.open)}
            toolsHide={true}
            ariaLabels={appLayoutNavigationLabels}
        />
    );
}
