import React from 'react';
import {Box, Checkbox, ColumnLayout, FormField, Input, Link} from "@amzn/awsui-components-react-v3";
import Select from "@amzn/awsui-components-react-v3/polaris/select";
import {WorkflowInstanceUtil} from "src/utils/workflow-instance-util";
import {SelectProps} from "@amzn/awsui-components-react-v3/polaris/select/interfaces";
import {BaseChangeDetail} from '@amzn/awsui-components-react-v3/polaris/input/interfaces';

export interface WorkflowMetadataProps {
    programSelectedOption: SelectProps.Option;
    programOptions: SelectProps.Options;
    selectedSubProgram: SelectProps.Option;
    subProgramOptions: SelectProps.Options;
    targetWebsite: SelectProps.Option;
    targetWebsiteOptions: SelectProps.Options;
    domainName: string;
    disableDomain?: boolean;
    disableTargetWebsiteOption?: boolean;
    isScheduled?: boolean;
    isScheduleDisabled?: boolean;
    startWFLoading?: boolean;
    schemaLoading?: boolean;
    onProgramChangeHandler?: (detail: SelectProps.ChangeDetail) => void;
    onSubProgramChangeHandler?: (detail: SelectProps.ChangeDetail) => void;
    onDomainChange?: (detail: BaseChangeDetail) => void;
    onTargetWebsiteChange?: (detail: SelectProps.ChangeDetail) => void;
    onScheduleStatusChange?: (isScheduleOptionChecked: boolean) => void;
}

export const WorkflowMetadata: React.FC<WorkflowMetadataProps> = (props) => {
    const {
        programSelectedOption,
        programOptions,
        selectedSubProgram,
        subProgramOptions,
        targetWebsite,
        targetWebsiteOptions,
        domainName,
        disableDomain,
        disableTargetWebsiteOption,
        isScheduled,
        isScheduleDisabled,
        startWFLoading,
        schemaLoading,
        onProgramChangeHandler,
        onSubProgramChangeHandler,
        onDomainChange,
        onTargetWebsiteChange,
        onScheduleStatusChange
    } = props
    return (
        <>
            <Box padding={{bottom: "l"}}>
                <ColumnLayout columns={2} borders={"vertical"}>
                    <ColumnLayout columns={1}>
                        <FormField
                            label="Select Program"
                        >
                            <Select
                                selectedOption={programSelectedOption}
                                disabled={Boolean(startWFLoading || schemaLoading)}
                                onChange={({detail}) => {
                                    onProgramChangeHandler && onProgramChangeHandler(detail);
                                }}
                                data-testid={"select-program-field"}
                                options={programOptions}
                                selectedAriaLabel="Selected"
                            />
                        </FormField>
                        {!isScheduled && selectedSubProgram && <FormField
                            label={<>Select Sub Program</>}>
                            <Select
                                selectedOption={selectedSubProgram}
                                disabled={startWFLoading || schemaLoading || subProgramOptions.length === 0}
                                onChange={({detail}) => {
                                    onSubProgramChangeHandler && onSubProgramChangeHandler(detail);
                                }}
                                options={subProgramOptions}
                                selectedAriaLabel="Selected"
                                data-testid={'select-sub-program-field'}
                            />
                        </FormField>
                        }
                        {WorkflowInstanceUtil.isDomainFieldVisible(programSelectedOption) && <FormField
                            label={<>Domain name</>}>
                            <Input
                                placeholder={"www.walmart.com"}
                                disabled={Boolean(!WorkflowInstanceUtil.isDomainFieldVisible(programSelectedOption) || disableDomain)}
                                onChange={({detail}) => onDomainChange && onDomainChange(detail)}
                                value={domainName}
                                data-testid={"domain-input-field"}
                            />
                        </FormField>}
                        {WorkflowInstanceUtil.isDomainFieldVisible(programSelectedOption) && <FormField
                            label={<>Select targeted website</>}>
                            <Select
                                selectedOption={targetWebsite}
                                disabled={startWFLoading || schemaLoading || programSelectedOption.value == "" || Boolean(disableTargetWebsiteOption)}
                                onChange={({detail}) => {
                                    onTargetWebsiteChange && onTargetWebsiteChange(detail);
                                }}
                                options={targetWebsiteOptions}
                                data-testid={'select-target-website-field'}
                                selectedAriaLabel="Selected"
                            />
                        </FormField>
                        }
                    </ColumnLayout>
                    <ColumnLayout columns={1}>
                        <FormField
                            label={<>&nbsp;</>}>
                            <Checkbox
                                onChange={({detail}) => onScheduleStatusChange && onScheduleStatusChange(detail.checked)}
                                checked={Boolean(isScheduled)}
                                disabled={Boolean(isScheduleDisabled)}
                                data-testid={"schedule-wi-checkbox"}
                            >
                                Schedule Workflow Instance?
                            </Checkbox>
                        </FormField>
                        {WorkflowInstanceUtil.isContinuousProgramVariantSupported(programSelectedOption.label) &&
                            <FormField
                                label={<>&nbsp;</>}>
                                <Checkbox
                                    checked={true}
                                    disabled={true}
                                    data-testid={"continuous-wi-checkbox"}
                                >
                                    Is Continuous Workflow
                                </Checkbox>
                            </FormField>}
                    </ColumnLayout>
                </ColumnLayout>
            </Box>
        </>
    )
}

interface ScheduleWorkflowMetadataProps {
    isScheduled: boolean;
    scheduleName: string;
    onScheduleNameChange: (scheduleName: string) => void;
    cronExpression: string;
    onCronExpressionChange: (cronExpression: string) => void;
}

export const ScheduleWorkflowMetadata: React.FC<ScheduleWorkflowMetadataProps> = (props) => {
    const {scheduleName, cronExpression, onScheduleNameChange, onCronExpressionChange, isScheduled} = props
    return (
        <>
            {isScheduled &&
                <Box padding={{bottom: "l"}}>
                    <ColumnLayout columns={2} borders={"vertical"}>
                        <FormField
                            label="Schedule Name"
                        >
                            <Input value={scheduleName} onChange={(e) => onScheduleNameChange(e.detail.value)}/>
                        </FormField>

                        <FormField
                            label={"Cron Expression"}
                            secondaryControl={<><Link
                                href={"https://docs.aws.amazon.com/eventbridge/latest/userguide/eb-create-rule-schedule.html#eb-cron-expressions"}
                                external={true}>
                                Refer this link</Link> for cron expression syntax</>}
                        >
                            <Input value={cronExpression} onChange={(e) => onCronExpressionChange(e.detail.value)}
                                   placeholder={"0 12 * * ? *"}/>
                        </FormField>
                    </ColumnLayout>
                </Box>
            }
        </>
    )
}

