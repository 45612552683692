import React from "react"
import {CollectionPreferences, FormField, RadioGroup} from "@amzn/awsui-components-react-v3";
import {timeConversion} from "src/components/virtual-pipeline/virtual-pipeline-instance/dashboard-utils";
import Moment from "moment";

export const COLUMN_DEFINITIONS = [
    {
        id: 'targetDatasetInstanceNumber',
        sortingField: 'targetDatasetInstanceNumber',
        header: 'Target Dataset Instance Number',
        cell: (item: { targetDatasetInstanceNumber: number; }) => item.targetDatasetInstanceNumber,
        minWidth: 10,
        maxWidth: 100
    },
    {
        id: 'creationDate',
        sortingField: 'creationDate',
        header: 'Creation Date',
        cell: (item: { creationDate: string; }) => Moment(item.creationDate).format('YYYY-MM-DD HH:MM:SS'),
        minWidth: 10,
        maxWidth: 100
    },
    {
        id: 'endToEndLatency(hours)',
        field: 'endToEndLatency(hours)',
        sortingField: 'endToEndLatency',
        header: 'End to End Latency',
        cell: (item: { endToEndLatency: number; }) => timeConversion(item.endToEndLatency),
        minWidth: 10,
        maxWidth: 100
    },
    {
        id: 'endToEndLatency(days)',
        field: 'endToEndLatency(hours)',
        sortingField: 'endToEndLatency',
        header: 'End to End Latency',
        cell: (item: { endToEndLatency: number; }) => timeConversion(item.endToEndLatency, "day"),
        minWidth: 10,
        maxWidth: 100
    }
];

export const CUSTOM_LATENCY_METRIC_OPTIONS = [
    {value: "hours", label: "Hours"},
    {value: "days", label: "Days"}
]

export const PAGE_SIZE_OPTIONS = [
    { value: 10, label: '10 Instances' },
    { value: 30, label: '30 Instances' },
    { value: 50, label: '50 Instances' }
];

export const DEFAULT_PREFERENCES = {
    pageSize: 30,
    visibleContent: ['targetDatasetInstanceNumber', 'creationDate', 'endToEndLatency(hours)'],
    wrapLines: true,
    custom: CUSTOM_LATENCY_METRIC_OPTIONS[0].value
};

export const Preferences = (props: {
    preferences: any,
    setPreferences: any,
    disabled: boolean
}) => (
    <CollectionPreferences
        title="Preferences"
        confirmLabel="Confirm"
        cancelLabel="Cancel"
        disabled={props.disabled}
        preferences={props.preferences}
        onConfirm={({ detail }) => {
            if (detail.custom == "days") {
                detail.visibleContent = ['targetDatasetInstanceNumber', 'creationDate', 'endToEndLatency(days)']
            } else {
                detail.visibleContent = ['targetDatasetInstanceNumber', 'creationDate', 'endToEndLatency(hours)']
            }
            props.setPreferences(detail)
        }}
        pageSizePreference={{
            title: 'Page size',
            options: PAGE_SIZE_OPTIONS
        }}
        wrapLinesPreference={{
            label: 'Wrap lines',
            description: 'Check to see all the text and wrap the lines'
        }}
        customPreference={((metric, setMetric) => (
            <FormField label="Latency Metric">
                <RadioGroup
                    value={metric}
                    onChange={({ detail }) => {
                        setMetric(detail.value)
                    }}
                    items={CUSTOM_LATENCY_METRIC_OPTIONS}
                />
            </FormField>
        ))}
    />
);

export type VirtualPipelineInstances = { [key: string]: string | number }[];