import * as React from "react";
import {Modal} from "@amzn/awsui-components-react-v3";
import Box from "@amzn/awsui-components-react-v3/polaris/box";
import Button from "@amzn/awsui-components-react-v3/polaris/button";
import {SWOStageDetailDataI} from "src/interfaces/workflow-instance-detail";
import ReactJson from "react-json-view";

export function SWOStageDetailData(props: SWOStageDetailDataI) {
    const [visible, setVisible] = React.useState(false);

    return <>
        {visible && <Box data-testid={"swo_stage-instance-events-modal-box"}
        >
            <Modal
                className={"swo_stage-instance-events-modal"}
                data-testid={"swo_stage-instance-events-modal"}
                onDismiss={() => setVisible(false)}
                visible={visible}
                closeAriaLabel="Close modal"
                size="max"
                footer={
                    <Box float="right">
                        <Button variant="primary" onClick={() => setVisible(false)}>Close</Button>
                    </Box>
                }
                header="Stage Details"
            >
            <div>
                <b>Click ' . . . ' to Expand/Collapse </b>
            </div>
                <ReactJson src={props.stageInstance[0]}   enableClipboard={true}
                       displayDataTypes={false}
                       displayObjectSize={true}
                       iconStyle={"circle"}
                       theme={"rjv-default"}
                       name={false}
                       collapseStringsAfterLength={100} style={{ width: 400 }}
            />

            </Modal>
        </Box>}
        <Button onClick={() => {
            setVisible(true)
        }}>View</Button>
    </>
}
