import React from "react"
import {CollectionPreferences} from "@amzn/awsui-components-react-v3";
import Moment from "moment";

export const COLUMN_DEFINITIONS = [
    {
        id: 'vpId',
        sortingField: 'vpId',
        header: 'VpId',
        cell: (item: { vpId: string }) => item.vpId,
        minWidth: 180
    },
    {
        id: 'vpName',
        sortingField: 'vpName',
        header: 'VpName',
        cell: (item: { vpName: string }) => item.vpName,
        minWidth: 180
    },
    {
        id: 'username',
        sortingField: 'username',
        header: 'Username',
        cell: (item: { username: string }) => item.username,
        minWidth: 180
    },
    {
        id: 'team',
        sortingField: 'team',
        header: 'Team',
        cell: (item: { team: string }) => item.team,
        minWidth: 180
    },
    {
        id: 'creationDate',
        sortingField: 'creationDate',
        header: 'Creation Date',
        cell: (item: { creationDate: string }) =>  Moment(item.creationDate).format("YYYY-MM-DD hh:mm:ss"),
        minWidth: 100,
        maxWidth: 180
    },
    {
        id: 'lastUpdateDate',
        sortingField: 'lastUpdateDate',
        header: 'Last Update Date',
        cell: (item: { lastUpdateDate: string }) => Moment(item.lastUpdateDate).format("YYYY-MM-DD hh:mm:ss"),
        minWidth: 100,
        maxWidth: 180
    },
    {
        id: 'latestInstanceOfTargetDataset',
        sortingField: 'latestInstanceOfTargetDataset',
        header: 'Latest Instance of Target Dataset',
        cell: (item: { latestInstanceOfTargetDataset: string }) => item.latestInstanceOfTargetDataset,
        minWidth: 180
    },
    {
        id: 'comments',
        sortingField: 'comments',
        header: 'Comments',
        cell: () => "",
        minWidth: 180
    },
    {
        id: 'pipelineInformation',
        sortingField: 'pipelineInformation',
        header: 'Pipeline Information',
        cell: () => "",
        minWidth: 180
    }
];

const VISIBLE_CONTENT_OPTIONS = [
    {
        label: 'Main virtual pipeline properties',
        options: [
            { id: 'vpId', label: 'VpId', editable: false },
            { id: 'vpName', label: 'Vp Name' },
            { id: 'username', label: 'Username' },
            { id: 'team', label: 'Team' },
            { id: 'creationDate', label: 'Creation Date' },
            { id: 'lastUpdateDate', label: 'Last Update Date' },
            { id: 'latestInstanceOfTargetDataset', label: 'Latest Instance of Target Dataset' },
        ]
    }
];

export const PAGE_SIZE_OPTIONS = [
    { value: 10, label: '10 Virtual Pipelines' },
    { value: 30, label: '30 Virtual Pipelines' },
    { value: 50, label: '50 Virtual Pipelines' }
];

export const DEFAULT_PREFERENCES = {
    pageSize: 30,
    visibleContent: ['vpId', 'vpName', 'username', 'team', 'creationDate', 'lastUpdateDate', 'latestInstanceOfTargetDataset'],
    wrapLines: true,
};

export const Preferences = (props: { preferences: any, setPreferences: any, disabled: boolean }) => (
    <CollectionPreferences
        title="Preferences"
        confirmLabel="Confirm"
        cancelLabel="Cancel"
        disabled={props.disabled}
        preferences={props.preferences}
        onConfirm={({ detail }) => props.setPreferences(detail)}
        pageSizePreference={{
            title: 'Page size',
            options: PAGE_SIZE_OPTIONS
        }}
        wrapLinesPreference={{
            label: 'Wrap lines',
            description: 'Check to see all the text and wrap the lines'
        }}
        visibleContentPreference={{
            title: 'Select visible columns',
            options: VISIBLE_CONTENT_OPTIONS
        }}
    />
);

export type VirtualPipelineDetails = {[key: string]: string};