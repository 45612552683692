import React, {useEffect, useState} from "react";
import {TableTypes} from "src/interfaces/domain-tracker";
import {DomainTracker} from "src/model/DomainTracker";
import {TableMetadataInterface} from "src/interfaces/server-side-table";
import {UrlHelper} from "src/utils/url-helper";
import {toast} from "react-toastify";
import {WORKFLOW_START_DATE} from "src/constants/domain-tracker";
import {DEFAULT_TABLE_PREFERENCE, UserTablePreferences} from "src/components/common/table-user-preferences";
import {getStartDateAndEndDate} from "src/utils/table-helper";
import produce from "immer";
import {Alert, Spinner} from "@amzn/awsui-components-react-v3";
import {ServerSideTableV3} from "src/components/table/server-side-table-v3";
import {DEFAULT_ES_QUERY_FILTER} from "src/constants/elastic-search";
import {AttributeFilterItemsI, EsQueryTableFilterI, FilterOperation} from "src/interfaces/attribute-filters";


/**
 * Execution list table. Retrieving metadata for execution list and passing it to server-side table.
 * Columns details are managed via config, which will be replaced by backed API call.
 * @constructor
 */
export function ExecutionListTableV3() {
    const [tableMetadata, setTableMetadata] = useState<TableMetadataInterface>({})
    const [metadataError, setMetadataError] = useState<boolean>(false)
    const userTablePreference = new UserTablePreferences(TableTypes.EXECUTION_LIST)
    let urlTableFilters: EsQueryTableFilterI | null = null;
    try {
        urlTableFilters = UrlHelper.getTableFiltersFromUrlParams(UrlHelper.ES_QUERY_PARAMS_KEY)
    } catch (e) {
        console.error("Error while parsing url params. ", e)
        toast.error("Error while parsing url params. Redirecting to default view.")
    }

    // Checking and adding execution start date
    const isWFStartDateExists = urlTableFilters?.attributeFilterItems.find(attributeFilterItem => {
        return attributeFilterItem.attributeName?.value == WORKFLOW_START_DATE
    })

    // Execution start date must be present on this view. So hardcoding workflow start date to last 30 days if it's not
    // available in the URL
    if ((urlTableFilters == null) || (isWFStartDateExists == undefined)) {
        const sortingOptions = {
            isDescending: true,
            sortingColumn: {
                sortingField: WORKFLOW_START_DATE
            }
        }

        urlTableFilters = {
            ...DEFAULT_ES_QUERY_FILTER,
            size: userTablePreference.getTablePreference().size || DEFAULT_TABLE_PREFERENCE.size,
            attributeFilterItems: [...DEFAULT_ES_QUERY_FILTER.attributeFilterItems, {
                value: getStartDateAndEndDate(30),
                columnDefinition: null,
                attributeName: {
                    value: WORKFLOW_START_DATE,
                    label: WORKFLOW_START_DATE
                },
                condition: FilterOperation.BETWEEN,
                tableType: TableTypes.EXECUTION_LIST,
            }],
            sortingOptions
        }
    }


    const [esQueryTableFilter, setEsQueryTableFilter] = useState<EsQueryTableFilterI>(urlTableFilters)
    useEffect(() => {
        async function retrieveColumnMetadata() {
            setMetadataError(false)
            const metadata: TableMetadataInterface = await new DomainTracker().getTableMetadata("WORKFLOW")
            const esQueryTableFilterUpdated = produce(urlTableFilters?.attributeFilterItems, (draft: Array<AttributeFilterItemsI>) => {
                draft.forEach(urlTableFilter => {
                    if (metadata[urlTableFilter.attributeName?.value || ""] != undefined) {
                        urlTableFilter.columnDefinition = metadata[urlTableFilter.attributeName?.value || ""] || null
                        urlTableFilter.tableType = TableTypes.EXECUTION_LIST
                    }
                })
            })
            if (esQueryTableFilterUpdated != undefined) {
                setEsQueryTableFilter({
                    ...esQueryTableFilter,
                    attributeFilterItems: esQueryTableFilterUpdated
                })
            }
            setTableMetadata(metadata)
        }

        retrieveColumnMetadata()
    }, [])

    return <>
        <>{JSON.stringify(tableMetadata) != "{}" ?
            <ServerSideTableV3 tableMetadata={tableMetadata}
                               tableType={TableTypes.EXECUTION_LIST}
                               hidePrimaryFilter={false}
                               hideSecondaryFilter={false}
                               esQueryTableFilter={esQueryTableFilter}
            /> : <>{metadataError ? <><Alert type={"error"}>Failed to retrieve metadata. Please refresh the page and try again.</Alert></>: <><Spinner/>Loading metadata..</>}</>
        }
        </>
    </>
}
