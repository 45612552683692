import {TableMetadataInterface} from "src/interfaces/server-side-table";
import React, {useEffect} from "react";
import {Box, Grid, SpaceBetween} from "@amzn/awsui-components-react-v3";
import {ES_SEARCH_TYPES} from "src/constants/elastic-search";
import {GridProps} from "@amzn/awsui-components-react-v3/polaris/grid/interfaces";
import {CommonAttrValue} from "src/components/filters/common";
import {AttributeFilterI, AttributeFilterItemsI, FilterOperation} from "src/interfaces/attribute-filters";

/**
 * Primary filters which we display on top in execution list page like program, workflow start date, targeted websites.
 * @param props
 * @constructor
 */
export const PrimaryFilter = (props: AttributeFilterI) => {
    const [items, setItems] = React.useState<Array<AttributeFilterItemsI>>(props.attributeFilterItems);
    const primaryFilters: TableMetadataInterface = {}

    useEffect(() => {
        const updatedItems: Array<AttributeFilterItemsI> = []
        Object.entries(props.tableMetadata).forEach(([columnKey, columnDefinition]) => {
            if (columnDefinition.uiFeatures.onTopFilter) primaryFilters[columnKey] = columnDefinition;
            if (columnDefinition.uiFeatures.onTopFilter) {
                const existingValue = props.attributeFilterItems.find(token => token.attributeName?.value == columnKey)
                const condition: FilterOperation = columnDefinition.dataType == ES_SEARCH_TYPES.DATE ? FilterOperation.BETWEEN : FilterOperation.EQUALS
                updatedItems.push({
                    value: existingValue?.value || "",
                    attributeName: {label: columnDefinition.uiFeatures.visibleName, value: columnKey},
                    condition: condition,
                    columnDefinition: columnDefinition,
                    tableType: props.tableType

                })
            }
        })
        setItems(updatedItems)
    }, [props.tableMetadata, props.attributeFilterItems])

    const gridDefinition: ReadonlyArray<GridProps.ElementDefinition> = items.map(item => {
        const colSpanXxs: number = item.columnDefinition?.dataType == ES_SEARCH_TYPES.DATE ? 5 : 3
        return {colspan: {default: 12, xxs: colSpanXxs}}
    })
    return <Box>
        <Grid gridDefinition={gridDefinition}>
            {items.map((item, itemIndex) => {
                return <Box key={`primary-filter-attribute-${itemIndex}`} variant={"div"} data-testid={`primary-filter-attribute-${itemIndex}`}>
                    <Box padding={{bottom: item.columnDefinition?.dataType != ES_SEARCH_TYPES.DATE ? "xs" : "xxs"}}>
                        {item.attributeName?.label}
                    </Box>
                    {CommonAttrValue(items, item, props.tableType, setItems, itemIndex, props.onFilteringTokenUpdate)}
                </Box>
            })}
        </Grid>
    </Box>
}