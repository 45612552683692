'use strict';

import React, {useState} from 'react';
import {Button, Modal, ColumnLayout, SpaceBetween, Box} from "@amzn/awsui-components-react-v3";
import {
    VirtualPipelineDetail,
    VirtualPipelineInstanceDashboard,
    VirtualPipelineVisualization
} from "src/constants/virtual-pipelines";
import {COLUMN_DEFINITIONS} from "src/components/virtual-pipeline/virtual-pipeline-list-table-config";
import {AppRoutes} from "src/components/common/router-common";
import {ButtonWithRouter} from "src/components/virtual-pipeline/common-components";

/**
 * Contains two buttons for showing virtual pipeline record info and visualization.
 * @param props
 * @constructor
 */
export const VirtualPipelineDetailModal = ({ ...props }) => {
    const [showDetailModal, setShowDetailModal] = useState<boolean>(false);

    const selectedItem = props.selectedItems[0] || {}

    return (
        <>
            <Modal
                id={"virtual-pipeline-detail-modal"}
                onDismiss={() => setShowDetailModal(false)}
                data-testid={"virtual-pipeline-detail-modal"}
                visible={showDetailModal}
                size="large"
                footer={
                    <span className={"awsui-util-f-r"}>
                        <Button id={"virtual-pipeline-detail-close-button"}
                                variant="primary"
                                onClick={() => setShowDetailModal(false)}>Close</Button>
                    </span>
                }
                header={VirtualPipelineDetail.header}
            >
                <ColumnLayout columns={3} variant={"text-grid"}>
                    {Object.entries(COLUMN_DEFINITIONS).map(([columnId, colDefinition]) => {
                        return <div key={columnId}>
                            <h5 className="awsui-util-label">{colDefinition.header}</h5>
                            <div>{JSON.stringify(selectedItem[colDefinition.id])}</div>
                        </div>
                    })}
                </ColumnLayout>
            </Modal>

            <SpaceBetween size={"xs"} direction={"horizontal"}>
                <Button id={"virtual-pipeline-detail-modal-button"}
                        onClick={() => setShowDetailModal(true)}
                        disabled={JSON.stringify(selectedItem) === "{}"}
                >{VirtualPipelineDetail.header}</Button>

                <ButtonWithRouter variant={"normal"}
                                  id={"virtual-pipeline-visualization-button"}
                                  href={`${AppRoutes.virtualPipelines}/visualization/${selectedItem["vpId"]}`}
                                  disabled={JSON.stringify(selectedItem) === "{}"}
                >{VirtualPipelineVisualization.header}</ButtonWithRouter>

                <ButtonWithRouter variant={"primary"}
                                  id={"virtual-pipeline-instance-dashboard-button"}
                                  href={`${AppRoutes.virtualPipelines}/instance/${selectedItem["vpId"]}`}
                                  disabled={JSON.stringify(selectedItem) === "{}"}
                >{VirtualPipelineInstanceDashboard.header}</ButtonWithRouter>

            </SpaceBetween>
        </>
    )
}