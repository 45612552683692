import React, {useMemo} from "react";
import {Header, Box, Alert, Button} from "@amzn/awsui-components-react-v3";
import { RelatedTable, useTreeCollection } from '@amzn/polaris-related-table';
import {SMOverviewAppLayout} from "src/components/sm-overview-dashboards/sm-overview-app-layout";
import {metricFeaturesList, metricPageNames} from "src/constants/swo-ui-metric-constants";
import {UserActionsInfoBar} from "src/components/sm-overview-dashboards/common/UserActionsInfoBar";
import {DashboardRefreshTime} from "src/components/sm-overview-dashboards/common/dashboard-refresh-time";
import {FilterInput} from "src/components/sm-overview-dashboards/workflow-list-view/filters/filterInput";
import {
    getRunningProgramSummaryColumnDefinition
} from "src/components/sm-overview-dashboards/workflow-detail-view/workflow-running-column-config";
import {useLocation} from "react-router-dom";
import {QueryParams} from "src/components/sm-overview-dashboards/common/query-params";
import {useQuery} from "@tanstack/react-query";
import {SMSummary} from "src/model/SMSummary";
import {
    RUNNING_WORKFLOW_AGE_DISTRIBUTION, transformRunningDataToTreeView
} from "src/components/sm-overview-dashboards/workflow-detail-view/workflow-detail-view.utils";
import {ExternalRoutes} from "src/components/common/router-common";

export const ProgramDetailRunningView = () => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const program = params.get(QueryParams.PROGRAM) || '';
    const filterQuery = params.get(QueryParams.FILTER_QUERY_KEY) || JSON.stringify({});
    const {data: programRunningDetailSummary, isLoading, error} =
        /* istanbul ignore next */
        useQuery({
            queryKey: ['workflowRunningDetailSummary', filterQuery],
            queryFn: () => new SMSummary().getProgramRunningDetail({
                filterQuery: filterQuery,
                timeRangeBucket: JSON.stringify(RUNNING_WORKFLOW_AGE_DISTRIBUTION)
            })
        });

    const [workflowColumnDefinitions, stageColumnDefinitions] = useMemo(() => {
        return [getRunningProgramSummaryColumnDefinition(filterQuery, true)
            , getRunningProgramSummaryColumnDefinition(filterQuery, false)]
    }, [filterQuery])

    const [workflowData, stageData] = useMemo(() => {
        const sortedStageData = (programRunningDetailSummary?.stageRunningWorkflowDetails || []).sort(
            (a, b) =>
                a.entity && b.entity && a.entity.toLowerCase() > b.entity.toLowerCase() ? 1 : -1)
        return [transformRunningDataToTreeView(programRunningDetailSummary?.programRunningWorkflowDetail || [], true)
            , transformRunningDataToTreeView( sortedStageData, false)]
    }, [programRunningDetailSummary])

    const {expandNode: stageTableExpandNode, items: stageTableData, collectionProps: stageTableCollectionProps} =
        useTreeCollection(stageData, {
        columnDefinitions: stageColumnDefinitions,
        keyPropertyName: 'nodeId',
        parentKeyPropertyName: 'parentId',
        sorting: {},
        selection: {
            trackBy: 'nodeId',
        },
    }, false);

    const {expandNode: workflowTableExpandNode, items: workflowTableData, collectionProps: workflowTableCollectionProps}
        = useTreeCollection(workflowData, {
        columnDefinitions: workflowColumnDefinitions,
        keyPropertyName: 'nodeId',
        parentKeyPropertyName: 'parentId',
        sorting: {},
        selection: {
            trackBy: 'nodeId',
        },
    }, true);

    return(
        <>
            <Box padding={{vertical: "s", horizontal: "xl"}}>
                <UserActionsInfoBar view={metricFeaturesList.programDetailsRunning} hasError={!!error}/>
                <DashboardRefreshTime />
            </Box>
            <Box padding={{vertical: "s", horizontal: "xl"}}>
                <FilterInput />
            </Box>
            <Box padding={{vertical: 'l', horizontal: 'l'}}>
                <RelatedTable
                    {...workflowTableCollectionProps}
                    data-testid={'running-workflow-detail'}
                    expandChildren={workflowTableExpandNode}
                    columnDefinitions={workflowColumnDefinitions}
                    items={workflowTableData}
                    loading={isLoading}
                    loadingText={'Loading running stage details'}
                    header={
                        <Header>
                            Running Workflows details
                        </Header>
                    }
                />
            </Box>
            <Box padding={{vertical: 's', horizontal: 'xl'}}>
                <Alert
                    statusIconAriaLabel="Info"
                    action={<Button
                        ariaLabel="Running Workflow dive deep view (opens new tab)"
                        href={ExternalRoutes.runningWorkflowDiveDeep}
                        iconAlign="left"
                        iconName="external"
                        target="_blank"
                    >
                        Running stages
                    </Button>}
                    header="Running Workflow dive deep view"
                >
                    <p>Please use following link to see Running stages and their age. </p>
                </Alert>
            </Box>
            <Box padding={{vertical: 'l', horizontal: 'l'}} display={'none'}>
                <RelatedTable
                    {...stageTableCollectionProps}
                    data-testid={'running-stage-detail'}
                    expandChildren={stageTableExpandNode}
                    columnDefinitions={stageColumnDefinitions}
                    items={stageTableData}
                    loading={isLoading}
                    loadingText={'Loading running stage details'}
                    header={
                        <Header description={'Disclaimer: this view will be modified to correctly attribute' +
                            ' long running workflow instance to right stage.' }>
                            Running Workflows (And the time they have been running in that stage)
                        </Header>
                    }
                />
            </Box>
        </>
    )
}

export const ProgramDetailRunning = () => <SMOverviewAppLayout
    content={<ProgramDetailRunningView />}
    metricName={metricFeaturesList.programDetailsRunning}
    page={metricPageNames.programDetails}
/>
