import React, {useEffect, useState} from "react";
import {useCollection} from "@amzn/awsui-collection-hooks";
import {DateRangePicker, Pagination, Table, TextFilter} from "@amzn/awsui-components-react-v3";
import {
    COLUMN_DEFINITIONS,
    DEFAULT_PREFERENCES,
    Preferences, VirtualPipelineInstances
} from "src/components/virtual-pipeline/virtual-pipeline-instance/instance-table/virtual-pipeline-instance-table-config";
import {VirtualPipelineInstanceTableHeader} from "src/components/virtual-pipeline/virtual-pipeline-instance/instance-table/common-components";
import {
    paginationLabels
} from "src/components/virtual-pipeline/virtual-pipeline-instance/instance-table/virtual-pipeline-instance-table-labels";
import {
    convertToAbsoluteRange, dateRangeFilterI18nStrings,
    isValidRangeFunction, timeConversion
} from "src/components/virtual-pipeline/virtual-pipeline-instance/dashboard-utils";
import {isWithinInterval} from "date-fns";
import Moment from "moment";

/**
 * A table to display raw data of virtual pipeline instances.
 * @param props
 * @constructor
 */
export function VirtualPipelineInstanceTable(props: {
    instances: VirtualPipelineInstances,
    isLoading: boolean
}) {
    const [preferences, setPreferences] = useState(DEFAULT_PREFERENCES);
    const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
        props.instances,
        {
            filtering: {
                empty: <div> Empty </div>,
                noMatch: <div> No match </div>,
                filteringFunction: (item, filteringText) => {
                    const filteringTextLowerCase = filteringText.toLowerCase();

                    return COLUMN_DEFINITIONS.map(key => {
                        let value = item[key.id]
                        switch (key.id) {
                            case "targetDatasetInstanceNumber":
                                value = item[key.id].toString()
                                break;
                            case "creationDate":
                                value = Moment(item[key.id]).format('YYYY-MM-DD HH:MM:SS');
                                break;
                            case "endToEndLatency(hours)":
                                value = timeConversion(Number(item[key.sortingField]));
                                break;
                            case "endToEndLatency(days)":
                                value = timeConversion(Number(item[key.sortingField]), "day");
                                break;
                        }
                        return value
                    }).some(
                        value => typeof value === 'string' && value.toLowerCase().indexOf(filteringTextLowerCase) > -1
                    );
                }
            },
            pagination: { pageSize: preferences.pageSize },
            sorting: {},
            selection: {}
        }
    );

    const getFilterCounterText = (count: number) => `${count} ${count === 1 ? 'match' : 'matches'}`;

    return (
        <Table
            {...collectionProps}
            columnDefinitions={COLUMN_DEFINITIONS}
            visibleColumns={preferences.visibleContent}
            loading={props.isLoading}
            items={items}
            stickyHeader={true}
            resizableColumns={false}
            wrapLines={preferences.wrapLines}
            header={<VirtualPipelineInstanceTableHeader
                selectedItems={collectionProps.selectedItems}
                totalItems={props.instances}
            />}
            filter={
                <div className="filter-container">
                    <div className="input-filter">
                        <TextFilter
                            {...filterProps}
                            disabled={props.isLoading}
                            filteringAriaLabel="Filter instances"
                            filteringPlaceholder="Find instances"
                            countText={getFilterCounterText(filteredItemsCount!)}
                        />
                    </div>
                </div>
            }
            pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
            preferences={<Preferences preferences={preferences} setPreferences={setPreferences} disabled={false}/>}
        />
    )
}