import {createTheme} from "@material-ui/core";

export const startScreenInputFormStyles = createTheme({
    overrides: {
        MuiFormControlLabel: {
            label: {
                fontSize: '1em',
                color: '#21252c'
            },
        },
        MuiInputLabel: {
            filled: {
                fontSize: '1em',
                color: '#21252c'
            },
            formControl: {
                fontSize: '1em',
            },
            root: {
                fontSize: '1em',
            }
        },
        MuiGrid: {
            root: {
                marginBottom: '0px'
            }
        }
    },
});
