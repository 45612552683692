import './polyfills';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import katalReady from '@katal/components-ready';

import App from './components/App';
import AppInitWrapper from './components/AppInitWrapper';
import './i18n';
import './index.scss';

katalReady(() => {
  // The Suspense component causes React to display the fallback KatSpinner until
  // the i18n translation strings have been loaded.

  ReactDOM.render(
    <AppInitWrapper>
      <Suspense fallback={<p>Loading.....</p> }>
            <App />
      </Suspense>
    </AppInitWrapper>,
    document.getElementById('root')
  );
});
export {DEFAULT_ES_QUERY_FILTER} from "src/constants/elastic-search";