import React, {useEffect, useState} from "react";
import {ElasticSearchHelper} from "src/utils/elastic-search-helper";
import {GetAggregateCardResponse} from "@amzn/swo-unified-dashboard-service-lambda-js-client/lib/smswounifieddashboardservicelambda";
import {ExecutionSummary} from "src/model/ExecutionSummary";
import {Alert, Box, Header, Spinner} from "@amzn/awsui-components-react-v3";
import {WorkflowSummarySubStageChartI} from "src/interfaces/charts";
import {Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {CHART_COLORS, getAggregateCardName, getRandomChartColor} from "src/utils/aggregate-card";
import {convertBackendDataToChartDataXAxis, getUniqueYAxisValues} from "src/components/common/charts";
import {ChartCustomTooltip} from "src/components/charts/tooltip";
import {SwoUiMetrics} from "src/utils/swo-ui-metrics/swo-ui-metrics";
import {metricFeaturesList, metricPageNames} from "src/constants/swo-ui-metric-constants";

export function WorkflowSubStageGraph(props: WorkflowSummarySubStageChartI) {
    const [tooltipTriggerType, setTooltipTriggerType] = useState<'hover' | 'click'>("hover")
    const esQuery = ElasticSearchHelper.convertAttributeFilterTokensToESQueryV3(props.esQueryTableFilter)
    const [aggregateCardResponse, setAggregateCardResponse] = useState<GetAggregateCardResponse | null>(null)
    const [aggregateCardError, setAggregateCardResponseError] = useState<string>("")
    const [subStageChartLoading, setSubStageChartLoading] = useState<boolean>(false)
    const [chartData, setChartData] = useState<Array<any>>([])

    useEffect(() => {
        SwoUiMetrics.publishCloudWatchCountMetric(metricFeaturesList.showSubStage, metricPageNames.workflowAggregateInstanceView)
        setSubStageChartLoading(true)
        setAggregateCardResponseError("")
        const aggregateCard = new ExecutionSummary().getSummaryDetails(getAggregateCardName(props.subStage), JSON.stringify(esQuery))
        aggregateCard.then((resp) => {
            setChartData(convertBackendDataToChartDataXAxis(resp.aggregateCardData))
            setAggregateCardResponse(resp)
        }).catch(error => {
            setAggregateCardResponseError(JSON.stringify(error))
            setAggregateCardResponse(null)
            setChartData([])
        }).then(() => {
            setSubStageChartLoading(false)
        })

    }, [props.esQueryTableFilter, props.subStage])
    return (
        <>
            <Alert type={"error"} data-testid={"workflow-sub-stage-summary-chart-error-alert"}
                   visible={aggregateCardError != ""}>
                Failed to retrieve chart details. Please refresh the page and try again. {aggregateCardError}</Alert>
            <Box padding={{bottom: "l"}}>
                <Header variant={"h2"}>Sub stage - {props.subStage}</Header>
            </Box>

            <Box variant={"div"} textAlign={"center"}>
                <div>{(props.loading || subStageChartLoading) ? <><Spinner size={"large"}/> Loading... </> : <>
                    {(aggregateCardResponse != null && JSON.stringify(aggregateCardResponse.aggregateCardData) != "{}") ? <>
                        <Box padding={{bottom: "l"}}>
                            <Alert >These numbers are only for last known sub stage when the crawl was marked as successful in SWO</Alert>
                        </Box>
                        <ResponsiveContainer width={'99%'} height={500}>
                        <BarChart
                            onClick={(e: any) => {
                                setTooltipTriggerType(tooltipTriggerType == "hover" ? "click" : "hover")
                            }}
                            data={chartData}
                            margin={{
                                top: 20,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3"/>
                            <XAxis dataKey="name" label={{
                                value: aggregateCardResponse.cardDescription.xAxis,
                                position: 'insideBottomRight',
                                offset: -10
                            }}/>
                            <YAxis type={"number"}
                                   label={{
                                       value: aggregateCardResponse.cardDescription.yAxis,
                                       angle: 0,
                                       position: 'insideTopLeft',
                                       offset: -20
                                   }}
                            />
                            <Tooltip cursor={false}
                                     trigger={tooltipTriggerType}
                                     content={(e) => {
                                         return <ChartCustomTooltip active={e.active || false} payload={e.payload}
                                                                    label={e.label}
                                                                    handleTooltipClose={() => setTooltipTriggerType("hover")}
                                                                    xToY={aggregateCardResponse?.aggregateCardData}
                                                                    showSubStage={false}
                                                                    handleSubStageLinkClick={() => {
                                                                    }}
                                                                    isXAxisPrimaryKey={true}
                                         />
                                     }}
                                     active={true}
                            />
                            <Legend/>
                            {getUniqueYAxisValues(aggregateCardResponse?.aggregateCardData || {}).map((status, index) => {
                                return <Bar dataKey={status} stackId={"sub-stage-agg"}
                                            fill={index >= CHART_COLORS.length ? getRandomChartColor() : CHART_COLORS[index]}
                                            key={status}/>
                            })}
                        </BarChart>
                    </ResponsiveContainer>
                        </> : <Alert> Sub-stage data not found</Alert>
                    }
                </>}</div>
            </Box>
        </>
    );
}