/*
 * Decided chart are color based on status
 */
import {XToY} from "@amzn/swo-unified-dashboard-service-lambda-js-client/lib/smswounifieddashboardservicelambda";
import * as lodash from "lodash";

export const findColour = (status: string): string => {
    let hexCode: string = "#67a353"
    switch (status) {
        case "SUCCESSFUL":
            hexCode = "#67a353"
            break;
        case "FAILED":
            hexCode = "#ba2e0f"
            break;
        case "RUNNING":
            hexCode = "#3184c2"
            break;
        default:
            break;
    }
    return hexCode;
}

export const getUniqueYAxisValues = (xToY: XToY) : Array<string> => {
    const yAxisValues: Array<string> = []
    Object.entries(xToY).forEach(([stage, yAxes]) => {
        Object.entries(yAxes).forEach(([status, statusValue]) => {
            yAxisValues.push(status)
        })
    })
    return lodash.uniq(yAxisValues)
}


export const convertBackendDataToChartData = (xToY: XToY): Array<object> => {
    const chartData: Array<object> = []
    const yAxisValuesUniq = getUniqueYAxisValues(xToY)

    yAxisValuesUniq.forEach(yAxisValue => {
        const statusMap: { [key: string]: number | string} = {name: yAxisValue}
        Object.entries(xToY).forEach(([stage, yAxes]) => {
            statusMap[stage] = parseInt(xToY[stage][yAxisValue].value)
        })
        chartData.push(statusMap)
    })
    return chartData;
}

export const convertBackendDataToChartDataXAxis = (xToY: XToY): Array<object> => {
    let chartData: Array<object> = []
    Object.entries(xToY).forEach(([stage, yAxis]) => {
        const temp: {[key: string]: number | string} = {
            name: stage
        }
        Object.entries(yAxis).forEach(([yAxisKey, yAxisValue]) => {
            temp[yAxisKey] = yAxisValue.value
        })
        chartData.push(temp)
    })
    return chartData;
}