import {Credentials, STS} from "aws-sdk";
import aws4 from "aws4";
import { wimAPIConfig, APIGWInterface } from "src/utils/api-gateway/mappings";
import { MidwayTokenManager } from "src/utils/auth/midway-token-manager";
import { getStage } from "src/utils/env";
import AWS from 'aws-sdk';

/**
 * Handles authentication with midway and IAM. Retrieves id_token from midway API and get temporary credetials
 * to access AWS resources. It'll sign HTTP requests with valid credentials.
 * Signature Version 4 signing process https://docs.aws.amazon.com/general/latest/gr/signature-version-4.html
 */
export class MidwayAuth {
  // Signs any HTTP requests.
  static async signRequest(request: any): Promise<any> {
    try {
      const midwayToken: string = await MidwayTokenManager.getTokenOrRedirect();
      return await this.signRequestWithMidwayToken(midwayToken, request);
    } catch (e) {
      alert(
        "Failed to authenticate with midway and IAM. Please ensure that you are logged in to midway and try again. " +
          "If this error continues please contact the swo team: sm-swo@amazon.com"
      );
      throw new Error("Authentication with midway and IAM failed: " + e);
    }
  }

  // Authenticate user using Midway, Gets IAM Credentials to access API GW and other AWS Services
  static async signIn(): Promise<any> {
    const midwayToken: string = await MidwayTokenManager.getTokenOrRedirect();
    const iamCredentials = await this.iamCredentials(midwayToken);
    const credentials = new Credentials({
      accessKeyId: iamCredentials.AccessKeyId,
      secretAccessKey: iamCredentials.SecretAccessKey,
      sessionToken: iamCredentials.SessionToken
    })

    const config = wimAPIConfig(getStage())
    AWS.config.update({
      credentials: credentials,
      region: config.region
    })
  }

  // Sign requests with midway "id_token"
  private static async signRequestWithMidwayToken(midwayToken: string, request: any): Promise<any> {
    const iamCredentials = await this.iamCredentials(midwayToken);
    const signedRequest: any = await aws4.sign(request, {
      accessKeyId: iamCredentials.AccessKeyId,
      secretAccessKey: iamCredentials.SecretAccessKey,
      sessionToken: iamCredentials.SessionToken,
    });

    // Browser will thorw error/warning if signed headers has Host and Content-Length. So removing them.
    delete signedRequest.headers["Host"];
    delete signedRequest.headers["Content-Length"];

    return signedRequest;
  }

  // Using STS.assumeRoleWithWebIdentity to get temporary credentials.
  private static async iamCredentials(midwayToken: string): Promise<STS.Types.Credentials> {
    const apiConfig: APIGWInterface = wimAPIConfig(getStage());
    const roleARN: string = apiConfig.iam;
    const sts = new AWS.STS({
      region: apiConfig.region,
    });
    const assumeRoleWithWebIdentityParams: STS.Types.AssumeRoleWithWebIdentityRequest = {
      RoleArn: roleARN,
      RoleSessionName: "SWO-UI",
      WebIdentityToken: midwayToken
    };

    const stsResponse: STS.Types.AssumeRoleWithWebIdentityResponse = await sts
      .assumeRoleWithWebIdentity(assumeRoleWithWebIdentityParams)
      .promise();
    if (stsResponse.Credentials == undefined) {
      throw new Error("STS Returned No Credentials");
    }
    return stsResponse.Credentials;
  }
}
