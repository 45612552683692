import React, {useCallback, useMemo, useState} from "react";
import {StageInstances} from "@amzn/swo-unified-dashboard-service-lambda-js-client/lib/smswounifieddashboardservicelambda";
import {ColumnLayout, Header, Container} from "@amzn/awsui-components-react-v3";
import Button from "@amzn/awsui-components-react-v3/polaris/button";
import {getDownloadUrl} from "src/utils/s3Util";
import {downloadResourceFromUrl} from "src/utils/file-download-helper";

const DOWNLOAD_DATA_KEY = 'downloadLinks';

const S3_LOCATION = 's3Location';
const S3_PREFIX = 'prefix';
const S3_KEY = 'key';

export const WorkflowInstanceDownloadLinks: React.FC<{stageInstances: StageInstances}>
    = ({stageInstances}) => {

    const [buttonDownloadState, setButtonDownloadState] = useState<Record<string, boolean>>({});
    /*
    accumulate all download links metadata.
    * */
    const downloadMetaData: Record<string, Record<string, string>> = useMemo(() => {
        return stageInstances.reduce((downloadableDataFromStages, stageInstance) => {
            const stageOutput = stageInstance.output ? JSON.parse(stageInstance.output) : {};
            const downloadDataFromStage = stageOutput[DOWNLOAD_DATA_KEY];
            if(downloadDataFromStage && Object.keys(downloadDataFromStage).length > 0){
                downloadableDataFromStages  = {...downloadableDataFromStages, ...downloadDataFromStage};
            }
            return downloadableDataFromStages
        }, {} as Record<string, Record<string, string>>)
    }, [])

    const triggerDownload = useCallback(async (bucket: string, prefix: string, key: string, fileName: string) => {
        setButtonDownloadState((prevState) => ({...prevState, [fileName]: true}));
        const link: string = await getDownloadUrl(bucket, prefix, key);
        await downloadResourceFromUrl(link, fileName);
        setButtonDownloadState((prevState) => ({...prevState, [fileName]: false}));
    }, [])

    return (
        <>
            {downloadMetaData && Object.keys(downloadMetaData).length > 0 ?
                <>
                <hr/>
                <Container
                    header={
                        <Header
                            variant="h2"
                            description="Exported data by workflow stages"
                        >
                            Download Links
                        </Header>
                }>
                <ColumnLayout columns={0}>
                    {Object.keys(downloadMetaData).map((downloadDisplayName, index) => {
                        const s3DataForDownload = downloadMetaData[downloadDisplayName];
                        const fileDownloadName = `${downloadDisplayName}-${index}`;
                            return < Button
                                data-testid={`download-stage-results-${downloadDisplayName}`}
                                iconAlign="right"
                                iconName="download"
                                variant="primary"
                                onClick={() => triggerDownload(
                                    s3DataForDownload[S3_LOCATION]
                                    , s3DataForDownload[S3_KEY]
                                    , s3DataForDownload[S3_PREFIX]
                                    , fileDownloadName
                                )}
                                key={fileDownloadName}
                                loading={buttonDownloadState[fileDownloadName]}
                            >
                                {downloadDisplayName.toLocaleUpperCase()}
                            </Button>
                        }
                    )}
                </ColumnLayout>
                </Container>
                <hr/>
                </>
             : null}
            </>
    )
}