import React, {useState} from 'react';
import {Area, AreaChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import {StackedAreaChartI} from "src/interfaces/charts";
import {ChartCustomTooltip} from "src/components/charts/tooltip";
import {convertBackendDataToChartData, findColour, getUniqueYAxisValues} from "src/components/common/charts";
import {Alert, Box} from "@amzn/awsui-components-react-v3";

/**
 * Stacked area chart to display execution count, stages and its status.
 * @param props
 * @constructor
 */
export const StackedAreaChart = (props: StackedAreaChartI) => {
    const [tooltipTriggerType, setTooltipTriggerType] = useState<'hover' | 'click'>("hover")
    const chartData: Array<object> = convertBackendDataToChartData(props.data.aggregateCardData)
    console.log("Chart data", chartData)

    function handleTooltipClose() {
        setTooltipTriggerType("hover")
    }

    return (<Box variant={"div"} data-testid={"stacked-area-chart-container"}>
            <Alert type={"info"}>Click anywhere on the chart to hold tooltip and access links.</Alert>
            <ResponsiveContainer width={'99%'} height={400}>

                <AreaChart
                    layout={"horizontal"}
                    onClick={(e: any) => {
                        setTooltipTriggerType(tooltipTriggerType == "hover" ? "click" : "hover")
                    }}
                    data={chartData}
                    margin={{
                        top: 50,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" horizontal={false}/>
                    <XAxis dataKey="name" label={{value: props.data.cardDescription.xAxis, position: 'insideBottomRight', offset: -10}}/>
                    <Legend />
                    <YAxis type={"number"}
                           label={{value: props.data.cardDescription.yAxis, angle: 0, position: 'insideTopLeft', offset: -20}}
                    />
                    <Tooltip trigger={tooltipTriggerType}
                             content={(e) => {
                                 return <ChartCustomTooltip active={e.active || false} payload={e.payload}
                                                            label={e.label}
                                                            handleTooltipClose={handleTooltipClose}
                                                            xToY={props.data.aggregateCardData}
                                                            handleSubStageLinkClick={(stageName: string) => {
                                                                props.handleSubStageLinkClick(stageName)
                                                            }}
                                                            showSubStage={true}
                                 />
                             }}
                             active={true}
                    />

                    {Object.keys(props.data.aggregateCardData).map(xAxisValue => {
                        return <Area key={xAxisValue} type={"monotone"} dataKey={xAxisValue} stackId="1" stroke={findColour(xAxisValue)}
                                     fill={findColour(xAxisValue)}
                                     fillOpacity={"0.9"}/>
                    })}
                </AreaChart>
            </ResponsiveContainer>
        </Box>
    );

}
