import React from "react";
import {useQuery} from "@tanstack/react-query";
import {SMSummary} from "src/model/SMSummary";
import {Alert, Box} from "@amzn/awsui-components-react-v3";

const getTimeDiffInHoursAndMinutes = (startTme: number, endTime: number) => {
    const millisecondsPerMinute = 1000 * 60;
    const millisecondsPerHour = millisecondsPerMinute * 60;

    const timeDiff = endTime - startTme;
    const hours = Math.floor(timeDiff / millisecondsPerHour);
    const minutes = Math.floor((timeDiff % millisecondsPerHour) / millisecondsPerMinute);
    return `${hours} hours & ${minutes} mins`;
}

export const DashboardRefreshTime = () => {
    const {data, isLoading} = useQuery({
        queryKey: ['dashboardRefreshTime'],
        /* istanbul ignore next */
        queryFn: () => new SMSummary().getDashboardRefreshTime()
    })

    return <>{!isLoading && data?.lastUpdatedTime ?
        <Box margin={{vertical: 'xs'}}>
            <Alert
                statusIconAriaLabel="Info"
                header="Data Refresh Time"
            >
        {`The Metrics/Data visible on screen was last updated ${getTimeDiffInHoursAndMinutes(data?.lastUpdatedTime, Date.now())} ago`}
            </Alert>
        </Box> : null}
    </>
}
