import React, {useEffect, useState} from "react";
import Button from "@amzn/awsui-components-react/polaris/button";
import {ATTEMPT_ID, EXECUTION_ID, SWO_ORCHESTRATOR_SERVICE_CLIENT_ID} from "src/constants/domain-tracker";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import {UpdateDelayAlert} from "src/components/common/user-alerts";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import {
    ObserveExecutionRequestObservationValueMap,
    ObserveExecutionResult
} from "@amzn/sm-workflow-orchestration-service-js-client/lib/smworkfloworchestrationservicelambda";
import {DomainTracker} from "src/model/DomainTracker";
import {getUserAlias} from "src/utils/cookie-helper";
import {EditSelectedItemsInterface} from "src/interfaces/domain-tracker";
import {SMSWOUnifiedDashboardServiceLambda} from "@amzn/swo-unified-dashboard-service-lambda-js-client";
import {v1 as uuidv1} from 'uuid';
import {KeyValuePairString} from "src/interfaces/common";
import {TableProgressBar, useProgressBar} from "src/components/common/table-progress-bar";
import {EditingFormField} from "src/components/common/editing-form-field";
import initialMetricsPublisher from 'src/metrics';
import {SwoUiMetrics} from "src/utils/swo-ui-metrics/swo-ui-metrics";
import {metricFeaturesList, metricPageNames} from "src/constants/swo-ui-metric-constants";
import {logger} from "src/utils/logger";
/**
 * Modal to update execution details. Support single and multiple columns based on visibleColumn Param.
 * @param props
 * @constructor
 */
export function BulkEditExecutionModel(props: EditSelectedItemsInterface) {
    const [obserationValues, setObserationValues] = useState<SMSWOUnifiedDashboardServiceLambda.Types.Details>({});
    const [requestObservationValueMap, setRequestObservationValueMap] = useState<ObserveExecutionRequestObservationValueMap>({});
    const [selectedItems, setSelectedItems] = useState<Array<SMSWOUnifiedDashboardServiceLambda.Types.Details>>(props.selectedItems);
    const [bulkUpdatePromises, setBulkUpdatePromises] = useState<Array<Promise<any>>>([])
    const [progressBarVisible, setProgressBarVisible] = useState<boolean>(false)
    const actionMetricsPublisher = initialMetricsPublisher.newChildActionPublisherForMethod("BulkEditExecutionDetail")
    const toastSuccessMessage: React.ReactNode = <>Updated successfully.
        <UpdateDelayAlert feature={"value"} plainText={true}/></>;
    const {
        progressPercentage,
        progressLoading,
        progressStatus,
        isCompleted,
        isCompletedSuccessfully,
        errorMessages
    } = useProgressBar(bulkUpdatePromises, toastSuccessMessage);

    function handleValueChange(columnValue: string, columnKey: string): void {
        const newValue: KeyValuePairString = {}
        newValue[columnKey] = columnValue
        const updatedItem = {
            ...obserationValues,
            ...newValue
        }
        setObserationValues(updatedItem)
        setProgressBarVisible(false)
    }

    useEffect(() => {
        initialMetricsPublisher.newChildActionPublisherForMethod("BulkEditExecutionDetail")
        setSelectedItems(props.selectedItems)
        setObserationValues({})
        setProgressBarVisible(false)
    }, [props.selectedItems, props.modalVisible])

    useEffect(() => {
        if (isCompletedSuccessfully) {
            props.handleModalVisibleChange(false)
        }
    }, [isCompletedSuccessfully])


    async function handleEditExecutionSubmitButton(event: CustomEvent<Button.ClickDetail>) {
        const observeExecutionRequestObservationValueMap: ObserveExecutionRequestObservationValueMap = {}

        Object.entries(obserationValues).forEach(([key, value]) => {
            if (value.trim() != "") observeExecutionRequestObservationValueMap[key] = value.trim();
        })

        if (Object.keys(observeExecutionRequestObservationValueMap).length == 0) {
            alert("Please update at least one field");
            return;
        }
        setProgressBarVisible(true)
        setRequestObservationValueMap(observeExecutionRequestObservationValueMap)
        logger.info(`Observing ${props.selectedItems.length} workflows`, props.selectedItems.map(selectedItem => selectedItem[EXECUTION_ID]))
        const domainTracker = new DomainTracker();
        const updateRecordPromises: Array<Promise<ObserveExecutionResult>> = selectedItems.map((selectedItem) => {
            const latestAttemptId: string = selectedItem[ATTEMPT_ID] || selectedItem[EXECUTION_ID]
            return domainTracker.observeExecution(selectedItem[EXECUTION_ID], SWO_ORCHESTRATOR_SERVICE_CLIENT_ID, latestAttemptId, uuidv1(), "COMMENTS",
                observeExecutionRequestObservationValueMap, getUserAlias())
        });


        setBulkUpdatePromises(updateRecordPromises);
        SwoUiMetrics.publishCloudWatchCountMetric(metricFeaturesList.bulkObserveWorkflowInstance, metricPageNames.workflowInstanceListView)
        actionMetricsPublisher.publishCounterMonitor("clicks", 1)
        actionMetricsPublisher.publishCounterMonitor("noOfItems", updateRecordPromises.length)
    }

    return (<Modal
            id={"bulk-edit-execution-modal"}
            onDismiss={() => props.handleModalVisibleChange(false)}
            visible={(props.modalVisible)}
            expandToFit={true}
            size="medium"
            footer={
                <span className={"awsui-util-f-r"}>
                    <Button id={"bulk-edit-execution-cancel-button"}
                            onClick={() => props.handleModalVisibleChange(false)}
                            loading={progressLoading}>Cancel</Button>
                    <Button variant="primary" onClick={handleEditExecutionSubmitButton}
                            loading={progressLoading}
                            id={"bulk-edit-execution-submit-button"}>Update</Button>
                </span>
            }
            header={<>{`Update Execution Details`}<p className={"awsui-text-secondary"}>
                Maximum 1000 characters per field.</p></>}>

            {Object.entries(props.tableMetadata).map(([key, value]) => {
                return <div key={key}> {value.allowUpdateByUser && <div>
                    <FormField label={value.uiFeatures.visibleName} id={`${key}-table-edit-form`} key={key}
                               className={`awsui-util-spacing-v-s ${props.visibleColumnKey != undefined && props.visibleColumnKey !== key && "awsui-util-d-n"}`}>
                        <EditingFormField columnDefinition={props.tableMetadata[key]} value={obserationValues[key]}
                                          handleValueChange={(key: string, value: string) => handleValueChange(key, value)}
                                          columnKey={key} modalVisible={props.modalVisible}
                        />
                    </FormField>
                </div>
                } </div>
            })}


            {((progressLoading || isCompleted) && progressBarVisible) &&

            <div data-testid={"table-progress-flash-bar"} id={"table-progress-flash-bar"}>
                <TableProgressBar progressStatus={progressStatus}
                                  progressPercentage={progressPercentage}
                                  totalRecordLength={selectedItems.length}
                                  description={`Field(s): ${JSON.stringify(Object.keys(requestObservationValueMap).join(", "))}`}
                                  title={`Update ${selectedItems.length} execution`}
                                  errorMessages={errorMessages}
                />
            </div>
            }
            <br/>
            <UpdateDelayAlert feature={"value"} plainText={false}/>
        </Modal>
    )
}

