import {
    Container,
    Header,
    SpaceBetween,
    DateRangePicker
} from "@amzn/awsui-components-react-v3";
import React from "react";
import {
    DateRangePickerProps
} from "@amzn/awsui-components-react-v3/polaris/date-range-picker/interfaces";
import {
    convertToAbsoluteRange,
    dateRangeFilterI18nStrings,
    isValidRangeFunction
} from "src/components/virtual-pipeline/virtual-pipeline-instance/dashboard-utils";
import Moment from "moment";

/**
 * Select time range from here and get the virtual pipeline instances within this time range in the dashboard.
 * @param props Get default start and end date from parent, dateChangeHandler will retrieve new date data and
 *        apply them to api calls and virtual pipeline instance overview.
 * @constructor
 */
export default function TimeRangeSelect(props: {
    dateRange: DateRangePickerProps.Value
    dateChangeHandler: Function,
    isLoading: boolean
}) {
    const [dateRange, setDateRange] = React.useState(props.dateRange)

    return (
        <Container
            id="virtual-pipeline-instance-dashboard-time-select-container"
            header={
                <Header variant="h2" description="Get virtual pipeline instances within time interval">
                    Instances Creation Date
                </Header>
            }>
            <SpaceBetween size="xxl">
                <DateRangePicker
                    id={"virtual-pipeline-instance-dashboard-time-select-date-range-picker"}
                    disabled={props.isLoading}
                    onChange={({ detail }) => {
                        setDateRange(detail.value!)
                        if (detail.value !== null) {
                            const range = convertToAbsoluteRange(detail.value);
                            props.dateChangeHandler(Moment(range.start), Moment(range.end), detail.value)
                        }
                    }}
                    value={dateRange}
                    placeholder="Filter by a date and time range"
                    relativeOptions={[
                        {
                            type: 'relative',
                            amount: 1,
                            unit: 'month',
                            key: 'one-month'
                        },
                        {
                            type: 'relative',
                            amount: 3,
                            unit: 'month',
                            key: 'three-months'
                        },
                        {
                            type: 'relative',
                            amount: 6,
                            unit: 'month',
                            key: 'six-months'
                        },
                        {
                            type: 'relative',
                            amount: 1,
                            unit: 'year',
                            key: 'one-year'
                        }
                    ]}
                    isValidRange={isValidRangeFunction}
                    i18nStrings={dateRangeFilterI18nStrings}
                />
            </SpaceBetween>
        </Container>
    )
}