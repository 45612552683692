import Alert from "@amzn/awsui-components-react/polaris/alert";
import React from "react";

interface UpdateDelayAlertInterface {
    minutes?: number
    feature: string,
    plainText: boolean
}

/**
 * Alert to notify user about the delay in record update.
 * @param props
 * @constructor
 */
export const UpdateDelayAlert = (props: UpdateDelayAlertInterface) => {
    const textMessage: string = `It may take upto ${props.minutes || 2} minutes to reflect new ${props.feature} in the Dashboard`
    return props.plainText ? <>{textMessage}</> : <Alert>{textMessage}</Alert>
}