import axios from "axios";
import * as Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import { FIFTEEN_MINUTES_IN_MILLISECONDS, MIDWAY_AUTH_USERNAME, MIDWAY_ID_TOKEN } from "src/utils/cookie-helper";
type QueryParameters = {[key: string]: string} // tslint:disable-line:readonly-keyword

/**
 * When user is signedin using midway, it'll midway token by calling midway API. This token is valid for
 * 15 minutes. We're storing it in cookie and set expire to 15 min.
 * When user is not logged it, it'll redirect to midwayportal(midway-auth.amazon.com) for authentication.
 */
export class MidwayTokenManager {
  /**
   * Get midway token used to retrieve AWS credentials. It'll try to retrieve recent tokens from midway.
   * If it fails, it'll query midway again for token. This token include username(alias, for eg, aravip),
   * we're extracting that information and store it in cookie. So that when workitem manager(or any other service)
   * need it, we can retrieve it from cookie.
   */
  static async getTokenOrRedirect(): Promise<string> {
    let midwayToken: string | undefined = Cookies.get(MIDWAY_ID_TOKEN);
    if (midwayToken == undefined) {
      midwayToken = await this.getMidwayToken();
      // "sub" key has username
      const { sub } = jwtDecode(midwayToken) as string;
      const date = new Date();
      // Sets midway token expiry to 15 min.
      date.setMilliseconds(date.getMilliseconds() + FIFTEEN_MINUTES_IN_MILLISECONDS);
      // Set cookies for token and username.
      Cookies.set(MIDWAY_ID_TOKEN, midwayToken, { expires: date });
      Cookies.set(MIDWAY_AUTH_USERNAME, sub);
    }
    return midwayToken;
  }

  // Get midway token
  private static async getMidwayToken(): Promise<string> {
    const midwayUrl: string = this.buildSSOUrl();
    const midwayResponse = await axios
      .request({ url: midwayUrl, method: "GET", withCredentials: true })
      .then((result) => {
        return result;
      })
      .catch((error) => {
        console.log(error);
        // Redirect to midway website for authentication using midway pin and yubikey
        window.location.href = this.buildRedirectUrl();
        return Promise.reject(error);
      });
    return midwayResponse.data;
  }

  private static buildRedirectUrl(): string {
    const queryParams: QueryParameters = {
      client_id: encodeURIComponent(`https://${window.location.host}`),
      redirect_uri: encodeURIComponent(`https://${window.location.host}`),
      response_type: 'id_token',
      scope: 'openid',
      nonce: this.generateNonce(),
    }

    return `https://midway-auth.amazon.com/login?next=/SSO/redirect${encodeURIComponent(this.buildQuery(queryParams))}`
  }

  private static buildSSOUrl(): string {
    const queryParams: QueryParameters = {
      response_type: 'id_token',
      client_id: encodeURIComponent(`https://${window.location.host}`),
      redirect_uri: encodeURIComponent(`https://${window.location.host}`),
      scope: 'openid',
      nonce: this.generateNonce(),
    }

    return `https://midway-auth.amazon.com/SSO${this.buildQuery(queryParams)}`
  }

  private static buildQuery(parameters: QueryParameters): string {
    return Object.keys(parameters).reduce((accumulator, key) => `${accumulator + key}=${parameters[key]}&`, '?')
  }

  // Nonce needed for midway URL.
  private static generateNonce(): string {
    return (
      Math.random()
        .toString(36)
        .substring(2, 15) +
      Math.random()
        .toString(36)
        .substring(2, 15)
    );
  }
}
