import {StatusIndicator} from "@amzn/awsui-components-react-v3";
import React from "react";
import {STATES} from "src/interfaces/workflow";
import {
    GetWorkflowListResponse
} from "@amzn/swo-unified-dashboard-service-lambda-js-client/lib/smswounifieddashboardservicelambda";
import {WorkflowList} from "src/model/WorkflowList";
import {SMSWOUnifiedDashboardServiceLambda} from "@amzn/swo-unified-dashboard-service-lambda-js-client";
import {FilterOperation} from "src/interfaces/attribute-filters";
import {Range} from "@amzn/polaris-date-time-range";
import {ElasticSearchHelper} from "src/utils/elastic-search-helper";
import {DEFAULT_ES_QUERY_FILTER} from "src/constants/elastic-search";
import {DomainTracker} from "src/model/DomainTracker";
import {getTimeDiff} from "src/utils/sm-overview-dashboard-utils";
import {getTimeRange} from "src/components/sm-overview-dashboards/workflow-detail-view/workflow-detail-view.utils";

export const WorkFlowStatusIndicator: React.FC<{status: string}> = ({status}) => {
    let workflowStatus: JSX.Element | string = status;
    switch (status.toLowerCase()) {
        case STATES.FAILED.toLowerCase() :
            workflowStatus = <StatusIndicator type={'error'}>{workflowStatus}</StatusIndicator>;
            break;
        case STATES.SUCCEEDED.toLowerCase() :
            workflowStatus = <StatusIndicator type={'success'}>{workflowStatus}</StatusIndicator>;
            break;
        case STATES.RUNNING.toLowerCase() :
            workflowStatus = <StatusIndicator type={'pending'}>{workflowStatus}</StatusIndicator>;
            break;
    }
    return <>{workflowStatus}</>
}

export const PAGE_CURSOR_FROM_KEY = 'FROM';
export const PAGE_CURSOR_SIZE_KEY = 'SIZE';
export const getNextPageCursor
    = (lastPage: GetWorkflowListResponse , allPages: GetWorkflowListResponse[]) =>
{
    let cursor;
    try{
        cursor = JSON.parse(lastPage?.pageCursor || '');
    }catch {
        console.error('page cursor is missing in API response')
    }
    return cursor && cursor[PAGE_CURSOR_FROM_KEY] >= 0 ? lastPage?.pageCursor : null;
}

export const buildExecutionViewUrl = async (filterQuery: string) => {
    const workflowList = await new WorkflowList().geWorkflowList({
        filterQuery: filterQuery,
        pageCursor: JSON.stringify({[PAGE_CURSOR_FROM_KEY]: 0, [PAGE_CURSOR_SIZE_KEY]: 150})
    })
    const workflowIds = workflowList.filteredWorkflowRecords?.reduce(
        (workflowIdString: string, workflowRecord: SMSWOUnifiedDashboardServiceLambda.WorkflowRecord)=> {
        const workflowId = workflowRecord.recipeExecutionId;
        return workflowIdString ? workflowIdString.concat(`,${workflowId}`) : `${workflowId}`;
    }, '');

    let oldestWorkflowDate = getTimeDiff(3, 'MONTH');
     workflowList.filteredWorkflowRecords?.forEach(workflowRecord => {
         if(workflowRecord.workflowCreateTimestamp && workflowRecord.workflowCreateTimestamp <= oldestWorkflowDate){
             oldestWorkflowDate = workflowRecord.workflowCreateTimestamp;
         }
     })

    const timeRangeArray =  getTimeRange(oldestWorkflowDate, 0)
        .split(':').map(time => parseInt(time)) as Range;

    const executionViewMetaData = await new DomainTracker().getTableMetadata("WORKFLOW");

    const filterAttribute = {
        attributeName: {value:'workflowId'},
        condition: FilterOperation.EQUALS,
        value: workflowIds || '',
        columnDefinition: executionViewMetaData["workflowId"],
        tableType: null
    }

    const timeFilterAttribute = {
        attributeName: {value:'workflowStartDate', label: 'workflowStartDate'},
        condition: FilterOperation.BETWEEN,
        value: timeRangeArray,
        columnDefinition: executionViewMetaData['workflowStartDate'],
        tableType: null
    }

    const sortingOptions = {
        isDescending: true,
            sortingColumn: {
            sortingField: 'workflowEndDate'
        }
    }

    return JSON.stringify(ElasticSearchHelper.convertAttributeFilterTokensToESQueryV3({
        ...DEFAULT_ES_QUERY_FILTER,
        attributeFilterItems: [filterAttribute, timeFilterAttribute],
        sortingOptions
    }) )
}
