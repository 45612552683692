import React, {useCallback, useEffect, useMemo} from 'react';
import Header from "@amzn/awsui-components-react-v3/polaris/header";
import {
    Alert,
    Box,
    Button,
    Container,
    Form as AWSUIForm,
    Link,
    Spinner
} from "@amzn/awsui-components-react-v3";
import {
    RESOURCES_DEFAULT
} from "src/constants/workflow-instance";
import {DomainTracker} from "src/model/DomainTracker";
import {getUserAlias} from "src/utils/cookie-helper";
import {AppRoutes} from "src/components/common/router-common";
import {toast} from "react-toastify";
import {WorkflowInstanceUtil} from "../../utils/workflow-instance-util";
import {TabInputForm} from "src/components/common/tab-input-form";
import {useStartScreenUtil} from "src/components/start-workflow-instance/start-screen-util-hook";
import {
    ScheduleWorkflowMetadata,
    WorkflowMetadata
} from "src/components/start-workflow-instance/start-screen-components";

/**
 * Start new workflow in Adhoc or Scheduled(later) manner with JSON schema in tabular view
 * @constructor
 */
export function StartWorkflowInstanceContent() {
    const {
        schemaLoading,
        startWFLoading,
        workflowDetails,
        enableLiveValidate,
        workflowInput,
        adhocStartRecipeExecutionsResult,
        programSelectedOption,
        subProgramOptions,
        selectedSubProgram,
        domainName,
        programOptions,
        isContinuous,
        targetWebsite,
        targetWebsiteOptions,
        workflowDefaultLoading,
        workflowUISchema,
        onTabFormDataChange,
        onTabFormDataError,
        errorText,
        itemsRef,
        onProgramChangeHandler,
        onSubProgramChangeHandler,
        onDomainChangeHandler,
        onTargetWebsiteChange,
        isScheduled,
        isScheduleDisabled,
        scheduleName,
        cronExpression,
        onScheduleNameChange,
        onCronExpressionChange,
        onScheduleStatusChange,
        resetFormValues,
        updateWorkflowLoadingStatus,
        updateAdhocStartRecipeExecutionsResult,
        onTabDataChangeHandler,
        onFormSubmitHandler
    } = useStartScreenUtil();

    // When user clicks start button, it triggers all JSON Schema start button
    function handleFormSubmit() {
        onFormSubmitHandler(() => {
            areContextInputsValid() && submitVaidatedForm()
        });
    }

    function areContextInputsValid(): boolean {
        if (isScheduled) {
            if ((scheduleName.trim() == "") || (cronExpression.trim() == "")) {
                toast.error("Schedule name and Cron expression can not be blank");
                return false;
            }
        }
        return true;
    }

    // Once schema is validate across all Tabs, trigerring start API.
    function submitVaidatedForm() {
        updateWorkflowLoadingStatus(true)

        new DomainTracker().adhocStartWorkflow(programSelectedOption.label || "",
            WorkflowInstanceUtil.getProgramRecipe(programSelectedOption.label || "", isContinuous),
            getUserAlias(),
            programSelectedOption.label || "",
            WorkflowInstanceUtil.trimObject(workflowInput),
            RESOURCES_DEFAULT,
            isScheduled ? scheduleName.trim() : selectedSubProgram?.value,
            isScheduled ? scheduleName.trim() : undefined,
            isScheduled ? `cron(${cronExpression.trim()})` : undefined,
            WorkflowInstanceUtil.isDomainFieldVisible(programSelectedOption) ? domainName : undefined,
            targetWebsite.value)
            .then((resp) => {
                if (isScheduled) {
                    toast.success("Successfully Scheduled Workflow Instances")
                    updateAdhocStartRecipeExecutionsResult({successfulRequests: {}})
                } else {
                    updateAdhocStartRecipeExecutionsResult(resp)

                }
                if (Object.keys(resp.failedRequests || {}).length > 0) {
                    toast.error(JSON.stringify(resp.failedRequests))
                }

                resetFormValues()
                window.scrollTo({top: 0, behavior: 'smooth'})
            })
            .catch(err => {
                try {
                    toast.error(err.message)
                } catch (e) {
                    toast.error(JSON.stringify(err))
                }

            })
            .then(() => {
                updateWorkflowLoadingStatus(false)
            })
    }

    /**
     * Callback function is fired on submit action of forms.
     * the start screen contains multiple forms, ie every stage input is mapped to an individual form.
     * When user clicks on submit button (start workflow instance button), all forms are submitted, post which form
     * data is sent to backend.
     */
    const onTabFormDataSubmit = useCallback((tabId: string, tabIndex: number) => {
        onTabDataChangeHandler(tabId, tabIndex, () => {
            areContextInputsValid() && submitVaidatedForm()
        })
    }, [onTabDataChangeHandler, areContextInputsValid, submitVaidatedForm])

    return <>
        <Box padding={{bottom: "l"}}>
            <Alert visible={Object.entries(adhocStartRecipeExecutionsResult.successfulRequests).length > 0}
                   data-testid={"start-workflow-success-alert"}
                   type={"success"}
                   dismissible={true}
                   onDismiss={() => updateAdhocStartRecipeExecutionsResult({successfulRequests: {}})}>
                {Object.entries(adhocStartRecipeExecutionsResult.successfulRequests).map(([key, value]) => {
                    return <div key={key}>Successfully started Workflow Instance. View Instance detail <Link
                        href={AppRoutes.executionDetail(value)}> {value} </Link></div>
                })}
            </Alert>
        </Box>
        <AWSUIForm
            errorText={errorText}
            actions={
                <Button variant="primary"
                        data-testid={"start-workflow-instance-submit-button"}
                        disabled={schemaLoading || programSelectedOption.value == ""}
                        loading={startWFLoading}
                        onClick={() => handleFormSubmit()}>Start Workflow Instance</Button>

            }
        >

            <Container header={<Header variant="h2">Start Workflow Instance</Header>}>
                <WorkflowMetadata
                    programSelectedOption={programSelectedOption}
                    programOptions={programOptions}
                    selectedSubProgram={selectedSubProgram}
                    subProgramOptions={subProgramOptions}
                    targetWebsite={targetWebsite}
                    targetWebsiteOptions={targetWebsiteOptions}
                    domainName={domainName}
                    isScheduled={isScheduled}
                    startWFLoading={startWFLoading}
                    schemaLoading={schemaLoading}
                    isScheduleDisabled={isScheduleDisabled}
                    onProgramChangeHandler={onProgramChangeHandler}
                    onSubProgramChangeHandler={onSubProgramChangeHandler}
                    onDomainChange={onDomainChangeHandler}
                    onTargetWebsiteChange={onTargetWebsiteChange}
                    onScheduleStatusChange={onScheduleStatusChange}
                />
                <ScheduleWorkflowMetadata
                    isScheduled={isScheduled}
                    scheduleName={scheduleName}
                    cronExpression={cronExpression}
                    onScheduleNameChange={onScheduleNameChange}
                    onCronExpressionChange={onCronExpressionChange}
                />
                <Container
                    data-testid={"workflow-input-rjsf-form-field"}
                    header={<Header variant={"h3"}>Workflow Input</Header>}
                >
                    {programSelectedOption.value == "" ?
                        <Alert>Please select program to provide workflow input</Alert> :
                        <>
                            {schemaLoading ? <><Spinner size={"big"}/> Loading Workflow input form... </> :
                                <TabInputForm
                                    stageInstanceInputSchemaMap={workflowDetails.stageInstanceInputSchemaMap || {}}
                                    formInputData={workflowInput}
                                    onTabFormChange={onTabFormDataChange}
                                    onTabFormSubmit={onTabFormDataSubmit}
                                    onTabFormError={onTabFormDataError}
                                    ref={itemsRef}
                                    enableLiveValidate={enableLiveValidate}
                                    stageInstanceUISchemaMap={workflowUISchema || {}}
                                    stageRenderingOrder={workflowDetails.stages || []}
                                />
                            }
                        </>
                    }
                </Container>
            </Container>
        </AWSUIForm>
    </>
}
