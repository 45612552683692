import {getStage} from "src/utils/env";
import {SMSWOUnifiedDashboardServiceLambda} from "@amzn/swo-unified-dashboard-service-lambda-js-client";
import {unifiedDashboardAPIConfig} from "src/utils/api-gateway/swo-ud-api-mappings";
import {
    AggregateCard,
    GetAggregateCardRequest,
    GetAggregateCardResponse,
    RedirectResponse
} from "@amzn/swo-unified-dashboard-service-lambda-js-client/lib/smswounifieddashboardservicelambda";
import {getUserAlias} from "src/utils/cookie-helper";
import {APPLICATION_NAME} from "src/utils/app-constants";

/**
 * Execution summary
 */
export class ExecutionSummary {

    private unifiedDashboardServiceLambda: SMSWOUnifiedDashboardServiceLambda;

    constructor() {

        const unifiedDashboardConfig = unifiedDashboardAPIConfig(getStage())
        this.unifiedDashboardServiceLambda = new SMSWOUnifiedDashboardServiceLambda({
            endpoint: `https://${unifiedDashboardConfig.endpoint}/${unifiedDashboardConfig.stage}`,
            region: unifiedDashboardConfig.region
        })
    }

    // Fetches Aggregate card details
    async getSummaryDetails(cardName: AggregateCard, queryString: string): Promise<GetAggregateCardResponse> {
        const request: GetAggregateCardRequest = {
            name: cardName,
            queryString: queryString,
            requesterSystemType: APPLICATION_NAME,
            requesterUserName: getUserAlias()
        }
        const aggregateCardResp = this.unifiedDashboardServiceLambda.getAggregateCard(request).promise()
        return Promise.resolve(aggregateCardResp);
    }

    /*
     * Get redirection context.
     */
    async getRedirectURL(queryString: string, redirectionContext: string): Promise<RedirectResponse> {
        const redirectUrl = this.unifiedDashboardServiceLambda.redirect({
            queryString: queryString,
            redirectionContext: redirectionContext,
            requesterSystemType: APPLICATION_NAME,
            requesterUserName: getUserAlias()
        }).promise();
        return Promise.resolve(redirectUrl);
    }
}
