import React from "react";
import {AxiosError} from "axios";
import Header from "@amzn/awsui-components-react-v3/polaris/header";
import {AjvError} from "@rjsf/core";

interface ErrorMessageHelperI {
    error: AxiosError<any>
}

/**
 * This will format APIGW error response and display proper error message.
 * @param props - APIGW error response
 * @constructor
 */
export function ErrorMessageHelper(props: ErrorMessageHelperI) {
    return <>
        {props.error.message}
    </>
}

/**
 *
 * Usage: component aggregating user input errors for form fields.
 */
export const UserInputError: React.FC<{errorListMap: {[key: string]: AjvError[]}}> =  ({errorListMap}) => {
    return (
    <>
        {Object.entries(errorListMap).map(([key, value1],) => {
            return <div><Header variant={"h3"}>{key}</Header>
                <ul> {value1.map((errStack, index2) => {
                    return <li key={index2}>{errStack.stack}</li>
                })}
                </ul>

            </div>
        })}
    </>
    )
}