import {PROGRAM_SUBPROGRAM_MAP} from "src/constants/workflow-instance";
import {sortArrayAlphabetically} from "src/utils/array-helper";

export const mergeFiltersToFilterQuery = (filterQuery:string, newFilterParams: Record<string, string>) => {
    return filterQuery ? JSON.stringify(mergeFilters(JSON.parse(filterQuery), newFilterParams)) : JSON.stringify({});
}

export const mergeFilters = (filter: Record<string, string>, newFilterParams: Record<string, string>) => {
    return {...filter, ...newFilterParams};
}

export const getSubProgramList = () => {
    return sortArrayAlphabetically(Object.values(PROGRAM_SUBPROGRAM_MAP).reduce((allSubPrograms: string[], programSpecificSubPrograms: Array<string>) => {
        return Array.from(new Set([...allSubPrograms, ...programSpecificSubPrograms]))
    }, []));
}

export const buildFilterOptionsForProperty = (propertyKey: string, propertyValues: string[]) => {
    return propertyValues.map((propertyValue) => ({propertyKey: propertyKey, value: propertyValue}))
}
