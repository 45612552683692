import React, {useState} from 'react';
import Button from '@amzn/awsui-components-react/polaris/button';
import {SelectedItemsInterface} from "src/interfaces/domain-tracker";
import ColumnLayout from "@amzn/awsui-components-react/polaris/column-layout";
import {formatColumnValue} from "src/utils/table-helper";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import {ExecutionQuickOverview} from "src/constants/domain-tracker";

/**
 * Shows all columns for a single record.
 * @param props
 * @constructor
 */
export function ExecutionQuickOverviewModal(props: SelectedItemsInterface) {
    const [showQuickOverviewModal, setShowQuickOverviewModal] = useState<boolean>(false);
    const selectedItem = props.selectedItems[0] || {}
    return <>
        <Modal
            id={"execution-quick-overview-modal"}
            onDismiss={() => setShowQuickOverviewModal(false)}
            visible={showQuickOverviewModal}
            size="large"
            footer={
                <span className={"awsui-util-f-r"}>
                    <Button id={"execution-quick-overview-modal-submit-button"} variant="primary"
                            onClick={() => setShowQuickOverviewModal(false)}>Close</Button>
                </span>
            }
            header={ExecutionQuickOverview.header}
        >
            <ColumnLayout columns={3} variant={"text-grid"}>
                <div data-awsui-column-layout-root="true">
                    {Object.entries(props.tableMetadata).map(([columnKey, colDefinition]) => {
                        return <div key={columnKey}>
                            <div className="awsui-util-label">{colDefinition.uiFeatures.visibleName}</div>
                            <div>{formatColumnValue(selectedItem[columnKey], colDefinition.dataType)}</div>
                        </div>
                    })}
                </div>
            </ColumnLayout>
        </Modal>

        <Button text={ExecutionQuickOverview.header}
                id={"execution-quick-overview-modal-button"}
                onClick={() => setShowQuickOverviewModal(true)}
                disabled={props.selectedItems.length !== 1}
        />
    </>
}