import React, {useEffect, useState} from "react";
import cytoscape, {ElementDefinition} from "cytoscape";
import dagre from "cytoscape-dagre"
import {PipelineExtractor} from "src/components/virtual-pipeline/virtual-pipeline-visualization/pipeline-extractor";
import {Button} from "@amzn/awsui-components-react-v3";
import {DetailedVirtualPipelineNode} from "@amzn/sm-virtual-pipeline-lambda-js-client/lib/smvirtualpipelinelambda";
cytoscape.use(dagre)

/**
 * Create virtual pipeline visualization graph with hooks.
 * @param props
 * @constructor
 */
export function VirtualPipelineVisualizationGraph(props: {pipelineInfo: DetailedVirtualPipelineNode}) {
    const [virtualPipeline, setVirtualPipeline] = useState<{
        [key: number]: ElementDefinition[],
        [key: string]: ElementDefinition[]
    }>({})
    const [refresh, setRefresh] = useState(false)
    const [cyStyle, setCyStyle] = useState(
        {
            height: '600px',
            width: '100%'
        })

    useEffect(() => {
        setRefresh(false)
        const extractResult = PipelineExtractor(props.pipelineInfo);
        setVirtualPipeline(extractResult["notDisplayedElems"])
        setCyStyle({
            width: "100%",
            height: Math.max((extractResult.numberOfNodes * 100), 600) + "px"
        })
        let cy = cytoscape(
            {
                container: document.getElementById('cy'),

                boxSelectionEnabled: false,
                autounselectify: true,

                style: [{
                    selector: 'node',
                    css: {
                        'height': 80,
                        'width': 80,
                        'background-fit': 'cover',
                        'border-color': '#000',
                        'border-width': 3,
                        'border-opacity': 0.5,
                        'content': 'data(label)',
                        'text-valign': 'center',
                    }
                }, {
                    selector: 'edge',
                    css: {
                        'width': 6,
                        'target-arrow-shape': 'triangle',
                        'line-color': '#ffaaaa',
                        'target-arrow-color': '#ffaaaa',
                        'curve-style': 'bezier'
                    }
                }],
                layout: {
                    name: 'cose',
                    fit: false
                }
            });

        cy.add(extractResult["returnedElems"])
        const layout = cy.makeLayout({
            name: 'dagre',
            rankDir: 'BT',
            nodeDimensionsIncludeLabels: true,
            fit: true, // whether to fit to viewport
            randomize: true,
            animate: false
        });
        layout.run()

        cy.on('tap', 'node', function (node: { target: { id: () => string | number; }; }) {
            //if the node's children have been hidden
            //getting the element at 1 because the element at 0 is the node itself
            //want to check if its children are hidden
            const hiddenElems = virtualPipeline[node.target.id()]
            // @ts-ignore
            if (this._private.data["isExpanded"] === "false") {
                // @ts-ignore
                this._private.data["isExpanded"] = "true"
                cy.add(hiddenElems)
            } else {
                // @ts-ignore
                this._private.data["isExpanded"] = "false"
                hiddenElems.map((/** @type {{ [x: string]: { [x: string]: string; }; }} */ elem) => {
                    cy.remove('node[id = "' + elem["data"]["id"] + '"]')
                })
            }
            const layout = cy.makeLayout({
                name: 'dagre',
                rankDir: 'BT',
                nodeDimensionsIncludeLabels: true,
                fit: true, // whether to fit to viewport
                randomize: true,
                animate: false
            });
            layout.run();
        });
    }, [props.pipelineInfo, refresh])

    const legendElements = [
        {
            data: {
                id: "datasetNode",
                label: ".  Dataset Node"
            },
            style: {
                'background-color': '#3ec99a'
            }
        },
        {
            data: {
                source: "targetDatasetNode",
                target: "datasetNode"
            },
            style: {
                'opacity': '0'
            }
        },
        {
            data: {
                id: "targetDatasetNode",
                label: ".  Target Dataset Node"
            },
            style: {
                'background-color': '#ff165d'
            }
        },
        {
            data: {
                source: "operationNode",
                target: "targetDatasetNode"
            },
            style: {
                'opacity': '0'
            }
        },
        {
            data: {
                id: "operationNode",
                label: ".  Operation Node"
            },
            style: {
                'background-color': '#3ec1d3',
                'shape': 'round-triangle'
            }
        }
    ]

    useEffect(() => {
        setRefresh(true) // Set to true to trigger a refresh to bypass error in virtual pipeline graph
        let cy = cytoscape(
            {
                container: document.getElementById('cylegend'),

                boxSelectionEnabled: false,
                autounselectify: true,

                style: [{
                    selector: 'node',
                    css: {
                        'height': 40,
                        'width': 40,
                        'background-fit': 'cover',
                        'border-color': '#000',
                        'border-width': 3,
                        'border-opacity': 0.5,
                        'content': 'data(label)',
                        'text-valign': 'center',
                        'text-halign': 'right',
                    }
                }],
                layout: {
                    name: 'cose',
                    fit: false
                }
            });

        cy.add(legendElements)
        const layout = cy.makeLayout({
            name: 'dagre',
            rankDir: 'BT',
            spacingFactor: 0.8,
            nodeDimensionsIncludeLabels: true,
            fit: true, // whether to fit to viewport
            padding: 10, // fit padding
            randomize: false,
            animate: false
        });
        layout.run();
    }, [refresh])

    return (
        <>
            <div>
                <div style={cyStyle} id="cy"/>
                <Button id={"refresh-visualization-canvas-button"} onClick={() => setRefresh(false)}>refresh</Button>
            </div>
            <div style={{
                height: '21%',
                aspectRatio: "2/2.5",
                top: '150px',
                right: '100px',
                opacity: '0.7',
                background: '#eeeeee',
                position: "absolute"
            }}
            >
                <div style={{
                    height: '100%',
                    width: '100%'
                }} id="cylegend"/>
                <div
                    style={{
                        width: '100%',
                        height: '100%',
                        top: '0px',
                        right: '0px',
                        opacity: '0',
                        background: '#eeeeee',
                        position: "absolute"
                    }}
                >
                </div>
            </div>
        </>
    )
}