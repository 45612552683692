import React, {useCallback, useMemo, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {useQuery} from "@tanstack/react-query";
import {useCollection} from "@amzn/awsui-collection-hooks";
import {TableProps} from "@amzn/awsui-components-react-v3/polaris/table/interfaces";
import {Box, Header, Table} from "@amzn/awsui-components-react-v3";
import {
    getFailureSummaryColumnDefinition, isStageRecord
} from "src/components/sm-overview-dashboards/workflow-detail-view/workflow-failure-column-config";
import {SMOverviewAppLayout} from "src/components/sm-overview-dashboards/sm-overview-app-layout";
import {
    WorkflowFailureDistributionView
} from "src/components/sm-overview-dashboards/workflow-detail-view/workflow-failure-distribution-view";
import {metricFeaturesList, metricPageNames} from "src/constants/swo-ui-metric-constants";
import {SMSummary} from "src/model/SMSummary";
import {
    getErrorColumnTotals,
    getFailureViewTimeDistributionRanges
} from "src/components/sm-overview-dashboards/workflow-detail-view/workflow-detail-view.utils";
import {AppLayoutProps} from "@amzn/awsui-components-react-v3/polaris/app-layout";
import {UserActionsInfoBar} from "src/components/sm-overview-dashboards/common/UserActionsInfoBar";
import {QueryParams} from "src/components/sm-overview-dashboards/common/query-params";
import {DashboardRefreshTime} from "src/components/sm-overview-dashboards/common/dashboard-refresh-time";
import {FilterInput} from "src/components/sm-overview-dashboards/workflow-list-view/filters/filterInput";

const PAGE_SIZE = 15;

export const ProgramDetailFailureSummaryView = (
    {openSplitPanel}: {openSplitPanel?: (size?: number, preference?: AppLayoutProps.SplitPanelPreferences) => void}) =>
{
    const location = useLocation();
    const history = useHistory()
    const params = new URLSearchParams(location.search);
    const program = params.get(QueryParams.PROGRAM) || '';
    const filterQuery = params.get(QueryParams.FILTER_QUERY_KEY) || JSON.stringify({});
    const {data: programFailureDetailSummary, isLoading, error} =
        /* istanbul ignore next */
        useQuery({
            queryKey: ['workflowFailureDetailSummary', filterQuery],
            queryFn: () => new SMSummary().getProgramFailureDetailData({
                filterQuery: filterQuery,
                timeRangeBucket: getFailureViewTimeDistributionRanges()
            })
        });

    const {items: tableItems} = useCollection(
        programFailureDetailSummary?.failureDetails || [], {
            pagination: { pageSize: PAGE_SIZE }
        }
    )

    const errorTotalMap = useMemo(() =>
            getErrorColumnTotals(programFailureDetailSummary?.failureDetails || [])
        , [programFailureDetailSummary])

    const [
        selectedItems,
        setSelectedItems
    ] = useState<any[]>([]);

    /* istanbul ignore next */
    const onSelectionChangeHandler = useCallback(
        ({detail}: {detail: TableProps.SelectionChangeDetail<any>}
        ) => {
            params.delete(QueryParams.STAGE);
            params.append(QueryParams.STAGE, detail.selectedItems[0].stage);
            setSelectedItems(detail.selectedItems);
            history.replace({search: params.toString()})
            openSplitPanel && openSplitPanel(700, {position: 'bottom'});;
        }, [openSplitPanel, setSelectedItems, params, history])

    return (
        <>
            <Box padding={{vertical: "s", horizontal: "xl"}}>
                <UserActionsInfoBar view={metricFeaturesList.programDetailsFailure} hasError={!!error}/>
                <DashboardRefreshTime />
            </Box>
            <Box padding={{vertical: "s", horizontal: "xl"}}>
                <FilterInput />
            </Box>
            <Box padding={{vertical: 'l', horizontal: 'l'}}>
                <Table
                    data-testid={'[data-testid="workflow-failure-detail"]'}
                    items={tableItems}
                    columnDefinitions={getFailureSummaryColumnDefinition(filterQuery, errorTotalMap)}
                    onSelectionChange={onSelectionChangeHandler}
                    selectedItems={selectedItems}
                    isItemDisabled={item => !isStageRecord(item.stage)}
                    wrapLines
                    selectionType='single'
                    loading={isLoading}
                    loadingText='Loading Failure Summary'
                    empty={
                        <Box textAlign='center' color='inherit'>
                            <b>No resources</b>
                            <Box
                                padding={{ bottom: "s" }}
                                variant='p'
                                color='inherit'
                            >
                                No Failures to Display.
                            </Box>
                        </Box>
                    }
                    header = {
                        <Header
                            variant={'h3'}
                        >
                            Failed Workflows
                        </Header>
                    }
                />
            </Box>
        </>
    )
}

export const ProgramDetailFailure = () => <SMOverviewAppLayout
    content={<ProgramDetailFailureSummaryView />}
    splitPanelContent={<WorkflowFailureDistributionView />}
    addSplitPanel={true}
    metricName={metricFeaturesList.programDetailsFailure}
    page={metricPageNames.programDetails}
    splitPanelHeader={'Failure Distribution'}
/>
