import React from "react";
import {Link as LinkRouter} from "react-router-dom";
import {AppRoutes} from "src/components/common/router-common";
import {Link} from "@amzn/awsui-components-react-v3";
import {QueryParams} from "src/components/sm-overview-dashboards/common/query-params";

type FilterLinkProps = {
    displayText: string|number|React.ReactNode;
    payload: Record<string, string|number|boolean|undefined>;
    disabled?: boolean;
}

export const FILTER_QUERY_KEY = 'filterQuery';

export const buildQueryParams = (payload: Record<string, string|number|boolean|undefined>) => {
    const filteredParams = Object.entries(payload)
            .reduce((acc: Record<string, string|number|boolean>, [payloadKey, payloadValue]) => {
        if (payloadValue !== null && payloadValue !== undefined && payloadValue !== '') {
            acc[payloadKey] = payloadValue;
        }
        return acc;
    }, {});
    return JSON.stringify(filteredParams);
}

export const FilterLink: React.FC<FilterLinkProps> =
    ({displayText, payload, disabled}) => {
        const queryParamsString = buildQueryParams(payload);
        return <>
            {disabled ? <>{displayText}</> : <LinkRouter to={`${AppRoutes.workflowList}?${QueryParams.FILTER_QUERY_KEY}=${encodeURIComponent(queryParamsString)}`}>
                <Link external>{displayText}</Link>
            </LinkRouter>}
        </>
    }
