import Moment from "moment";

export const TEAM: Array<string> = ["SM_SP", "SGM"]

const yesterday = 1614959088

/**
 * Downloaded virtual pipeline metadata.
 */
export const VIRTUAL_PIPELINE_SAMPLE = [
    {
        "comments": "Product Head Selection: Smpear-SMProgramItem-hybrid -> zenithProductPredictionFeatureset -> zenithProductPredictions -> zenithMergedItemPredictions",
        "creationDate": yesterday,
        "lastUpdateDate": 1615490688.988,
        "latestInstanceOfTargetDataset": 400,
        "pipelineInformation": {
            "datasetId": "zenithMergedItemPredictions",
            "parents": [
                {
                    "datasetId": "zenithProductPredictions",
                    "parents": [
                        {
                            "datasetId": "zenithProductPredictionFeatureset",
                            "parents": [
                                {
                                    "datasetId": "Smpear-SMProgramItem-hybrid",
                                    "parents": [],
                                    "team": "SM_SGM"
                                }
                            ],
                            "team": "SM_SP"
                        }
                    ],
                    "team": "SA"
                }
            ],
            "team": "SM_SP"
        },
        "team": "SM_SP",
        "username": "sm-sp",
        "vpId": "48714a9d-e80b-41e4-91e0-d9c629a6187a",
        "vpName": "Product Head Selection"
    },
    {
        "comments": "Product Head Selection: Smpear-SMProgramItem-hybrid -> zenithProductPredictionFeatureset -> zenithProductPredictions -> zenithMergedItemPredictions",
        "creationDate": yesterday,
        "lastUpdateDate": 1615490818.178,
        "latestInstanceOfTargetDataset": 400,
        "pipelineInformation": {
            "datasetId": "zenithMergedItemPredictions",
            "parents": [
                {
                    "datasetId": "zenithProductPredictions",
                    "parents": [
                        {
                            "datasetId": "zenithProductPredictionFeatureset",
                            "parents": [
                                {
                                    "datasetId": "Smpear-SMProgramItem-hybrid",
                                    "parents": [],
                                    "team": "SM_SGM"
                                }
                            ],
                            "team": "SM_SP"
                        }
                    ],
                    "team": "SA"
                }
            ],
            "team": "SM_SP"
        },
        "team": "SM_SP",
        "username": "sm-sp",
        "vpId": "416b2903-ae46-4db1-a809-cfe5216cd557",
        "vpName": "Product Head Selection"
    },
    {
        "comments": "Product Head Selection: Smpear-SMProgramItem-hybrid -> zenithProductPredictionFeatureset -> zenithProductPredictions -> zenithMergedItemPredictions",
        "creationDate": yesterday,
        "lastUpdateDate": 1615490827.424,
        "latestInstanceOfTargetDataset": 400,
        "pipelineInformation": {
            "datasetId": "zenithMergedItemPredictions",
            "parents": [
                {
                    "datasetId": "zenithProductPredictions",
                    "parents": [
                        {
                            "datasetId": "zenithProductPredictionFeatureset",
                            "parents": [
                                {
                                    "datasetId": "Smpear-SMProgramItem-hybrid",
                                    "parents": [],
                                    "team": "SM_SGM"
                                }
                            ],
                            "team": "SM_SP"
                        }
                    ],
                    "team": "SA"
                }
            ],
            "team": "SM_SP"
        },
        "team": "SM_SP",
        "username": "sm-sp",
        "vpId": "d6aaf009-d3d0-4e51-b5c1-ba9d3d37280a",
        "vpName": "Product Head Selection"
    },
    {
        "comments": "SMPEAR: Smpear-SMProgramItem-hybrid",
        "creationDate": yesterday,
        "lastUpdateDate": 1615490710.93,
        "latestInstanceOfTargetDataset": 733,
        "pipelineInformation": {
            "datasetId": "Smpear-SMProgramItem-hybrid",
            "parents": [],
            "team": "SM_SGM"
        },
        "team": "SM_SP",
        "username": "sm-sp",
        "vpId": "4bb2838d-daee-49c9-97ae-1dcab72eb941",
        "vpName": "USHAH: SMPEAR"
    },
    {
        "comments": "SMPEAR: Smpear-SMProgramItem-hybrid",
        "creationDate": yesterday,
        "lastUpdateDate": 1615490786,
        "latestInstanceOfTargetDataset": 733,
        "pipelineInformation": {
            "datasetId": "Smpear-SMProgramItem-hybrid",
            "parents": [],
            "team": "SM_SGM"
        },
        "team": "SM_SP",
        "username": "sm-sp",
        "vpId": "24708504-07d2-4537-8eea-1709aa29eebd",
        "vpName": "USHAH: SMPEAR"
    },
    {
        "comments": "Ushah Product Head Selection: Smpear-SMProgramItem-hybrid -> zenithProductPredictionFeatureset -> zenithProductPredictions -> zenithMergedItemPredictions",
        "creationDate": yesterday,
        "lastUpdateDate": 1615490698.903,
        "latestInstanceOfTargetDataset": 400,
        "pipelineInformation": {
            "datasetId": "zenithMergedItemPredictions",
            "parents": [
                {
                    "datasetId": "zenithProductPredictions",
                    "parents": [
                        {
                            "datasetId": "zenithProductPredictionFeatureset",
                            "parents": [
                                {
                                    "datasetId": "Smpear-SMProgramItem-hybrid",
                                    "parents": [],
                                    "team": "SM_SGM"
                                }
                            ],
                            "team": "SM_SP"
                        }
                    ],
                    "team": "SA"
                }
            ],
            "team": "SM_SP"
        },
        "team": "SM_SP",
        "username": "sm-sp",
        "vpId": "8e3abcbd-b77c-4328-b380-360df5e05991",
        "vpName": "Ushah Test VP"
    },
    {
        "comments": "uecarmecbsnapshot -> uecumssnapshot",
        "creationDate": yesterday,
        "lastUpdateDate": 1615490666.423,
        "latestInstanceOfTargetDataset": 1074,
        "pipelineInformation": {
            "datasetId": "uecumssnapshot",
            "parents": [
                {
                    "datasetId": "uecarmecbsnapshot",
                    "parents": [],
                    "team": "UEC"
                }
            ],
            "team": "UEC"
        },
        "team": "SM_SP",
        "username": "ushah",
        "vpId": "809a2932-57fd-4ff0-bb3a-876d32831ea7",
        "vpName": "arm-ums_by_ushah"
    },
    {
        "comments": "ARM: armresults",
        "creationDate": yesterday,
        "lastUpdateDate": 1615490678.863,
        "latestInstanceOfTargetDataset": 676,
        "pipelineInformation": {
            "datasetId": "armresults",
            "parents": [],
            "team": "SM_AIM"
        },
        "team": "SM_SP",
        "username": "ushah",
        "vpId": "db79636d-3b4c-49bb-809f-cf22234a1954",
        "vpName": "arm_by_ushah"
    },
    {
        "comments": "Deduper: airDeduperVertexSnapshot",
        "creationDate": yesterday,
        "lastUpdateDate": 1615490781.264,
        "latestInstanceOfTargetDataset": 656,
        "pipelineInformation": {
            "datasetId": "airDeduperVertexSnapshot",
            "parents": [],
            "team": "SM_AIM"
        },
        "team": "SM_SP",
        "username": "ushah",
        "vpId": "1c77ab64-db08-4ed5-821e-961624b1d2b6",
        "vpName": "deduper_by_ushah"
    },
    {
        "comments": "uecpartitionedsnapshot -> armresults",
        "creationDate": yesterday,
        "lastUpdateDate": 1615490833.297,
        "latestInstanceOfTargetDataset": 676,
        "pipelineInformation": {
            "datasetId": "armresults",
            "parents": [
                {
                    "datasetId": "uecpartitionedsnapshot",
                    "parents": [],
                    "team": "UEC"
                }
            ],
            "team": "SM_AIM"
        },
        "team": "SM_SP",
        "username": "ushah",
        "vpId": "61084ff6-2b57-4f8a-911a-3cf3987542f3",
        "vpName": "epc-arm_by_ushah"
    },
    {
        "comments": "uecpartitionedsnapshot -> airDeduperVertexSnapshot",
        "creationDate": yesterday,
        "lastUpdateDate": 1615490742.31,
        "latestInstanceOfTargetDataset": 656,
        "pipelineInformation": {
            "datasetId": "airDeduperVertexSnapshot",
            "parents": [
                {
                    "datasetId": "uecpartitionedsnapshot",
                    "parents": [],
                    "team": "UEC"
                }
            ],
            "team": "SM_AIM"
        },
        "team": "SM_SP",
        "username": "ushah",
        "vpId": "f77c8759-d571-4342-97d0-52237b5c3d4a",
        "vpName": "epc-deduper_by_ushah"
    },
    {
        "comments": "uecpartitionedsnapshot -> Smpear-SMProgramItem-hybrid -> zenithProductPredictionFeatureset -> zenithProductPredictions -> zenithMergedItemPredictions -> SgmProductWorkItems",
        "creationDate": yesterday,
        "lastUpdateDate": 1615490850.832,
        "latestInstanceOfTargetDataset": 273,
        "pipelineInformation": {
            "datasetId": "SgmProductWorkItems",
            "parents": [
                {
                    "datasetId": "zenithMergedItemPredictions",
                    "parents": [
                        {
                            "datasetId": "zenithProductPredictions",
                            "parents": [
                                {
                                    "datasetId": "zenithProductPredictionFeatureset",
                                    "parents": [
                                        {
                                            "datasetId": "Smpear-SMProgramItem-hybrid",
                                            "parents": [
                                                {
                                                    "datasetId": "uecpartitionedsnapshot",
                                                    "parents": [],
                                                    "team": "UEC"
                                                }
                                            ],
                                            "team": "SM_SGM"
                                        }
                                    ],
                                    "team": "SM_SP"
                                }
                            ],
                            "team": "SA"
                        }
                    ],
                    "team": "SM_SP"
                }
            ],
            "team": "SM_SGM"
        },
        "team": "SM_SP",
        "username": "ushah",
        "vpId": "71f7eeb6-b8a2-4db8-ad98-6d50f1dc7a15",
        "vpName": "epc-smpear-pp-sgm_by_ushah"
    },
    {
        "comments": "uecpartitionedsnapshot -> Smpear-SMProgramItem-hybrid",
        "creationDate": yesterday,
        "lastUpdateDate": 1615490776.499,
        "latestInstanceOfTargetDataset": 733,
        "pipelineInformation": {
            "datasetId": "Smpear-SMProgramItem-hybrid",
            "parents": [
                {
                    "datasetId": "uecpartitionedsnapshot",
                    "parents": [],
                    "team": "UEC"
                }
            ],
            "team": "SM_SGM"
        },
        "team": "SM_SP",
        "username": "ushah",
        "vpId": "464191e2-ac2e-431b-a719-c2b2d4814b96",
        "vpName": "epc-smpear_by_ushah"
    },
    {
        "comments": "uecpartitionedsnapshot -> UBCBrandInferenceEPCToUBI -> Smpear-SMProgramItem-hybrid -> zenithProductPredictionFeatureset -> zenithProductPredictions -> zenithMergedItemPredictions -> SgmProductWorkItems",
        "creationDate": yesterday,
        "lastUpdateDate": 1615490735.645,
        "latestInstanceOfTargetDataset": 273,
        "pipelineInformation": {
            "datasetId": "SgmProductWorkItems",
            "parents": [
                {
                    "datasetId": "zenithMergedItemPredictions",
                    "parents": [
                        {
                            "datasetId": "zenithProductPredictions",
                            "parents": [
                                {
                                    "datasetId": "zenithProductPredictionFeatureset",
                                    "parents": [
                                        {
                                            "datasetId": "Smpear-SMProgramItem-hybrid",
                                            "parents": [
                                                {
                                                    "datasetId": "UBCBrandInferenceEPCToUBI",
                                                    "parents": [
                                                        {
                                                            "datasetId": "uecpartitionedsnapshot",
                                                            "parents": [],
                                                            "team": "UEC"
                                                        }
                                                    ],
                                                    "team": "SM_UBC"
                                                }
                                            ],
                                            "team": "SM_SGM"
                                        }
                                    ],
                                    "team": "SM_SP"
                                }
                            ],
                            "team": "SA"
                        }
                    ],
                    "team": "SM_SP"
                }
            ],
            "team": "SM_SGM"
        },
        "team": "SM_SP",
        "username": "ushah",
        "vpId": "6280745e-d568-4cc0-9a14-78a400d73f12",
        "vpName": "epc-ubc-smpear-pp-sgm_by_ushah"
    },
    {
        "comments": "uecpartitionedsnapshot -> UBCBrandInferenceEPCToUBI",
        "creationDate": yesterday,
        "lastUpdateDate": 1615490808.546,
        "latestInstanceOfTargetDataset": 297,
        "pipelineInformation": {
            "datasetId": "UBCBrandInferenceEPCToUBI",
            "parents": [
                {
                    "datasetId": "uecpartitionedsnapshot",
                    "parents": [],
                    "team": "UEC"
                }
            ],
            "team": "SM_UBC"
        },
        "team": "SM_SP",
        "username": "ushah",
        "vpId": "416b3122-d389-4415-a6d4-a994be9268a2",
        "vpName": "epc-ubc_by_ushah"
    },
    {
        "comments": "EPC",
        "creationDate": yesterday,
        "lastUpdateDate": 1615490714.203,
        "latestInstanceOfTargetDataset": 1529,
        "pipelineInformation": {
            "datasetId": "uecpartitionedsnapshot",
            "parents": [],
            "team": "UEC"
        },
        "team": "SM_SP",
        "username": "ushah",
        "vpId": "98ec792e-6196-4475-9527-26a405898fe8",
        "vpName": "epc_by_ushah"
    },
    {
        "comments": "zenithMergedItemPredictions -> SgmProductWorkItems",
        "creationDate": yesterday,
        "lastUpdateDate": 1615490865.824,
        "latestInstanceOfTargetDataset": 273,
        "pipelineInformation": {
            "datasetId": "SgmProductWorkItems",
            "parents": [
                {
                    "datasetId": "zenithMergedItemPredictions",
                    "parents": [],
                    "team": "SM_SP"
                }
            ],
            "team": "SM_SGM"
        },
        "team": "SM_SP",
        "username": "ushah",
        "vpId": "176ae00e-f81d-4de8-82f4-ce94ff86ea29",
        "vpName": "pp-sgm_by_ushah"
    },
    {
        "comments": "PP: zenithProductPredictionFeatureset -> zenithProductPredictions -> zenithMergedItemPredictions",
        "creationDate": yesterday,
        "lastUpdateDate": 1615490706.174,
        "latestInstanceOfTargetDataset": 400,
        "pipelineInformation": {
            "datasetId": "zenithMergedItemPredictions",
            "parents": [
                {
                    "datasetId": "zenithProductPredictions",
                    "parents": [
                        {
                            "datasetId": "zenithProductPredictionFeatureset",
                            "parents": [],
                            "team": "SM_SP"
                        }
                    ],
                    "team": "SA"
                }
            ],
            "team": "SM_SP"
        },
        "team": "SM_SP",
        "username": "ushah",
        "vpId": "c8372e54-126c-49aa-bac2-4745f71c6995",
        "vpName": "pp_by_ushah"
    },
    {
        "comments": "SGM: SgmProductWorkItems",
        "creationDate": yesterday,
        "lastUpdateDate": 1615490804.047,
        "latestInstanceOfTargetDataset": 273,
        "pipelineInformation": {
            "datasetId": "SgmProductWorkItems",
            "parents": [],
            "team": "SM_SGM"
        },
        "team": "SM_SP",
        "username": "ushah",
        "vpId": "544bcf22-004a-45f4-88e4-c521bb953c7f",
        "vpName": "sgm_by_ushah"
    },
    {
        "comments": "Smpear-SMProgramItem-hybrid -> zenithProductPredictionFeatureset -> zenithProductPredictions -> zenithMergedItemPredictions -> SgmProductWorkItems",
        "creationDate": yesterday,
        "lastUpdateDate": 1615490800.563,
        "latestInstanceOfTargetDataset": 273,
        "pipelineInformation": {
            "datasetId": "SgmProductWorkItems",
            "parents": [
                {
                    "datasetId": "zenithMergedItemPredictions",
                    "parents": [
                        {
                            "datasetId": "zenithProductPredictions",
                            "parents": [
                                {
                                    "datasetId": "zenithProductPredictionFeatureset",
                                    "parents": [
                                        {
                                            "datasetId": "Smpear-SMProgramItem-hybrid",
                                            "parents": [],
                                            "team": "SM_SGM"
                                        }
                                    ],
                                    "team": "SM_SP"
                                }
                            ],
                            "team": "SA"
                        }
                    ],
                    "team": "SM_SP"
                }
            ],
            "team": "SM_SGM"
        },
        "team": "SM_SP",
        "username": "ushah",
        "vpId": "89bf14a8-dd87-46af-9642-016d860e4220",
        "vpName": "smpear-pp-sgm_by_ushah"
    },
    {
        "comments": "Smpear-SMProgramItem-hybrid -> zenithProductPredictionFeatureset -> zenithProductPredictions -> zenithMergedItemPredictions",
        "creationDate": yesterday,
        "lastUpdateDate": 1615490859.989,
        "latestInstanceOfTargetDataset": 400,
        "pipelineInformation": {
            "datasetId": "zenithMergedItemPredictions",
            "parents": [
                {
                    "datasetId": "zenithProductPredictions",
                    "parents": [
                        {
                            "datasetId": "zenithProductPredictionFeatureset",
                            "parents": [
                                {
                                    "datasetId": "Smpear-SMProgramItem-hybrid",
                                    "parents": [],
                                    "team": "SM_SGM"
                                }
                            ],
                            "team": "SM_SP"
                        }
                    ],
                    "team": "SA"
                }
            ],
            "team": "SM_SP"
        },
        "team": "SM_SP",
        "username": "ushah",
        "vpId": "2f3100c9-ec6c-4c8e-b707-219400f73eb3",
        "vpName": "smpear-pp_by_ushah"
    },
    {
        "comments": "Smpear-SMProgramItem-hybrid -> SgmProductWorkItems",
        "creationDate": yesterday,
        "lastUpdateDate": 1615490767.946,
        "latestInstanceOfTargetDataset": 273,
        "pipelineInformation": {
            "datasetId": "SgmProductWorkItems",
            "parents": [
                {
                    "datasetId": "Smpear-SMProgramItem-hybrid",
                    "parents": [],
                    "team": "SM_SGM"
                }
            ],
            "team": "SM_SGM"
        },
        "team": "SM_SP",
        "username": "ushah",
        "vpId": "99075aae-54f5-4a3a-b169-15ef2ce0926f",
        "vpName": "smpear-sgm_by_ushah"
    },
    {
        "comments": "SMPEAR: Smpear-SMProgramItem-hybrid",
        "creationDate": yesterday,
        "lastUpdateDate": 1615490672.562,
        "latestInstanceOfTargetDataset": 733,
        "pipelineInformation": {
            "datasetId": "Smpear-SMProgramItem-hybrid",
            "parents": [],
            "team": "SM_SGM"
        },
        "team": "SM_SP",
        "username": "ushah",
        "vpId": "4935bea4-250d-4df2-b220-479d46f6e405",
        "vpName": "smpear_by_ushah"
    },
    {
        "comments": "UBCBrandInferenceEPCToUBI -> Smpear-SMProgramItem-hybrid -> zenithProductPredictionFeatureset -> zenithProductPredictions -> zenithMergedItemPredictions -> SgmProductWorkItems",
        "creationDate": yesterday,
        "lastUpdateDate": 1615490759.531,
        "latestInstanceOfTargetDataset": 273,
        "pipelineInformation": {
            "datasetId": "SgmProductWorkItems",
            "parents": [
                {
                    "datasetId": "zenithMergedItemPredictions",
                    "parents": [
                        {
                            "datasetId": "zenithProductPredictions",
                            "parents": [
                                {
                                    "datasetId": "zenithProductPredictionFeatureset",
                                    "parents": [
                                        {
                                            "datasetId": "Smpear-SMProgramItem-hybrid",
                                            "parents": [
                                                {
                                                    "datasetId": "UBCBrandInferenceEPCToUBI",
                                                    "parents": [],
                                                    "team": "SM_UBC"
                                                }
                                            ],
                                            "team": "SM_SGM"
                                        }
                                    ],
                                    "team": "SM_SP"
                                }
                            ],
                            "team": "SA"
                        }
                    ],
                    "team": "SM_SP"
                }
            ],
            "team": "SM_SGM"
        },
        "team": "SM_SP",
        "username": "ushah",
        "vpId": "1e544971-7247-4530-a606-ecc14ce77b3a",
        "vpName": "ubc-smpear-pp-sgm_by_ushah"
    },
    {
        "comments": "UBC: UBCBrandInferenceEPCToUBI",
        "creationDate": yesterday,
        "lastUpdateDate": 1615490761.962,
        "latestInstanceOfTargetDataset": 297,
        "pipelineInformation": {
            "datasetId": "UBCBrandInferenceEPCToUBI",
            "parents": [],
            "team": "SM_UBC"
        },
        "team": "SM_SP",
        "username": "ushah",
        "vpId": "324030b3-f45e-44dd-8172-c4525822f9af",
        "vpName": "ubc_by_ushah"
    }
]

export const EXPANDED_VIRTUAL_PIPELINE_SAMPLE = {
    "detailedPipelineInformation": {
        "id": "zenithMergedItemPredictions",
        "isInVirtualPipeline": true,
        "parents": [
            {
                "id": "SPNileItemDeltaGenerator",
                "isInVirtualPipeline": true,
                "parents": [
                    {
                        "id": "zenithObsolescencePredictions",
                        "isInVirtualPipeline": false,
                        "parents": null,
                        "type": "DATASET"
                    },
                    {
                        "id": "zenithProductPredictions",
                        "isInVirtualPipeline": true,
                        "parents": [
                            {
                                "id": "SPProductPredictionPublisher",
                                "isInVirtualPipeline": true,
                                "parents": [
                                    {
                                        "id": "zenithProductPredictionFeatureset",
                                        "isInVirtualPipeline": true,
                                        "parents": [
                                            {
                                                "id": "SPProductDataMerger",
                                                "isInVirtualPipeline": true,
                                                "parents": [
                                                    {
                                                        "id": "Smpear-SMProgramItem-hybrid",
                                                        "isInVirtualPipeline": true,
                                                        "parents": [
                                                            {
                                                                "id": "Smpear-SMProgramItem-hybrid",
                                                                "isInVirtualPipeline": true,
                                                                "parents": [
                                                                    {
                                                                        "id": "UBCBrandInferenceEPCToUBI_Brands",
                                                                        "isInVirtualPipeline": false,
                                                                        "parents": null,
                                                                        "type": "DATASET"
                                                                    },
                                                                    {
                                                                        "id": "Smpear-SMProgramItem-hybrid",
                                                                        "isInVirtualPipeline": false,
                                                                        "parents": null,
                                                                        "type": "DATASET"
                                                                    },
                                                                    {
                                                                        "id": "SgmProgramConfig",
                                                                        "isInVirtualPipeline": false,
                                                                        "parents": null,
                                                                        "type": "DATASET"
                                                                    },
                                                                    {
                                                                        "id": "deduperV3VariantItemMembersSnapshot",
                                                                        "isInVirtualPipeline": false,
                                                                        "parents": null,
                                                                        "type": "DATASET"
                                                                    },
                                                                    {
                                                                        "id": "reportingsnapshot",
                                                                        "isInVirtualPipeline": false,
                                                                        "parents": null,
                                                                        "type": "DATASET"
                                                                    },
                                                                    {
                                                                        "id": "brandGapComputationByChannel",
                                                                        "isInVirtualPipeline": false,
                                                                        "parents": null,
                                                                        "type": "DATASET"
                                                                    },
                                                                    {
                                                                        "id": "deduperSmtItemMembersSnapshot",
                                                                        "isInVirtualPipeline": false,
                                                                        "parents": null,
                                                                        "type": "DATASET"
                                                                    },
                                                                    {
                                                                        "id": "uecpartitionedsnapshot",
                                                                        "isInVirtualPipeline": false,
                                                                        "parents": null,
                                                                        "type": "DATASET"
                                                                    },
                                                                    {
                                                                        "id": "UBCBrandInferenceEPCToUBI",
                                                                        "isInVirtualPipeline": false,
                                                                        "parents": null,
                                                                        "type": "DATASET"
                                                                    },
                                                                    {
                                                                        "id": "SgmPrograms",
                                                                        "isInVirtualPipeline": false,
                                                                        "parents": null,
                                                                        "type": "DATASET"
                                                                    },
                                                                    {
                                                                        "id": "uecglptdenrichmentsnapshot",
                                                                        "isInVirtualPipeline": false,
                                                                        "parents": null,
                                                                        "type": "DATASET"
                                                                    },
                                                                    {
                                                                        "id": "cfg_variant_competitor_gl",
                                                                        "isInVirtualPipeline": false,
                                                                        "parents": null,
                                                                        "type": "DATASET"
                                                                    },
                                                                    {
                                                                        "id": "SgmItemOfferState",
                                                                        "isInVirtualPipeline": false,
                                                                        "parents": null,
                                                                        "type": "DATASET"
                                                                    }
                                                                ],
                                                                "type": "OPERATION"
                                                            }
                                                        ],
                                                        "type": "DATASET"
                                                    },
                                                    {
                                                        "id": "UniversalBrandCatalog_ConsolidatedBrand",
                                                        "isInVirtualPipeline": false,
                                                        "parents": null,
                                                        "type": "DATASET"
                                                    },
                                                    {
                                                        "id": "Smpear-SMProgramItem-hybrid-parent",
                                                        "isInVirtualPipeline": false,
                                                        "parents": null,
                                                        "type": "DATASET"
                                                    },
                                                    {
                                                        "id": "UBCBrandInferenceSODAToUBI",
                                                        "isInVirtualPipeline": false,
                                                        "parents": null,
                                                        "type": "DATASET"
                                                    },
                                                    {
                                                        "id": "uecpartitionedsnapshot",
                                                        "isInVirtualPipeline": false,
                                                        "parents": null,
                                                        "type": "DATASET"
                                                    },
                                                    {
                                                        "id": "zenithTrendsSnapshot",
                                                        "isInVirtualPipeline": false,
                                                        "parents": null,
                                                        "type": "DATASET"
                                                    },
                                                    {
                                                        "id": "zenithAsinMetricsSnapshot",
                                                        "isInVirtualPipeline": false,
                                                        "parents": null,
                                                        "type": "DATASET"
                                                    },
                                                    {
                                                        "id": "uecumssnapshot",
                                                        "isInVirtualPipeline": false,
                                                        "parents": null,
                                                        "type": "DATASET"
                                                    },
                                                    {
                                                        "id": "CDW_Soda_EnrichedItemParquet",
                                                        "isInVirtualPipeline": false,
                                                        "parents": null,
                                                        "type": "DATASET"
                                                    },
                                                    {
                                                        "id": "UniversalBrandCatalog_Tier1TagToMarketplaceMapping",
                                                        "isInVirtualPipeline": false,
                                                        "parents": null,
                                                        "type": "DATASET"
                                                    }
                                                ],
                                                "type": "OPERATION"
                                            }
                                        ],
                                        "type": "DATASET"
                                    },
                                    {
                                        "id": "zenithProductPredictions",
                                        "isInVirtualPipeline": false,
                                        "parents": null,
                                        "type": "DATASET"
                                    },
                                    {
                                        "id": "zenithBrandPredictions",
                                        "isInVirtualPipeline": false,
                                        "parents": null,
                                        "type": "DATASET"
                                    }
                                ],
                                "type": "OPERATION"
                            }
                        ],
                        "type": "DATASET"
                    },
                    {
                        "id": "zenithMergedItemPredictions",
                        "isInVirtualPipeline": false,
                        "parents": null,
                        "type": "DATASET"
                    }
                ],
                "type": "OPERATION"
            }
        ],
        "type": "DATASET"
    },
    "vpId": "48714a9d-e80b-41e4-91e0-d9c629a6187a"
}

export const VIRTUAL_PIPELINE_INST_SAMPLE = [
    {
        "creationDate": 1622124979.192,
        "endToEndLatency": 391999450,
        "pipelineInstanceInformation": {
            "datasetId": "zenithMergedItemPredictions",
            "datasetInstanceCreateTime": 1622124979.192,
            "datasetInstanceId": [
                468
            ],
            "jobId": "2788113-210314100429719-oozie-oozi-W",
            "operationId": "SPNileItemDeltaGenerator",
            "operationIdleTime": [
                4184887
            ],
            "operationProcessTime": 163500877,
            "operationResolveTime": 1621961478.315,
            "parents": [
                {
                    "datasetId": "zenithProductPredictions",
                    "datasetInstanceCreateTime": 1621957293.428,
                    "datasetInstanceId": [
                        414
                    ],
                    "jobId": "2725898-210314100429719-oozie-oozi-W",
                    "operationId": "SPProductPredictionPublisher",
                    "operationIdleTime": [
                        105290630
                    ],
                    "operationProcessTime": 119023056,
                    "operationResolveTime": 1621838270.372,
                    "parents": [
                        {
                            "datasetId": "zenithProductPredictionFeatureset",
                            "datasetInstanceCreateTime": 1621732979.742,
                            "datasetInstanceId": [
                                446
                            ],
                            "jobId": "2672144-210314100429719-oozie-oozi-W",
                            // Test for case when older instance has different operation compared with current vp.
                            "operationId": "SPProductDataMergerTest",
                            "operationIdleTime": [],
                            "operationProcessTime": 22960174,
                            "operationResolveTime": 1621710019.568,
                            "parents": [],
                            "team": "SM_SP"
                        }
                    ],
                    "team": "SM_SP"
                }
            ],
            "team": "SM_SP"
        },
        "targetDatasetInstanceNumber": 468,
        "vpId": "ff04e2c1-3f83-4b84-899b-51f93d083faa"
    },
    {
        "creationDate": 1622253529.221,
        "endToEndLatency": 202596279,
        "pipelineInstanceInformation": {
            "datasetId": "zenithMergedItemPredictions",
            "datasetInstanceCreateTime": 1622253529.221,
            "datasetInstanceId": [
                469
            ],
            "jobId": "2869782-210314100429719-oozie-oozi-W",
            "operationId": "SPNileItemDeltaGenerator",
            "operationIdleTime": [
                5567497
            ],
            "operationProcessTime": 122277669,
            "operationResolveTime": 1622131251.552,
            "parents": [
                {
                    "datasetId": "zenithProductPredictions",
                    "datasetInstanceCreateTime": 1622125684.055,
                    "datasetInstanceId": [
                        416
                    ],
                    "jobId": "2831838-210314100429719-oozie-oozi-W",
                    "operationId": "SPProductPredictionPublisher",
                    "operationIdleTime": [
                        29883
                    ],
                    "operationProcessTime": 74721230,
                    "operationResolveTime": 1622050962.825,
                    "parents": [
                        {
                            "datasetId": "zenithProductPredictionFeatureset",
                            "datasetInstanceCreateTime": 1622050932.942,
                            "datasetInstanceId": [
                                449
                            ],
                            "jobId": "2793404-210314100429719-oozie-oozi-W",
                            "operationId": "SPProductDataMerger",
                            "operationIdleTime": [],
                            "operationProcessTime": 75661244,
                            "operationResolveTime": 1621975271.698,
                            "parents": [],
                            "team": "SM_SP"
                        }
                    ],
                    "team": "SM_SP"
                }
            ],
            "team": "SM_SP"
        },
        "targetDatasetInstanceNumber": 469,
        "vpId": "ff04e2c1-3f83-4b84-899b-51f93d083faa"
    },
    {
        "creationDate": 1622289201.021,
        "endToEndLatency": 93723407,
        "pipelineInstanceInformation": {
            "datasetId": "zenithMergedItemPredictions",
            "datasetInstanceCreateTime": 1622289201.021,
            "datasetInstanceId": [
                470
            ],
            "jobId": "2932008-210314100429719-oozie-oozi-W",
            "operationId": "SPNileItemDeltaGenerator",
            "operationIdleTime": [
                15134840
            ],
            "operationProcessTime": 20539115,
            "operationResolveTime": 1622268661.906,
            "parents": [
                {
                    "datasetId": "zenithProductPredictions",
                    "datasetInstanceCreateTime": 1622253527.066,
                    "datasetInstanceId": [
                        418
                    ],
                    "jobId": "2899344-210314100429719-oozie-oozi-W",
                    "operationId": "SPProductPredictionPublisher",
                    "operationIdleTime": [
                        28178
                    ],
                    "operationProcessTime": 58021274,
                    "operationResolveTime": 1622195505.792,
                    "parents": [
                        {
                            "datasetId": "zenithProductPredictionFeatureset",
                            "datasetInstanceCreateTime": 1622195477.614,
                            "datasetInstanceId": [
                                451
                            ],
                            "jobId": "2874511-210314100429719-oozie-oozi-W",
                            "operationId": "SPProductDataMerger",
                            "operationIdleTime": [],
                            "operationProcessTime": 49571719,
                            "operationResolveTime": 1622145905.895,
                            "parents": [],
                            "team": "SM_SP"
                        }
                    ],
                    "team": "SM_SP"
                }
            ],
            "team": "SM_SP"
        },
        "targetDatasetInstanceNumber": 470,
        "vpId": "ff04e2c1-3f83-4b84-899b-51f93d083faa"
    },
    {
        "creationDate": 1622332091.472,
        "endToEndLatency": 136613858,
        "pipelineInstanceInformation": {
            "datasetId": "zenithMergedItemPredictions",
            "datasetInstanceCreateTime": 1622332091.472,
            "datasetInstanceId": [
                471
            ],
            "jobId": "2948146-210314100429719-oozie-oozi-W",
            "operationId": "SPNileItemDeltaGenerator",
            "operationIdleTime": [
                48850830
            ],
            "operationProcessTime": 29713576,
            "operationResolveTime": 1622302377.896,
            "parents": [
                {
                    "datasetId": "zenithProductPredictions",
                    "datasetInstanceCreateTime": 1622253527.066,
                    "datasetInstanceId": [
                        418
                    ],
                    "jobId": "2899344-210314100429719-oozie-oozi-W",
                    "operationId": "SPProductPredictionPublisher",
                    "operationIdleTime": [
                        28178
                    ],
                    "operationProcessTime": 58021274,
                    "operationResolveTime": 1622195505.792,
                    "parents": [
                        {
                            "datasetId": "zenithProductPredictionFeatureset",
                            "datasetInstanceCreateTime": 1622195477.614,
                            "datasetInstanceId": [
                                451
                            ],
                            "jobId": "2874511-210314100429719-oozie-oozi-W",
                            "operationId": "SPProductDataMerger",
                            "operationIdleTime": [],
                            "operationProcessTime": 49571719,
                            "operationResolveTime": 1622145905.895,
                            "parents": [],
                            "team": "SM_SP"
                        }
                    ],
                    "team": "SM_SP"
                }
            ],
            "team": "SM_SP"
        },
        "targetDatasetInstanceNumber": 471,
        "vpId": "ff04e2c1-3f83-4b84-899b-51f93d083faa"
    },
    {
        "creationDate": 1622343343.025,
        "endToEndLatency": 147865411,
        "pipelineInstanceInformation": {
            "datasetId": "zenithMergedItemPredictions",
            "datasetInstanceCreateTime": 1622343343.025,
            "datasetInstanceId": [
                472
            ],
            "jobId": "2964694-210314100429719-oozie-oozi-W",
            "operationId": "SPNileItemDeltaGenerator",
            "operationIdleTime": [
                28777494
            ],
            "operationProcessTime": 4890535,
            "operationResolveTime": 1622338452.49,
            "parents": [
                {
                    "datasetId": "zenithProductPredictions",
                    "datasetInstanceCreateTime": 1622309674.996,
                    "datasetInstanceId": [
                        419
                    ],
                    "jobId": "2927066-210314100429719-oozie-oozi-W",
                    "operationId": "SPProductPredictionPublisher",
                    "operationIdleTime": [
                        66007511
                    ],
                    "operationProcessTime": 48189871,
                    "operationResolveTime": 1622261485.125,
                    "parents": [
                        {
                            "datasetId": "zenithProductPredictionFeatureset",
                            "datasetInstanceCreateTime": 1622195477.614,
                            "datasetInstanceId": [
                                451
                            ],
                            "jobId": "2874511-210314100429719-oozie-oozi-W",
                            "operationId": "SPProductDataMerger",
                            "operationIdleTime": [],
                            "operationProcessTime": 49571719,
                            "operationResolveTime": 1622145905.895,
                            "parents": [],
                            "team": "SM_SP"
                        }
                    ],
                    "team": "SM_SP"
                }
            ],
            "team": "SM_SP"
        },
        "targetDatasetInstanceNumber": 472,
        "vpId": "ff04e2c1-3f83-4b84-899b-51f93d083faa"
    },
    {
        "creationDate": 1622396606.178,
        "endToEndLatency": 201128564,
        "pipelineInstanceInformation": {
            "datasetId": "zenithMergedItemPredictions",
            "datasetInstanceCreateTime": 1622396606.178,
            "datasetInstanceId": [
                473
            ],
            "jobId": "2989252-210314100429719-oozie-oozi-W",
            "operationId": "SPNileItemDeltaGenerator",
            "operationIdleTime": [
                80754760
            ],
            "operationProcessTime": 6176422,
            "operationResolveTime": 1622390429.756,
            "parents": [
                {
                    "datasetId": "zenithProductPredictions",
                    "datasetInstanceCreateTime": 1622309674.996,
                    "datasetInstanceId": [
                        419
                    ],
                    "jobId": "2927066-210314100429719-oozie-oozi-W",
                    "operationId": "SPProductPredictionPublisher",
                    "operationIdleTime": [
                        66007511
                    ],
                    "operationProcessTime": 48189871,
                    "operationResolveTime": 1622261485.125,
                    "parents": [
                        {
                            "datasetId": "zenithProductPredictionFeatureset",
                            "datasetInstanceCreateTime": 1622195477.614,
                            "datasetInstanceId": [
                                451
                            ],
                            "jobId": "2874511-210314100429719-oozie-oozi-W",
                            "operationId": "SPProductDataMerger",
                            "operationIdleTime": [],
                            "operationProcessTime": 49571719,
                            "operationResolveTime": 1622145905.895,
                            "parents": [],
                            "team": "SM_SP"
                        }
                    ],
                    "team": "SM_SP"
                }
            ],
            "team": "SM_SP"
        },
        "targetDatasetInstanceNumber": 473,
        "vpId": "ff04e2c1-3f83-4b84-899b-51f93d083faa"
    },
    {
        "creationDate": 1622482210.555,
        "endToEndLatency": 141080760,
        "pipelineInstanceInformation": {
            "datasetId": "zenithMergedItemPredictions",
            "datasetInstanceCreateTime": 1622482210.555,
            "datasetInstanceId": [
                474
            ],
            "jobId": "3029388-210314100429719-oozie-oozi-W",
            "operationId": "SPNileItemDeltaGenerator",
            "operationIdleTime": [
                78464670
            ],
            "operationProcessTime": 5381225,
            "operationResolveTime": 1622476829.33,
            "parents": [
                {
                    "datasetId": "zenithProductPredictions",
                    "datasetInstanceCreateTime": 1622398364.66,
                    "datasetInstanceId": [
                        420
                    ],
                    "jobId": "2967165-210314100429719-oozie-oozi-W",
                    "operationId": "SPProductPredictionPublisher",
                    "operationIdleTime": [
                        3278700
                    ],
                    "operationProcessTime": 53956165,
                    "operationResolveTime": 1622344408.495,
                    "parents": [
                        {
                            "datasetId": "zenithProductPredictionFeatureset",
                            "datasetInstanceCreateTime": 1622341129.795,
                            "datasetInstanceId": [
                                453
                            ],
                            "jobId": "2954001-210314100429719-oozie-oozi-W",
                            "operationId": "SPProductDataMerger",
                            "operationIdleTime": [],
                            "operationProcessTime": 25253940,
                            "operationResolveTime": 1622315875.855,
                            "parents": [],
                            "team": "SM_SP"
                        }
                    ],
                    "team": "SM_SP"
                }
            ],
            "team": "SM_SP"
        },
        "targetDatasetInstanceNumber": 474,
        "vpId": "ff04e2c1-3f83-4b84-899b-51f93d083faa"
    },
    {
        "creationDate": 1622569396.551,
        "endToEndLatency": 128735945,
        "pipelineInstanceInformation": {
            "datasetId": "zenithMergedItemPredictions",
            "datasetInstanceCreateTime": 1622569396.551,
            "datasetInstanceId": [
                475
            ],
            "jobId": "3069878-210314100429719-oozie-oozi-W",
            "operationId": "SPNileItemDeltaGenerator",
            "operationIdleTime": [
                7500417
            ],
            "operationProcessTime": 6164641,
            "operationResolveTime": 1622563231.91,
            "parents": [
                {
                    "datasetId": "zenithProductPredictions",
                    "datasetInstanceCreateTime": 1622555731.493,
                    "datasetInstanceId": [
                        422
                    ],
                    "jobId": "3047521-210314100429719-oozie-oozi-W",
                    "operationId": "SPProductPredictionPublisher",
                    "operationIdleTime": [
                        75767745
                    ],
                    "operationProcessTime": 39303142,
                    "operationResolveTime": 1622516428.351,
                    "parents": [
                        {
                            "datasetId": "zenithProductPredictionFeatureset",
                            "datasetInstanceCreateTime": 1622440660.606,
                            "datasetInstanceId": [
                                454
                            ],
                            "jobId": "2994382-210314100429719-oozie-oozi-W",
                            "operationId": "SPProductDataMerger",
                            "operationIdleTime": [],
                            "operationProcessTime": 39442965,
                            "operationResolveTime": 1622401217.641,
                            "parents": [],
                            "team": "SM_SP"
                        }
                    ],
                    "team": "SM_SP"
                }
            ],
            "team": "SM_SP"
        },
        "targetDatasetInstanceNumber": 475,
        "vpId": "ff04e2c1-3f83-4b84-899b-51f93d083faa"
    },
    {
        "creationDate": 1622655185.33,
        "endToEndLatency": 53520571,
        "pipelineInstanceInformation": {
            "datasetId": "zenithMergedItemPredictions",
            "datasetInstanceCreateTime": 1622655185.33,
            "datasetInstanceId": [
                476
            ],
            "jobId": "3111036-210314100429719-oozie-oozi-W",
            "operationId": "SPNileItemDeltaGenerator",
            "operationIdleTime": [
                10618657
            ],
            "operationProcessTime": 5553164,
            "operationResolveTime": 1622649632.166,
            "parents": [
                {
                    "datasetId": "zenithProductPredictions",
                    "datasetInstanceCreateTime": 1622639013.509,
                    "datasetInstanceId": [
                        423
                    ],
                    "jobId": "3088999-210314100429719-oozie-oozi-W",
                    "operationId": "SPProductPredictionPublisher",
                    "operationIdleTime": [
                        1166418
                    ],
                    "operationProcessTime": 36182332,
                    "operationResolveTime": 1622602831.177,
                    "parents": [
                        {
                            "datasetId": "zenithProductPredictionFeatureset",
                            "datasetInstanceCreateTime": 1622601664.759,
                            "datasetInstanceId": [
                                456
                            ],
                            "jobId": "3075447-210314100429719-oozie-oozi-W",
                            "operationId": "SPProductDataMerger",
                            "operationIdleTime": [],
                            "operationProcessTime": 27643644,
                            "operationResolveTime": 1622574021.115,
                            "parents": [],
                            "team": "SM_SP"
                        }
                    ],
                    "team": "SM_SP"
                }
            ],
            "team": "SM_SP"
        },
        "targetDatasetInstanceNumber": 476,
        "vpId": "ff04e2c1-3f83-4b84-899b-51f93d083faa"
    }
]

export const EMPTY_DATASET_NODE = {
    datasetId: "",
    parents: [],
    team: ""
}

export const VirtualPipelineDetail = {
    header: "Detail"
}

export const VirtualPipelineVisualization = {
    header: "Visualization"
}

export const VirtualPipelineInstanceDashboard = {
    header: "Instance Dashboard"
}

export const VirtualPipelinesListTable = {
    header: "Virtual Pipeline List"
}

/**
 * Table related constants
 */

export const INIT_PAGE_SIZE = 10
export const INIT_PAGE_INDEX = 1