import Select from "@amzn/awsui-components-react/polaris/select/select";
import TablePropertyFiltering
    from "@amzn/awsui-components-react/polaris/table-property-filtering/table-property-filtering";
import {Range} from "@amzn/polaris-date-time-range";
import {ExecutionsListTable, ExecutionsSummary, SELECT_DROPDOWN_ALL_PLACEHOLDER} from "src/constants/domain-tracker";
import {ES_SEARCH_TYPES} from "src/constants/elastic-search";
import {SelectProps} from "@amzn/awsui-components-react-v3/polaris/select/interfaces";
import {TableTypes} from "src/interfaces/domain-tracker";
import {SMSWOUnifiedDashboardServiceLambda} from "@amzn/swo-unified-dashboard-service-lambda-js-client";
import {DomainTracker} from "src/model/DomainTracker";
import {ExecutionsAttempsTableConstants} from "src/constants/execution-attempts";
import {DataType} from "@amzn/swo-unified-dashboard-service-lambda-js-client/lib/smswounifieddashboardservicelambda";

/**
 * Polaris Select dropdown requires values to be in different format. This will convert array of strings into needed format.
 * @param options
 * @param withPlaceholder
 * @param placeholderValue
 */
export const convertArrayToTableSelect = (options: Array<string>, withPlaceholder: boolean, placeholderValue?: Select.Option): Array<Select.IOption | Select.Option | Select.OptionsGroup> => {
    const dropdownOptions: Array<Select.Option> = []
    if (withPlaceholder) dropdownOptions.push({
        id: placeholderValue?.id || SELECT_DROPDOWN_ALL_PLACEHOLDER,
        label: placeholderValue?.label || SELECT_DROPDOWN_ALL_PLACEHOLDER
    })
    dropdownOptions.push(...options.sort().map(option => {
        return {id: option, label: option}
    }));
    return dropdownOptions.sort();
}

export const convertArrayToTableSelectV3 = (options: Array<string>, withPlaceholder: boolean, placeholderValue?: SelectProps.Option): Array<SelectProps.Option> => {

    const dropdownOptions: Array<SelectProps.Option> = []
    if (withPlaceholder) dropdownOptions.push({
        value: placeholderValue?.value || SELECT_DROPDOWN_ALL_PLACEHOLDER,
        label: placeholderValue?.label || SELECT_DROPDOWN_ALL_PLACEHOLDER
    })
    dropdownOptions.push(...options.sort().map(option => {
        return {value: option, label: option}
    }));
    return dropdownOptions.sort();
}

/**
 * ES Sends date in epoch format. Since epoch is not human readable, this will convert them to UTC string
 * @param epochDate
 */
export const convertEsDateToHumanReadable = (epochDate: string): string => {
    return new Date(parseInt(epochDate)).toUTCString()
}

/**
 * Remove null, undefined values and format date
 * @param value - Column Value
 * @param type  Column type
 */
export const formatColumnValue = (value: string, type: DataType): string => {
    if ((type == ES_SEARCH_TYPES.TEXT) || (type == ES_SEARCH_TYPES.KEYWORD)) {
        return value || "-"
    }
    if (type == ES_SEARCH_TYPES.DATE) {
        if ((value == undefined) || (value == "") || (value == null)) {
            return "-"
        }
        return convertEsDateToHumanReadable(value)
    }
    return value;
}

/**
 * Get Start date and End date
 * @param daysAgo - Last X days from today.
 */
export const getStartDateAndEndDate = (daysAgo: number): Range => {
    const date = new Date()
    const endDate: number = Date.UTC(date.getFullYear(), date.getUTCMonth(), date.getDate()) + 86400000 // Current day in epoch + 1 day(In MS)
    const startDate: number = endDate - (daysAgo * 86400 * 1000) // X days ago
    return [startDate, endDate - 1000]
}

/**
 * Get pages count from total count and page size.
 * @param totalCount
 * @param pageSize
 */
export const getPagesCount = (totalCount: number, pageSize: number): number => {
    return Math.ceil(totalCount / pageSize)
}

/**
 * Get table filtering token from property key and value
 * @param propertyKey
 * @param propertyValue
 */
export const getFilteringToken = (propertyKey: string, propertyValue: string): TablePropertyFiltering.FilteringToken => {
    return {
        propertyKey: propertyKey,
        propertyLabel: propertyKey,
        value: propertyValue.trim(),
        label: propertyValue.trim(),
        negated: false,
    }
}


/**
 * Get card name bases on Table type
 * @param tableType
 */
export const getListCardName = (tableType: TableTypes): SMSWOUnifiedDashboardServiceLambda.Types.ListCard => {
    let listCardName: SMSWOUnifiedDashboardServiceLambda.Types.ListCard = ""
    switch (tableType) {
        case TableTypes.EXECUTION_ATTEMPTS:
            listCardName = "ATTEMPTS"
            break;
        case TableTypes.EXECUTION_LIST:
            listCardName = "WORKFLOWS"
            break;
    }
    return listCardName
}

/**
 * Get Attribute filter header based on Table type
 * @param tableType - @link{TableTypes}
 */
export const getAttributeFilterHeader = (tableType: TableTypes): string => {
    let headerString: string = ""
    switch (tableType) {
        case TableTypes.EXECUTION_ATTEMPTS:
            headerString = ExecutionsAttempsTableConstants.header
            break;
        case TableTypes.EXECUTION_LIST:
            headerString = ExecutionsListTable.header
            break;
        case TableTypes.EXECUTION_SUMMARY:
            headerString = ExecutionsSummary.header
            break;
    }
    return headerString
}

/**
 * Search dropdown value for select and multiselect component.
 * @param key - Attribute to query
 * @param tableType - @link{TableTypes}
 */
export async function searchAttributeValuesByKey(key: string, tableType: TableTypes): Promise<Array<string>> {
    if (key == "") return Promise.resolve([]);
    const attributeValue: Array<string> = []
    if ((tableType == TableTypes.EXECUTION_LIST) || (tableType == TableTypes.EXECUTION_ATTEMPTS) || (tableType == TableTypes.EXECUTION_SUMMARY)) {
        await DomainTracker.searchAttributeValuesByKeyAsync(key)
            .then(resp => {
                attributeValue.push(...resp)
            })
            .catch(error => {
                console.error("SearchAttributeValuesByKey error", error)
            })
    }
    return Promise.resolve(attributeValue)
}

/**
 * Calculate TP50 and TP90 for input array. Default will be TP50
 * @param input
 * @param fractionDigit
 * @param method: Allow null, select default TP50
 * @param metric
 * @constructor
 */
export const TPHelper = (input: Array<any>, fractionDigit: number, method?: "TP50"|"TP90", metric?: "hours"|"days") => {
    const sortedInput = input.sort((a, b) => a > b? 1: -1)
    let index
    switch (method) {
        case "TP90":
            index = parseInt(String(sortedInput.length * 0.9))
            break
        default:
            index = parseInt(String(sortedInput.length * 0.5))
    }
    if (isNaN(sortedInput[index])) {
        return "-"
    }
    if (metric == "hours") {
        return (sortedInput[index] / (60 * 60 * 1000)).toFixed(fractionDigit)

    } else {
        return (sortedInput[index] / (60 * 60 * 1000 * 24)).toFixed(fractionDigit)
    }
}