import {EsQueryTableFilterI} from "src/interfaces/attribute-filters";

export enum ES_SORT_TYPES {
    ASC = "asc",
    DESC = "desc"
}

export enum ES_SEARCH_TYPES {
    TEXT = "TEXT",
    KEYWORD = "KEYWORD",
    DATE = "DATE"
}

export const DEFAULT_ES_QUERY_FILTER: EsQueryTableFilterI = {
    size: 10,
    from: 0,
    attributeFilterItems: []
}

export enum ES_QUERY_RESERVED_KEYWORDS {
    "query" = "query",
    "bool" = "bool",
    "filter" = "filter",
    "must" = "must",
    "must_not" = "must_not",
    "terms" = "terms",
    "match" = "match",
    "range" = "range",
    "gte" = "gte",
    "lte" = "lte",
    "exists" = "exists",
    "order" = "order",
    "asc" = "asc",
    "desc" = "desc"
}