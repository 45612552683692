import {STAGES} from "src/utils/app-constants";

export function getStage(): string {
  const envStage = document.getElementById("env-stage");
  if (envStage == null) {
    return ""
  }
  return envStage.innerHTML.toLocaleLowerCase().trim()
}

export function isProd(): boolean {
  return getStage() == STAGES.PROD.toLowerCase()
}
