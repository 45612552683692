import React from "react"
import {getHeaderCounterText} from "src/components/common/table-counter-strings";
import {Header} from "@amzn/awsui-components-react-v3";

export const VirtualPipelineInstanceTableHeader = ({ ...props }) => {

    return (
        <Header
            {...props}
            counter={getHeaderCounterText(props.totalItems, props.selectedItems)}
            description={""}
        >
            Virtual Pipeline Instances
        </Header>
    );
};