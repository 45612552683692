import {WorkflowDetailContent} from "src/components/execution-detail/workflow-detail-content";
import React from "react";
import {useLocation} from "react-router-dom";
import {QueryParams} from "src/components/sm-overview-dashboards/common/query-params";

export const WorkflowView = () => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const workflowId = params.get(QueryParams.WORKFLOW_ID)?.toString()|| '';
    return <WorkflowDetailContent workflowId={workflowId} />
}
