import React from 'react';
import {Box, Link, SpaceBetween} from '@amzn/awsui-components-react-v3';
import {getHeaderCounterText, getServerHeaderCounterText} from './table-counter-strings';
import {GetCounterI, TableHeaderI} from "src/interfaces/server-side-table";
import {FlashbarProps} from "@amzn/awsui-components-react-v3/polaris/flashbar";
import {ProgressBarProps} from "@amzn/awsui-components-react-v3/polaris/progress-bar/interfaces";

/**
 * For displaying help text on right panel.
 * TODO: Not used in v0 release. Needs to be updated
 * @param id
 * @param onFollow
 * @constructor
 */
export const InfoLink = ({id, onFollow}: { id: string, onFollow: any }) => (
    <Link variant="info" id={id} onFollow={onFollow}>
        Info
    </Link>
);

/**
 * Number of items counter helper
 * @param props
 */
function getCounter(props: GetCounterI) {
    if (props.counter) {
        return props.counter;
    }
    if (props.serverSide) {
        return getServerHeaderCounterText(props.totalItemCount, props.selectedItems);
    }
    return getHeaderCounterText(props.totalItems, props.selectedItems);
}


/**
 * Display table header with information like table title, item count, help text link and view/edit actions
 * @param props - Title, Item count, Help text link id
 * @returns {JSX.Element}
 * @constructor
 */
export const TableHeader = (props: TableHeaderI) => {
    const counter = getCounter(props);
    return (
        <div>
            <div className="awsui-util-action-stripe">
                <div className="awsui-util-action-stripe-title">
                    <h2 className={"awsui-util-d-ib"}>
                        {`${props.title} `}
                        {counter ? (
                            <span id="custom-action-stripe-counter" className="awsui-util-header-counter">
                {`${counter}`}
              </span>
                        ) : null}
                    </h2>
                    {props.updateTools ? (
                        <span className="awsui-util-ml-s">
              <InfoLink onFollow={props.updateTools} id=""/>
            </span>
                    ) : null}
                </div>
                <div className="awsui-util-action-stripe-group">{props.actionButtons}</div>
            </div>
            <div className="awsui-util-container-header-description">{props.description}</div>
        </div>
    );
};

/**
 * When no matches found(Or no search results), this component will be rendered.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const TableNoMatchState = () => (
    <Box margin={{vertical: 'xs'}} textAlign="center" color="inherit">
        <SpaceBetween size="xxs">
            <div key={"table-no-match-state"}>
                <b>No matches</b>
                <Box variant="p" color="inherit">
                    We can't find a match.
                </Box>
            </div>
        </SpaceBetween>
    </Box>
);

/**
 * We're using Progress bar of type "flash". It requires flash bar status to be updated based on progress bar status.
 * This will convert required status.
 * @param progressStatus
 */
export const convertProgressStatusToFlashType = (progressStatus: ProgressBarProps.Status): FlashbarProps.Type => {
    if (progressStatus == "success") {
        return "success"
    } else if (progressStatus == "error") {
        return "error"
    }
    return "info"
}

