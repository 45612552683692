import {Box, Tabs} from "@amzn/awsui-components-react-v3";
import {AjvError, IChangeEvent} from "@rjsf/core";
import Form from "@rjsf/core";
import {JSONSchema7} from "json-schema";
import React from "react";
import {TabsProps} from "@amzn/awsui-components-react-v3/polaris/tabs/interfaces";
import {sortPrimaryArrayBySecondaryArrayOrder} from "src/utils/array-helper";
import {ThemeProvider} from "@material-ui/core";
import { startScreenInputFormStyles } from "src/styles/start-screen-inputform-styles";

interface TabInputFormProps {
    stageInstanceInputSchemaMap: Record<string, string>;
    formInputData: { [key: string]: any };
    enableLiveValidate?: boolean;
    onTabFormChange?: <T>(formInput: IChangeEvent<T>, tabId: string) => void;
    onTabFormSubmit?: (tabId: string, fieldIndex: number) => void;
    onTabFormError?: (err: AjvError[], tabId: string) => void;
    stageInstanceUISchemaMap?: Record<string, string>;
    stageRenderingOrder?: string[];
}

/**
 * This Component is reusable Tabbed form input component.
 * Takes in Tabbed Input schema & UI schema, and renders
 */
export const TabInputForm = React.forwardRef((props: TabInputFormProps, ref: any) => {
    const {
        stageInstanceInputSchemaMap,
        formInputData,
        enableLiveValidate,
        onTabFormChange,
        onTabFormSubmit,
        onTabFormError,
        stageInstanceUISchemaMap,
        stageRenderingOrder
    } = props;
    const tabOrder = React.useMemo(() =>
            sortPrimaryArrayBySecondaryArrayOrder(Object.keys(stageInstanceInputSchemaMap), stageRenderingOrder)
        , [stageInstanceInputSchemaMap,stageRenderingOrder])
    const schemaTabs: Array<TabsProps.Tab> = React.useMemo(() => {
        return tabOrder.map((tabId) => {
            const schema = stageInstanceInputSchemaMap[tabId] ?? JSON.stringify({});
            const stageUISchema = (stageInstanceUISchemaMap && stageInstanceUISchemaMap[tabId])
                ? JSON.parse(stageInstanceUISchemaMap[tabId]) : {};
            return {
                label: tabId,
                id: tabId,
                content:
                <>
                    <ThemeProvider theme={startScreenInputFormStyles}>
                    <Form schema={JSON.parse(schema) as JSONSchema7}
                          id={"workflow-input-rjsf-form"}
                          data-testid={"workflow-input-rjsf-form"}
                          omitExtraData={true}
                          liveOmit={true}
                          noHtml5Validate={true}
                          liveValidate={enableLiveValidate || false}
                          onChange={(formInput) => {
                              onTabFormChange && onTabFormChange(formInput, tabId)
                          }}
                          formData={formInputData[tabId]}
                          showErrorList={false}
                          uiSchema={stageUISchema}
                    >
                        {/*Hiding Default Schema Form Button*/}
                        <button type="submit" style={{display: "none"}}>Submit</button>
                    </Form>
                    </ThemeProvider>
                </>
            }
        })
    }, [stageInstanceInputSchemaMap, enableLiveValidate, formInputData])

    return (
        <>
            <Tabs
                tabs={schemaTabs}
            />
            {<Box display={"none"}>
                {Object.entries(stageInstanceInputSchemaMap || {}).map(([tabId, schema], tabIndex) => {
                    return <Form schema={JSON.parse(schema) as JSONSchema7}
                                 key={tabIndex}
                                 omitExtraData={true}
                                 liveOmit={true}
                                 noHtml5Validate={true}
                                 liveValidate={true}
                                 onChange={(formInput) => {
                                     onTabFormChange && onTabFormChange(formInput, tabId)
                                 }}
                                 formData={formInputData[tabId]}
                                 showErrorList={false}
                                 onSubmit={() => {
                                     onTabFormSubmit && onTabFormSubmit(tabId, tabIndex);
                                 }}
                                 onError={(err: AjvError[]) => {
                                     onTabFormError && onTabFormError(err, tabId);
                                 }}
                    >

                        <button key={tabIndex} id={`${tabId}Tab`} data-testid={`${tabId}Tab`} ref={el => {
                            if (ref?.current) {
                                ref.current[tabIndex] = el
                            }
                        }} type="submit"

                        >Submit
                        </button>
                    </Form>
                })
                }
            </Box>}
        </>
    )
})

